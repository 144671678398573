// @flow

import { motion, AnimatePresence } from 'framer-motion';
import { debounce } from 'lodash';
import { rem } from 'polished';
// $FlowFixMe
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import VisibilitySensor from 'react-visibility-sensor';
import styled from 'styled-components';

import Button from '../../atoms/Button';

import { ReactComponent as CloseSVG } from './close.svg';

//
// Styled components
// -------------------------------------------------------------------------------------------------

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${rem(8)};
`;

const AuxiliaryInfo = styled(motion.div)`
  align-items: center;
  color: ${(p) => p.theme.colors.lightGray};
  display: flex;
  height: ${rem(96)};
  justify-content: center;
`;

const Close = styled(CloseSVG)`
  height: ${rem(8)};
  width: ${rem(8)};
`;

const CloseButton = styled.button`
  align-items: center;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: flex;
  height: ${rem(16)};
  justify-content: center;
  opacity: 0.7;
  padding: 0;
  position: absolute;
  right: ${(p) => rem(p.theme.spacing.sm)};
  top: ${(p) => rem(p.theme.spacing.sm)};
  transition: opacity 250ms ${(p) => p.theme.timing.easeOutQuart};
  width: ${rem(16)};

  &:hover {
    opacity: 1;
  }
`;

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.ghostWhite};
  color: ${({ theme }) => theme.colors.lightSlateGray} !important;
  font-size: ${rem(12)};
  font-weight: auto;
  line-height: 150% !important;
  min-width: ${rem(250)};
  max-width: fit-content;
`;

const Footer = styled.footer`
  border-top: ${(p) => p.theme.colors.aliceBlue} solid ${rem(1)};
  line-height: ${rem(24)};
  padding: 0 ${rem(8)};
`;

const Header = styled.header`
  border-bottom: ${(p) => p.theme.colors.aliceBlue} solid ${rem(1)};
  color: ${(p) => p.theme.colors.onyx};
  font-weight: 700;
  line-height: ${rem(32)};
  padding: 0 ${rem(8)};
`;

const Notification = styled(motion.div)`
  background-color: ${(p) => p.theme.colors.white};
  border: ${(p) => p.theme.colors.glitter} solid ${rem(1)};
  border-radius: ${rem(4)};
  margin-top: ${rem(8)};
  padding: ${rem(8)};
  position: relative;
  user-select: none;

  &:last-of-type {
    margin-bottom: ${rem(8)};
  }
`;

const Notifications = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  max-height: ${rem(300)};
  overflow: hidden;
  position: relative;
`;

const Scroller = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 ${rem(8)};
`;

const Text = styled.div`
  margin-right: ${rem(16)};
  max-width: ${rem(240)};
`;

//
// Main component
// -------------------------------------------------------------------------------------------------

interface ActionData {
  payload?: string;
  i18nKey: string;
  type: 'DOWNLOAD';
}

interface NotificationData {
  actions: ActionData[];
  dismissedAt?: ?Date;
  i18nKey: string;
  id: string;
  readAt?: ?Date;
}

interface Props {
  data?: NotificationData[];
  loading?: boolean;
  onAction?: (action?: ActionData) => void;
  onDismiss?: (id: string | string[]) => void;
  onRead?: (id: string | string[]) => void;
}

export default function NotificationCenter({ data = [], loading = false, onAction, onDismiss, onRead }: Props) {
  const { t } = useTranslation('common');

  function handleDismissAll(event) {
    event.stopPropagation();
    if (onDismiss) onDismiss(data.map((d) => d.id));
  }

  return (
    <Container>
      <Header>{t('notifications')}</Header>

      <Notifications>
        <AnimatePresence initial={false}>
          {loading && (
            <AuxiliaryInfo
              animate={{ opacity: 1 }}
              exit={{ opacity: 0, transition: { duration: 0.1 } }}
              initial={{ opacity: 0 }}
              key="loading"
            >
              {t('loading')}
            </AuxiliaryInfo>
          )}

          {!loading && data.length === 0 && (
            <AuxiliaryInfo
              animate={{ opacity: 1 }}
              exit={{ opacity: 0, transition: { duration: 0.1 } }}
              initial={{ opacity: 0 }}
              key="no-data"
            >
              {t('noNotifications')}
            </AuxiliaryInfo>
          )}

          {!loading && (
            <Scroller>
              {data.map((notification) => (
                <VisibilitySensor key={notification.id}>
                  {({ isVisible }) => (
                    <NotificationRow
                      isVisible={isVisible}
                      notification={notification}
                      onAction={onAction}
                      onDismiss={onDismiss}
                      onRead={onRead}
                    />
                  )}
                </VisibilitySensor>
              ))}
            </Scroller>
          )}
        </AnimatePresence>
      </Notifications>

      <Footer>
        <Button clickableText="blue" onClick={handleDismissAll}>
          {t('dismissAll')}
        </Button>
      </Footer>
    </Container>
  );
}

//
// Private components
// -------------------------------------------------------------------------------------------------

interface NotificationRowProps {
  isVisible: Boolean;
  notification: NotificationData;
  onAction?: any;
  onDismiss?: any;
  onRead?: any;
}

function NotificationRow({ isVisible, notification, onAction, onDismiss, onRead }: NotificationRowProps) {
  const { t } = useTranslation('common');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleRead = useCallback(
    debounce((id) => onRead && onRead(id), 500, { leading: true }),
    [onRead]
  );

  useEffect(() => {
    if (!notification.readAt && isVisible) {
      handleRead(notification.id);
    }
  }, [isVisible, notification, handleRead]);

  function handleAction(action) {
    if (onAction) onAction(action);
  }

  function handleDismiss(id) {
    return (event) => {
      event.stopPropagation();
      if (onDismiss) onDismiss(id);
    };
  }

  return (
    <Notification
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.1 } }}
      initial={{ opacity: 0, scale: 0.3 }}
    >
      <CloseButton onClick={handleDismiss(notification.id)} title={t('close')}>
        <Close />
      </CloseButton>

      <Text>{t(notification.i18nKey)}</Text>

      <Actions>
        {notification.actions.map((action, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Button key={`${notification.id}-${i}`} noMargin onClick={() => handleAction(action)} small>
            {t(action.i18nKey)}
          </Button>
        ))}
      </Actions>
    </Notification>
  );
}
