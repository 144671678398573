// @flow

import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import TopBecRecipients from './TopBecRecipients';
import TopMaliciousRecipients from './TopMaliciousRecipients';

export default function MaliciousTargetsOverview() {
  const { t } = useTranslation('email');

  return (
    <>
      <Helmet title={`${t('targets')} :: ${t('common:email')} - ${t('common:area1security')}`} />
      <TopBecRecipients />
      <TopMaliciousRecipients />
    </>
  );
}
