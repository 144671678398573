"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useFeatureFlag = useFeatureFlag;

var _axios = _interopRequireDefault(require("axios"));

var _react = require("react");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// $FlowFixMe
//
// Main hook function
// -------------------------------------------------------------------------------------------------
function useFeatureFlag(name) {
  const [isPermitted, setIsPermitted] = (0, _react.useState)(initialStateForArgs(name));
  (0, _react.useEffect)(async () => {
    const url = `/api/features/${argsToParams(name)}`;

    try {
      const response = await _axios.default.get(url);
      setIsPermitted(response && response.data && response.data.allowed);
    } catch {
      setIsPermitted(false);
    }
  }, [JSON.stringify(isPermitted), JSON.stringify(name), setIsPermitted]);
  return isPermitted;
} //
// Private functions
// -------------------------------------------------------------------------------------------------


function argsToParams(args) {
  const params = Array.isArray(args) ? args.join(',') : args;
  return encodeURIComponent(params);
}

function initialStateForArgs(args) {
  if (!Array.isArray(args)) return false;
  return new Array(args.length).fill(false);
}