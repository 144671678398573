// @flow

import { rem } from 'polished';
import styled, { css } from 'styled-components';

const Container = styled.div`
  position: relative;

  ${({ placeholder, placeholderText }) =>
    placeholder &&
    css`
      & > * {
        opacity: 0.3;
        pointer-events: none;
      }

      &::after {
        color: ${({ theme }) => theme.colors.manatee};
        content: '${placeholderText}';
        font-family: ${({ theme }) => theme.fonts.roboto};
        font-size: ${rem(24)};
        font-weight: 500;
        left: 0;
        opacity: 1;
        position: absolute;
        text-align: center;
        top: 40%;
        width: 100%;
      }
    `};
`;

export default Container;
