// @flow

import Cookies from 'js-cookie';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Dropdown from 'ui/organisms/Dropdown';

type SelectTypes = 'en' | 'es' | 'ja' | 'pt' | 'zh';

const languageDictionary = {
  en: {
    name: 'English',
  },
  es: {
    name: 'Español',
  },
  ja: {
    name: '日本語', // Japanese
  },
  pt: {
    name: 'Português',
  },
  zh: {
    name: '简体中文', // Simple Chinese
  },
};

// Main Component
// -------------------------------------------------------------------------------------------------

interface Props {
  direction?: 'down' | 'up';
  languages?: any;
}

export default function LanguageSelector({ direction = 'up', languages = 'all' }: Props) {
  const { i18n, t } = useTranslation();

  const handleSelect = (selectedLanguage: SelectTypes) => {
    i18n.changeLanguage(selectedLanguage);
  };

  const optionsArr = Object.keys(languageDictionary).map((lang) => languageDictionary[lang].name);

  const handleLangugageSelection = (selected) => {
    Cookies.set('currentLanguage', selected);

    const languageAbbr =
      Object.keys(languageDictionary)
        // If languages is an array, only display the options specific in the array otherwise show all of them
        .filter((lng) => (Array.isArray(languages) ? languages.includes(lng) : true))
        .find((lng) => languageDictionary[lng].name === selected) || 'en';

    return handleSelect(languageAbbr);
  };

  return (
    <>
      <Dropdown
        dataTestId="language-dropdown"
        direction={direction}
        expanded={false}
        noMargin
        options={optionsArr}
        onOptionSelected={(selected) => handleLangugageSelection(selected)}
        renderAsOption={(l) => t(`common:${l}`)}
      >
        {Cookies.get('currentLanguage') || 'English'}
      </Dropdown>
    </>
  );
}
