// @flow

import { loader } from 'graphql.macro';
import { get } from 'lodash';
import qs from 'qs';
// $FlowFixMe
import React, { useState } from 'react';
import { useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import Button from 'ui/atoms/Button';
import Form from 'ui/atoms/Form';
import Field from 'ui/molecules/Field';
import TextField from 'ui/molecules/TextField';
import validations from 'utils/validations';

const resendReset = loader('./PostResendResetEmail.graphql');

export default function ForgotPasswordForm() {
  const location = useLocation();
  const [email, setEmail] = useState(get(location, 'state.email', ''));
  const [valid, setValid] = useState(false);
  const [urlParamsChecked, setUrlParamsChecked] = useState(false);

  const { t } = useTranslation('loginPage');

  const [resendResetPasswordEmail, { data, loading }] = useMutation(resendReset);

  const urlParams = qs.parse(location.search, { ignoreQueryPrefix: true });

  const decodeEmail = (userEmail) => decodeURIComponent(userEmail);

  if (!urlParamsChecked && urlParams.email) {
    setUrlParamsChecked(true);
    setEmail(decodeEmail(urlParams.email));
  }

  const handleChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setEmail(value);
    setValid(!(validations.within(4, 128, value.length) && validations.email(value)));
  };

  const handleSubmit = async (event: SyntheticInputEvent<HTMLInputElement>) => {
    event.preventDefault();
    await resendResetPasswordEmail({ variables: { input: { email: decodeEmail(email) } } });
  };

  const renderIcon = () => {
    if (loading) return 'spinner';
    if (data) return 'check';
    return '';
  };

  return (
    <Form onSubmit={handleSubmit}>
      <TextField label={t('emailLabel')} name="email" onChange={handleChange} value={decodeEmail(email)} />
      <Field>
        <Button disabled={!email || (email && valid)} icon={renderIcon()} type="submit">
          {t('sendEmail')}
        </Button>
      </Field>
    </Form>
  );
}
