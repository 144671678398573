// @flow

import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import ConditionalRender from 'ui/atoms/ConditionalRender';

import Info from 'ui/molecules/Info';
import PopupMenu, { Group, Option } from 'ui/molecules/PopupMenu';

const ReleaseMenuContainer = styled.div`
  align-items: center;
  display: flex;
`;

interface Props {
  clawbackPermitted: boolean;
  handleDetailsClick: Function;
  handleRedressActions: Function;
  hasRedressActions: boolean;
  onClawback: Function;
  onPreview: Function;
  row: {
    clientRecipients: string[],
    envelopeTo: string[],
    messageId: string,
    phishSubmission: boolean,
  };
}

export default function RowMenu({
  clawbackPermitted,
  handleDetailsClick,
  handleRedressActions,
  hasRedressActions,
  onClawback,
  onPreview,
  row,
}: Props) {
  const { t } = useTranslation('searchResults');

  const releaseRetractOptionDisabled = !row.clientRecipients.length;

  return (
    <>
      <PopupMenu dataTestId="row-menu">
        <Group>
          <Option data-testid="view-details" icon="list" onClick={handleDetailsClick}>
            {t('viewDetails')}
          </Option>
        </Group>

        <ConditionalRender condition={clawbackPermitted && !row.phishSubmission}>
          <Group>
            <ReleaseMenuContainer>
              <Option disabled={releaseRetractOptionDisabled} icon="preview" onClick={() => onPreview(row)}>
                {t('preview')}
              </Option>
              <ConditionalRender condition={releaseRetractOptionDisabled}>
                <Info tooltipPosition="left">{t('noneConfigured')}</Info>
              </ConditionalRender>
            </ReleaseMenuContainer>
          </Group>

          <Group>
            <ReleaseMenuContainer>
              <Option disabled={releaseRetractOptionDisabled} icon="mail-retract" onClick={() => onClawback(row)}>
                {t('retract')}
              </Option>
              <ConditionalRender condition={releaseRetractOptionDisabled}>
                <Info tooltipPosition="left">{t('noneConfigured')}</Info>
              </ConditionalRender>
            </ReleaseMenuContainer>
          </Group>
        </ConditionalRender>

        <ConditionalRender condition={hasRedressActions}>
          <Group>
            <Option icon="mail-redress-actions" onClick={handleRedressActions}>
              {t('redressActions')}
            </Option>
          </Group>
        </ConditionalRender>
      </PopupMenu>
    </>
  );
}
