// @flow

import { loader } from 'graphql.macro';
import hash from 'hash-sum';
import { get } from 'lodash';
import { rem, rgba } from 'polished';
// $FlowFixMe
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import PreviewOrRetractionErrorMessage from './PreviewOrRetractionErrorMessage';

import Button from 'ui/atoms/Button';
import ConditionalRender from 'ui/atoms/ConditionalRender';
import Loading from 'ui/atoms/Loading';
import Alert from 'ui/molecules/Alert';
import BlockHeader from 'ui/molecules/BlockHeader';
import NoData from 'ui/molecules/NoData';

const fetchPreviews = loader('./queries/previews.graphql');

const PreviewArea = styled.div`
  background-color: ${(p) => p.theme.colors.antiFlashWhite};
  border: ${(p) => p.theme.colors.tuscanBrown} solid 1px;
  border-radius: ${rem(4)};
  margin-top: ${(p) => rem(p.theme.spacing.lg)};
  max-height: calc(100vh - ${rem(196)});
  overflow: auto;
  padding: ${(p) => rem(p.theme.spacing.md)};
`;

const EmailView = styled.div`
  background-color: ${(p) => p.theme.colors.white};
  box-shadow: ${(p) => rgba(p.theme.colors.black, 0.1)} 0 ${rem(4)} ${rem(4)};
  font-family: ${(p) => p.theme.fonts.roboto};
  height: calc(100% - ${rem(132)});
  max-width: 100%;
  padding: ${(p) => rem(p.theme.spacing.md)};

  & + & {
    margin-top: ${(p) => rem(p.theme.spacing.md)};
  }

  hr {
    border: 0;
    border-top: ${(p) => p.theme.colors.tuscanBrown} solid 1px;
  }

  img {
    height: auto;
    max-width: 100%;
  }
`;

type Props = {
  handleRetract: Function,
  messages: Object,
  messageKeys: Set<string>,
  selectedKeysDispatch: Function,
};

export default function Preview({ handleRetract, messages, messageKeys, selectedKeysDispatch }: Props) {
  const [error, setError] = useState('');
  const [previewErrors, setPreviewErrors] = useState([]);

  const { t } = useTranslation('searchResults');

  const handlePreviewErrors = (previews) => {
    const errorMessages = previews.reduce(
      (acc, preview) => (preview.error ? [...acc, { status: preview.error, messageId: preview.messageId }] : acc),
      []
    );
    if (errorMessages.length) setPreviewErrors(errorMessages);
  };

  const [runFetchPreviews, { data, loading }] = useMutation(fetchPreviews, {
    onCompleted({ previews: previewData }) {
      handlePreviewErrors(previewData.previews);
    },
  });

  let requests = [];
  // reqMessages are the messages requested for preview corresponding to the key
  const reqMessages = [...messageKeys].map((key) => messages.find((message) => message.key === key));
  reqMessages.forEach((req) => {
    // one request for every valid message recipient
    req.clientRecipients.forEach((recipient) => {
      requests = [
        ...requests,
        {
          message_id: req.messageId,
          recipient,
        },
      ];
    });
  });

  const hashedRequests = hash(requests);

  useEffect(() => {
    const fetch = async () => {
      try {
        await runFetchPreviews({ variables: { input: { requests } } });
      } catch (runError) {
        // check Sentry for potential errors
        const { response, result } = runError.networkError;
        if (result?.previews) {
          handlePreviewErrors(result.previews);
        } else if (response?.statusText) {
          setError(response.statusText);
        } else setError('noDataError');
      }
    };

    fetch();
    // wants 'requests' and 'runFetchPreviews'. This causes an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hashedRequests, runFetchPreviews]);

  const previews = get(data, 'previews.previews', []);

  // validPreviewIds is the array of messageIds that can be retracted based on the previews response
  const validPreviewIds = previews.reduce(
    (acc, preview) => (!preview.preview.error ? [...acc, preview.messageId] : acc),
    []
  );

  // retractKeys are the message keys of messages with a valid requested preview and therefore retractable
  const retractKeys = reqMessages.reduce(
    (acc, req) => (validPreviewIds.includes(req.messageId) ? [...acc, req.key] : acc),
    []
  );

  const handleRetractClick = () => {
    selectedKeysDispatch({ payload: retractKeys, type: 'replace' });
    handleRetract();
  };

  return (
    <>
      <BlockHeader title={t('messagePreview')} />
      <Alert
        expanded
        onDismiss={() => setPreviewErrors([])}
        open={!!previewErrors.length}
        title={t('somePreviewErrors')}
        zebraStripe
      >
        {previewErrors.map((err) => (
          <PreviewOrRetractionErrorMessage error={err} key={err} />
        ))}
      </Alert>

      <ConditionalRender condition={loading || ((!previews.length || error) && !loading)}>
        {loading ? <Loading /> : <NoData>{t('noPreviewData')}</NoData>}
      </ConditionalRender>

      <ConditionalRender condition={!!previews.length && !loading && !error}>
        <>
          <Button icon="mail-retract" onClick={handleRetractClick}>
            {t('retract')} ({validPreviewIds.length})
          </Button>
          <PreviewArea>
            {previews.map(({ preview }) => (
              <ConditionalRender condition={!preview.error}>
                <EmailView
                  dangerouslySetInnerHTML={{ __html: preview.html }}
                  data-testid="preview-section"
                  key={hash(preview)}
                />
              </ConditionalRender>
            ))}
          </PreviewArea>
        </>
      </ConditionalRender>
    </>
  );
}
