import React from 'react';

import { DetailMetadata } from '..';
import { HighlightDataType as HighlightData, SearchResultRow } from '../../types';

//
// Main component
// -------------------------------------------------------------------------------------------------

interface EmailDetailsProps {
  loading?: boolean;
  mailviewHighlightData?: HighlightData;
  searchInfo?: SearchResultRow;
}

export function EmailDetails({ loading = false, mailviewHighlightData, searchInfo }: EmailDetailsProps) {
  return (
    <DetailMetadata isBenign={false} loading={!!loading} mailviewData={mailviewHighlightData} searchInfo={searchInfo} />
  );
}
