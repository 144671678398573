"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledCluster = void 0;

var _styled = require("../../styled");

//
// Styled components
// -------------------------------------------------------------------------------------------------
const StyledCluster = (0, _styled.styled)('div', {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  variants: {
    /**
     * Aligns the children of the `Cluster` in a vertical direction within itself.
     * @type "baseline" | "center" | "end" | "start" | "stretch"
     */
    align: {
      baseline: {
        alignItems: 'baseline'
      },
      center: {
        alignItems: 'center'
      },
      end: {
        alignItems: 'flex-end'
      },
      start: {
        alignItems: 'flex-start'
      },
      stretch: {
        alignItems: 'stretch'
      }
    },

    /**
     * The amount of horizontal space to insert between the child components.
     * @type $space
     */
    gap: {
      true: {
        gap: '$3'
      },
      none: {
        gap: '$none'
      },
      px: {
        gap: '$px'
      },
      0.5: {
        gap: '$0.5'
      },
      1: {
        gap: '$1'
      },
      1.5: {
        gap: '$1.5'
      },
      2: {
        gap: '$2'
      },
      2.5: {
        gap: '$2.5'
      },
      3: {
        gap: '$3'
      },
      3.5: {
        gap: '$3.5'
      },
      4: {
        gap: '$4'
      },
      5: {
        gap: '$5'
      },
      6: {
        gap: '$6'
      },
      7: {
        gap: '$7'
      },
      8: {
        gap: '$8'
      },
      9: {
        gap: '$9'
      },
      10: {
        gap: '$10'
      },
      11: {
        gap: '$11'
      },
      12: {
        gap: '$12'
      }
    },

    /**
     * Aligns the children horizontally, and the space/gap will be honored regardless of wrapping.
     * @type "center" | "end" | "space-around" | "space-between" | "space-evenly" | "start" | "stretch"
     */
    justify: {
      center: {
        justifyContent: 'center'
      },
      end: {
        justifyContent: 'flex-end'
      },
      'space-around': {
        justifyContent: 'space-around'
      },
      'space-between': {
        justifyContent: 'space-between'
      },
      'space-evenly': {
        justifyContent: 'space-evenly'
      },
      start: {
        justifyContent: 'flex-start'
      },
      stretch: {
        justifyContent: 'stretch',
        '& > *': {
          flexGrow: 1
        }
      }
    },

    /**
     * Tells the cluster not let it's children wrap.
     */
    nowrap: {
      true: {
        flexWrap: 'nowrap'
      }
    }
  }
});
exports.StyledCluster = StyledCluster;