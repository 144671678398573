// @flow

import { toQueryString } from '@a1s/lib';
import { endOfDay, formatISO, isAfter, startOfDay, subDays } from 'date-fns';
import Cookies from 'js-cookie';
import { defer, get, isFunction } from 'lodash';
import { rem, rgba } from 'polished';

// $FlowFixMe
import React, { useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';

// $FlowFixMe
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useSpring } from 'react-spring';
import styled, { css } from 'styled-components';

import DetectionSearchResults from '../DetectionSearchResults';
import MailTraceResults from '../MailTraceResults';

import { useSearchQuery } from './hooks';
import MultiSearch, { type SearchParams, type SearchType } from './MultiSearch';
import Notifications from './Notifications';
import Suggestions from './Suggestions';

import { DaysBackContext, GlobalSearchContext } from 'screens/App';
// $FlowFixMe
import { INITIAL_STATE as INITIAL_SEARCH_STATE } from 'screens/Search';
// $FlowFixMe
import { DurationDropdown } from 'ui-new';
import AccountabilityLink from 'ui/atoms/AccountabilityLink';
import Button from 'ui/atoms/Button';
import Centered from 'ui/atoms/Centered';
import ConditionalRender from 'ui/atoms/ConditionalRender';
import Loading from 'ui/atoms/Loading';
import Logo from 'ui/atoms/Logo';
import Overlay from 'ui/atoms/Overlay';
import PaddedContent from 'ui/atoms/PaddedContent';
import SearchPlaceholder from 'ui/atoms/SearchPlaceholder';
import SettingsLink from 'ui/atoms/SettingsLink';
import SupportLink from 'ui/atoms/SupportLink';
import UserLink from 'ui/atoms/UserLink';
import AccessChecker, { permissionTypes } from 'ui/molecules/AccessChecker';
import Alert from 'ui/molecules/Alert';
import HeaderOptions from 'ui/molecules/HeaderOptions';
import MainNavigation from 'ui/molecules/MainNavigation';
import NoData from 'ui/molecules/NoData';
// $FlowFixMe
import { useDurationContext } from 'utils/duration';
import useAccessControl, { type PermissionsType } from 'utils/hooks/useAccessControl';
import { decodeValue, utf8ToBase64 } from 'utils/util';

//
// Constants
// -------------------------------------------------------------------------------------------------

/* Permissions Constants */
const {
  ADMIN,
  CONFIG_ADMIN,
  DELEGATED_ROLES_ENABLED,
  MAILSEARCH_ENABLED,
  READ_WRITE,
  RESUME_PARENT_ENABLED,
  SETTINGS_READ_ONLY,
} = permissionTypes;

//
// Styled components
// -------------------------------------------------------------------------------------------------

const Center = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: flex-start;
  margin: 0 ${({ theme }) => rem(theme.spacing.md)};
`;

const Container = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: center;
  min-height: ${rem(56)};
  position: relative;
  z-index: 11;

  ${(p) =>
    p.hasShadow &&
    css`
      box-shadow: ${rgba(p.theme.colors.jet, 0.1)} 0 ${rem(2)} ${rem(4)};
    `};
`;

const Navigation = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: flex-start;
  margin-left: ${({ theme }) => rem(theme.spacing.md)};
  position: relative;

  & > :nth-child(1) {
    flex-grow: 1;
  }

  & > :nth-child(2) {
    margin-right: ${rem(24)};
  }

  & > :nth-child(3) {
    width: ${rem(184)};
  }

  & > :nth-child(5) {
    margin-left: ${rem(24)};
    width: ${rem(144)};
  }
`;

const SearchToggleButton = styled(Button)`
  font-size: ${rem(12)};
  text-transform: uppercase;
`;

const SearchToggleContainer = styled.div`
  align-items: center;
  background-color: cornsilk;
  display: flex;
  font-size: ${rem(12)};
  justify-content: left;
  margin-top: ${rem(5)};
  max-width: ${rem(520)};
  max-width: fit-content;
  padding: 5px;
  position: relative;
  z-index: 11;

  & > p {
    padding: 8px;
    text-transform: uppercase;
    width: ${rem(320)};
  }
`;

const Search = styled.div`
  background-color: ${(p) => p.theme.colors.white};
  height: ${rem(38)};
  margin-right: ${(p) => rem(p.theme.spacing.md)};
  margin-top: ${rem(1)};
  position: relative;
  transition: left 150ms ${(p) => p.theme.timing.easeOutcirc}, width 150ms ${(p) => p.theme.timing.easeOutcirc};
  white-space: nowrap;
  will-change: left, width;
  z-index: 100;

  ${(p) =>
    p.isExpanded &&
    css`
      left: 0;
      margin-right: 0;
      position: absolute;
      right: 0;
      width: 100% !important;
    `};
`;

//
// Main component
// -------------------------------------------------------------------------------------------------

export default function Header() {
  const { t } = useTranslation('home');

  const { loading: accessControlLoading, permissions } = useAccessControl([ADMIN, READ_WRITE]);

  const { currentInterval } = useContext(DaysBackContext);
  const { setGlobalSearchConnector } = useContext(GlobalSearchContext);

  const history = useHistory();
  const location = useLocation();

  const [alertMessage, setAlertMessage] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [hasData, setHasData] = useState(true);

  // Keep track of the state of the `MultiSearch` component.
  const dateRange = [startOfDay(subDays(new Date(), currentInterval)), endOfDay(new Date())];
  const [state, dispatch] = useReducer(reducer, initialState(dateRange));

  const [run, { called, data, fetchMore, loading, variables }] = useSearchQuery(state.searchType);

  const calculateDateRangeError = useMemo(
    () => (params) => {
      const { dateRange: dateScope } = params;
      let message = '';

      if (dateScope) {
        const start = new Date(dateScope[0]);
        const end = new Date(dateScope[1]);

        if (isAfter(start, end)) {
          message = t('endBeforeStart');
        }
      }

      return message;
    },
    [t]
  );

  const headerStyle = useSpring({
    config: { tension: 1200, friction: 80 },
    opacity: state.searchExpanded ? 0 : 1,
  });

  const onAlertDismiss = () => {
    setDialogOpen(false);
    setAlertMessage('');
  };

  // This needs to be a callback or else the component will get into a re-render loop.
  const handleSubmit = useCallback(
    async (params: SearchParams, searchType: SearchType = state.searchType) => {
      dispatch({ type: 'HIDE_SUGGESTIONS' });

      const range = Array.isArray(params.dateRange) ? { end: params.dateRange[1], start: params.dateRange[0] } : {};

      const incorrectDateRange = calculateDateRangeError(params);
      if (incorrectDateRange) {
        setDialogOpen(true);
        setAlertMessage(incorrectDateRange);
        return;
      }

      if (searchType === 'detectionSearch') {
        const { query } = params;
        try {
          await run({
            variables: { limit: 50, offset: 0, search: query ? utf8ToBase64(query) : '', ...range },
            searchType,
          });
          // We're watching search input `blur` events to hide suggestions (line 239), but this is also causing the PopupMenu used for row
          // actions to close immediately after opening - the line below is to get around that.
          // $FlowFixMe
          document.activeElement?.blur();
        } catch (error) {
          if (error?.networkError?.statusCode === 400 || error?.networkError?.statusCode === 523) {
            setHasData(false);
          } else {
            // check Sentry for potential errors
          }
        }
      } else if (searchType === 'mailTrace') {
        try {
          await run({ variables: { ...params, ...range }, searchType });
        } catch (error) {
          if (error?.networkError?.statusCode === 400) {
            setHasData(false);
          } else {
            // check Sentry for potential errors
          }
        }
      }
    },

    [calculateDateRangeError, run, state.searchType]
  );

  const dateRangeDependency = JSON.stringify(dateRange);
  // This gets passed to the search context and then is fired whenever a component uses the `useGlobalSearch` hook.
  const triggerSearch = useCallback(
    (triggeredSearchType: SearchType, params: SearchParams) => {
      if (Cookies.get('currentSearch') === 'unified') {
        const searchType = triggeredSearchType === 'detectionSearch' ? 'detection-only' : 'all-mail';
        setClosePath(location.pathname);

        if (searchType === 'all-mail') {
          history.push(
            `/beta/search?${toQueryString({
              ...INITIAL_SEARCH_STATE,
              ...{
                alertId: params?.alertId,
                daysBack: getIsoDates(params.dateRange, dateRange),
                domain: params?.domain,
                messageId: params?.messageId,
                recipient: params.recipient,
                searchTerm: params.query,
                searchType,
                sender: params?.sender,
                subject: params?.subject,
              },
            })}`
          );
        } else {
          const query =
            params.query && params.query.includes('final_disposition')
              ? {
                  daysBack: getIsoDates(params.dateRange, dateRange),
                  finalDisposition: params.query && params.query.substring(18),
                }
              : { searchTerm: params.query, searchType };

          history.push(`/beta/search?${toQueryString({ ...INITIAL_SEARCH_STATE, ...query })}`);
        }
      } else {
        dispatch({
          payload: { params, searchType: triggeredSearchType, ...{ q: params.query } },
          type: 'TRIGGER_SEARCH',
        });
        defer(() => handleSubmit(params, triggeredSearchType));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dateRangeDependency, handleSubmit, history, location]
  );

  useEffect(() => {
    if (isFunction(setGlobalSearchConnector)) setGlobalSearchConnector(() => triggerSearch);
  }, [setGlobalSearchConnector, triggerSearch]);

  function handleLogout() {
    window.location = '/logout';
  }

  function handleBlur() {
    window.setTimeout(() => dispatch({ type: 'HIDE_SUGGESTIONS' }), 250);
  }

  function handleClose() {
    dispatch({ payload: dateRange, type: 'COLLAPSE_SEARCH' });
  }

  function handleFocus(key) {
    if (key === 'query') {
      dispatch({ type: 'SHOW_SUGGESTIONS' });
    }
  }

  function handleLogoClick() {
    dispatch({ payload: dateRange, type: 'COLLAPSE_SEARCH' });
  }

  function handleOverlayClick() {
    const { showSuggestions } = state;
    if (showSuggestions) dispatch({ type: 'HIDE_SUGGESTIONS' });
  }

  function handleSuggestionSelect(suggestion) {
    const updated = { ...state.params, query: suggestion };
    dispatch({ payload: updated, type: 'UPDATE_SEARCH_PARAMS' });
    handleSubmit(updated);
  }

  const handleTryNewSearchPress = (event) => {
    // $FlowFixMe
    event.stopPropagation();
    Cookies.set('currentSearch', 'unified');
    Cookies.set('searchLaunchedFrom', document.location.pathname);
    ReactGA.event({
      category: 'Search preference set',
      action: 'Unified search selected',
      label: 'user selected Unified search',
    });
    dispatch({ payload: dateRange, type: 'COLLAPSE_SEARCH' });
    const currentSearchType = state.searchType === 'detectionSearch' ? 'detection-only' : 'all-mail';

    history.push(
      `/beta/search?${toQueryString({
        ...INITIAL_SEARCH_STATE,
        ...{
          alertId: state.params?.alertId,
          dateRange: state.params?.dateRange,
          domain: state.params?.domain,
          messageId: state.params?.messageId,
          recipient: state.params.recipient,
          searchTerm: state.params.query || '',
          searchType: currentSearchType,
          sender: state.params?.sender,
          subject: state.params?.subject,
        },
      })}`
    );
  };

  function handlePlaceholderClick(e) {
    if (Cookies.get('currentSearch') === 'unified') {
      handleTryNewSearchPress(e);
    } else {
      dispatch({ type: 'EXPAND_SEARCH' });
    }
  }

  function handleSearchTypeChange(searchType) {
    dispatch({ payload: searchType, type: 'CHANGE_SEARCH_TYPE' });
  }

  function handleSearchParamsChange(params, key) {
    dispatch({ payload: params, type: 'UPDATE_SEARCH_PARAMS' });
    // Run the search again if we've already ran it and its just the date range that has changed
    if (called && key === 'dateRange') defer(() => handleSubmit(params));
  }

  return (
    <>
      <Container hasShadow={location.pathname === '/user'}>
        <Center>
          <Logo onClick={handleLogoClick} />

          <Navigation>
            <MainNavigation style={headerStyle} />

            <DashboardDateRange />
            <AccessChecker allowed={MAILSEARCH_ENABLED} renderNoAccess={false}>
              <AccessChecker denied={DELEGATED_ROLES_ENABLED} renderNoAccess={false}>
                <Search data-testid="main-navigation-search" isExpanded={state.searchExpanded}>
                  <ConditionalRender condition={!state.searchExpanded}>
                    <SearchPlaceholder onClick={handlePlaceholderClick} />
                  </ConditionalRender>

                  <ConditionalRender condition={state.searchExpanded}>
                    <>
                      <MultiSearch
                        onBlur={handleBlur}
                        onClose={handleClose}
                        onFocus={handleFocus}
                        onSearchParamsChange={handleSearchParamsChange}
                        onSearchTypeChange={handleSearchTypeChange}
                        onSubmit={handleSubmit}
                        searchParams={state.params}
                        searchType={state.searchType}
                      />
                      <ConditionalRender condition={state.searchExpanded}>
                        <SearchToggleContainer>
                          <p>{t('newSearchDescription')}</p>
                          <SearchToggleButton primary onClick={handleTryNewSearchPress}>
                            {t('newSearchButton')}
                          </SearchToggleButton>
                        </SearchToggleContainer>
                      </ConditionalRender>
                      <ConditionalRender condition={state.searchType === 'detectionSearch'}>
                        <Suggestions
                          onSelect={handleSuggestionSelect}
                          query={state.params.query}
                          shouldShow={state.suggestionsVisible}
                        />
                      </ConditionalRender>
                    </>
                  </ConditionalRender>
                </Search>
              </AccessChecker>
            </AccessChecker>

            <HeaderOptions style={headerStyle}>
              <AccessChecker denied={DELEGATED_ROLES_ENABLED} renderNoAccess={false}>
                <AccountabilityLink />
              </AccessChecker>
              <AccessChecker
                allowed={[ADMIN, CONFIG_ADMIN, SETTINGS_READ_ONLY, DELEGATED_ROLES_ENABLED]}
                canAccess={canAccessSettings}
                renderNoAccess={false}
              >
                <SettingsLink />
              </AccessChecker>
              <SupportLink />
              <Notifications />
              <AccessChecker denied={RESUME_PARENT_ENABLED} renderNoAccess={false}>
                <UserLink
                  onClickLogout={handleLogout}
                  onClickProfile={(e) => {
                    e.preventDefault();
                    history.push('/user');
                  }}
                />
              </AccessChecker>
            </HeaderOptions>
          </Navigation>
        </Center>
      </Container>

      <Overlay data-testid="search-overlay" hidden={!state.searchExpanded} onClick={handleOverlayClick}>
        {() => {
          if (!called) return null;
          if (!hasData) return <DataNone term="" />;
          if (loading) return <DataLoading />;

          const results = get(data, 'results', []);
          if (results.length === 0) return <DataNone term={get(variables, 'search')} />;

          return (
            <PaddedContent pushTop="lg">
              <Results
                accessControlLoading={accessControlLoading}
                fetchMore={fetchMore}
                handleSuggestionSelect={handleSuggestionSelect}
                permissions={permissions}
                results={results}
                searchType={state.searchType}
              />
            </PaddedContent>
          );
        }}
      </Overlay>

      <ConditionalRender condition={!!alertMessage.length && dialogOpen}>
        <Alert message={alertMessage} onDismiss={onAlertDismiss} open={dialogOpen} />
      </ConditionalRender>
    </>
  );
}

//
// Private components
// -------------------------------------------------------------------------------------------------

/**
 * Displays a centered loading animation. Should be shown when the GraphQL query is in its
 * _loading_ state.
 */
function DataLoading() {
  return (
    <Centered>
      <Loading />
    </Centered>
  );
}

/**
 * Displays a centered message that there are no results. Should be shown when the GraphQL
 * query returns back an _empty_ data set.
 */
function DataNone({ term }: { term?: string }) {
  const { t } = useTranslation('components');
  const decodedTerm = decodeValue(term);

  return (
    <Centered>
      <ConditionalRender
        condition={!decodedTerm || decodedTerm === ''}
        fallback={<NoData>{t('NoData.noResultsSearch', { term: decodedTerm })}</NoData>}
      >
        <NoData message="noResults" />
      </ConditionalRender>
    </Centered>
  );
}

type ResultsProps = {
  accessControlLoading: boolean,
  fetchMore: Function,
  handleSuggestionSelect: Function,
  permissions: PermissionsType,
  results: any,
  searchType: string,
};

function Results({
  accessControlLoading,
  fetchMore,
  handleSuggestionSelect,
  permissions,
  results,
  searchType,
}: ResultsProps) {
  // $FlowFixMe
  const [adminPermitted, readWritePermitted] = permissions;
  const rowActionsPermitted = adminPermitted || readWritePermitted;

  if (searchType === 'detectionSearch') {
    return <DetectionSearchResults fetchMore={fetchMore} results={results} searchEmailPart={handleSuggestionSelect} />;
  }

  if (searchType === 'mailTrace') {
    return (
      <MailTraceResults
        accessControlLoading={accessControlLoading}
        results={results}
        rowActionsPermitted={rowActionsPermitted}
      />
    );
  }

  return null;
}

//
// Private components
// -------------------------------------------------------------------------------------------------

function DashboardDateRange() {
  const location = useLocation();
  const [duration, setDuration] = useDurationContext();

  if (location.pathname === '/home') return <DurationDropdown onChange={setDuration} value={duration} />;

  // We need to return back a DOM element since some of the CSS is nth-child based.
  return <div />;
}

//
// Private functions
// -------------------------------------------------------------------------------------------------

function canAccessSettings(permissions: boolean[]): boolean {
  return permissions.includes(true); // At least one permission is true
}

function getIsoDates(dateParams, dateScope) {
  return [
    formatISO(dateParams?.[0] || dateScope[0], { representation: 'date' }),
    formatISO(dateParams?.[1] || dateScope[1], { representation: 'date' }),
  ];
}

/* State Constants */

function initialState(dateRange) {
  return {
    params: { dateRange },
    // FIXME: Don't start expanded
    searchExpanded: false,
    searchType: 'detectionSearch',
    suggestionsVisible: false,
  };
}

function reducer(state, action) {
  switch (action.type) {
    case 'CHANGE_SEARCH_TYPE': {
      if (action.payload === 'detectionSearch') {
        return {
          ...state,
          params: {
            dateRange: new Date(),
            messageId: '',
            recipient: '',
            sender: '',
            subject: '',
          },
          searchType: 'detectionSearch',
        };
      }

      if (action.payload === 'mailTrace') {
        return {
          ...state,
          searchType: 'mailTrace',
        };
      }

      throw new Error(`[REDUCER] Unknown searchType "${action.payload}"`);
    }

    case 'COLLAPSE_SEARCH':
      return { ...state, ...initialState(action.payload) };
    case 'EXPAND_SEARCH':
      return { ...state, searchExpanded: true };
    case 'HIDE_SUGGESTIONS':
      return { ...state, suggestionsVisible: false };
    case 'SHOW_SUGGESTIONS':
      return { ...state, suggestionsVisible: true };

    case 'TRIGGER_SEARCH':
      return { ...state, params: action.payload.params, searchExpanded: true, searchType: action.payload.searchType };

    case 'UPDATE_SEARCH_PARAMS':
      return { ...state, params: action.payload, suggestionsVisible: true };

    default:
      throw new Error(`[REDUCER] Unknown action type "${action.type}"`);
  }
}

function setClosePath(path) {
  // $FlowFixMe
  document.body.dataset.closePath = path;
}

// eslint-disable-next-line no-unused-vars
function SearchButton() {
  const { permissions } = useAccessControl([ADMIN, CONFIG_ADMIN, READ_WRITE]);

  const history = useHistory();
  const location = useLocation();

  const [adminPermitted, configAdminPermitted, readWritePermitted] = permissions;
  if (!(adminPermitted || configAdminPermitted || readWritePermitted)) return null;

  function handleClick() {
    setClosePath(location.pathname);

    history.push({
      pathname: `/search`,
      state: {
        background: location,
      },
    });
  }

  return <SearchPlaceholder onClick={handleClick} />;
}
