import { css, CSS } from '@a1s/ui';
import React, { PropsWithChildren } from 'react';

import { StyledArea, StyledGrid } from './styled';

//
// Main component
// -------------------------------------------------------------------------------------------------

export interface NamedGridProps {
  /**
   * A breakpoint-scoped object of strings specifing CSS track sizing functions of the grid columns. For a complete list of
   * tracking functions, see the CSS documentation: https://developer.mozilla.org/en-US/docs/Web/CSS/grid-template-columns
   */
  columns?: Record<string, CSS['gridTemplateColumns']>;

  /**
   * Set the spacing between the child components of the grid.
   */
  gap?: CSS['gridGap'];

  /**
   * A breakpoint-scoped object of strings specifing named `NamedGrid.Area` components, establishing the cells in the
   * grid and assigning them names.
   */
  layout: Record<string, CSS['gridTemplateAreas']>;

  /**
   * A breakpoint-scoped object of strings specifing CSS track sizing functions of the grid rows. For a complete list of
   * tracking functions, see the CSS documentation: https://developer.mozilla.org/en-US/docs/Web/CSS/grid-template-rows
   */
  rows?: Record<string, CSS['gridTemplateRows']>;
}

/**
 * Experimental layout component for laying out child elements at specific places within a grid.
 *
 * @alpha
 */
export function NamedGrid({ children, columns, gap, layout, rows }: PropsWithChildren<NamedGridProps>) {
  const columnStyles = mapToCSS('gridTemplateColumns', columns);
  const layoutStyles = css(Object.entries(layout).reduce((a, [b, c]) => ({ ...a, [b]: { gridTemplateAreas: c } }), {}));
  const propStyles = css({ gridGap: gap });
  const rowStyles = mapToCSS('gridTemplateRows', rows);

  const className = `${columnStyles()} ${layoutStyles()} ${propStyles()} ${rowStyles()}`;
  return <StyledGrid className={className}>{children}</StyledGrid>;
}

//
// Sub-components
// -------------------------------------------------------------------------------------------------

NamedGrid.Area = Area;

export interface NamedGridArea {
  /**
   * Forces its components to grow it use all available space within the grid.
   */
  grow?: true;

  /**
   * Specifies the name of the grid area.
   */
  name: CSS['gridArea'];
}

/**
 * Sets up a named area with in a `NamedGrid` component.
 *
 * @alpha
 */
function Area({ children, grow, name }: PropsWithChildren<NamedGridArea>) {
  const propStyles = css({ gridArea: name });
  return (
    <StyledArea className={propStyles()} data-area={name} grow={grow}>
      {children}
    </StyledArea>
  );
}

//
// Private functions
// -------------------------------------------------------------------------------------------------

type StylesToMap = NamedGridProps['columns'] | NamedGridProps['rows'];

function mapToCSS(property: 'gridTemplateColumns' | 'gridTemplateRows', styles?: StylesToMap) {
  return css(Object.entries(styles || {}).reduce((a, [b, c]) => ({ ...a, [b]: { [property]: c } }), {}));
}
