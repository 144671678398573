// @flow

import { rem, rgba } from 'polished';
// $FlowFixMe
import React, { useEffect, type Node } from 'react';
import { animated, useTransition } from 'react-spring';
import styled, { css } from 'styled-components';

import { ReactComponent as CloseSVG } from './close.svg';
import Modal from './Modal';

//
// Styled components
// -------------------------------------------------------------------------------------------------

const Close = styled(CloseSVG)`
  cursor: pointer;
  height: ${rem(16)};
  position: absolute;
  right: ${(p) => rem(p.theme.spacing.lg)};
  transition: opacity 250ms ${(p) => p.theme.timing.easeOutQuart};
  width: ${rem(16)};

  &:hover {
    opacity: 0.7;
  }
`;

const AnimatedContainer = styled(animated.div).attrs({ 'aria-modal': true, role: 'dialog' })`
  background-color: ${(p) => p.theme.colors.white};
  bottom: 0;
  box-shadow: ${(p) => rgba(p.theme.colors.raisinBlack, 0.08)} ${rem(-4)} 0 ${rem(8)};
  overflow-y: auto;
  padding: ${(p) => rem(p.theme.spacing.lg)};
  position: fixed;
  right: 0;
  top: 0;
  max-width: ${rem(640)};
  transition: transform 300ms ${(p) => p.theme.timing.easeOutCirc};
  width: 100%;
  z-index: 999;

  ${({ closed }) =>
    closed &&
    css`
      transform: translateX(100%);
    `};

  ${({ size }) =>
    size === 'large' &&
    css`
      max-width: ${rem(960)};
    `};

  ${({ size }) =>
    size === 'full' &&
    css`
      max-width: 100%;
    `};
`;

const Container = styled(({ paddedSides, ...rest }) => <AnimatedContainer {...rest} />)`
  ${({ paddedSides }) =>
    !paddedSides &&
    css`
      padding-left: 0;
      padding-right: 0;
    `};
`;
//
// Main component
// -------------------------------------------------------------------------------------------------

type Props = {
  children?: Node | (({ closed: boolean }) => Node),
  closed?: boolean,
  dataTestId?: string,
  onClose?: (SyntheticEvent<HTMLDivElement>) => void,
  paddedSides?: boolean,
  size?: 'full' | 'normal' | 'large',
};

function Drawer({
  children,
  closed = false,
  dataTestId = 'molecule-drawer',
  onClose,
  paddedSides = true,
  size = 'normal',
}: Props) {
  const transitions = useTransition(!closed, null, {
    config: { tension: 500, friction: 40, mass: 1 },
    from: { transform: 'translateX(100%)' },
    enter: { transform: 'translateX(0)' },
    leave: { transform: 'translateX(100%)' },
  });

  useEffect(() => {
    function handleEscKeyDown(event: SyntheticKeyboardEvent<*>) {
      if (closed || !onClose) return;
      if (event.key === 'Escape') {
        event.preventDefault();
        event.stopPropagation();
        onClose(event);
      }
    }

    window.addEventListener('keydown', handleEscKeyDown);
    return () => window.removeEventListener('keydown', handleEscKeyDown);
  });

  useEffect(() => {
    if (document.body) {
      // If the overlay component is is open, don't mess with the scrollbar since it also hides it.
      if (!document.body.dataset.isOverlayOpen) {
        document.body.style.overflow = closed ? 'auto scroll' : ' hidden';
      }
    }
  }, [closed]);

  function handleKeyDown(event: SyntheticKeyboardEvent<HTMLDivElement>) {
    if (!onClose) return;

    const { key } = event;
    if (key === 'Enter' || key === 'Escape' || key === 'Spacebar') onClose(event);
  }

  // Other components need a simple way to know if the drawer is open or not so we add a `data-` attribute to
  // the body tag that the other components can check.
  useEffect(() => {
    // $FlowFixMe: For some reason, Flow isn't reading checks for document.body is not null
    if (document.body) document.body.dataset.drawerClosed = closed.toString();
  }, [closed]);

  return transitions.map(
    ({ item, key, props: style }) =>
      item && (
        <Container
          closed={closed.toString()}
          data-testid={dataTestId}
          key={key}
          paddedSides={paddedSides ? 'true' : undefined}
          size={size}
          style={style}
        >
          <Close aria-label="Close" onClick={onClose} onKeyDown={handleKeyDown} tabIndex={0} />
          {children && typeof children === 'function' ? children({ closed }) : children}
        </Container>
      )
  );
}

Drawer.Modal = Modal;

export default Drawer;
