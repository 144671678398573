"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _DonutChart = require("./DonutChart");

Object.keys(_DonutChart).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _DonutChart[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _DonutChart[key];
    }
  });
});

var _LineGraph = require("./LineGraph");

Object.keys(_LineGraph).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _LineGraph[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _LineGraph[key];
    }
  });
});

var _HorizontalBars = require("./HorizontalBars");

Object.keys(_HorizontalBars).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _HorizontalBars[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _HorizontalBars[key];
    }
  });
});

var _SegmentChart = require("./SegmentChart");

Object.keys(_SegmentChart).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _SegmentChart[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _SegmentChart[key];
    }
  });
});

var _StackedBarChart = require("./StackedBarChart");

Object.keys(_StackedBarChart).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _StackedBarChart[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _StackedBarChart[key];
    }
  });
});