import { rem } from 'polished';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Label, type SearchComponentProps } from '.';

import Input from 'ui/atoms/Input';
import Link from 'ui/atoms/Link';
import PaddedContent from 'ui/atoms/PaddedContent';
import DateRangePicker from 'ui/molecules/DateRangePicker';
import Info from 'ui/molecules/Info';

//
// Styled components
// -------------------------------------------------------------------------------------------------

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  order: 3;
`;

const Divider = styled.span.attrs({ role: 'presentation' })`
  background: ${({ theme }) => theme.colors.azureishWhite};
  display: block;
  height: ${rem(24)};
  margin-right: ${({ theme }) => rem(theme.spacing.md)};
  pointer-events: none;
  width: ${rem(1)};
`;

const SearchInput = styled(Input)`
  flex-grow: 1;
`;

//
// Main component
// -------------------------------------------------------------------------------------------------

export default function DetectionSearch(props: SearchComponentProps) {
  const { t } = useTranslation('components');

  const { onBlur, onFocus, onSearchParamsChange, onSubmit, searchParams } = props;

  function handleBlur(key: string) {
    return () => onBlur && onBlur(key);
  }

  function handleDateRangePickerChange(dateRange: [Date, Date]) {
    if (onSearchParamsChange && Array.isArray(dateRange)) {
      onSearchParamsChange({ ...searchParams, dateRange }, 'dateRange');
    }
  }

  function handleFocus(key: string) {
    return () => onFocus && onFocus(key);
  }

  function handleKeyDown(event) {
    if (event.key === 'Enter' && !!onSubmit) onSubmit(searchParams);
  }

  function handleSearchInputChange({ currentTarget }) {
    if (onSearchParamsChange) {
      onSearchParamsChange({ ...searchParams, query: currentTarget.value }, 'query');
    }
  }

  return (
    <Container>
      <SearchInput
        autocomplete="off"
        autoFocus
        onBlur={handleBlur('query')}
        onFocus={handleFocus('query')}
        onChange={handleSearchInputChange}
        onKeyDown={handleKeyDown}
        value={searchParams.query}
      />
      <PaddedContent pushRight="md" pushTop="xs">
        <Info tooltipPosition="bottom">
          <Trans i18nKey="">
            <>
              <p>
                <Link
                  external
                  to="https://area1security.zendesk.com/hc/en-us/articles/360025517934"
                  rel="nofollow"
                  target="_blank"
                >
                  {t('common:seeDocumentation')}
                </Link>
              </p>
            </>
          </Trans>
        </Info>
      </PaddedContent>
      <Divider />
      <Label>{t('GlobalSearch.dateRange')}</Label>
      <DateRangePicker
        onChange={handleDateRangePickerChange}
        onBlur={handleBlur('dateRange')}
        onFocus={handleFocus('dateRange')}
        onKeyDown={handleKeyDown}
        value={searchParams.dateRange}
      />
    </Container>
  );
}
