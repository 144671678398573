"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledChildContainer = exports.StyledCaption = exports.StyledBase = exports.StyledBarContainer = exports.StyledBar = void 0;

var _styled = require("../../styled");

//
// Styled components
// -------------------------------------------------------------------------------------------------
const StyledBar = (0, _styled.styled)('div', {
  borderRadius: '$1',
  minHeight: 25,
  position: 'absolute',
  top: 0,
  variants: {
    bordered: {
      true: {
        borderColor: '$white',
        borderRadius: '$1',
        borderStyle: 'solid',
        borderWidth: 2
      }
    }
  }
});
exports.StyledBar = StyledBar;
const StyledBarContainer = (0, _styled.styled)('div', {
  position: 'relative',
  '&:has(a:hover) > div:last-of-type': {
    backgroundColor: 'rgba(108, 144, 161, 0.3) !important'
  }
});
exports.StyledBarContainer = StyledBarContainer;
const StyledBase = (0, _styled.styled)('div', {
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  '& > *': {
    flexGrow: 1
  }
});
exports.StyledBase = StyledBase;
const StyledCaption = (0, _styled.styled)('data', {
  display: 'inline-block',
  whiteSpace: 'nowrap'
});
exports.StyledCaption = StyledCaption;
const StyledChildContainer = (0, _styled.styled)('div', {
  padding: '0 $1',
  position: 'relative',
  // Apply z-index so the text sits on top of the absolute positioned StyledBar
  zIndex: 1
});
exports.StyledChildContainer = StyledChildContainer;