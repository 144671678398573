"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledText = void 0;

var _styled = require("../../styled");

var _utils = require("../../utils");

//
// Styled components
// -------------------------------------------------------------------------------------------------
const StyledText = (0, _styled.styled)('span', {
  variants: {
    /**
     * Sets the font family of the text.
     * @type $fonts
     */
    font: (0, _utils.tokenVariant)('fontFamily', _styled.theme.fonts),

    /**
     * Keeps the text from wrapping to the next line and cuts if off with an ellipsis
     */
    nowrap: {
      true: {
        maxInlineSize: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        '&::after': {
          content: ' ',
          display: 'block',
          height: 0,
          lineHeight: 0,
          width: 0
        }
      }
    },

    /**
     * Sets the font-variant-numeric CSS prop
     * @type "tabular"
     */
    nums: {
      tabular: {
        fontVariantNumeric: 'tabular-nums'
      }
    },

    /**
     * Sets the stretch (i.e., condensed, normal, or expanded) of the text.
     * @type "ultraCondensed" | "extraCondensed" | "condensed" | "semiCondensed" | "normal" | "semiExpanded" | "expanded" | "extraExpanded"
     */
    stretch: {
      ultraCondensed: {
        fontStretch: '50%'
      },
      extraCondensed: {
        fontStretch: '62.5%'
      },
      condensed: {
        fontStretch: '75%'
      },
      semiCondensed: {
        fontStretch: '87.5%'
      },
      normal: {
        fontStretch: '100%'
      },
      semiExpanded: {
        fontStretch: '112.5%'
      },
      expanded: {
        fontStretch: '125%'
      },
      extraExpanded: {
        fontStretch: '150%'
      }
    },

    /**
     * Sets the size of the text.
     * @type $fontSizes
     */
    size: (0, _utils.tokenVariant)('fontSize', _styled.theme.fontSizes),

    /**
     * Sets the style (i.e., italic or normal) of the text.
     * @type "italic" | "normal"
     */
    style: {
      italic: {
        fontStyle: 'italic'
      },
      normal: {
        fontStyle: 'normal'
      }
    },

    /**
     * Changes the tracking (i.e. kerning) of the text.
     * @type $letterSpacings
     */
    tracking: (0, _utils.tokenVariant)('letterSpacing', _styled.theme.letterSpacings),

    /**
     * Transforms the text to be in all capital letters.
     * @type "lowercase" | "uppercase"
     */
    transform: {
      lowercase: {
        textTransform: 'lowercase'
      },
      none: {
        textTransform: 'none'
      },
      uppercase: {
        textTransform: 'uppercase'
      }
    },

    /**
     * Sets the font weight of the text.
     * @type $fontWeights
     */
    weight: (0, _utils.tokenVariant)('fontWeight', _styled.theme.fontWeights)
  }
});
exports.StyledText = StyledText;