"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useNetworkStatus = useNetworkStatus;

var _react = require("react");

/**
 * Returns the `true` if the user's browser is online and `false` if it is offline and updates the component state if
 * the network status changes
 */
function useNetworkStatus() {
  const [isOnline, setIsOnline] = (0, _react.useState)(navigator.onLine);
  (0, _react.useEffect)(() => {
    function handleNetworkStatusChange() {
      setIsOnline(navigator.onLine);
    }

    window.addEventListener('online', handleNetworkStatusChange);
    window.addEventListener('offline', handleNetworkStatusChange);
    return () => {
      window.removeEventListener('online', handleNetworkStatusChange);
      window.removeEventListener('offline', handleNetworkStatusChange);
    };
  }, [setIsOnline]);
  return isOnline;
}