"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Label = exports.Input = exports.Container = void 0;

var _styled = require("../../styled");

//
// Styled components
// -------------------------------------------------------------------------------------------------
const Container = (0, _styled.styled)('span', {
  display: 'inline',
  '& + &': {
    marginLeft: '1rem'
  },
  variants: {
    block: {
      true: {
        display: 'block',
        '& + &': {
          marginLeft: '0px'
        }
      }
    }
  }
});
exports.Container = Container;
const Input = (0, _styled.styled)('input', {
  display: 'inlineBlock'
});
exports.Input = Input;
const Label = (0, _styled.styled)('label', {
  color: '$gray600',
  fontSize: '0.875rem',
  marginLeft: '0.5rem',
  marginRight: '1rem'
});
exports.Label = Label;