// @flow

import React from 'react';

import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import FirstCard from './FirstCard';
import SecondCard from './SecondCard';

export default function AdminQuarantine() {
  const { t } = useTranslation('common');

  return (
    <>
      <Helmet title={`${t('email')} :: ${t('email:adminQuarantine')} - ${t('common:area1security')}`} />
      <FirstCard />
      <SecondCard />
    </>
  );
}
