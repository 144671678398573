// @flow

import React from 'react';
import { useTranslation } from 'react-i18next';

import PaddedContent from 'ui/atoms/PaddedContent';

type RetractionErrorMessageType = {
  error: {
    messageId: string,
    status: string,
  },
};

export default function PreviewOrRetractionErrorMessage({ error }: RetractionErrorMessageType) {
  const { t } = useTranslation('common');

  return (
    <PaddedContent style={{ textAlign: 'left' }}>
      <dl>
        <dt>
          <b>{t('messageId')}</b>
        </dt>
        <dd>{error.messageId}</dd>
        <dt>
          <b>{t('reason')}</b>
        </dt>
        <dd>{error.status}</dd>
      </dl>
    </PaddedContent>
  );
}
