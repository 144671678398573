// @flow

import React from 'react';
import { useTranslation } from 'react-i18next';

import { mungeLineGraphData } from '../dataTypesAndUtils';

import type { SuspiciousSenderCountsByDate, PreviousSuspiciousSenderCountsByDate } from './dataTypesAndUtils';

import PaddedContent from 'ui/atoms/PaddedContent';
import BlockHeader from 'ui/molecules/BlockHeader';
import ContentBlock from 'ui/molecules/ContentBlock';
import PlaceholderLineGraph from 'ui/molecules/PlaceholderLineGraph';
import RingChart from 'ui/molecules/RingChart';
import DataView from 'ui/organisms/DataView';
import SectionOverview from 'ui/organisms/SectionOverview';
import Split from 'ui/templates/Split';
import withDataHandler from 'utils/withDataHandler';

type DataFromProps = {
  lineGraph: {
    current: SuspiciousSenderCountsByDate[],
    previous: PreviousSuspiciousSenderCountsByDate[],
  },
  ringChart: number,
  sectionOverview: {
    current: number,
    previous: number,
  },
};

type Props = {
  data: DataFromProps,
  error: boolean,
  loading: boolean,
};

const DataViewWithDataHandler = withDataHandler({
  empty: <PlaceholderLineGraph />,
})(DataView);

export default function SuspiciousSenders({ data, error, loading }: Props) {
  const { lineGraph, ringChart, sectionOverview } = data;
  const { t } = useTranslation('email');

  return (
    <ContentBlock
      dataTestId="suspicious-senders-component"
      top={
        <>
          <BlockHeader pullBottom title={t('suspiciousSenders')} />
          <Split>
            <SectionOverview
              dataTestId="suspicious-senders-statistic"
              currentValue={sectionOverview.current}
              dimmed={loading}
              formatValue="number"
              previousValue={sectionOverview.previous}
            />
            <RingChart formatValue="number" label={t('totalSuspiciousEmails')} percent={ringChart} />
          </Split>
          <PaddedContent pushTop="xl">
            <DataViewWithDataHandler
              data={{
                '': {
                  current: mungeLineGraphData(lineGraph.current),
                  previous: mungeLineGraphData(lineGraph.previous),
                },
              }}
              isEmpty={() => loading || error || lineGraph.current.length === 0}
              isLoading={loading}
              previousData={mungeLineGraphData(lineGraph.previous)}
            />
          </PaddedContent>
        </>
      }
    />
  );
}
