"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.trimImage = trimImage;

//
// Typescript types
// -------------------------------------------------------------------------------------------------
//
// Main function
// -------------------------------------------------------------------------------------------------

/**
 * Trims the whitespace from the image at the given URI.
 *
 * @param uri - The URL of the image to trim _**Note:** can be a data URI._
 * @returns Returns a Promise that resolves to the trimmed image data.
 */
function trimImage(uri) {
  if (typeof Image !== 'function') return Promise.reject('This function requires a browser environment to run');
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onerror = reject;

    image.onload = () => {
      // Set up the canvas element to the exact same size of the image
      // TODO: look into using the new `OffscreenCanvas` API (https://developer.mozilla.org/en-US/docs/Web/API/OffscreenCanvas)
      const canvas = document.createElement('canvas');
      canvas.height = image.height;
      canvas.width = image.width; // Make sure that we're set up to draw onto our newly created canvas before moving on

      const context = canvas.getContext('2d');
      if (!context) return reject(new Error(`Could get canvas context for image: ${uri}`)); // Draw the image, filling up the entire canvas with the image

      context.drawImage(image, 0, 0);
      const pixels = context === null || context === void 0 ? void 0 : context.getImageData(0, 0, canvas.width, canvas.height);
      if (!pixels) return reject(new Error(`Could not get the pixel data from the image: ${uri}`)); // Find the color in the top-left pixel of the image. Is is returned as an array of of four
      // `Uint8` values, representing the _red_, _green_, _blue_, and _alpha_ (i.e. rgba) channels
      // respectively.

      const topLeftPixel = pixels.data.slice(0, 4); // Keep track of how many pixels we need to trim off of each side of the image.

      const bounds = {
        bottom: null,
        left: null,
        right: null,
        top: null
      }; // Keep track of our corrindates as we traverse across the image data.

      let x = null;
      let y = null; // Move four "spaces" at time, again, because the pixel data is in groups of rgba values.

      for (let i = 0; i < pixels.data.length; i += 4) {
        // Load in the current color of the current pixel
        const pixel = pixels.data.slice(i, i + 4); // See if the current pixel's color is not equal to the color at the top-left pixel,
        // make its current position as one that need to *not* be trimmed off.

        if (!equalColor(pixel, topLeftPixel)) {
          x = i / 4 % canvas.width;
          y = ~~(i / 4 / canvas.width);
          if (bounds.top === null) bounds.top = y;
          if (bounds.left === null) bounds.left = x;else if (x < bounds.left) bounds.left = x;
          if (bounds.right === null) bounds.right = x;else if (bounds.right < x) bounds.right = x;
          if (bounds.bottom === null) bounds.bottom = y;else if (bounds.bottom < y) bounds.bottom = y;
        }
      } // Calculate the coordinates and dimensions of area of the image that we need to keep


      const sh = bounds.bottom && bounds.top ? bounds.bottom - bounds.top + 3 : 0;
      const sw = bounds.right && bounds.left ? bounds.right - bounds.left + 2 : 0;
      const sx = bounds.left || 0;
      const sy = bounds.top ? bounds.top - 1 : 0;

      try {
        // Extract the image data from the coordinates that we calculated above
        const trimmed = context.getImageData(sx, sy, sw, sh); // Return the image data as data URI, along with the image dimensions

        resolve({
          data: dataURI(trimmed),
          height: trimmed.height,
          width: trimmed.width
        });
      } catch (error) {
        console.error('[ERROR]');
        console.error(error);
        reject();
      } finally {
        canvas.remove();
      }
    }; // Initialize the loading of the image by setting the source of the image object.


    image.src = uri;
  });
} //
// Private functions
// -------------------------------------------------------------------------------------------------

/**
 * Encodes the given image data as base64-encoded PNG data URI.
 *
 * @param data ImageData -
 * @returns string
 */


function dataURI(data) {
  var _canvas$getContext;

  const canvas = document.createElement('canvas');
  canvas.height = data.height;
  canvas.width = data.width;
  (_canvas$getContext = canvas.getContext('2d')) === null || _canvas$getContext === void 0 ? void 0 : _canvas$getContext.putImageData(data, 0, 0);
  return canvas.toDataURL();
}
/**
 * True if all RGBA channels are the same for both of the given colors.
 *
 * @param rgba1 Uint8ClampedArray - The first RGBA value to compare
 * @param rgba2 Uint8ClampedArray - The second RGBA value to compare
 * @returns boolean
 */


function equalColor([r1, g1, b1, a1], [r2, g2, b2, a2]) {
  return r1 === r2 && g1 === g2 && b1 === b2 && a1 === a2;
}