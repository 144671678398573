import { Box, Cluster, LineGraph, LineGraphProps, Loadable, PercentageChange, Stack, Text } from '@a1s/ui';
import { ApolloError } from 'apollo-client';
import axios from 'axios';
import { subSeconds } from 'date-fns';
import { format } from 'date-fns-tz';
import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-apollo';

import { useTranslation } from 'react-i18next';
import useDimensions from 'react-use-dimensions';

import { FIFTEEN_MINUTE_POLL_INTERVAL } from '../../lib';

import { ChartWrapper, LiveMode, TiltedDivider } from './styled';

import { LargeNumber } from 'ui-new';
import ConditionalRender from 'ui/atoms/ConditionalRender';
import { Duration } from 'utils/duration';

//
// Main component
// -------------------------------------------------------------------------------------------------

interface MiddleProps {
  /**
   * The duration in which set data, set by the dropdown in at the top of `SystemStatsPanel`.
   */
  duration: Duration;

  /**
   * Whether "live" data should be fetched from the API or not, set by the toggle at the top of `SystemStatsPanel`.
   */
  isLiveMode: boolean;
}

export default function Middle({ duration, isLiveMode }: MiddleProps) {
  return (
    <Box pl pr testId="system-stats-box-middle">
      <Cluster align="end" gap justify="space-between">
        <ConditionalRender condition={!isLiveMode} fallback={<LiveData />}>
          <DurationData duration={duration} />
        </ConditionalRender>
      </Cluster>
    </Box>
  );
}

//
// Private components
// -------------------------------------------------------------------------------------------------

interface ChartProps {
  curve?: LineGraphProps['curve'];
  edgeOffsets: LineGraphProps['edgeOffsets'];
  data?: APIData['chartData'];
  tooltipShowTime?: boolean;
  width?: string;
}

function Chart({ curve = 'rounded', edgeOffsets, data, tooltipShowTime = false, width = '300' }: ChartProps) {
  return (
    <Box bg="$gray100" r testId="system-stats-line-graph">
      <LineGraph.Loadable
        curve={curve}
        data={data}
        edgeOffsets={edgeOffsets}
        height="160"
        width={width}
        tooltipShowTime={tooltipShowTime}
      />
    </Box>
  );
}

/**
 * Component responsible for displaying clock that updates every second during Live Mode view.
 */
function LiveModeClock() {
  const time = useClock();

  return (
    <Box p="1" css={{ backgroundColor: '#00C2D8', position: 'absolute', right: 6, top: 6 }} r="1">
      <Text color="$white" size="xs" weight="normal">
        <Cluster gap="1">
          <LiveMode /> <Text weight="bold">{format(time, 'HH:mm:ss')}</Text> {format(time, 'z')}
        </Cluster>
      </Text>
    </Box>
  );
}

interface DurationDataProps {
  duration: Duration;
}

function DurationData({ duration }: DurationDataProps) {
  const [baseRef, { width: chartWidth }] = useDimensions();
  const { data, loading } = useRemoteData(duration, false);

  return (
    <Loadable loading={loading}>
      <ProcessedStats
        attacksPrevented={data?.statsSummaryData?.attacksPrevented}
        emailsProcessed={data?.statsSummaryData?.emailsProcessed}
      />
      <ChartWrapper ref={baseRef}>
        <Chart
          curve="rounded"
          data={data?.chartData}
          edgeOffsets={{ left: -5, right: -5 }}
          tooltipShowTime={false}
          width={String(chartWidth)}
        />
      </ChartWrapper>
    </Loadable>
  );
}

function LiveData() {
  const [baseRef, { width: chartWidth }] = useDimensions();
  const { data, loading } = useLiveData('30', true);

  // I derived these numbers by resizing my browser window and tweaking these numbers until the result in the UI looked consistent.
  const edgeOffsets = chartWidth >= 350 ? -17.5 : -12.5;

  return (
    <Loadable loading={loading}>
      <ProcessedStats
        attacksPrevented={data?.statsSummaryData?.attacksPrevented}
        emailsProcessed={data?.statsSummaryData?.emailsProcessed}
        showLiveMode
      />
      <ChartWrapper ref={baseRef}>
        <Stack css={{ position: 'relative' }}>
          <LiveModeClock />
          <Chart
            curve="sharp"
            data={data?.chartData}
            edgeOffsets={{ left: edgeOffsets, right: edgeOffsets }}
            tooltipShowTime
            width={String(chartWidth)}
          />
        </Stack>
      </ChartWrapper>
    </Loadable>
  );
}

interface ProcessedStatsProps {
  attacksPrevented?: ChangeData;
  emailsProcessed?: ChangeData;
  showLiveMode?: boolean;
}

function ProcessedStats({ attacksPrevented, emailsProcessed, showLiveMode = false }: ProcessedStatsProps) {
  const { t } = useTranslation('dashboardHome');
  const showPercentagesEmail = !!(emailsProcessed?.current && emailsProcessed?.previous);
  const showPercentagesAttacks = !!(attacksPrevented?.current && attacksPrevented?.previous);

  return (
    <Cluster justify="space-between" testId="system-stats-processed-stats">
      <Stack gap>
        <Cluster align="baseline" gap="2">
          <Text color="$gray600" font="sans" size="xs" stretch="ultraCondensed" transform="uppercase" weight="medium">
            {t('emailProcessed')}
          </Text>

          {showLiveMode && <LiveMode css={{ fill: '$gray600' }} />}

          <ConditionalRender condition={showPercentagesEmail}>
            <PercentageChange.Calculate.Loadable
              color="gray400"
              current={emailsProcessed?.current}
              previous={emailsProcessed?.previous}
            />
          </ConditionalRender>
        </Cluster>

        <LargeNumber value={emailsProcessed?.current} />
      </Stack>

      <TiltedDivider />

      <Stack gap>
        <Cluster align="baseline" gap="2">
          <Text color="$pink500" font="sans" size="xs" stretch="ultraCondensed" transform="uppercase" weight="medium">
            {t('preventedAttacks')}
          </Text>

          {showLiveMode && <LiveMode css={{ fill: '$pink500' }} />}

          <ConditionalRender condition={showPercentagesAttacks}>
            <PercentageChange.Calculate.Loadable
              color="gray400"
              current={attacksPrevented?.current}
              previous={attacksPrevented?.previous}
            />
          </ConditionalRender>
        </Cluster>

        <LargeNumber color="$pink500" value={attacksPrevented?.current} />
      </Stack>
    </Cluster>
  );
}

//
// Private hooks
// -------------------------------------------------------------------------------------------------

/**
 * This hook returns the current datetime (UTC) at 1 second intervals.
 * The return value is a datetime object that can be formatted with utility functions like date-fns
 */
function useClock(): Date {
  const [time, setTime] = useState<Date>(new Date());

  useEffect(() => {
    let timer: number = 0;

    const updateClock = () => {
      const now = new Date();
      setTime(now);
    };

    timer = window.setInterval(() => updateClock(), 1000);

    // Make sure the interval gets cleared on teardown
    return () => {
      window.clearInterval(timer);
      setTime(new Date());
    };
  }, []);

  return time;
}

//
// Data fetching
// -------------------------------------------------------------------------------------------------

/**
 * Each tick on the X axis of the chart is a day with a value.
 */
type ChartData = Array<{ date: string; value: number }>;

/**
 * Change data two _totals_ returned from the API, one for the current `Duration`, and one for the previous one.
 */
interface ChangeData {
  current: number;
  previous: number;
}

/**
 * Represents the summary data that is displayed next to the line chart.
 */
interface SummaryData {
  attacksPrevented: ChangeData;
  emailsProcessed: ChangeData;
}

export interface APIData {
  /**
   * The data that is displayed on the line graph
   */
  chartData: [ChartData, ChartData];

  /**
   * The_summary_ data that is displayed next to the line graph.
   */
  statsSummaryData: SummaryData;
}

interface HookResult {
  /**
   * The data that has been returned from the API
   */
  data: APIData | null;

  /**
   * If there is a problem loading the data, the error information will be available as an error object
   */
  error: ApolloError | null;

  /**
   * Returns true if the data is currently being loaded
   */
  loading: boolean;
}

/**
 * Setting for how often the `useLiveData` hook polls for data.
 */
const LIVE_POLL_INTERVAL: number = 5000; // 5 seconds

/**
 * Configures the max number of plotted points on the Live Mode line graph.
 */
const LIVE_GRAPH_RESPONSE_LIMIT: number = 12; // Last 60 seconds

interface MailcounterProps {
  date: string;
  malicious: number;
  // eslint-disable-next-line camelcase
  malicious_bec?: number;
  spoof: number;
  suspicious: number;
  total: number;
}

// initial state for the Live Mode line graph. This presents a line across the graph from the beginning.
/* eslint-disable camelcase */
const initialLiveState = [
  { date: subSeconds(new Date(), 60).toString(), malicious: 0, malicious_bec: 0, spoof: 0, suspicious: 0, total: 0 },
  { date: subSeconds(new Date(), 55).toString(), malicious: 0, malicious_bec: 0, spoof: 0, suspicious: 0, total: 0 },
  { date: subSeconds(new Date(), 50).toString(), malicious: 0, malicious_bec: 0, spoof: 0, suspicious: 0, total: 0 },
  { date: subSeconds(new Date(), 45).toString(), malicious: 0, malicious_bec: 0, spoof: 0, suspicious: 0, total: 0 },
  { date: subSeconds(new Date(), 40).toString(), malicious: 0, malicious_bec: 0, spoof: 0, suspicious: 0, total: 0 },
  { date: subSeconds(new Date(), 35).toString(), malicious: 0, malicious_bec: 0, spoof: 0, suspicious: 0, total: 0 },
  { date: subSeconds(new Date(), 30).toString(), malicious: 0, malicious_bec: 0, spoof: 0, suspicious: 0, total: 0 },
  { date: subSeconds(new Date(), 25).toString(), malicious: 0, malicious_bec: 0, spoof: 0, suspicious: 0, total: 0 },
  { date: subSeconds(new Date(), 20).toString(), malicious: 0, malicious_bec: 0, spoof: 0, suspicious: 0, total: 0 },
  { date: subSeconds(new Date(), 15).toString(), malicious: 0, malicious_bec: 0, spoof: 0, suspicious: 0, total: 0 },
  { date: subSeconds(new Date(), 10).toString(), malicious: 0, malicious_bec: 0, spoof: 0, suspicious: 0, total: 0 },
  { date: subSeconds(new Date(), 5).toString(), malicious: 0, malicious_bec: 0, spoof: 0, suspicious: 0, total: 0 },
];
/* eslint-enable camelcase */

interface TotalsData {
  attacksPrevented: number;
  emailsProcessed: number;
}

/**
 * Private hook that encapsulates the LIVE data loading and caching for the `SystemStatsPanel`.
 */
function useLiveData(duration: Duration = '30', isLiveMode: boolean): HookResult {
  const [graphData, setGraphData] = useState<MailcounterProps[]>(initialLiveState);
  const [totalsData, setTotalsData] = useState<TotalsData>({ attacksPrevented: 0, emailsProcessed: 0 });

  useEffect(() => {
    // We'll store the previous response here so we can diff the incoming API values.
    let previousResponse: MailcounterProps;
    // timer variable for our setTimeout that will poll the mailcounter endpoint.
    let timer: number = 0;

    const diffNumbers = (current: number, previous: number): number => {
      const difference = current - previous;
      // Ocassionaly, the mailcounter API "corrects" itself and will send a number lower than the previous response
      // (eg: previous total emails processed was 139 and after correcting it's 127).
      // If this happens we just want to return 0 so users aren't confused by decrementing numbers.
      if (difference < 0) return 0;
      return difference;
    };

    if (isLiveMode) {
      const fetchData = async () => {
        try {
          const {
            data: { summary },
          } = await axios.get(`/api/mailcounter/overview/${duration}`);

          if (!previousResponse) {
            previousResponse = summary;
          }

          setTotalsData((t: TotalsData) => {
            const {
              malicious,
              // Not all customers have Malicious BEC enabled
              malicious_bec: maliciousBec = 0,
              spoof,
              suspicious,
              total,
            } = summary;

            const {
              malicious: maliciousPrev = malicious,
              malicious_bec: maliciousBecPrev = maliciousBec,
              spoof: spoofPrev = spoof,
              suspicious: suspiciousPrev = suspicious,
              total: totalPrev = total,
            } = previousResponse;

            const totalAttacks: number =
              diffNumbers(malicious, maliciousPrev) +
              diffNumbers(maliciousBec, maliciousBecPrev) +
              diffNumbers(spoof, spoofPrev) +
              diffNumbers(suspicious, suspiciousPrev);

            const totalEmailsProcessed: number = diffNumbers(total, totalPrev);

            return {
              attacksPrevented: t.attacksPrevented + totalAttacks,
              emailsProcessed: t.emailsProcessed + totalEmailsProcessed,
            };
          });

          setGraphData((responses) => {
            const {
              malicious,
              // Not all customers have Malicious BEC enabled
              malicious_bec: maliciousBec = 0,
              spoof,
              suspicious,
              total,
            } = summary;

            const {
              malicious: maliciousPrev = malicious,
              malicious_bec: maliciousBecPrev = maliciousBec,
              spoof: spoofPrev = spoof,
              suspicious: suspiciousPrev = suspicious,
              total: totalPrev = total,
            } = previousResponse;

            const diffedSummary = {
              malicious: diffNumbers(malicious, maliciousPrev),
              malicious_bec: diffNumbers(maliciousBec, maliciousBecPrev),
              spoof: diffNumbers(spoof, spoofPrev),
              suspicious: diffNumbers(suspicious, suspiciousPrev),
              total: diffNumbers(total, totalPrev),
            };

            // Now update `previousResponse` with this response
            previousResponse = summary;

            return [
              ...responses.slice(-1 * (LIVE_GRAPH_RESPONSE_LIMIT - 1)),
              ...[{ ...diffedSummary, date: new Date().toString() }],
            ];
          });
        } catch (error) {
          window.clearInterval(timer);
          setGraphData([]);
        }
      };

      timer = window.setInterval(() => fetchData(), LIVE_POLL_INTERVAL);

      fetchData();
    }

    // Make sure the interval gets cleared on teardown
    return () => {
      window.clearInterval(timer);
      setGraphData([]);
    };
  }, [duration, isLiveMode]);

  const chartLineOne = graphData.map((r: MailcounterProps) => ({
    date: r.date,
    value: r.total,
  }));

  const chartLineTwo = graphData.map((r: MailcounterProps) => ({
    date: r.date,
    value: r.malicious + (r.malicious_bec || 0) + r.spoof + r.suspicious,
  }));

  const { attacksPrevented: totalAttacksPrevented, emailsProcessed: totalEmailsProcessed } = totalsData;

  const attacksPrevented = {
    current: totalAttacksPrevented,
    previous: 0,
  };

  const emailsProcessed = { current: totalEmailsProcessed, previous: 0 };

  return {
    data: { chartData: [chartLineOne, chartLineTwo], statsSummaryData: { attacksPrevented, emailsProcessed } },
    error: null,
    loading: false,
  };
}

export const query = loader('./loadSystemStats.graphql');

/**
 * Private hook that encapsulates loading the non-live data for the `SystemStatsPanel`.
 * Poll the endpoint every 15 minutes.
 */
function useRemoteData(duration: Duration = '30', isLiveMode: boolean = false): HookResult {
  const { data, error, loading } = useQuery(query, {
    pollInterval: FIFTEEN_MINUTE_POLL_INTERVAL,
    variables: { duration },
    skip: isLiveMode,
  });

  if (loading) return { data: null, error: null, loading: true };
  if (error) return { data: null, error, loading: false };

  if (!data?.insightsSystemStats?.data) return { data: null, error: null, loading: false };

  const chartLineOne = data?.insightsSystemStats?.data?.chartByDay?.map(
    (d: { day: string; emailsProcessed: number }) => ({ date: d.day, value: d.emailsProcessed })
  );

  const chartLineTwo = data?.insightsSystemStats?.data?.chartByDay?.map(
    (d: { day: string; attacksPrevented: number }) => ({ date: d.day, value: d.attacksPrevented })
  );

  const attacksPrevented: ChangeData = {
    current: data?.insightsSystemStats?.data.totalAttacksPrevented,
    previous: data?.insightsSystemStats?.data.totalAttacksPreventedPrevious,
  };

  const emailsProcessed: ChangeData = {
    current: data?.insightsSystemStats?.data.totalEmailsProcessed,
    previous: data?.insightsSystemStats?.data.totalEmailsProcessedPrevious,
  };

  return {
    data: { chartData: [chartLineOne, chartLineTwo], statsSummaryData: { attacksPrevented, emailsProcessed } },
    error: null,
    loading: false,
  };
}
