"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.pulse = void 0;

var _styled = require("../styled");

/**
 * An animation that fades in and out.
 */
const pulse = (0, _styled.keyframes)({
  '0%': {
    opacity: 1
  },
  '66%': {
    opacity: 0.5
  },
  '100%': {
    opacity: 1
  }
});
exports.pulse = pulse;