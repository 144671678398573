// @flow

import React from 'react';

import { useTranslation } from 'react-i18next';

import Modal from './Modal';

import PaddedContent from 'ui/atoms/PaddedContent';
import Radio from 'ui/atoms/Radio';
import Subtitle from 'ui/atoms/Subtitle';
import TextStyle from 'ui/atoms/TextStyle';

import AlertDialog from 'ui/molecules/AlertDialog';

type Props = {
  alertOpen?: boolean,
  buttonText: number,
  destination: string,
  handleRetractConfirm: Function,
  loading?: boolean,
  onActionCancel: Function,
  setDestination: Function,
};

function RetractDialog({
  alertOpen = false,
  buttonText,
  destination,
  handleRetractConfirm,
  loading = false,
  onActionCancel,
  setDestination,
}: Props) {
  const { t } = useTranslation('components');

  return (
    <AlertDialog
      busy={loading}
      buttonText={<span>{t('MailTraceResults.retract', { count: buttonText })}</span>}
      message={
        <>
          <Subtitle>{t('MailTraceResults.selectDestination')}</Subtitle>
          <PaddedContent pushBottom="lg" pushTop="md" style={{ textAlign: 'left' }}>
            {['Inbox', 'JunkEmail', 'DeletedItems', 'RecoverableItemsDeletions', 'RecoverableItemsPurges'].map(
              (folderName) => (
                <Radio
                  key={folderName}
                  checked={folderName === destination}
                  label={t(`MailTraceResults.${folderName}`)}
                  onChange={() => setDestination(folderName)}
                  value={folderName}
                />
              )
            )}
          </PaddedContent>
          <TextStyle warning>{t('MailTraceResults.warning')}</TextStyle>
        </>
      }
      open={alertOpen}
      onActionCancel={onActionCancel}
      onActionConfirm={handleRetractConfirm}
      zebraStripe
    />
  );
}

RetractDialog.Modal = Modal;

export default RetractDialog;
