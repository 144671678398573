// @flow

import { usePrevious } from '@a1s/hooks';

import { loader } from 'graphql.macro';
import { get, isNil, omitBy } from 'lodash';
import qs from 'qs';
// $FlowFixMe
import { useCallback, useEffect, useReducer } from 'react';
import { useApolloClient } from 'react-apollo';

const DetectionSearch = loader('./queries/DetectionSearch.graphql');
const MailTrace = loader('./queries/MailTrace.graphql');

//
// Constant
// -------------------------------------------------------------------------------------------------

const INITIAL_STATE = { called: false, data: null, loading: false, variables: {} };

//
// Main hook
// -------------------------------------------------------------------------------------------------

export function useSearchQuery(searchType: string) {
  const client = useApolloClient();
  const prevSearchType = usePrevious(searchType);
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  useEffect(() => {
    dispatch({ type: 'RESET_STATE' });
  }, [searchType]);

  const fetchMore = useCallback(
    async ({ updateQuery, ...options }: any) => {
      const query = searchType === 'detectionSearch' ? DetectionSearch : MailTrace;

      const oldVars = get(state, 'variables', {});
      const newVars = get(options, 'variables', {});
      const variables = { ...oldVars, ...newVars };

      const result = await client.query({
        fetchPolicy: 'network-only',
        ...options,
        query,
        variables: { ...variables, pathBuilder },
      });
      const data = updateQuery(state.data, { fetchMoreResult: result.data });

      dispatch({ payload: { data, variables }, type: 'RECEIVE_DATA' });
    },
    [client, searchType, state]
  );

  const exec = useCallback(
    async (options: any = {}) => {
      dispatch({ type: 'LOAD_DATA' });
      const query = (options?.searchType || searchType) === 'detectionSearch' ? DetectionSearch : MailTrace;
      const variables = get(options, 'variables', {});
      const result = await client.query({
        fetchPolicy: 'network-only',
        ...options,
        query,
        variables: { ...variables, pathBuilder },
      });

      dispatch({ payload: { data: result.data, variables }, type: 'RECEIVE_DATA' });
    },
    [client, searchType]
  );

  if (prevSearchType !== searchType) return [exec, { ...INITIAL_STATE, fetchMore }];

  return [exec, { ...state, fetchMore }];
}

//
// Private functions
// -------------------------------------------------------------------------------------------------

/**
 * A path builder for `apollo-link-rest` to remove variables that are null or empty strings since the server does not like those.
 */
export function pathBuilder({ args }: { args: {} }) {
  const options = omitBy(args, (val) => {
    if (isNil(val)) return true;
    if (val.length === 0) return true;
    return false;
  });
  return `/?${qs.stringify(options)}`;
}

function reducer(state, action) {
  switch (action.type) {
    case 'LOAD_DATA':
      return { ...state, called: true, loading: true };
    case 'RECEIVE_DATA':
      return { ...state, data: action.payload.data, loading: false, variables: action.payload.variables };
    case 'RESET_STATE':
      return { ...INITIAL_STATE };

    default:
      throw new Error(`[REDUCER] Unknown action type "${action.type}"`);
  }
}
