import { Box, Cluster, Loadable, Restrainer, Stack, TabList } from '@a1s/ui';
import { ApolloError } from 'apollo-client';
import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import useDimensions from 'react-use-dimensions';

import { FIFTEEN_MINUTE_POLL_INTERVAL } from '../../lib';
import { UpdatesDisplay } from '../../ui';

import BreakdownChart from './BreakdownChart';
import DataDisplay from './DataDisplay';
import { UseDimensionsContainer } from './styled';
import TimelineChart from './TimelineChart';

import { ButtonFooter, PanelContainer } from 'ui-new';
import { useDuration, Duration } from 'utils/duration';

//
// Main component
// -------------------------------------------------------------------------------------------------

export function DetectionStatsPanel() {
  const duration = useDuration();
  const [chart, setChart] = useState<Chart>('breakdown');

  const { t } = useTranslation('dashboardHome');

  return (
    <PanelContainer title={t('detectionStats')}>
      <Stack gap="7">
        <Top chart={chart} onChartChange={setChart} />
        <Middle chart={chart} duration={duration} />
        <Bottom />
      </Stack>
    </PanelContainer>
  );
}

//
// Private components
// -------------------------------------------------------------------------------------------------

function Bottom() {
  return (
    <Box pb px testId="detection-stats-bottom">
      <ButtonFooter to="/email">
        <UpdatesDisplay duration="PT15M" />
      </ButtonFooter>
    </Box>
  );
}

interface MiddleProps {
  /**
   * Which chart should be shown
   */
  chart: Chart;

  /**
   * The duration of time in which to display data for
   */
  duration: Duration;
}

function Middle({ chart, duration }: MiddleProps) {
  const [baseRef, { width: containerWidth }] = useDimensions();
  const { data, loading } = useRemoteData(duration);

  const chartWidth = containerWidth / 2 - 40; // 40 for padding

  return (
    <Loadable loading={loading}>
      <Box pl testId="detection-stats-middle">
        <UseDimensionsContainer ref={baseRef}>
          <Cluster justify="stretch">
            <Restrainer css={{ width: '50%' }}>
              {chart === 'breakdown' ? (
                <BreakdownChart data={data} duration={duration} />
              ) : (
                <TimelineChart data={data} width={chartWidth} />
              )}
            </Restrainer>
            <Restrainer css={{ width: '50%' }}>
              <DataDisplay data={data} duration={duration} />
            </Restrainer>
          </Cluster>
        </UseDimensionsContainer>
      </Box>
    </Loadable>
  );
}

interface TopProps {
  /**
   * Which chart type is currently selected
   */
  chart: Chart;

  /**
   * Callback that is fired when the chart type is changed
   */
  onChartChange: (chart: Chart) => void; // eslint-disable-line no-unused-vars
}

function Top({ chart, onChartChange }: TopProps) {
  const { t } = useTranslation('dashboardHome');

  return (
    <Box pt px testId="detection-stats-top">
      <Cluster justify="stretch">
        <TabList appearance="arrow-down">
          <TabList.Tab
            onClick={() => onChartChange('breakdown')}
            selected={chart === 'breakdown'}
            testId="detection-stats-tab-list-button-breakdown"
          >
            {t('breakdown')}
          </TabList.Tab>
          <TabList.Tab
            onClick={() => onChartChange('timeline')}
            selected={chart === 'timeline'}
            testId="detection-stats-tab-list-button-timeline"
          >
            {t('timeline')}
          </TabList.Tab>
        </TabList>
      </Cluster>
    </Box>
  );
}

//
// Data fetching
// -------------------------------------------------------------------------------------------------

/**
 * The types of charts uses in this component.
 */
type Chart = 'breakdown' | 'timeline';

interface BreakdownData {
  malicious?: number;
  maliciousBec?: number;
  none?: number;
  bulk?: number;
  suspicious?: number;
  spoof?: number;
  spam?: number;
}

interface TimelineData extends BreakdownData {
  date: string;
}

export interface APIData {
  breakdown: { current: BreakdownData; previous: BreakdownData };
  timeline: { current: TimelineData[]; previous: TimelineData[] };
  totals: { current: number; previous: number };
}

interface HookResult {
  /**
   * The data that has been returned from the API
   */
  data?: APIData;

  /**
   * If there is a problem loading the data, the error information will be available as an error object
   */
  error: ApolloError | null;

  /**
   * Returns true if the data is currently being loaded
   */
  loading: boolean;
}

export const query = loader('./load.graphql');

/**
 * Private hook that encapsulates loading the data for the `DetectionStatsPanel`.
 * Poll the endpoint every 15 minutes.
 */

export function useRemoteData(duration: Duration = '30'): HookResult {
  const { data, error, loading } = useQuery(query, {
    pollInterval: FIFTEEN_MINUTE_POLL_INTERVAL,
    variables: { duration },
  });

  if (loading) return { data: undefined, error: null, loading: true };
  if (error) return { data: undefined, error, loading: false };

  if (!data?.insightsDetectionStats?.data) return { data: undefined, error: null, loading: false };

  // NOTE: not thrilled about the `any` here but I'd need to type out the JSON response and I'm sort of doing that
  // with the return type of this hook —-JB

  const current = data.insightsDetectionStats.data.find((d: any) => 'totalEmailsProcessed' in d)?.totalEmailsProcessed;
  const previous = data.insightsDetectionStats.data.find(
    (d: any) => 'totalEmailsProcessedPrevious' in d
  )?.totalEmailsProcessedPrevious;

  const breakdown = data.insightsDetectionStats.data.find((d: any) => 'breakdown' in d)?.breakdown;
  const timeline = data.insightsDetectionStats.data.find((d: any) => 'timeline' in d)?.timeline;

  return { data: { totals: { current, previous }, breakdown, timeline }, error: null, loading: false };
}
