// @flow

import React from 'react';
import { useTranslation } from 'react-i18next';

import SectionOverview from '../SectionOverview';

import Container from './Container';
import Label from './Label';

import Progress from 'ui/atoms/Progress';

type Props = {
  barLabel?: string,
  cappedValue?: number,
  dataTestId?: string,
  dimmed?: boolean,
  heading: string,
  valueLabel: string,
  valueMax: number,
  valueMin?: number,
  valueNow: number,
};

export default function SectionProgress(props: Props) {
  const {
    barLabel,
    cappedValue,
    dataTestId = 'organism-section-progress',
    heading,
    valueLabel,
    valueMax,
    valueNow,
  } = props;
  const { dimmed = false, valueMin = 0 } = props;

  const percent = Number(valueNow) === 0 ? 0 : Number(valueNow) / valueMax || 0;

  const { t } = useTranslation('components');

  return (
    <Container data-testid={dataTestId} dimmed={dimmed}>
      <SectionOverview
        cappedValue={cappedValue}
        currentValue={valueMax}
        formatValue="number"
        size="small"
        title={heading}
      />

      <Progress valueMax={valueMax} valueMin={valueMin} valueNow={valueNow}>
        {barLabel}
      </Progress>

      <Label
        bottom={`${t(`Statistic.number`, { value: valueNow })} (${t('SectionProgress.percent', {
          value: percent,
        })})`}
        top={valueLabel}
      />
    </Container>
  );
}
