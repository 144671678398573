// @flow
import { loader } from 'graphql.macro';

import { get } from 'lodash';
// $FlowFixMe
import React, { useContext } from 'react';
import { useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';

import { directoriesQuery, displayNamesQuery } from './dataTypesAndUtils';

import { DaysBackContext } from 'screens/App';
import ConditionalRender from 'ui/atoms/ConditionalRender';
import BlockHeader from 'ui/molecules/BlockHeader';
import Content from 'ui/molecules/Content';
import ContentBlock from 'ui/molecules/ContentBlock';
import ProgressTable from 'ui/molecules/ProgressTable';
import ProgressTablePlaceholder from 'ui/molecules/ProgressTablePlaceholder';
import withDataHandler from 'utils/withDataHandler';

export const insightsBecTargetsQuery = loader('./queries/InsightsBecTargetsQuery.graphql');

const ProgressTableWithDataHandler = withDataHandler({
  empty: <ProgressTablePlaceholder />,
})(ProgressTable);

export default function TopBecRecipients() {
  const { t } = useTranslation('email');
  const { currentInterval } = useContext(DaysBackContext);

  const {
    data: becData,
    error: becError,
    loading: becLoading,
  } = useQuery(insightsBecTargetsQuery, {
    variables: { duration: currentInterval, limit: 20 },
  });

  const data = get(becData, 'insightsBecTargets.data', [
    {
      value: 0,
      becTargets: '',
    },
  ]);

  const {
    data: directoriesData = { directories: [] },
    error: directoriesError,
    loading: directoriesLoading,
  } = useQuery(directoriesQuery);

  const {
    data: displayNamesData,
    error: displayNamesError,
    loading: displayNamesLoading,
  } = useQuery(displayNamesQuery);

  const displayNames = displayNamesData?.displayNames || [];
  const directories = directoriesData?.directories || [];

  const error = becError || directoriesError || displayNamesError;
  const loading = becLoading || directoriesLoading || displayNamesLoading;

  const callToAction = directories.length <= 1 && displayNames.length === 0 && data?.length === 0;

  return (
    <>
      <ContentBlock
        dataTestId="top-bec-recipients-component"
        top={
          <>
            <BlockHeader title={t('topBecTargets')} />
            <ConditionalRender condition={callToAction && !loading && !error}>
              <Content>{t('directoriesCallToAction')}</Content>
            </ConditionalRender>
            <ConditionalRender condition={!callToAction}>
              <ProgressTableWithDataHandler
                /* eslint-disable no-shadow */
                // eslint-disable-next-line no-unused-vars
                calculatePercent={(data, [_, number]) =>
                  Number(number) / data.reduce((a, b) => Number(a) + Number(b[1]), 0)
                }
                cellWidths={['50%', '50%']}
                data={dataForGraphTable(data)}
                isEmpty={() => loading || error || !data || data.length === 0}
                isError={error}
                isLoading={loading}
                tint
              />
            </ConditionalRender>
          </>
        }
      />
    </>
  );
}

//
// Private function
// -------------------------------------------------------------------------------------------------
const dataForGraphTable = (data) => {
  if (!data) return [];
  return data.map((row) => [row.becTargets, row.value]);
};
