// @flow
import { loader } from 'graphql.macro';

export const DetectionsSuspiciousLinksQuery = loader('./queries/SuspiciousLinksQuery.graphql');

export type SuspiciousLinkCountsByDate = { date: number, keys: string[], values: number[] };

export type PreviousSuspiciousLinkCountsByDate = { date: number, keys: string[], values: number[] };

type GraphTableReturnType = {
  label: string,
  value: number,
};

export type SuspiciousLinksListByName = { count?: number, threatCategory?: string };

export type SuspiciousLinkCountsByDataType = {
  count: number,
  threatCategory: string,
};

type LineGraphType = {
  day: string,
  total: number,
  totals: Array<SuspiciousLinkCountsByDataType>,
};

type LineGraphReturnType = {
  date: number,
  value: number,
};

export function mungeLineGraphData(data: Array<LineGraphType>): Array<LineGraphReturnType> {
  return data
    .sort((a, b) => new Date(a.day) - new Date(b.day))
    .map((row) => ({ date: new Date(row.day), value: row.total }));
}

export function dataForGraphTable(data: Array<SuspiciousLinkCountsByDataType>): Array<GraphTableReturnType> {
  return data.map((row) => ({ label: row.threatCategory, value: row.count }));
}
