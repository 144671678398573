// @flow

import { format as dateFormat, formatDistanceToNow } from 'date-fns';
import { format as timeZoneFormat } from 'date-fns-tz';
import * as localeObj from 'date-fns/locale';
import es from 'date-fns/locale/es';
import ja from 'date-fns/locale/ja';
import pt from 'date-fns/locale/pt';

import countries from 'i18n-iso-countries';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import abbreviate from 'number-abbreviate';
import { initReactI18next } from 'react-i18next';

import * as resources from './i18n';

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .on('languageChanged', async (lng) => {
    const languages = await import(`i18n-iso-countries/langs/${lng}.json`);
    countries.registerLocale(languages);
  })
  .init({
    defaultNS: 'common',
    detection: {
      caches: ['cookie'],
      order: ['querystring', 'cookie'],
    },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
      format: (value: any, format: string, lng: string) => {
        if (value instanceof Date) {
          const locale = getLocaleObject(lng);
          switch (format) {
            case 'distance-in-words-to-now':
              return formatDistanceToNow(value, { locale });
            default:
              if (format === 'zzz') {
                const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'America/Los_Angeles';
                return timeZoneFormat(value, format, { timeZone, locale });
              }
              return dateFormat(value, format, { locale });
          }
        }

        if (format === 'country-code-to-name') return countries.getName(value, lng, { select: 'official' });
        if (format === 'abbreviated-number') return abbreviate(value, 2);
        if (format === 'currency')
          return Intl.NumberFormat(lng, {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          }).format(value);
        if (format === 'duration') return formatDuration(value, lng);
        if (format === 'number') return formatNumber(value, lng);
        if (format === 'percent') return new Intl.NumberFormat(lng, { style: 'percent' }).format(value);
        if (format === 'percent-with-decimal')
          return new Intl.NumberFormat(lng, { maximumFractionDigits: 2, style: 'percent' }).format(value);

        return value;
      },
    },
    ns: 'common',
    react: { wait: true },
    resources,
    saveMissing: true,
    // eslint-disable-next-line no-unused-vars
    missingKeyHandler(lng, ns, key) {
      // Sentry event
    },
  });

export default i18next;

export function formatNumber(value: any, lng: string = 'en') {
  return new Intl.NumberFormat(lng, { style: 'decimal' }).format(value);
}

//
// Private functions
// -------------------------------------------------------------------------------------------------

function formatDuration(duration: ?Object, lng: string) {
  let display = '';

  if (duration) {
    if (lng === 'en') {
      if (duration.years) display = `${display} ${duration.years} years`;
      if (duration.months) display = `${display} ${duration.months} months`;
      if (duration.days) display = `${display} ${duration.days} days`;
      if (duration.hours) display = `${display} ${duration.hours} hours`;
      if (duration.minutes) display = `${display} ${duration.minutes} mins`;
      if (duration.seconds) display = `${display} ${duration.seconds} secs`;
    } else if (lng === 'es') {
      if (duration.years) display = `${display} ${duration.years} años`;
      if (duration.months) display = `${display} ${duration.months} meses`;
      if (duration.days) display = `${display} ${duration.days} días`;
      if (duration.hours) display = `${display} ${duration.hours} horas`;
      if (duration.minutes) display = `${display} ${duration.minutes} minutos`;
      if (duration.seconds) display = `${display} ${duration.seconds} segundos`;
    } else if (lng === 'ja') {
      if (duration.years) display = `${display} ${duration.years}年`;
      if (duration.months) display = `${display} ${duration.months}ヶ月`;
      if (duration.days) display = `${display} ${duration.days}日`;
      if (duration.hours) display = `${display} ${duration.hours}時間`;
      if (duration.minutes) display = `${display} ${duration.minutes}分`;
      if (duration.seconds) display = `${display} ${duration.seconds}秒`;
    } else if (lng === 'pt') {
      if (duration.years) display = `${display} ${duration.years} anos`;
      if (duration.months) display = `${display} ${duration.months} meses`;
      if (duration.days) display = `${display} ${duration.days} dias`;
      if (duration.hours) display = `${display} ${duration.hours} horas`;
      if (duration.minutes) display = `${display} ${duration.minutes} minutos`;
      if (duration.seconds) display = `${display} ${duration.seconds} segundos`;
    } else if (lng === 'zh') {
      if (duration.years) display = `${display} ${duration.years} 年的更新数`;
      if (duration.months) display = `${display} ${duration.months} 个月的更新数`;
      if (duration.days) display = `${display} ${duration.days} 天的更新数`;
      if (duration.hours) display = `${display} ${duration.hours} 小时的更新数`;
      if (duration.minutes) display = `${display} ${duration.minutes} 分钟的更新数`;
      if (duration.seconds) display = `${display} ${duration.seconds} 秒的更新数`;
    }
  }

  return display.trim();
}

function getLocaleObject(lng: string) {
  switch (lng) {
    case 'es':
      return es;
    case 'ja':
      return ja;
    case 'pt':
      return pt;
    case 'zh':
      return localeObj.zhCN;
    default:
      return null;
  }
}
