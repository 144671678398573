import { parseQueryString, toQueryString } from '@a1s/lib';
import { fontImports, LinearGradient, rootStyles, Stack } from '@a1s/ui';
import React, { useMemo, useRef, ComponentProps } from 'react';
import { createPortal } from 'react-dom';
import { useHistory, useLocation } from 'react-router-dom';

import { PermissionsProvider } from './lib';
import { Backdrop, Modal, ModalHeader } from './ui';
import { AllMailSearch, DetectionSearch } from './views';

//
// Typescript types
// -------------------------------------------------------------------------------------------------

type DispositionOptionType = 'all' | 'bulk' | 'malicious' | 'malicious-bec' | 'spam' | 'suspicious' | 'spoof';

type SearchParams = ComponentProps<typeof ModalHeader>['params'];

interface NamedAction<T extends string> {
  payload: State;
  type: T;
}

type ChangeSearchModeAction = NamedAction<'CHANGE_SEARCH_MODE'>;
type ChangeSearchTypeAction = NamedAction<'CHANGE_SEARCH_TYPE'>;
type CloseSearchModalAction = NamedAction<'CLOSE_SEARCH'>;
type OpenSearchmodalAction = NamedAction<'OPEN_SEARCH'>;
type UpdateSearchParamsAction = NamedAction<'UPDATE_SEARCH_PARAMS'>;

type Action =
  | ChangeSearchModeAction
  | ChangeSearchTypeAction
  | CloseSearchModalAction
  | OpenSearchmodalAction
  | UpdateSearchParamsAction;
type State = SearchParams;

//
// Main component
// -------------------------------------------------------------------------------------------------

export default function SearchScreen() {
  fontImports();
  rootStyles();

  const ref = useRef(document.getElementById('modal'));
  const [state, dispatch] = useQueryParams();

  if (!ref.current) return null;

  function handleSubmit(params: State) {
    const payload = { ...params, searchMode: state.searchMode, searchType: state.searchType };
    dispatch({ payload, type: 'UPDATE_SEARCH_PARAMS' });
  }

  function handleSearchModeChange(mode: SearchParams['searchMode'], params: SearchParams) {
    const updated = { ...state, ...params, searchMode: mode };
    dispatch({ payload: updated, type: 'CHANGE_SEARCH_MODE' });
  }

  function handleSearchTypeChange(
    type: SearchParams['searchType'],
    term: string,
    finalDisposition?: DispositionOptionType
  ) {
    const updated = { ...state, searchType: type, searchTerm: term, finalDisposition: finalDisposition || '' };
    dispatch({ payload: updated, type: 'CHANGE_SEARCH_TYPE' });
  }

  return createPortal(
    <>
      <Modal>
        <PermissionsProvider>
          <Stack css={{ height: '100%' }}>
            <ModalHeader
              onSearch={handleSubmit}
              onSearchModeChange={handleSearchModeChange}
              onSearchTypeChange={(t, s, f) => handleSearchTypeChange(t, s || '', f)}
              params={state}
            />
            <Body params={state} />
          </Stack>
        </PermissionsProvider>
      </Modal>
      <Backdrop />
    </>,
    ref.current
  );
}

//
// Private components
// -------------------------------------------------------------------------------------------------

interface BodyProps {
  params: SearchParams;
}

function Body({ params }: BodyProps) {
  return (
    <LinearGradient css={{ borderRadius: '$4', flexBasis: '100%', flexGrow: 1, maxHeight: '100%' }} testId="GROUP">
      {params.searchType === 'detection-only' && <DetectionSearch params={params} />}
      {params.searchType === 'all-mail' && <AllMailSearch params={params} />}
    </LinearGradient>
  );
}

//
// Private hook
// -------------------------------------------------------------------------------------------------

// eslint-disable-next-line no-unused-vars
function useQueryParams(): [SearchParams, (action: Action) => void] {
  const history = useHistory();
  const { pathname, search } = useLocation();
  const state = useMemo(() => parseQueryString(search) as unknown as SearchParams, [search]);

  function dispatch(action: Action) {
    const result = reducer(state, action);
    history.push(`${pathname}?${toQueryString(result as Record<string, any>)}`);
  }

  if (Object.keys(state).length === 0) {
    history.push(`${pathname}?${toQueryString(INITIAL_STATE as Record<string, any>)}`);
    return [INITIAL_STATE, dispatch];
  }

  return [state, dispatch];
}

//
// Private reducer
// -------------------------------------------------------------------------------------------------

export const INITIAL_STATE: State = {
  daysBack: '30',
  finalDisposition: '',
  searchMode: 'fielded',
  searchTerm: '',
  searchType: 'detection-only',
};

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'CHANGE_SEARCH_MODE': {
      return {
        ...state,
        searchMode: action.payload.searchMode,
        searchType: action.payload.searchType,
      };
    }
    case 'CHANGE_SEARCH_TYPE': {
      if (action.payload.searchType === 'detection-only') {
        return {
          ...state,
          finalDisposition: action.payload.finalDisposition,
          searchTerm: action.payload.searchTerm,
          searchType: 'detection-only',
        };
      }

      if (action.payload.searchType === 'all-mail') {
        return {
          ...state,
          finalDisposition: '',
          searchTerm: action.payload.searchTerm,
          searchType: 'all-mail',
        };
      }

      throw new Error(`[REDUCER] Unknown searchType "${action.payload}"`);
    }

    case 'CLOSE_SEARCH':
    case 'OPEN_SEARCH':
      return { ...state, ...INITIAL_STATE };

    case 'UPDATE_SEARCH_PARAMS':
      return { ...state, ...action.payload };

    default:
      throw new Error(`[REDUCER] Unknown action type`);
  }
}
