// @flow

import { format } from 'date-fns';
import { loader } from 'graphql.macro';
import { rem } from 'polished';
// $FlowFixMe
import React, { useEffect, useRef, useState, type SyntheticMouseEventElement } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { useIdleTimer } from 'react-idle-timer';
import styled from 'styled-components';

import NotificationsLink from 'ui/atoms/NotificationsLink';
import Tooltip from 'ui/atoms/Tooltip';
import NotificationCenter from 'ui/molecules/NotificationCenter';

const FIVE_MINUTES = 1000 * 60 * 5; // Five minutes
const POLL_INTERVAL = FIVE_MINUTES;

const DismissNotificationMutation = loader('./queries/DismissNotification.graphql');
const NotificationCountQuery = loader('./queries/NotificationCount.graphql');
const NotificationsQuery = loader('./queries/Notifications.graphql');
const ReadNotificationMutation = loader('./queries/ReadNotification.graphql');

//
// Styled components
// -------------------------------------------------------------------------------------------------

const Container = styled.div`
  position: relative;
  user-select: none;
`;

const Positioner = styled.div`
  transform: translate(${rem(-94)}, ${rem(-40)});
`;

//
// Main component
// -------------------------------------------------------------------------------------------------

export default function Notifications() {
  const { data, startPolling, stopPolling } = useQuery(NotificationCountQuery, {
    onError: () => stopPolling(),
    pollInterval: POLL_INTERVAL,
  });

  const containerRef = useRef(null);
  const linkRef = useRef(null);

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    function handleWindowClick({ target }: SyntheticMouseEventElement<HTMLElement>) {
      if (isVisible && target.compareDocumentPosition(containerRef.current) < Node.DOCUMENT_POSITION_FOLLOWING) {
        setIsVisible(false);
      }
    }

    window.addEventListener('click', handleWindowClick);
    return () => window.removeEventListener('click', handleWindowClick);
  }, [isVisible, setIsVisible]);

  function handleClick(event) {
    event.preventDefault();

    setIsVisible(!isVisible);
  }

  const handleOnActive = () => {
    startPolling(POLL_INTERVAL);
  };

  const handleOnIdle = () => {
    stopPolling();
  };

  useIdleTimer({
    timeout: FIVE_MINUTES,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
  });

  const unread = data && data.me && data.me.notifications && data.me.notifications.length > 0;

  return (
    <Container ref={containerRef}>
      <NotificationsLink onClick={handleClick} ref={linkRef} unread={unread} />
      <Positioner>
        <Tooltip anchor={linkRef.current} arrowAlign="end" position="bottom" visible={isVisible}>
          {isVisible && <DisplayNotifications />}
        </Tooltip>
      </Positioner>
    </Container>
  );
}

//
// Private components
// -------------------------------------------------------------------------------------------------

function DisplayNotifications() {
  const [dismiss] = useMutation(DismissNotificationMutation);
  const [read] = useMutation(ReadNotificationMutation);

  const { data, loading } = useQuery(NotificationsQuery, {
    fetchPolicy: 'cache-and-network',
    pollInterval: POLL_INTERVAL,
  });

  function handleAction(action) {
    if (!action) return;

    switch (action.type) {
      case 'DOWNLOAD':
        window.location.assign(action.payload);
        break;
      default:
    }
  }

  function handleDismiss(id) {
    function update(cache, { data: { dismissNotification } }) {
      const { me } = cache.readQuery({ query: NotificationsQuery });
      const updated = me.notifications.filter((n) => n.id !== dismissNotification.notification.id);
      cache.writeQuery({ data: { me: { notifications: updated, __typename: 'User' } }, query: NotificationsQuery });
    }

    if (Array.isArray(id)) {
      id.forEach((i) => {
        const optimisticResponse = {
          dismissNotification: createOptimisticResponse(i, 'DimissNotificationPayload', 'dismissedAt'),
          __typename: 'Mutation',
        };
        dismiss({ optimisticResponse, update, variables: { id: i } });
      });
    } else {
      const optimisticResponse = {
        dismissNotification: createOptimisticResponse(id, 'DimissNotificationPayload', 'dismissedAt'),
        __typename: 'Mutation',
      };
      dismiss({ optimisticResponse, update, variables: { id } });
    }
  }

  function handleRead(id) {
    function update(cache, { data: { readNotification } }) {
      const { me } = cache.readQuery({ query: NotificationCountQuery });
      const updated = me.notifications.filter((n) => n.id !== readNotification.notification.id);
      cache.writeQuery({ data: { me: { notifications: updated, __typename: 'User' } }, query: NotificationCountQuery });
    }

    const optimisticResponse = {
      readNotification: createOptimisticResponse(id, 'ReadNotificationPayload', 'readAt'),
      __typename: 'Mutation',
    };
    read({ optimisticResponse, update, variables: { id } });
  }

  const notifications = data && data.me && data.me.notifications;

  return (
    <NotificationCenter
      data={notifications || []}
      loading={loading && !data}
      onAction={handleAction}
      onDismiss={handleDismiss}
      onRead={handleRead}
    />
  );
}

//
// Private function
// -------------------------------------------------------------------------------------------------

function createOptimisticResponse(id, __typename, field) {
  return {
    notification: { id, [field]: format(new Date(), 'eee, dd MMM yyyy HH:mm:ss GMT'), __typename: 'Notification' },
    __typename,
  };
}
