import { Box, Cluster, Stack, Table, Text } from '@a1s/ui';
import React from 'react';

import { useTranslation } from 'react-i18next';

import { Badge, IconBackground } from '..';

import { Dispositions, RedressedActionsType, ValidationType } from '../../types';

import { matchColorAndDisposition } from '../common';

import { ReactComponent as CheckSVG } from './check.svg';
import { ReactComponent as ErrorSVG } from './error.svg';
import { ReactComponent as NullSVG } from './na.svg';
import { ReactComponent as TriangleSVG } from './triangle.svg';

import { InlineTable } from 'ui-new';

//
// Main component
// -------------------------------------------------------------------------------------------------

interface StatusCellProps {
  disposition: Dispositions;
  isQuarantined: boolean;
  redressedActions: RedressedActionsType[];
  truncated?: boolean;
  validation: ValidationType;
}

/**
 * Displays status information about a search result.
 */
export function StatusCell({
  disposition,
  isQuarantined,
  redressedActions,
  truncated = false,
  validation,
}: StatusCellProps) {
  const { t } = useTranslation('unisearch');

  const lastRedressedAction: string = redressedActions?.[0]?.operation;

  if (truncated) {
    return (
      <Table.Cell border="none" css={{ overflow: 'hidden' }}>
        <Stack gap="3">
          <Badge color={matchColorAndDisposition(disposition)}>{t(disposition)}</Badge>
          {isQuarantined && <RenderBadge value={t('quarantined')} />}
        </Stack>
      </Table.Cell>
    );
  }

  return (
    <Table.Cell border="none">
      <InlineTable gap="0 5px">
        <tbody>
          <InlineTable.Row>
            <RenderLabel label={t('disposition')} />
            <RenderBadge color={matchColorAndDisposition(disposition || 'NONE')} value={t(disposition || 'NONE')} />
          </InlineTable.Row>
          <InlineTable.Row>
            <RenderLabel label={t('status')} />
            <InlineTable.Cell css={{ verticalAlign: 'middle' }}>
              <Cluster gap="2">
                <Badge color="gray500">{isQuarantined ? t('quarantined') : t('delivered')}</Badge>
                {!!lastRedressedAction && <Badge color="gray500">{t(lastRedressedAction)}</Badge>}
              </Cluster>
            </InlineTable.Cell>
          </InlineTable.Row>
          <RenderCustomCell dispositionType={disposition} validation={validation} />
        </tbody>
      </InlineTable>
    </Table.Cell>
  );
}

//
// Private components
// -------------------------------------------------------------------------------------------------

interface BadgeProps {
  color?: string;
  value: string;
}

function RenderBadge({ color = 'gray500', value }: BadgeProps) {
  return (
    <InlineTable.Cell css={{ verticalAlign: 'middle' }}>
      <Badge color={color}>{value}</Badge>
    </InlineTable.Cell>
  );
}

interface LabelProps {
  label: string;
}

function RenderLabel({ label }: LabelProps) {
  return (
    <InlineTable.Cell>
      <Text color="$gray600" font="sans" size="sm" stretch="ultraCondensed" transform="uppercase" weight="semibold">
        {label}
      </Text>
    </InlineTable.Cell>
  );
}

interface NoneRowProps {
  label: string;
  value: string;
}

function NoneRow({ label, value }: NoneRowProps) {
  return (
    <InlineTable.Row>
      <RenderLabel label={label} />
      <InlineTable.Cell>
        <Badge color="gray300">{value}</Badge>
      </InlineTable.Cell>
    </InlineTable.Row>
  );
}

interface CustomCellProps {
  dispositionType: StatusCellProps['disposition'];
  validation: ValidationType;
}

interface ValidationIconProps {
  label: 'DKIM' | 'DMARC' | 'SPF';
  val: ValidationType['dkim'] | ValidationType['dmarc'] | ValidationType['spf'];
}

function RenderCustomCell({ dispositionType, validation }: CustomCellProps) {
  const { t } = useTranslation('unisearch');

  if (dispositionType?.toLowerCase() === 'none') return <NoneRow label={t('threatType')} value={t('none')} />;

  const { dkim, dmarc, spf } = validation || { dkim: 'none', dmarc: 'none', spf: 'none' };

  function ValidationIcon({ label, val }: ValidationIconProps) {
    if (val === null) {
      return (
        <Cluster align="center" gap="2">
          <IconBackground color="#CCCCCC">
            <NullSVG />
          </IconBackground>
          <Text color="$gray600" font="sans" size="sm" stretch="ultraCondensed" transform="uppercase">
            {label}
          </Text>
        </Cluster>
      );
    }

    if (['pass', 'success'].includes(val?.toLowerCase())) {
      return (
        <Cluster align="center" gap="2">
          <IconBackground color="$green300">
            <CheckSVG />
          </IconBackground>
          <Text color="$gray600" font="sans" size="sm" stretch="ultraCondensed" transform="uppercase">
            {label}
          </Text>
        </Cluster>
      );
    }

    if (['error', 'fail', 'permerror', 'permfail', 'softfail', 'temperror', 'tempfail'].includes(val.toLowerCase())) {
      return (
        <Cluster align="center" gap="2">
          <IconBackground color="$red300">
            <ErrorSVG />
          </IconBackground>
          <Text color="$gray600" font="sans" size="sm" stretch="ultraCondensed" transform="uppercase">
            {label}
          </Text>
        </Cluster>
      );
    }

    if (['mixed', 'neutral', 'none'].includes(val.toLowerCase())) {
      return (
        <Cluster align="center" gap="2">
          <IconBackground color="$orange300">
            <TriangleSVG />
          </IconBackground>
          <Text color="$gray600" font="sans" size="sm" stretch="ultraCondensed" transform="uppercase">
            {label}
          </Text>
        </Cluster>
      );
    }

    return (
      <Cluster align="center" gap="2">
        <IconBackground color="$orange300">
          <TriangleSVG />
        </IconBackground>
        <Text color="$gray600" font="sans" size="sm" stretch="ultraCondensed" transform="uppercase">
          -
        </Text>
      </Cluster>
    );
  }

  return (
    <InlineTable.Row>
      <RenderLabel label={t('validation')} />
      <InlineTable.Cell>
        <Box pt="1">
          <Cluster align="center" gap="5">
            <ValidationIcon label="SPF" val={spf} />
            <ValidationIcon label="DMARC" val={dmarc} />
            <ValidationIcon label="DKIM" val={dkim} />
          </Cluster>
        </Box>
      </InlineTable.Cell>
    </InlineTable.Row>
  );
}
