import { num } from '@a1s/lib';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { StyledAbbr } from './styled';

//
// Settings and constants
// -------------------------------------------------------------------------------------------------

/**
 * Sets the minimum value that should be abbreviated by the `AbbreviatedNumber` component.
 */
const ABBREVIATION_THRESHOLD = 10000;

/**
 * By default, the component just renders the abbreviated number
 */
const DEFAULT_RENDER = (node: JSX.Element) => node;

//
// Main component
// -------------------------------------------------------------------------------------------------

interface AbbreviatedNumberProps {
  /**
   * Escape-hatch for taking control of how to the abbreviated number is rendered.
   */
  children?(value: JSX.Element): JSX.Element; // eslint-disable-line no-unused-vars

  /**
   * The number that should be abbreviated.
   */
  value?: number | string;
}

/**
 * Handles abbreviating a number, doing its best to abbreviate it a way that makes sense
 * for the user's locale.
 */
export function AbbreviatedNumber({ children = DEFAULT_RENDER, value }: AbbreviatedNumberProps) {
  const { t } = useTranslation('components');

  if (typeof value === 'undefined') return children(null);

  // Make sure the value passed in is actually a number
  const parsed = num(value);

  if (Number(value) <= ABBREVIATION_THRESHOLD) return children(<>{parsed.toLocaleString()}</>);

  return children(
    <StyledAbbr title={parsed.toLocaleString()}>{t('Statistic.abbreviatedNumber', { value: parsed })}</StyledAbbr>
  );
}
