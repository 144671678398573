// @flow

// $FlowFixMe
import React, { forwardRef } from 'react';

import Container from './Container';
import Icon from './Icon';

export type IconName = 'error' | 'success' | 'warning';

type Props = {
  dataTestId?: string,
  /*
    Determines the style of icon shown.
  */
  name: IconName,
  /*
    Determins whether right margin is applied or not.
    (usually you'd want this if content is coming after the icon).
    Defaults to with margin.
  */
  noMargin?: boolean,
  /*
    Renders a smaller icon.
  */
  small?: boolean,
};

function StatusIcon({ dataTestId = 'atom-status-icon', name, noMargin = false, small = false, ...rest }: Props, ref) {
  return (
    <Container data-testid={dataTestId} noMargin={noMargin} ref={ref} {...rest}>
      <Icon name={name} small={small} />
    </Container>
  );
}

export default forwardRef(StatusIcon);
