// @flow

import { rem, rgba } from 'polished';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import Navigation from './Navigation';

import Badge from 'ui/atoms/Badge';
import NavLink from 'ui/atoms/NavLink';
import { resumePrimaryAccount } from 'utils/delegatedAccounts';
import useAccessControl, { permissionTypes } from 'utils/hooks/useAccessControl';
import { useCurrentUser } from 'utils/hooks/useCurrentUser';

//
// Styled components
// -------------------------------------------------------------------------------------------------

const CloseButton = styled.button`
  background: none;
  border: 0;
  cursor: pointer;
  height: ${rem(12)};
  margin: 0 0 0 ${(p) => rem(p.theme.spacing.xs)};
  padding: 0;
  width: ${rem(12)};

  path {
    fill: ${(p) => rgba(p.theme.colors.white, 0.5)};
    transition: fill 150ms linear;
  }

  &:hover path {
    fill: ${(p) => p.theme.colors.white};
  }
`;

const MasqueradeBadge = styled(Badge)`
  background-color: ${({ theme }) => theme.colors.pastelRed};
  margin-left: ${(p) => rem(0 - p.theme.spacing.sm)};
  margin-right: ${(p) => rem(p.theme.spacing.md)};

  span {
    align-items: center;
    color: ${({ theme }) => theme.colors.white};
    display: flex;
    font-size: ${rem(12)};
    font-weight: 500;
    padding: 0 ${(p) => rem(p.theme.spacing.sm)};
  }
`;

//
// Main components
// -------------------------------------------------------------------------------------------------
interface Props {
  dataTestId?: string;
  style?: Object;
}

function MainNavigation({ dataTestId = 'molecule-main-navigation', style }: Props, ref) {
  const {
    BETTERMAIL_ONLY,
    DELEGATED_ROLES_ENABLED,
    DEMO_ENABLED,
    ENTERPRISE_ENABLED,
    MAILSTREAM_ENABLED,
    PHISHGUARD_ENABLED,
    RESUME_PARENT_ENABLED,
  } = permissionTypes;

  const { loading: accessControlLoading, permissions } = useAccessControl([
    BETTERMAIL_ONLY,
    DELEGATED_ROLES_ENABLED,
    DEMO_ENABLED,
    ENTERPRISE_ENABLED,
    MAILSTREAM_ENABLED,
    PHISHGUARD_ENABLED,
    RESUME_PARENT_ENABLED,
  ]);

  const { user = {} } = useCurrentUser();

  const { t } = useTranslation('common');

  const [
    bettermailAccess,
    delegatedRolesAccess,
    demoEnabled,
    enterpriseAccess,
    mailstreamAccess,
    phishguardEnabled,
    resumeParentAccess,
  ] = permissions;

  if (accessControlLoading) return null;

  const { customerName } = user;

  return (
    <Navigation data-testid={dataTestId} ref={ref} style={style}>
      {resumeParentAccess && (
        <MasqueradeBadge>
          {customerName}
          <CloseButton onClick={() => resumePrimaryAccount()}>
            <svg viewBox="0 0 24 24">
              <title>{t('resumeParent')}</title>
              <path d="M21.456 5.414l-2.829-2.828-6.585 6.586-6.586-6.586-2.829 2.828L9.213 12l-6.586 6.586 2.829 2.828 6.586-6.586 6.585 6.586 2.829-2.828L14.87 12z" />
            </svg>
          </CloseButton>
        </MasqueradeBadge>
      )}

      {!delegatedRolesAccess && (
        <NavLink data-testid="main-navigation-home" to="/home">
          {t('home')}
        </NavLink>
      )}

      {!delegatedRolesAccess && mailstreamAccess && (
        <NavLink data-testid="main-navigation-email" to="/email">
          {t('email')}
        </NavLink>
      )}

      {!delegatedRolesAccess && (
        <NavLink data-testid="main-navigation-web" to="/web">
          {t('web')}
        </NavLink>
      )}

      {!bettermailAccess && !delegatedRolesAccess && !demoEnabled && enterpriseAccess && (
        <NavLink data-testid="main-navigation-landscape" to="/landscape">
          {t('landscape')}
        </NavLink>
      )}

      {phishguardEnabled && (
        <NavLink data-testid="main-navigation-phishguard" to="/phishguard">
          {t('phishguard')}
        </NavLink>
      )}
    </Navigation>
  );
}

// $FlowFixMe
export default React.forwardRef(MainNavigation);
