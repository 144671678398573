"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.theme = exports.styled = exports.keyframes = exports.globalCss = exports.css = exports.config = void 0;

var _react = require("@stitches/react");

var themeTokens = _interopRequireWildcard(require("./theme"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function (nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

//
// Set up for Stitches
// -------------------------------------------------------------------------------------------------
const {
  config,
  css,
  globalCss,
  keyframes,
  styled,
  theme
} = (0, _react.createStitches)({
  media: {
    sm: '(max-width: 959px)',
    md: '(min-width: 960px)',
    lg: '(min-width: 1440px)'
  },
  theme: themeTokens,
  utils: {
    //
    // Abbreviated background propertiy
    // -------------------------------------------------------------------------------------------------
    bg: value => ({
      backgroundColor: value
    }),
    // Abbreviated border-radius properties
    r: value => ({
      borderRadius: value
    }),
    rb: value => ({
      borderBottomLeftRadius: value,
      borderBottomRightRadius: value
    }),
    rbl: value => ({
      borderBottomLeftRadius: value
    }),
    rbr: value => ({
      borderBottomRightRadius: value
    }),
    rl: value => ({
      borderBottomLeftRadius: value,
      borderTopLeftRadius: value
    }),
    rr: value => ({
      borderBottomRightRadius: value,
      borderTopRightRadius: value
    }),
    rt: value => ({
      borderTopLeftRadius: value,
      borderTopRightRadius: value
    }),
    rtl: value => ({
      borderTopLeftRadius: value
    }),
    rtr: value => ({
      borderTopRightRadius: value
    }),
    // Abbreviated padding properties
    p: value => ({
      padding: value
    }),
    pb: value => ({
      paddingBottom: value
    }),
    pl: value => ({
      paddingLeft: value
    }),
    pr: value => ({
      paddingRight: value
    }),
    pt: value => ({
      paddingTop: value
    }),
    px: value => ({
      paddingLeft: value,
      paddingRight: value
    }),
    py: value => ({
      paddingBottom: value,
      paddingTop: value
    })
  }
}); //
// Export the Stiches helpers
// -------------------------------------------------------------------------------------------------
//
// Export the CSS from Stitches with our theme tokens defined
// -------------------------------------------------------------------------------------------------

exports.theme = theme;
exports.styled = styled;
exports.keyframes = keyframes;
exports.globalCss = globalCss;
exports.css = css;
exports.config = config;