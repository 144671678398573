// @flow

import { loader } from 'graphql.macro';
import { get } from 'lodash';
// $FlowFixMe
import React, { useContext } from 'react';
import { useQuery } from 'react-apollo';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { type PropType } from '../../dataTypesAndUtils';

import { calculatePercent } from '../dataTypesAndUtils';

import SuspiciousLinks from './SuspiciousLinks';
import TopThreatTypes from './TopThreatTypes';

import { DaysBackContext } from 'screens/App';

export const insightSuspiciousLinksQuery = loader('./queries/InsightsSuspiciousLinks.graphql');

export default function SuspiciousLinksOverview({ detectionCount, detectionError, detectionLoading }: PropType) {
  const { currentInterval } = useContext(DaysBackContext);
  const { t } = useTranslation('email');

  const { data, error, loading } = useQuery(insightSuspiciousLinksQuery, { variables: { currentInterval } });
  const sectionOverviewData = get(data, 'suspiciousLinks.data.totals', { current: 0, previous: 0 });
  const lineGraphData = get(data, 'suspiciousLinks.data.timeline', { current: [], previous: [] });
  const graphTableData = get(data, 'suspiciousLinks.data.breakdown', {
    current: { 'Credential Harvester': 0 },
    previous: { 'Credential Harvester': 0 },
  });
  const topLinks = Object.keys(graphTableData.current).map((el) => ({
    threatCategory: t(el),
    count: graphTableData.current[el],
  }));

  return (
    <>
      <Helmet title={`${t('links')} :: ${t('common:email')} - ${t('common:area1security')}`} />
      <>
        <SuspiciousLinks
          data={{
            lineGraph: {
              current: lineGraphData.current,
              previous: lineGraphData.previous,
            },
            ringChart: calculatePercent(sectionOverviewData.current, detectionCount),
            sectionOverview: {
              current: sectionOverviewData.current,
              previous: sectionOverviewData.previous,
            },
          }}
          error={error || detectionError}
          loading={loading || detectionLoading}
        />
        <TopThreatTypes data={topLinks} error={error} loading={loading} />
      </>
    </>
  );
}
