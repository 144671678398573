// @flow

// $FlowFixMe
import { Box } from '@a1s/ui';
import { loader } from 'graphql.macro';
import { rem } from 'polished';
import React from 'react';
import { useMutation } from 'react-apollo';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Logo from 'ui/atoms/Logo';
import PaddedContent from 'ui/atoms/PaddedContent';
import Title from 'ui/atoms/Title';
import Content from 'ui/molecules/Content';
import ContentBlock from 'ui/molecules/ContentBlock';
import LanguageSelector from 'ui/molecules/LanguageSelector';
import ForgotPasswordForm from 'ui/organisms/ForgotPasswordForm';
import { useCurrentUser } from 'utils/hooks/useCurrentUser';

const resetQuery = loader('./queries/ResetPassword.graphql');

//
// Styled components
// -------------------------------------------------------------------------------------------------

const ContentContainer = styled(Content)`
  width: ${rem(450)};
`;

//
// Main component
// -------------------------------------------------------------------------------------------------

export default function ForgotPasswordScreen() {
  const { loading } = useCurrentUser();
  const { t } = useTranslation('loginPage');

  const [resetPassword, { called }] = useMutation(resetQuery);

  function handleReset(email: string) {
    resetPassword({ variables: { input: { email } } });
  }

  return (
    <ContentBlock
      top={
        <>
          <Helmet title={`${t('pageTitle')} - ${t('common:area1security')}`} />
          <Logo />
          <PaddedContent pushBottom="md" pushTop="md">
            <Title>{t('needAReset')}</Title>
            <ContentContainer>
              <p>{t('forgotPasswordCopy')}</p>
            </ContentContainer>
          </PaddedContent>
          <ForgotPasswordForm isLoading={loading} isResetSent={called} onReset={handleReset} />
          <Box css={{ bottom: rem(16), position: 'fixed', right: rem(16) }}>
            <LanguageSelector direction="up" languages={['en', 'es', 'ja', 'pt', 'zh']} />
          </Box>
        </>
      }
    />
  );
}
