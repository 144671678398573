// @flow

import { useWindowSize } from '@a1s/hooks';
import { rem } from 'polished';
import React, { type Node } from 'react';
import { css } from 'styled-components/macro';

import * as Table from '../Table';

import type { ColWidthType } from '../Table';

interface WithChildren {
  children?: Node;
}

interface Props extends WithChildren {
  colWidths?: Array<ColWidthType>;
  width?: number;
}

export default function WindowedTable({ children, colWidths = [], width, ...props }: Props) {
  const { innerWidth } = useWindowSize();

  const actualWidth = width || innerWidth;

  /* eslint-disable no-nested-ternary */
  const style = colWidths
    ? css`
        height: 100%;
        margin-bottom: 0;
        max-width: ${rem(actualWidth)};

        ${colWidths.map(
          (w, i) => css`
            & *[role='row'] > *:nth-child(${i + 1}) {
              flex-shrink: 0;
              width: ${w ? (typeof w === 'number' ? rem((actualWidth - 100) * (w / 100)) : w) : 'auto'};
            }
          `
        )};
        ${(p) =>
          p.zebraStripes &&
          css`
            /* NOTE: this is to work around some positioning issues with react-window. We should look into simplifying
           * the windowing code soon when the UI components get updated or during the next major revision of the search
           * results code. */
            & *[role='rowgroup']:not(:first-of-type) {
              margin-top: -28px;
              & > * {
                transform: translateY(28px);
              }
            }

            & *[role='rowgroup']:not(:first-of-type) *[role='row']:nth-child(odd) > * {
              background-color: ${p.theme.colors.ghostWhite};
              transition-delay: 50ms;
              transition-duration: 150ms;
              transition-property: background-color;
              transition-timing-function: linear;
            }
          `};
      `
    : undefined;
  /* eslint-enable no-nested-ternary */

  return (
    <Table.Container {...props} as="div" css={style} role="table">
      {children}
    </Table.Container>
  );
}

export function Body({ children, ...props }: WithChildren) {
  return (
    <Table.Body {...props} as="div" css={{ alignItems: 'stretch', height: '100%', maxWidth: '100%' }} role="rowgroup">
      {children}
    </Table.Body>
  );
}

export function Cell({ children, ...props }: WithChildren) {
  return (
    <Table.Cell {...props} as="div" role="cell">
      {children}
    </Table.Cell>
  );
}

export function Head({ children, ...props }: WithChildren) {
  return (
    <Table.Head {...props} as="div" role="rowgroup">
      {children}
    </Table.Head>
  );
}

export function Header({ children, ...props }: WithChildren) {
  return (
    <Table.Header {...props} as="div" role="columnheader">
      {children}
    </Table.Header>
  );
}

export function Row({ children, ...props }: WithChildren) {
  const style = css`
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `;

  return (
    <Table.Row {...props} as="div" css={style} role="row">
      {children}
    </Table.Row>
  );
}
