import { Button, Box, CSS, Radio, Stack, Table, Text } from '@a1s/ui';
import { loader } from 'graphql.macro';
import React, { ChangeEventHandler, ComponentProps, PropsWithChildren, ReactElement, useMemo, useState } from 'react';
import { useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';

import { RetractParams } from '../../types';

import { usePermissions } from 'screens/Search/lib/permissions';

import { Dialog } from 'ui-new';

import useAccessControl, { permissionTypes, PermissionsType } from 'utils/hooks/useAccessControl';

const RETRACT_DESTINATIONS = [
  'Inbox',
  'JunkEmail',
  'DeletedItems',
  'RecoverableItemsDeletions',
  'RecoverableItemsPurges',
] as const;

type DestinationTypes = typeof RETRACT_DESTINATIONS[number];

interface ResponseProps {
  tenantId: string;
  itemCount: number;
  recipient: string;
  destination: DestinationTypes;
  messageId: string;
  transport: string;
  clientName: string;
  operation: string;
  completedTimestamp: string;
  clientId: string;
  status: string;
}

interface RetractResponseProps {
  responses: ResponseProps[];
  statusCode: number;
  statusMessage: string;
}

interface Props {
  appearance?: ComponentProps<typeof Button>['appearance'];
  clawbackFeatureEnabled?: boolean;
  disabled?: ComponentProps<typeof Button>['disabled'];
  retractParams: RetractParams[];
}

const query = loader('./query.graphql');

export function RetractButton({ appearance, clawbackFeatureEnabled = false, disabled, retractParams }: Props) {
  const { ENTERPRISE_ENABLED } = permissionTypes;
  const { permissions } = useAccessControl([ENTERPRISE_ENABLED] as PermissionsType[]);
  const [enterpriseEnabled] = permissions as boolean[];

  const userPermitted = usePermissions() && (clawbackFeatureEnabled || enterpriseEnabled);

  const [completeDialogVisible, setCompleteDialogVisible] = useState<boolean>(false);
  const [confirmDialogVisible, setConfirmDialogVisible] = useState<boolean>(false);
  const [messageDetails, setMessageDetails] = useState([]);
  const [retractDestination, setRetractDestination] = useState<DestinationTypes>('Inbox');
  const [errorDialogVisible, setErrorDialogVisible] = useState<boolean>(false);

  const { t } = useTranslation('unisearch');

  const sanitizedRetractParams = useMemo(() => {
    return retractParams?.filter((row: RetractParams) => row.clientRecipients.length !== 0);
  }, [retractParams]);

  const [runMutation, { loading }] = useMutation<{ clawback: RetractResponseProps }>(query);

  // Quick return if the user doesn't have permissions to view this button
  if (!userPermitted) return null;

  const closeAlertDialog = () => {
    setCompleteDialogVisible(false);
    resetRetractDestination();
  };

  const openConfirmDialog = () => {
    setConfirmDialogVisible(true);
  };

  const onConfirmClose = () => {
    setConfirmDialogVisible(false);
    resetRetractDestination();
  };

  const closeErrorDialog = () => {
    setErrorDialogVisible(false);
    resetRetractDestination();
    setMessageDetails([]);
  };

  const onConfirm = async () => {
    if (sanitizedRetractParams.length === 0) {
      setConfirmDialogVisible(false);
      return;
    }

    // eslint-disable-next-line camelcase
    type RequestParams = { destination: DestinationTypes; message_id: RetractParams['messageId']; recipient: string };
    const requests = sanitizedRetractParams.reduce((acc: object[], { clientRecipients, messageId }) => {
      const recipients = clientRecipients?.map((recipient: string) => ({
        destination: retractDestination,
        message_id: messageId,
        recipient,
      }));
      return [...acc, ...recipients];
    }, [] as RequestParams[]);

    try {
      const { data: response } = await runMutation({
        variables: { input: { requests } },
      });

      if (response?.clawback?.statusCode === 200) {
        setCompleteDialogVisible(true);
      } else {
        setErrorDialogVisible(true);
      }
    } catch (e: any) {
      const err = JSON.stringify(e.networkError.result.responses);
      let errorDetails;

      try {
        errorDetails = JSON.parse(err).map((msg: any) => ({
          id: msg.message_id,
          destinationFolder: msg.destination,
          myStatus: msg.status,
        }));
        setMessageDetails(errorDetails);
      } catch (parseError) {
        // eslint-disable-next-line no-console
        console.warn('There was a problem with parsing error details.', parseError);
      }

      setErrorDialogVisible(true);
      resetRetractDestination();
      // Sentry handles this.
    }

    setConfirmDialogVisible(false);
  };

  const handleDestinationChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    e.stopPropagation();
    const { target } = e;
    if (target) setRetractDestination(target?.value as DestinationTypes);
  };

  const buttonDisabled: boolean | undefined = sanitizedRetractParams.length === 0 || completeDialogVisible || disabled;

  const resetRetractDestination = () => setRetractDestination('Inbox');

  return (
    <>
      <Button appearance={appearance} disabled={buttonDisabled} onPress={openConfirmDialog}>
        <TextDecorator disabled={buttonDisabled}>
          {!completeDialogVisible ? t('retract') : t('retracted')}
        </TextDecorator>
      </Button>

      <Dialog.Confirmation
        confirmButtonText={loading ? t('retracting') : t('retract')}
        message={
          sanitizedRetractParams.length > 0 ? (
            <>
              <Text as="p" color="$gray800" font="sans" size="md" stretch="ultraCondensed" weight="medium">
                {t('selectDestination')}
              </Text>
              <form>
                <Stack>
                  {RETRACT_DESTINATIONS.map((destination: string) => (
                    <Box css={{ label: { fontFamily: '$roboto !important' } }} key={destination}>
                      <Radio
                        block
                        label={t(`components:MailTraceResults.${destination}`)}
                        checked={retractDestination === destination}
                        onChange={handleDestinationChange}
                        value={destination}
                      />
                    </Box>
                  ))}
                </Stack>
              </form>
            </>
          ) : (
            <Text as="p" font="sans" size="md" stretch="ultraCondensed" weight="medium">
              {t('cannotRetract')}
            </Text>
          )
        }
        handleClose={onConfirmClose}
        handleConfirm={onConfirm}
        visible={confirmDialogVisible}
      />

      <Dialog.Alert
        confirmButtonText="OK"
        message={
          <Text as="p" font="sans" size="md" stretch="ultraCondensed" weight="medium">
            {t('messageRetracted')}
          </Text>
        }
        handleClose={closeAlertDialog}
        visible={completeDialogVisible}
      />

      <Dialog.Alert
        confirmButtonText="OK"
        maxWidth
        message={
          <>
            <Text as="p" font="sans" size="md" stretch="ultraCondensed" weight="medium">
              {t('messageRetractionProblem')}
            </Text>

            <Table>
              <Table.Header>
                <HeaderCell css={{ width: '50%' }}>{t('Message ID')}</HeaderCell>
                <HeaderCell>{t('Destination folder')}</HeaderCell>
                <HeaderCell>{t('Status')}</HeaderCell>
              </Table.Header>

              <Table.Main>
                {messageDetails.length > 0 &&
                  messageDetails.map((message) => (
                    <Table.Row key={message.id}>
                      <DataCell>{message.id}</DataCell>
                      <DataCell>{message.destinationFolder}</DataCell>
                      <DataCell>{message.myStatus}</DataCell>
                    </Table.Row>
                  ))}
              </Table.Main>
            </Table>
          </>
        }
        handleClose={closeErrorDialog}
        visible={errorDialogVisible}
      />
    </>
  );
}

//
// Private components
// -------------------------------------------------------------------------------------------------

interface TextDecoratorType {
  children: ReactElement;
  disabled?: boolean;
}

function TextDecorator({ children, disabled }: TextDecoratorType) {
  if (disabled) return <s>{children}</s>;
  return children;
}

type Cellprops = {
  css?: CSS;
  children: string;
};
function HeaderCell({ css, children }: Cellprops) {
  return (
    <Table.HeaderCell>
      <Text
        as="div"
        color="$gray800"
        css={{ ...css, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
        font="sans"
        stretch="ultraCondensed"
        size="sm"
        transform="uppercase"
        weight="semibold"
      >
        {children}
      </Text>
    </Table.HeaderCell>
  );
}

interface DataCellProps {
  border?: ComponentProps<typeof Table.Cell>['border'];
}

function DataCell({ border, children }: PropsWithChildren<DataCellProps>) {
  return (
    <Table.Cell border={border} css={{ lineBreak: 'strict', wordBreak: 'break-all', webkitWordBreak: 'break-word' }}>
      <Text color="$gray600" size="sm" font="sans" stretch="ultraCondensed">
        {children}
      </Text>
    </Table.Cell>
  );
}
