"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toQueryString = toQueryString;

var _queryString = require("query-string");

/**
 * Stringify an object into a URL [query string](https://en.wikipedia.org/wiki/Query_string) while sorting the keys.
 */
function toQueryString(value) {
  return (0, _queryString.stringify)(value);
}