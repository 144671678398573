"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useWindowFocus = useWindowFocus;

var _react = require("react");

/**
 * Returns, as a stateful value, `true` if the broswer window is focused and `false` if it is not. The stateful value is
 * updated when the browser window's focus changes.
 */
function useWindowFocus() {
  const [isFocused, setIsFocused] = (0, _react.useState)(true);
  (0, _react.useEffect)(() => {
    function handleBlur() {
      setIsFocused(false);
    }

    function handleFocus() {
      setIsFocused(true);
    }

    window.addEventListener('blur', handleBlur);
    window.addEventListener('focus', handleFocus);
    return () => {
      window.removeEventListener('blur', handleBlur);
      window.removeEventListener('focus', handleFocus);
    };
  });
  return isFocused;
}