import { styled, Box, Button, Table } from '@a1s/ui';
import { useFeatureFlag } from '@marshall/hooks';
import { loader } from 'graphql.macro';
import hash from 'object-hash';
import React, { useEffect, useMemo, ComponentProps, MouseEvent } from 'react';
import { useQuery, QueryResult } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { Waypoint } from 'react-waypoint';

import { fixMessagesTs, toVariables } from '../../../lib';
import { Dispositions, SearchResultRow } from '../../../types';
import {
  MetaCell,
  ModalHeader,
  ReasonCell,
  ReleaseButton,
  RenderNoData,
  RetractButton,
  RowWrapper,
  SectionHeader,
  StatusCell,
  ThreatCell,
} from '../../../ui';

import { Scrollable } from 'ui-new';

//
// Main component
// -------------------------------------------------------------------------------------------------

interface ResultsProps {
  checked?: Array<SearchResultRow['messageId']>;

  collapsed: boolean;

  // NOTE: this will probably need to be reworked so the event handler can get the row ID
  onPressViewButton?: RowProps['onPressViewButton'];

  // eslint-disable-next-line  no-unused-vars
  onRowCheck?(checked: Array<SearchResultRow['messageId']>): void;

  // eslint-disable-next-line no-unused-vars
  onSearchFinished?(counts: ComponentProps<typeof SectionHeader>['searchCounts']): void;

  /**
   * The string and date to run the search query on.
   */
  search: ComponentProps<typeof ModalHeader>['params'];

  /**
   * The message ID of the message details currently selected
   */
  selectedMessageId?: string;
}

export function Results({
  checked = [],
  collapsed,
  onPressViewButton,
  onRowCheck,
  onSearchFinished,
  search,
  selectedMessageId,
}: ResultsProps) {
  const clawbackFeatureEnabled = useFeatureFlag('clawback');
  const { data, error, loading, fetchMore } = useRemoteData(search);

  const effectDependency = hash({ data });
  useEffect(() => {
    if (!onSearchFinished) return;
    if (!data?.length) return;

    const detections = data.filter((d) => d.finalDisposition.toLowerCase() !== 'none');
    onSearchFinished({ total: data.length, detections: detections.length });
  }, [effectDependency]); // eslint-disable-line react-hooks/exhaustive-deps

  function handleCheck(id: string, isChecked: boolean) {
    if (!onRowCheck) return;
    if (isChecked) {
      onRowCheck([...checked, id]);
    } else {
      onRowCheck(checked.filter((i) => i !== id));
    }
  }

  function handleEnter() {
    if (data?.length && fetchMore) fetchMore();
  }

  const hasData = data && data.length > 0 && !loading && !error;

  return (
    <Box css={{ flexGrow: 1, height: '100%', minWidth: 470, position: 'relative' }}>
      <Scrollable>
        <Table>
          <tbody>
            {hasData && !loading ? (
              <>
                {data.map((row, index) => (
                  <Row
                    checked={checked.includes(row.messageId)}
                    collapsed={collapsed}
                    clawbackFeatureEnabled={clawbackFeatureEnabled}
                    data={row}
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${row.messageId}-${index}`}
                    onCheck={handleCheck}
                    onPressViewButton={onPressViewButton}
                    selectedMessageId={selectedMessageId}
                  />
                ))}
                <Waypoint onEnter={handleEnter} />
              </>
            ) : (
              <tr>
                <td>
                  <RenderNoData loading={loading} term={search.searchTerm} />
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Scrollable>
    </Box>
  );
}

//
// Private components
// -------------------------------------------------------------------------------------------------

interface BlankCellProps {
  hidden?: boolean;
}

function BlankCell({ hidden }: BlankCellProps) {
  return <Table.Cell border="none" css={{ display: hidden === true ? 'none' : undefined }} />;
}

interface RowProps {
  checked: ComponentProps<typeof MetaCell>['checked'];
  clawbackFeatureEnabled?: boolean;
  collapsed: ResultsProps['collapsed'];
  data: SearchResultRow;
  onCheck: ComponentProps<typeof MetaCell>['onCheck'];
  onPressViewButton?: (details: SearchResultRow) => void; // eslint-disable-line no-unused-vars
  selectedMessageId?: string;
}

function Row({
  checked,
  clawbackFeatureEnabled,
  collapsed,
  data,
  onCheck,
  onPressViewButton,
  selectedMessageId,
}: RowProps) {
  const { t } = useTranslation('unisearch');

  const {
    clientRecipients,
    isQuarantined,
    messageId,
    phishSubmission,
    storedAt,
    threatCatsBlocking,
    threatCatsSpam,
    threatCatsSuspicious,
    validation,
  } = data;

  const allFindings = Array.from(
    new Set([...(threatCatsBlocking || []), ...(threatCatsSpam || []), ...(threatCatsSuspicious || [])])
  );

  const handlePressView = () => {
    if (onPressViewButton) onPressViewButton(data);
  };

  function handleRowClick(e: MouseEvent<HTMLElement>) {
    e.stopPropagation();
    if (collapsed && onPressViewButton) onPressViewButton(data);
  }

  return (
    <RowWrapper collapsed={collapsed} onClick={handleRowClick} selected={selectedMessageId === messageId}>
      <MetaCell
        checked={checked}
        from={data.from}
        messageId={messageId}
        onCheck={onCheck}
        subject={data.subject}
        timestamp={data.ts}
        to={clientRecipients}
      />
      <StatusCell
        disposition={data.finalDisposition}
        isQuarantined={isQuarantined}
        redressedActions={data.redressedActions}
        truncated={collapsed}
        validation={validation}
      />
      <RenderThreatCell collapsed={collapsed} dispositionType={data.finalDisposition} findings={allFindings} />
      <RenderReasonCell collapsed={collapsed} detectionReasons={data.detectionReasons} />
      <Table.Cell border="none" css={{ display: collapsed === true ? 'none' : undefined }}>
        <ButtonWrapper>
          {isQuarantined && <ReleaseButton releaseParams={[{ clientRecipients, storedAt }]} />}
          {!phishSubmission && !isQuarantined && (
            <RetractButton
              clawbackFeatureEnabled={clawbackFeatureEnabled}
              retractParams={[{ clientRecipients, messageId }]}
            />
          )}
          <Button onPress={handlePressView}>{t('view')}</Button>
        </ButtonWrapper>
      </Table.Cell>
    </RowWrapper>
  );
}

interface RenderThreatCellProps {
  collapsed: ResultsProps['collapsed'];
  dispositionType: Dispositions;
  findings: string[];
}

function RenderThreatCell({ collapsed, dispositionType, findings }: RenderThreatCellProps) {
  if (dispositionType !== 'NONE') {
    return <ThreatCell findings={findings} hidden={collapsed} />;
  }

  return <BlankCell hidden={collapsed} />;
}

interface ReasonCellProps {
  collapsed: ResultsProps['collapsed'];
  detectionReasons: SearchResultRow['detectionReasons'];
}

function RenderReasonCell({ collapsed, detectionReasons = [] }: ReasonCellProps) {
  if (detectionReasons.length > 0) {
    return <ReasonCell data={detectionReasons} hidden={collapsed} />;
  }

  return <BlankCell hidden={collapsed} />;
}

//
// Private hooks
// -------------------------------------------------------------------------------------------------

const query = loader('../queries/search.graphql');

interface HookResult {
  /**
   * The data that has been returned from the API
   */
  data?: SearchResultRow[];

  /**
   * If there is a problem loading the data, the error information will be available as an error object
   */
  error: QueryResult['error'] | null;

  /**
   * Returns a function that can be used to fetch more data
   */
  fetchMore?(): void;

  /**
   * Returns true if the data is currently being loaded
   */
  loading: boolean;
}

function useRemoteData(search: ResultsProps['search']): HookResult {
  const date = useMemo(() => new Date(), []);

  const { data, error, fetchMore, loading } = useQuery(query, {
    fetchPolicy: 'network-only',
    variables: toVariables(date, search),
  });

  if (error) return { data: undefined, error, fetchMore: undefined, loading: false };
  if (!data?.unisearchResults?.messages) return { data: undefined, error: null, fetchMore: undefined, loading };

  function more() {
    fetchMore({
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        return {
          unisearchResults: {
            // @ts-ignore
            messages: [...(prev.unisearchResults.messages || []), ...(fetchMoreResult.unisearchResults.messages || [])],
            __typename: 'UnisearchDetectionSearchResult',
          },
        };
      },
      variables: { limit: 50, offset: data?.unisearchResults?.messages.length },
    });
  }
  const results = fixMessagesTs(data.unisearchResults.messages);

  return { data: results, error, fetchMore: more, loading };
}

//
// Styled components
// -------------------------------------------------------------------------------------------------

const ButtonWrapper = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  float: 'right',
  gap: '$2',
  justifyContent: 'end',
  maxWidth: 180,
  width: '100%',
});
