// @flow

import { rem } from 'polished';
import styled from 'styled-components';

export default styled.label`
  color: ${({ theme }) => theme.colors.spanishGray};
  font-size: ${rem(14)};
  margin-left: ${({ theme }) => rem(theme.spacing.sm)};
  margin-right: ${({ theme }) => rem(theme.spacing.sm)};
  white-space: nowrap;
`;
