"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledBox = void 0;

var _styled = require("../../styled");

var _utils = require("../../utils");

//
// Styled components
// -------------------------------------------------------------------------------------------------
const StyledBox = (0, _styled.styled)('div', {
  boxSizing: 'border-box',
  variants: {
    //
    // Dimensional props
    // -------------------------------------------------------------------------------------------------

    /**
     * Sets minimum height of the box.
     * @type $sizes
     */
    height: (0, _utils.tokenVariant)('minHeight', _styled.theme.sizes),

    /**
     * Sets min width of the box.
     * @type $sizes
     */
    width: (0, _utils.tokenVariant)('minWidth', _styled.theme.sizes),
    //
    // Border props
    // -------------------------------------------------------------------------------------------------

    /**
     * Sets width of the border of the box.
     * @type $borderWidths
     */
    b: {
      true: {
        b: '$1'
      },
      0: {
        b: '$0'
      },
      1: {
        b: '$1'
      },
      2: {
        b: '$2'
      }
    },

    /**
     * Sets width of the bottom border of the box.
     * @type $borderWidths
     */
    bb: {
      true: {
        bb: '$1'
      },
      0: {
        bb: '$0'
      },
      1: {
        bb: '$1'
      },
      2: {
        bb: '$2'
      }
    },

    /**
     * Sets width of the left border of the box.
     * @type $borderWidths
     */
    bl: {
      true: {
        bl: '$1'
      },
      0: {
        bl: '$0'
      },
      1: {
        bl: '$1'
      },
      2: {
        bl: '$2'
      }
    },

    /**
     * Sets width of the right border of the box.
     * @type $borderWidths
     */
    br: {
      true: {
        br: '$1'
      },
      0: {
        br: '$0'
      },
      1: {
        br: '$1'
      },
      2: {
        br: '$2'
      }
    },

    /**
     * Sets width of the top border of the box.
     * @type $borderWidths
     */
    bt: {
      true: {
        b: '$1'
      },
      0: {
        bt: '$0'
      },
      1: {
        bt: '$1'
      },
      2: {
        bt: '$2'
      }
    },
    //
    // Padding props
    // -------------------------------------------------------------------------------------------------

    /**
     * Sets the padding for all edges of the box.
     * @type $space
     */
    p: {
      true: {
        p: '$3'
      },
      none: {
        p: '$none'
      },
      px: {
        p: '$px'
      },
      0.5: {
        p: '$0.5'
      },
      1: {
        p: '$1'
      },
      1.5: {
        p: '$1.5'
      },
      2: {
        p: '$2'
      },
      2.5: {
        p: '$2.5'
      },
      3: {
        p: '$3'
      },
      3.5: {
        p: '$3.5'
      },
      4: {
        p: '$4'
      },
      5: {
        p: '$5'
      },
      6: {
        p: '$6'
      },
      7: {
        p: '$7'
      },
      8: {
        p: '$8'
      },
      9: {
        p: '$9'
      },
      10: {
        p: '$10'
      },
      11: {
        p: '$11'
      },
      12: {
        p: '$12'
      }
    },

    /**
     * Sets the padding padding for the bottom edge of the box.
     * @type $space
     */
    pb: {
      true: {
        pb: '$3'
      },
      none: {
        pb: '$none'
      },
      px: {
        pb: '$px'
      },
      0.5: {
        pb: '$0.5'
      },
      1: {
        pb: '$1'
      },
      1.5: {
        pb: '$1.5'
      },
      2: {
        pb: '$2'
      },
      2.5: {
        pb: '$2.5'
      },
      3: {
        pb: '$3'
      },
      3.5: {
        pb: '$3.5'
      },
      4: {
        pb: '$4'
      },
      5: {
        pb: '$5'
      },
      6: {
        pb: '$6'
      },
      7: {
        pb: '$7'
      },
      8: {
        pb: '$8'
      },
      9: {
        pb: '$9'
      },
      10: {
        pb: '$10'
      },
      11: {
        pb: '$11'
      },
      12: {
        pb: '$12'
      }
    },

    /**
     * Sets the padding for the left edge of the box.
     * @type $space
     */
    pl: {
      true: {
        pl: '$3'
      },
      none: {
        pl: '$none'
      },
      px: {
        pl: '$px'
      },
      0.5: {
        pl: '$0.5'
      },
      1: {
        pl: '$1'
      },
      1.5: {
        pl: '$1.5'
      },
      2: {
        pl: '$2'
      },
      2.5: {
        pl: '$2.5'
      },
      3: {
        pl: '$3'
      },
      3.5: {
        pl: '$3.5'
      },
      4: {
        pl: '$4'
      },
      5: {
        pl: '$5'
      },
      6: {
        pl: '$6'
      },
      7: {
        pl: '$7'
      },
      8: {
        pl: '$8'
      },
      9: {
        pl: '$9'
      },
      10: {
        pl: '$10'
      },
      11: {
        pl: '$11'
      },
      12: {
        pl: '$12'
      }
    },

    /**
     * Sets the padding for the right edge of the box.
     * @type $space
     */
    pr: {
      true: {
        pr: '$3'
      },
      none: {
        pr: '$none'
      },
      px: {
        pr: '$px'
      },
      0.5: {
        pr: '$0.5'
      },
      1: {
        pr: '$1'
      },
      1.5: {
        pr: '$1.5'
      },
      2: {
        pr: '$2'
      },
      2.5: {
        pr: '$2.5'
      },
      3: {
        pr: '$3'
      },
      3.5: {
        pr: '$3.5'
      },
      4: {
        pr: '$4'
      },
      5: {
        pr: '$5'
      },
      6: {
        pr: '$6'
      },
      7: {
        pr: '$7'
      },
      8: {
        pr: '$8'
      },
      9: {
        pr: '$9'
      },
      10: {
        pr: '$10'
      },
      11: {
        pr: '$11'
      },
      12: {
        pr: '$12'
      }
    },

    /**
     * Sets the padding for the top edge of the box.
     * @type $space
     */
    pt: {
      true: {
        pt: '$3'
      },
      none: {
        pt: '$none'
      },
      px: {
        pt: '$px'
      },
      0.5: {
        pt: '$0.5'
      },
      1: {
        pt: '$1'
      },
      1.5: {
        pt: '$1.5'
      },
      2: {
        pt: '$2'
      },
      2.5: {
        pt: '$2.5'
      },
      3: {
        pt: '$3'
      },
      3.5: {
        pt: '$3.5'
      },
      4: {
        pt: '$4'
      },
      5: {
        pt: '$5'
      },
      6: {
        pt: '$6'
      },
      7: {
        pt: '$7'
      },
      8: {
        pt: '$8'
      },
      9: {
        pt: '$9'
      },
      10: {
        pt: '$10'
      },
      11: {
        pt: '$11'
      },
      12: {
        pt: '$12'
      }
    },

    /**
     * Sets the padding for the left and right edges of the box.
     * @type $space
     */
    px: {
      true: {
        px: '$3'
      },
      none: {
        px: '$none'
      },
      px: {
        px: '$px'
      },
      0.5: {
        px: '$0.5'
      },
      1: {
        px: '$1'
      },
      1.5: {
        px: '$1.5'
      },
      2: {
        px: '$2'
      },
      2.5: {
        px: '$2.5'
      },
      3: {
        px: '$3'
      },
      3.5: {
        px: '$3.5'
      },
      4: {
        px: '$4'
      },
      5: {
        px: '$5'
      },
      6: {
        px: '$6'
      },
      7: {
        px: '$7'
      },
      8: {
        px: '$8'
      },
      9: {
        px: '$9'
      },
      10: {
        px: '$10'
      },
      11: {
        px: '$11'
      },
      12: {
        px: '$12'
      }
    },

    /**
     * Sets the padding for the bottom and top edges of the box.
     * @type $space
     */
    py: {
      true: {
        py: '$3'
      },
      none: {
        py: '$none'
      },
      px: {
        py: '$px'
      },
      0.5: {
        py: '$0.5'
      },
      1: {
        py: '$1'
      },
      1.5: {
        py: '$1.5'
      },
      2: {
        py: '$2'
      },
      2.5: {
        py: '$2.5'
      },
      3: {
        py: '$3'
      },
      3.5: {
        py: '$3.5'
      },
      4: {
        py: '$4'
      },
      5: {
        py: '$5'
      },
      6: {
        py: '$6'
      },
      7: {
        py: '$7'
      },
      8: {
        py: '$8'
      },
      9: {
        py: '$9'
      },
      10: {
        py: '$10'
      },
      11: {
        py: '$11'
      },
      12: {
        py: '$12'
      }
    },
    //
    // Border radius prop
    // -------------------------------------------------------------------------------------------------

    /**
     * Rounds the corners of the box.
     * @type $radii
     */
    r: {
      true: {
        r: '$2'
      },
      1: {
        r: '$1'
      },
      2: {
        r: '$2'
      },
      3: {
        r: '$3'
      },
      4: {
        r: '$4'
      },
      5: {
        r: '$5'
      },
      6: {
        r: '$6'
      },
      7: {
        r: '$7'
      },
      full: {
        r: '$full'
      }
    },

    /**
     * Rounds the bottom corners of the box.
     * @type $radii
     */
    rb: {
      true: {
        rb: '$2'
      },
      1: {
        rb: '$1'
      },
      2: {
        rb: '$2'
      },
      3: {
        rb: '$3'
      },
      4: {
        rb: '$4'
      },
      5: {
        rb: '$5'
      },
      6: {
        rb: '$6'
      },
      7: {
        rb: '$7'
      }
    },

    /**
     * Rounds the bottom-left corner of the box.
     * @type $radii
     */
    rbl: {
      true: {
        rbl: '$2'
      },
      1: {
        rbl: '$1'
      },
      2: {
        rbl: '$2'
      },
      3: {
        rbl: '$3'
      },
      4: {
        rbl: '$4'
      },
      5: {
        rbl: '$5'
      },
      6: {
        rbl: '$6'
      },
      7: {
        rbl: '$7'
      }
    },

    /**
     * Rounds the bottom-right corner of the box.
     * @type $radii
     */
    rbr: {
      true: {
        rbr: '$2'
      },
      1: {
        rbr: '$1'
      },
      2: {
        rbr: '$2'
      },
      3: {
        rbr: '$3'
      },
      4: {
        rbr: '$4'
      },
      5: {
        rbr: '$5'
      },
      6: {
        rbr: '$6'
      },
      7: {
        rbr: '$7'
      }
    },

    /**
     * Rounds the left corners of the box.
     * @type $radii
     */
    rl: {
      true: {
        rl: '$2'
      },
      1: {
        rl: '$1'
      },
      2: {
        rl: '$2'
      },
      3: {
        rl: '$3'
      },
      4: {
        rl: '$4'
      },
      5: {
        rl: '$5'
      },
      6: {
        rl: '$6'
      },
      7: {
        rl: '$7'
      }
    },

    /**
     * Rounds the right corners of the box.
     * @type $radii
     */
    rr: {
      true: {
        rr: '$2'
      },
      1: {
        rr: '$1'
      },
      2: {
        rr: '$2'
      },
      3: {
        rr: '$3'
      },
      4: {
        rr: '$4'
      },
      5: {
        rr: '$5'
      },
      6: {
        rr: '$6'
      },
      7: {
        rr: '$7'
      }
    },

    /**
     * Rounds the top corners of the box.
     * @type $radii
     */
    rt: {
      true: {
        rt: '$2'
      },
      1: {
        rt: '$1'
      },
      2: {
        rt: '$2'
      },
      3: {
        rt: '$3'
      },
      4: {
        rt: '$4'
      },
      5: {
        rt: '$5'
      },
      6: {
        rt: '$6'
      },
      7: {
        rt: '$7'
      }
    },

    /**
     * Rounds the top-left corner of the box.
     * @type $radii
     */
    rtl: {
      true: {
        rtl: '$2'
      },
      1: {
        rtl: '$1'
      },
      2: {
        rtl: '$2'
      },
      3: {
        rtl: '$3'
      },
      4: {
        rtl: '$4'
      },
      5: {
        rtl: '$5'
      },
      6: {
        rtl: '$6'
      },
      7: {
        rtl: '$7'
      }
    },

    /**
     * Rounds the top-right corner of the box.
     * @type $radii
     */
    rtr: {
      true: {
        rtr: '$2'
      },
      1: {
        rtr: '$1'
      },
      2: {
        rtr: '$2'
      },
      3: {
        rtr: '$3'
      },
      4: {
        rtr: '$4'
      },
      5: {
        rtr: '$5'
      },
      6: {
        rtr: '$6'
      },
      7: {
        rtr: '$7'
      }
    },
    //
    // Shadow prop
    // -------------------------------------------------------------------------------------------------

    /**
     * Adds a drop shadow to the box.
     * @type $shadows
     */
    shadow: {
      true: {
        boxShadow: '$1'
      },
      1: {
        boxShadow: '$1'
      }
    }
  }
});
exports.StyledBox = StyledBox;