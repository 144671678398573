// @flow

import hash from 'hash-sum';
import { rem } from 'polished';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ReactComponent as ErrorIcon } from './error.svg';

type FailuresType = {
  recipient: string,
  status: string,
};

type FailedRetractionsType = {
  failures: Array<FailuresType>,
  message: string,
};

type Props = {
  failedRetractions: FailedRetractionsType,
};

const StyledErrorIcon = styled(ErrorIcon)`
  height: ${rem(12)};
  position: relative;
  width: ${rem(12)};
`;

export default function RetractionFailures({ failedRetractions }: Props) {
  const { t } = useTranslation('retractionFailures');
  const { failures } = failedRetractions;

  return (
    <>
      {failures.map((failure, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <p key={hash(failure) + i}>
          <StyledErrorIcon /> <strong>{failure.recipient}</strong>
          <br />
          <em>- {t(translateForI18n[failure.status] ? translateForI18n[failure.status] : failure.status)}</em>
        </p>
      ))}
    </>
  );
}

//
// Private variables
// -------------------------------------------------------------------------------------------------

const translateForI18n = {
  'message id not found': 'noMessageId',
  'missing required data from request': 'missingData',
  'The request failed. The request failed. The remote server returned an error: (401)Unauthorized': '401Unauthorized',
  'The request failed. The request failed. Read timed out': 'readTimeOut',
  'The request failed. The SMTP address has no mailbox associated with it.': 'noSMTP',
  'The specified object was not found in the store., The process failed to get the correct properties.':
    'objectNotFound',
};
