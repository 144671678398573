// @flow

import { loader } from 'graphql.macro';

type MaliciousTargetedRecipient = {
  address?: string,
  count?: number,
};

type MaliciousTargetsType = {
  maliciousTargetedRecipients: Array<MaliciousTargetedRecipient>,
};

export const directoriesQuery = loader(
  '../../../../../Settings/screens/DirectoryManagement/queries/FetchDirectoriesQuery.graphql'
);
export const displayNamesQuery = loader('./queries/DisplayNamesQuery.graphql');
export const maliciousTargetsQuery = loader('./queries/MaliciousTargetsQuery.graphql');

export const maliciousTargetsRecord: MaliciousTargetsType = {
  maliciousTargetedRecipients: [],
};
