import { useDebounce } from '@a1s/hooks';
import { encodeBase64 } from '@a1s/lib';
import { styled, Box, Cluster, Dropdown, Stack, TabList, Text } from '@a1s/ui';
import gql from 'graphql-tag';
// @ts-ignore
import Cookies from 'js-cookie';
import React, { useState, ComponentProps, ChangeEvent, FormEvent, MouseEvent } from 'react';
import { useQuery } from 'react-apollo';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { Checkbox, DateRangeDropdown, IconButton, SearchInput } from '..';

import CSVDownloadButton from './CSVDownloadButton';

import { usePermissions } from 'screens/Search/lib/permissions';
import { CloseButton } from 'ui-new';
import { useCurrentUser } from 'utils/hooks/useCurrentUser';
import useGlobalSearch from 'utils/hooks/useGlobalSearch';

//
// Constants
// -------------------------------------------------------------------------------------------------

interface DispositionOptionType {
  key: 'all' | 'bulk' | 'malicious' | 'malicious-bec' | 'spam' | 'suspicious' | 'spoof';
  text: 'All Detections' | 'Bulk' | 'Malicious' | 'Malicious BEC' | 'Spam' | 'Suspicious' | 'Spoof';
}

const DISPOSITION_OPTIONS = [
  { key: 'all', text: 'All Detections' },
  { key: 'bulk', text: 'Bulk' },
  { key: 'malicious', text: 'Malicious' },
  { key: 'malicious-bec', text: 'Malicious BEC' },
  { key: 'spam', text: 'Spam' },
  { key: 'suspicious', text: 'Suspicious' },
  { key: 'spoof', text: 'Spoof' },
] as DispositionOptionType[];

const SEARCH_HINTS_ID = 'search-hints';

//
// Main component
// -------------------------------------------------------------------------------------------------

type Disposition = 'all' | 'bulk' | 'malicious' | 'malicious-bec' | 'spam' | 'suspicious' | 'spoof';

interface FieldedFields {
  alertId?: string;
  domain?: string;
  messageId?: string;
  recipient?: string;
  sender?: string;
  subject?: string;
}

interface SearchParams extends FieldedFields {
  daysBack: ComponentProps<typeof DateRangeDropdown>['value'];
  finalDisposition: Disposition;
  redress?: string;
  searchMode: 'basic' | 'fielded' | 'freeform';
  searchTerm?: string;
  searchType: 'all-mail' | 'detection-only';
}

interface ModalHeaderProps {
  /* eslint-disable no-unused-vars */
  /**
   * Callback that is called when the search form is submitted
   */
  onSearch(params: SearchParams): void;

  /**
   * Callback is called when the search mode changes
   */
  onSearchModeChange: (mode: SearchParams['searchMode'], params: SearchParams) => void;

  /**
   * Callback is called when the search type changes
   */
  onSearchTypeChange: (
    tab: SearchParams['searchType'],
    search: SearchParams['searchTerm'],
    finalDisposition?: Disposition
  ) => void;

  /**
   * Various search parameters set by the user in the form
   */
  params: SearchParams;
  /* eslint-enable no-unused-vars */
}

/**
 * Displays the header of the whole unifed search modal. Contains the search input and ways to filter
 * the search results. Curiously, it also has options for downloading the results in different formats.
 */
export function ModalHeader({ onSearch, onSearchModeChange, onSearchTypeChange, params }: ModalHeaderProps) {
  const {
    alertId,
    daysBack,
    domain,
    finalDisposition,
    messageId,
    recipient,
    searchMode,
    searchTerm,
    searchType,
    sender,
    subject,
  } = params;
  const {
    user: { maliciousBecEnabled = false },
  } = useCurrentUser();
  const searchDetections = useGlobalSearch('detectionSearch');
  const history = useHistory();
  const userPermitted = usePermissions();
  const [fieldedParams, setFieldedParams] = useState<FieldedFields>({
    alertId,
    domain,
    messageId,
    recipient,
    sender,
    subject,
  });
  const [searchParams, setSearchParams] = useState<SearchParams>({
    daysBack,
    finalDisposition,
    searchMode,
    searchTerm,
    searchType,
  });
  const [showHints, setShowHints] = useState(false);
  const { t } = useTranslation('unisearch');

  function handleOnEnter(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') {
      event.preventDefault();
      onSearch({ ...searchParams, ...fieldedParams });
    }
  }

  function handleDetectionToggleChange(checked: boolean) {
    if (!onSearchTypeChange) return;

    if (checked) onSearchTypeChange('detection-only', searchParams.searchTerm);
    else {
      setSearchParams({ ...params, finalDisposition: 'all' });
      onSearchTypeChange('all-mail', searchParams.searchTerm, 'all');
    }
  }

  function handleFieldChange(field: keyof FieldedFields, value: string) {
    setFieldedParams({ ...fieldedParams, [field]: value });
  }

  function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
    setShowHints(true);
    setSearchParams({ ...params, searchTerm: event.target.value });
  }

  function handlePress(event: MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();

    if (document.body.dataset.closePath) {
      history.push(document.body.dataset.closePath);
      delete document.body.dataset.closePath;
    } else {
      history.push(Cookies.get('searchLaunchedFrom') || '/home');
    }
  }

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setShowHints(false);
    onSearch({ ...searchParams, ...fieldedParams });
  }

  function handleGoBackToOldSearch(event: any) {
    event.preventDefault();
    event.stopPropagation();
    const { finalDisposition: disposition, searchTerm: term } = searchParams;
    Cookies.set('currentSearch', 'classic');
    ReactGA.event({
      category: 'Search preference set',
      action: 'Classic search selected',
      label: 'user selected Classic search',
    });

    searchDetections({
      query: getSearchQuery({ disposition, searchTerm: term, recipient }),
    });
    history.push(Cookies.get('searchLaunchedFrom') || '/home');
  }

  function onDaysBackDropdownChange(days: SearchParams['daysBack']) {
    setSearchParams({ ...params, daysBack: days, searchTerm: searchParams.searchTerm });
    onSearch({ ...params, ...fieldedParams, daysBack: days, searchTerm: searchParams.searchTerm });
  }

  function onDispositionDropdownChange(dispositionType: Disposition) {
    if (dispositionType === 'all') {
      setSearchParams({ ...params, finalDisposition: 'all' });
      onSearch({ ...params, ...fieldedParams, finalDisposition: 'all' });
    } else {
      setSearchParams({ ...params, finalDisposition: dispositionType });
      onSearch({ ...params, ...fieldedParams, finalDisposition: dispositionType });
    }
  }

  return (
    <StyledForm onSubmit={handleSubmit}>
      <Box px="6" py="5">
        <Cluster align="baseline" gap="12" justify="space-between">
          <Cluster align="baseline" css={{ flexBasis: '50%', flexGrow: 1 }} gap="6">
            <Text
              as="h2"
              color="$gray700"
              font="sans"
              size="lg"
              stretch="ultraCondensed"
              transform="uppercase"
              weight="semibold"
            >
              {t('emailSearch')}
            </Text>
            <Stack css={{ flexBasis: '50%', flexGrow: 1 }} gap="3">
              <Cluster align="center" css={{ flexBasis: '100%', flexGrow: 1 }} gap="6">
                <SearchInput
                  list={SEARCH_HINTS_ID}
                  onKeyDown={handleOnEnter}
                  onChange={handleInputChange}
                  placeholder={t('terms')}
                  value={searchParams.searchTerm}
                />
                {showHints && <SearchHints query={searchParams.searchTerm} />}
              </Cluster>
              {params.searchMode === 'fielded' && (
                <FieldedSearch
                  onKeyDown={handleOnEnter}
                  onFieldChange={handleFieldChange}
                  params={{ ...searchParams, ...fieldedParams }}
                />
              )}
            </Stack>

            <Stack align="end" gap="2">
              <TabList>
                <TabList.Tab
                  css={{ width: 168 }}
                  onClick={() =>
                    onSearchModeChange && onSearchModeChange('fielded', { ...searchParams, ...fieldedParams })
                  }
                  selected={params.searchMode === 'fielded'}
                >
                  {t('Fielded Search')}
                </TabList.Tab>
                <TabList.Tab
                  css={{ width: 168 }}
                  onClick={() =>
                    onSearchModeChange && onSearchModeChange('freeform', { searchTerm: searchParams.searchTerm })
                  }
                  selected={params.searchMode === 'freeform'}
                >
                  {t('Freeform Search')}
                </TabList.Tab>
              </TabList>
              <Text
                as="button"
                color="$blue400"
                css={{ backgroundColor: 'transparent', borderWidth: 0, cursor: 'pointer', textDecoration: 'underline' }}
                font="sans"
                size="sm"
                onClick={handleGoBackToOldSearch}
                stretch="ultraCondensed"
                weight="regular"
              >
                {t('switchBack')}
              </Text>
            </Stack>
          </Cluster>

          <div style={{ flexBasis: '50px', flexGrow: 1, flexShrink: 1 }} />

          <Cluster gap="8">
            <DetectionToggle
              checked={params.searchType === 'detection-only'}
              disabled={'redress' in params}
              onChange={handleDetectionToggleChange}
            />
            <Dropdown
              disabled={params.searchType === 'all-mail'}
              onChange={(selected: Disposition) => onDispositionDropdownChange(selected)}
              value={searchParams.finalDisposition}
            >
              {getDispositionOptions(maliciousBecEnabled).map((displayDisposition) => (
                <Dropdown.Option key={displayDisposition.key} value={displayDisposition.key}>
                  {t(displayDisposition.text)}
                </Dropdown.Option>
              ))}
            </Dropdown>

            <DateRangeDropdown onChange={onDaysBackDropdownChange} value={searchParams.daysBack} />

            {userPermitted && <IconButton disabled icon="pdf" title={t('comingSoon')} />}
            <CSVDownloadButton params={params} />

            <CloseButton onPress={handlePress} />
          </Cluster>
        </Cluster>
      </Box>
    </StyledForm>
  );
}

//
// Private components
// -------------------------------------------------------------------------------------------------

interface FieldedSearchProps {
  params: SearchParams;
  onKeyDown: ComponentProps<typeof SearchInput>['onKeyDown'];
  // eslint-disable-next-line no-unused-vars
  onFieldChange?(name: keyof FieldedFields, value: FieldedFields[keyof FieldedFields]): void;
}
function FieldedSearch({ onKeyDown, onFieldChange, params }: FieldedSearchProps) {
  const { t } = useTranslation('unisearch');

  function handleChange(field: keyof FieldedFields) {
    return (event: ChangeEvent<HTMLInputElement>) => {
      if (!onFieldChange) return;
      onFieldChange(field, event.target.value);
    };
  }

  return (
    <Stack gap="4">
      <Cluster gap="4" nowrap>
        <SearchInput
          css={{ flexBasis: '20%', width: '20%' }}
          onKeyDown={onKeyDown}
          onChange={handleChange('sender')}
          placeholder={t('fromExact')}
          value={params?.sender}
        />
        <SearchInput
          css={{ flexBasis: '20%', width: '20%' }}
          onKeyDown={onKeyDown}
          onChange={handleChange('recipient')}
          placeholder={t('toExact')}
          value={params?.recipient}
        />
        <SearchInput
          css={{ flexBasis: '40%', width: '40%' }}
          onKeyDown={onKeyDown}
          onChange={handleChange('subject')}
          placeholder={t('subjectPartial')}
          value={params?.subject}
        />
      </Cluster>
      <Cluster gap="4" nowrap>
        <SearchInput
          css={{ flexBasis: '33%', width: '33%' }}
          onKeyDown={onKeyDown}
          onChange={handleChange('domain')}
          placeholder={t('domain')}
          value={params?.domain}
        />
        <SearchInput
          css={{ flexBasis: '33%', width: '33%' }}
          onKeyDown={onKeyDown}
          onChange={handleChange('messageId')}
          placeholder={t('searchMessageId')}
          value={params?.messageId}
        />
        <SearchInput
          css={{ flexBasis: '33%', width: '33%' }}
          onKeyDown={onKeyDown}
          onChange={handleChange('alertId')}
          placeholder={t('alertId')}
          value={params?.alertId}
        />
      </Cluster>
    </Stack>
  );
}

interface DetectionToggleProps {
  checked?: boolean;
  disabled?: boolean;
  // eslint-disable-next-line no-unused-vars
  onChange?(checked: boolean): void;
}

function DetectionToggle({ checked, disabled = false, onChange }: DetectionToggleProps) {
  const { t } = useTranslation('unisearch');

  function handleChange() {
    if (!onChange) return;
    onChange(!checked);
  }

  return (
    <Cluster as="label" align="center" css={{ userSelect: 'none' }} gap="2">
      <Checkbox checked={checked} disabled={disabled} onChange={handleChange} size="sm" />
      <Text as="p" color="$gray700" font="sans" size="sm" stretch="ultraCondensed" transform="uppercase">
        {t('detectionsOnly')}
      </Text>
    </Cluster>
  );
}

interface SearchHintsProps {
  query?: string;
}

function SearchHints({ query }: SearchHintsProps) {
  const hints = useSearchHints(query);

  // Don't offer any suggestions if there's only one hint and it matches the query
  if (hints.length === 1 && hints[0] === query) return <datalist id={SEARCH_HINTS_ID} />;

  return (
    <datalist id={SEARCH_HINTS_ID}>
      {hints.map((hint) => (
        <option key={hint} value={hint} />
      ))}
    </datalist>
  );
}

//
// Private functions
// -------------------------------------------------------------------------------------------------

const getDispositionOptions = (maliciousBecEnabled: boolean) => {
  if (maliciousBecEnabled) return DISPOSITION_OPTIONS;
  return DISPOSITION_OPTIONS.filter(({ key }) => key !== 'malicious-bec');
};

interface SearchQueryProps {
  disposition?: DispositionOptionType['key'];
  searchTerm?: string;
  recipient?: string;
}

const getSearchQuery = ({ disposition, searchTerm, recipient }: SearchQueryProps) => {
  let oldSearchQuery = '';

  if (disposition) {
    oldSearchQuery += `final_disposition:${disposition}`;
  }

  if (searchTerm) {
    if (searchTerm.includes('final_disposition')) {
      oldSearchQuery += searchTerm.substring(18);
    } else oldSearchQuery += searchTerm;
  }

  if (recipient) {
    oldSearchQuery += recipient;
  }

  return oldSearchQuery;
};

//
// Private hooks
// -------------------------------------------------------------------------------------------------

const AUTOCOMPLETE_QUERY = gql`
  query Autocomplete($search: String!) {
    suggestions(q: $search) @rest(endpoint: "mailsearch", path: "/suggest?{args}", type: "SearchSuggestion") {
      matches
    }
  }
`;

function useSearchHints(query?: string): string[] {
  const debouncedQuery = useDebounce(query, 150, 250);

  const { data, loading } = useQuery(AUTOCOMPLETE_QUERY, {
    fetchPolicy: 'network-only',
    skip: !query || query.length < 3,
    variables: { search: debouncedQuery && encodeBase64(debouncedQuery) },
  });

  if (query === '') return [];
  if (loading) return [];

  return data?.suggestions?.matches || [];
}

//
// Styled components
// -------------------------------------------------------------------------------------------------

const StyledForm = styled('form', {
  display: 'contents',
});
