// @flow

import { get } from 'lodash';
// $FlowFixMe
import React, { lazy, Suspense, useContext, useState } from 'react';
import { useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';

import { insightsExtensionsQuery, mungeProgressTableData } from './dataTypesAndUtils';

import { DaysBackContext } from 'screens/App';

import Button from 'ui/atoms/Button';
import Loading from 'ui/atoms/Loading';
import BlockHeader from 'ui/molecules/BlockHeader';
import ContentBlock from 'ui/molecules/ContentBlock';
import Drawer from 'ui/molecules/Drawer';
import { type SearchValue } from 'ui/molecules/GlobalSearch';
import ProgressTable from 'ui/molecules/ProgressTable';
import ProgressTablePlaceholder from 'ui/molecules/ProgressTablePlaceholder';
import useGlobalSearch from 'utils/hooks/useGlobalSearch';
import withDataHandler from 'utils/withDataHandler';

const DetailsTable = lazy(() => import('./DetailsTable'));

const ProgressTableWithDataHandler = withDataHandler({
  empty: <ProgressTablePlaceholder />,
})(ProgressTable);

export default function TopMaliciousFileTypes() {
  const { currentInterval } = useContext(DaysBackContext);
  const searchDetections = useGlobalSearch('detectionSearch');
  const { t } = useTranslation('email');

  const [openModal, setOpenModal] = useState(false);

  const { data, error, loading } = useQuery(insightsExtensionsQuery, { variables: { currentInterval, limit: 5 } });
  const allExtensions = get(data, 'maliciousAttachmentsExtensions.data.extensions', []);

  const triggerGlobalSearch = (searchValue: SearchValue) => {
    setOpenModal(false);
    if (searchValue) searchDetections({ query: searchValue });
  };

  return (
    <ContentBlock
      dataTestId="top-malicious-file-types-component"
      top={
        <>
          <BlockHeader
            title={t('topMaliciousFileTypes')}
            action={
              <Button
                disabled={allExtensions.length === 0}
                icon="hamburger"
                muted
                onClick={() => allExtensions.length > 0 && setOpenModal(true)}
              >
                {t('viewDetails')}
              </Button>
            }
          />
          <ProgressTableWithDataHandler
            /* eslint-disable no-shadow */
            // eslint-disable-next-line no-unused-vars
            calculatePercent={(allExtensions, [_, number]) =>
              Number(number) / allExtensions.reduce((a, b) => Number(a) + Number(b[1]), 0)
            }
            cellWidths={['50%', '50%']}
            data={mungeProgressTableData(allExtensions)}
            isEmpty={() => loading || error || allExtensions.length === 0}
            isLoading={loading}
          />
        </>
      }
    >
      <Drawer.Modal visible={openModal} onClose={() => setOpenModal(false)} paddedSides={false} size="full">
        <Suspense fallback={<Loading />}>
          {openModal && <DetailsTable fetchData={openModal} handleGlobalSearch={triggerGlobalSearch} />}
        </Suspense>
      </Drawer.Modal>
    </ContentBlock>
  );
}
