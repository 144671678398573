// @flow

import { useFeatureFlag } from '@marshall/hooks';
import { loader } from 'graphql.macro';
import { get } from 'lodash';
import { rem, rgba } from 'polished';
// $FlowIssue
import React, { useReducer, useState } from 'react';
import { useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import styled, { keyframes } from 'styled-components';

import Preview from './Preview';
import RedressActions from './RedressActions';
import ResultView from './ResultView';
import RetractionFailures from './RetractionFailures';
import RowMenu from './RowMenu';

import Blip from 'ui/atoms/Blip';
import Checkbox from 'ui/atoms/Checkbox';
import ConditionalRender from 'ui/atoms/ConditionalRender';
import MaxHeight from 'ui/atoms/MaxHeight';
import PaddedContent from 'ui/atoms/PaddedContent';
import Alert from 'ui/molecules/Alert';
import DispositionBadge from 'ui/molecules/DispositionBadge';
import Drawer from 'ui/molecules/Drawer';
import ErrorMessage from 'ui/molecules/ErrorMessage';
import FormatTime from 'ui/molecules/FormatTime';
import Info from 'ui/molecules/Info';
import NoData from 'ui/molecules/NoData';
import PopupMenu, { Group, Option } from 'ui/molecules/PopupMenu';
import RetractDialog from 'ui/molecules/RetractDialog';
import { Body, Cell, Col, Container as Table, Header, Head, Row } from 'ui/molecules/Table';
import Toast from 'ui/molecules/Toast';

import useAccessControl, { permissionTypes } from 'utils/hooks/useAccessControl';

const clawbackMutation = loader('./queries/clawback.graphql');

const animation = keyframes`
  from, 20%, 40%, 60%, 80%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
`;

const CheckboxDiv = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: end;
`;

const StyledCell = styled(Cell)`
  vertical-align: top;
  word-wrap: break-word !important;

  :not(:first-of-type) {
    padding-left: ${(p) => rem(p.theme.spacing.sm)} !important;
  }

  :not(:last-of-type) {
    padding-right: ${(p) => rem(p.theme.spacing.sm)} !important;
  }

  [aria-selected='true'] > & {
    background-color: ${(p) => p.theme.colors.deepSkyBlue};
    color: ${(p) => p.theme.colors.antiFlashWhite};

    [data-testid='atom-badge'] {
      background-color: ${(p) => rgba(p.theme.colors.antiFlashWhite, 0.75)};
      span {
        color: ${(p) => p.theme.colors.deepSkyBlue};
      }
    }

    button {
      path {
        fill: ${(p) => rgba(p.theme.colors.antiFlashWhite, 0.75)};
      }

      &[aria-expanded='true'] path,
      &:hover path {
        fill: ${(p) => p.theme.colors.deepSkyBlue};
      }
    }
  }
`;

const StyledHeader = styled(Header)`
  :not(:first-of-type) {
    padding-left: ${(p) => rem(p.theme.spacing.sm)} !important;
  }

  :not(:last-of-type) {
    padding-right: ${(p) => rem(p.theme.spacing.sm)} !important;
  }

  [data-testid='molecule-popup-menu'] {
    animation-name: ${animation};
    animation-duration: 1s;
    margin-top: -16px;
    position: relative;
    top: 10px;
  }
`;

const StyledPre = styled.pre`
  display: inline-block;
  margin: 0;
  max-width: 100%;
  padding: 0;
  padding-bottom: ${(p) => rem(p.theme.spacing.sm)};
  white-space: pre-wrap;
`;

const StyledTable = styled(Table)`
  margin-top: ${(p) => rem(p.theme.spacing.xxl)};
  width: 100%;
`;

//
// Main component
// -------------------------------------------------------------------------------------------------

const INITIAL_SELECTED_KEYS_STATE = new Set();
const INITIAL_TOAST_STATE = { highlightedIds: [], isVisible: false, message: null, variant: null };

interface RedressActionsType {
  actionType: 'FALSE_NEGATIVE' | 'FALSE_POSITIVE' | 'PREVIEW' | 'QUARANTINE_RELEASE' | 'RETRACTION';
  clientId: string;
  duration_millis: number;
  responses: Array<Object>;
  responsesAsString: string;
  statusCode: number;
  statusMessage: string;
  ts: string;
}

interface MessageType {
  clientName: string;
  clientRecipients: string[];
  finalDisposition: string;
  from: string;
  key: number;
  kubrickHostname: string;
  messageId: string;
  phishSubmission: boolean;
  postfixIdent: string;
  postfixIdentOutbound: string;
  redressedActions: Array<RedressActionsType>;
  subject: string;
  ts: string;
}

interface Props {
  accessControlLoading: boolean;
  results: ?{ messages: Array<MessageType> };
  rowActionsPermitted: boolean;
}

export default function MailTraceResults({ accessControlLoading, results, rowActionsPermitted }: Props) {
  const { ENTERPRISE_ENABLED } = permissionTypes;
  const { permissions } = useAccessControl([ENTERPRISE_ENABLED]);
  const [enterpriseEnabled] = permissions;

  const clawbackPermitted = useFeatureFlag('clawback') || enterpriseEnabled;

  const [runClawbackMutation, { loading }] = useMutation(clawbackMutation);

  const [selectedKeys, selectedKeysDispatch] = useReducer(selectedKeysReducer, INITIAL_SELECTED_KEYS_STATE);
  const [toastState, toastDispatch] = useReducer(toastReducer, INITIAL_TOAST_STATE);

  const [alertOpen, setAlertOpen] = useState(false);
  const [clawBackFailures, setClawBackFailures] = useState({ failures: [], message: '' });
  const [destination, setDestination] = useState('Inbox');
  const [headerChecked, setHeaderChecked] = useState(false);
  const [messageId, setMessageId] = useState(null);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [selectedForRedressActions, setSelectedForRedressActions] = useState([]);
  const [showRedressActions, setShowRedressActions] = useState(false);

  const { t } = useTranslation('components');

  const messages = get(results, 'messages', []);

  const messagesNoRecipients = messages.filter(({ clientRecipients }) => !clientRecipients.length);

  function handleActionCancel() {
    setAlertOpen(false);
  }

  async function handleActionConfirm() {
    /* eslint-disable camelcase */
    const requests = [...selectedKeys]
      .map((key) => messages.find((r) => r.key === key))
      .reduce((acc, { messageId: message_id = '', clientRecipients }) => {
        if (message_id) {
          const map = clientRecipients.map((recipient) => ({ destination, message_id, recipient }));
          return [...acc, ...map];
        }
        return acc;
      }, []);

    try {
      const { data } = await runClawbackMutation({ variables: { input: { requests } } });

      setAlertOpen(false);

      const responses = get(data.clawback, 'responses', []);

      const failures = responses.filter(({ status }) => !['OK', 'SUCCESS'].includes(status.toUpperCase()));
      const failedIds = failures.map(({ messageId: id }) => id);

      const succeeded = requests.length - failures.length;

      if (failures.length) {
        toastDispatch({ payload: { highlightedIds: failedIds, isVisible: false }, type: 'warning' });
        setClawBackFailures({
          failures,
          message:
            requests.length > 1
              ? t('searchResults:retractFailurePlural', { count: requests.length, succeeded })
              : t('searchResults:retractFailure'),
        });
      } else {
        toastDispatch({
          payload: {
            message: requests.length > 1 ? t('searchResults:retractSuccessPlural') : t('searchResults:retractSuccess'),
          },
          type: 'success',
        });
      }
    } catch (e) {
      setAlertOpen(false);

      const responses = get(e, 'networkError.result.responses', []);

      const failures = responses.filter(({ status }) => status !== 'OK');
      const failedIds = failures.map(({ message_id }) => message_id);

      toastDispatch({ payload: { highlightedIds: failedIds, isVisible: false }, type: 'warning' });
      setClawBackFailures({
        failures,
        message:
          requests.length > 1
            ? t('searchResults:retractFailurePlural', { count: requests.length, succeeded: 0 })
            : t('searchResults:retractFailure'),
      });
    }

    selectedKeysDispatch({ type: 'reset' });
  }

  function handleClawback(data: any) {
    selectedKeysDispatch({ payload: [data.key], type: 'replace' });
    setAlertOpen(true);
  }

  function handleRetractClick() {
    toastDispatch({ type: 'reset' });
    setAlertOpen(true);
  }

  function handleClose() {
    setMessageId(null);
  }

  function handleHeaderCheckChange() {
    if (selectedKeys.size === messages.length - messagesNoRecipients.length) {
      selectedKeysDispatch({ type: 'reset' });
      setHeaderChecked(false);
    } else {
      const keys = messages.reduce(
        (validKeys, { clientRecipients, key }) => (clientRecipients.length ? [...validKeys, key] : validKeys),
        []
      );
      selectedKeysDispatch({ payload: keys, type: 'replace' });
      setHeaderChecked(true);
    }
  }

  function handleHeaderPreviewClick() {
    // no more than 10 previews at a time b/c it takes too long
    if (selectedKeys.size > 10) {
      setHeaderChecked(false);
      const firstTen = [...selectedKeys].slice(0, 10);
      selectedKeysDispatch({ payload: firstTen, type: 'replace' });
    }
    setPreviewOpen(true);
  }

  function handlePreview(data: any) {
    selectedKeysDispatch({ payload: [data.key], type: 'replace' });
    setPreviewOpen(true);
  }

  function handleRetractFromPreview() {
    setPreviewOpen(false);
    handleRetractClick();
  }

  const changeRedressActionsModalState = ({ redressActions, showDrawer }) => {
    setShowRedressActions(showDrawer);
    setSelectedForRedressActions(redressActions);
  };

  const resetClawbackFailures = () => {
    setClawBackFailures({ failures: [], message: '' });
  };

  return (
    <>
      <ConditionalRender
        condition={!!messages.length && !accessControlLoading}
        fallback={
          <StyledTable>
            <Body>
              <Row>
                <Cell>
                  <NoData />
                </Cell>
              </Row>
            </Body>
          </StyledTable>
        }
      >
        <StyledTable data-testid="mail-trace-search-results-table" fixed spaced zebraStripes>
          <colgroup>
            <Col width={80} />
            <Col width="20%" />
            <Col />
            <Col />
            <Col width={75} />
          </colgroup>

          <Head>
            <Row>
              <StyledHeader>
                <ConditionalRender
                  condition={
                    clawbackPermitted &&
                    rowActionsPermitted &&
                    messages.some((result) => result.phishSubmission === false) &&
                    messagesNoRecipients.length !== messages.length
                  }
                  fallback=""
                >
                  <CheckboxDiv>
                    <Checkbox checked={headerChecked} onChange={handleHeaderCheckChange} />
                  </CheckboxDiv>
                </ConditionalRender>
              </StyledHeader>

              <StyledHeader>{t('searchResults:dateAndDisposition')}</StyledHeader>
              <StyledHeader>{t('searchResults:subject')}</StyledHeader>
              <StyledHeader>{t('searchResults:senderRecipients')}</StyledHeader>
              <StyledHeader>
                {selectedKeys.size > 0 && (
                  <ConditionalRender condition={clawbackPermitted && rowActionsPermitted}>
                    <PopupMenu>
                      <Group>
                        <Option icon="preview" onClick={handleHeaderPreviewClick}>
                          {t('searchResults:preview')} ({selectedKeys.size > 10 ? '10' : selectedKeys.size})
                        </Option>
                        <Option icon="mail-retract" onClick={handleRetractClick}>
                          {t('searchResults:retract')} ({selectedKeys.size})
                        </Option>
                      </Group>
                    </PopupMenu>
                  </ConditionalRender>
                )}
              </StyledHeader>
            </Row>
          </Head>

          <Body>
            {messages.map((result) => {
              function handleChange({ currentTarget }) {
                const type = currentTarget.checked ? 'add' : 'remove';
                selectedKeysDispatch({ payload: result.key, type });

                if (selectedKeys.size === messages.length) {
                  setHeaderChecked(true);
                } else if (selectedKeys.size === 0) {
                  setHeaderChecked(false);
                } else {
                  setHeaderChecked('indeterminate');
                }
              }

              const { clientRecipients, redressedActions } = result;

              return (
                <Row data-testid="mail-trace-results-row" aria-selected={selectedKeys.has(result.key)} key={result.key}>
                  <StyledCell style={{ position: 'relative' }} verticalAlign="middle">
                    <ConditionalRender
                      condition={
                        clawbackPermitted && rowActionsPermitted && !result.phishSubmission && !!clientRecipients.length
                      }
                    >
                      <CheckboxDiv>
                        {toastState.highlightedIds.includes(result.messageId) && <Blip>!</Blip>}
                        <Checkbox checked={selectedKeys.has(result.key)} onChange={handleChange} />
                      </CheckboxDiv>
                    </ConditionalRender>
                  </StyledCell>
                  <StyledCell data-testid="mailtrace-result-date-disposition" verticalAlign="middle">
                    <PaddedContent pushBottom="sm">
                      <FormatTime date={result.ts} displayTime />
                      <ConditionalRender condition={!!redressedActions.length}>
                        {' '}
                        <Info
                          icon="mail-retract"
                          onClick={() =>
                            changeRedressActionsModalState({ redressActions: redressedActions, showDrawer: true })
                          }
                          tooltipPosition="right"
                        >
                          {t('searchResults:viewRedressActions')}
                        </Info>
                      </ConditionalRender>
                    </PaddedContent>
                    <DispositionBadge disposition={result.finalDisposition} />
                  </StyledCell>
                  <StyledCell data-testid="mailtrace-result-subject" verticalAlign="middle">
                    {result.subject}
                  </StyledCell>
                  <StyledCell data-testid="mailtrace-result-sender-recipient" verticalAlign="middle">
                    <MaxHeight height={150}>
                      <strong>{t('searchResults:senderLabel')}</strong>{' '}
                      <StyledPre data-testid="mailtrace-result-sender">{result.from}</StyledPre>
                      <br />
                      <strong>{t('searchResults:recipientsLabel')}</strong>{' '}
                      <StyledPre data-testid="mailtrace-result-recipient">
                        {result.clientRecipients.join(', ')}
                      </StyledPre>
                    </MaxHeight>
                  </StyledCell>
                  <StyledCell data-testid="mailtrace-result-menu" verticalAlign="middle">
                    <RowMenu
                      clawbackPermitted={clawbackPermitted && rowActionsPermitted}
                      handleRedressActions={() =>
                        changeRedressActionsModalState({ redressActions: redressedActions, showDrawer: true })
                      }
                      hasRedressActions={!!redressedActions.length}
                      onClawback={handleClawback}
                      onPreview={handlePreview}
                      handleDetailsClick={() => setMessageId(result.key)}
                      row={result}
                    />
                  </StyledCell>
                </Row>
              );
            })}
          </Body>
        </StyledTable>
      </ConditionalRender>

      <Drawer.Modal dataTestId="email-data-modal" onClose={handleClose} visible={messageId !== null} size="full">
        <ResultView messageId={messageId} />
      </Drawer.Modal>

      <Drawer.Modal dataTestId="preview-modal" onClose={() => setPreviewOpen(false)} size="full" visible={previewOpen}>
        <Preview
          handleRetract={handleRetractFromPreview}
          messages={messages}
          messageKeys={selectedKeys}
          selectedKeysDispatch={selectedKeysDispatch}
        />
      </Drawer.Modal>

      <Drawer.Modal
        dataTestId="redress-actions-modal"
        onClose={() => changeRedressActionsModalState({ redressActions: [], showDrawer: false })}
        size="full"
        visible={showRedressActions}
      >
        <ConditionalRender condition={!!selectedForRedressActions.length}>
          <RedressActions redressActions={selectedForRedressActions} />
        </ConditionalRender>
      </Drawer.Modal>

      <RetractDialog.Modal
        alertOpen={alertOpen}
        buttonText={selectedKeys.size}
        destination={destination}
        handleRetractConfirm={(handleActionConfirm: any)}
        loading={loading}
        onActionCancel={handleActionCancel}
        setDestination={setDestination}
      />

      <Alert.Modal
        expanded
        onDismiss={resetClawbackFailures}
        open={!!clawBackFailures.failures.length}
        title={<ErrorMessage>{clawBackFailures.message}</ErrorMessage>}
        zebraStripe
      >
        <RetractionFailures failedRetractions={clawBackFailures} title={clawBackFailures.message} />
      </Alert.Modal>

      <Toast onDismiss={() => toastDispatch({ type: 'hide' })} open={toastState.isVisible} variant={toastState.variant}>
        {toastState.message}
      </Toast>
    </>
  );
}

//
// Private functions
// -------------------------------------------------------------------------------------------------

function selectedKeysReducer(state, action) {
  switch (action.type) {
    case 'add':
      state.add(action.payload);
      return new Set([...state]);
    case 'remove':
      state.delete(action.payload);
      return new Set([...state]);
    case 'replace':
      return new Set([...action.payload]);
    case 'reset':
      return new Set();
    default:
      throw new Error();
  }
}

function toastReducer(state, action) {
  switch (action.type) {
    case 'hide':
      return { ...state, isVisible: false };
    case 'error':
    case 'fail':
      return { ...state, isVisible: true, message: action.payload, variant: 'fail' };
    case 'reset':
      return { ...state, highlightedIds: [], isVisible: false, message: '' };
    case 'success':
      return { ...state, highlightedIds: [], isVisible: true, message: action.payload.message, variant: 'success' };
    case 'warning': {
      const { highlightedIds, isVisible } = action.payload;
      return { ...state, highlightedIds, isVisible, variant: 'warning' };
    }
    default:
      throw new Error();
  }
}
