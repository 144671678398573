// @flow

import { Record, type RecordFactory } from 'immutable';

import {
  FETCH_USER_SUCCEEDED,
  FETCH_USER_FAILED,
  FETCH_USER_REQUESTED,
  type FetchUserAction,
} from '../actionTypes/user';
import { transformData, UserRecord } from '../data/user';

type CurrentUserType = {
  data: UserRecord,
  error: string,
  isLoading: boolean,
  isAuthenticated: boolean,
  isSubmitting: boolean,
  isValidInvite: ?boolean,
  loginError: string,
  recursiveDnsEnabled: boolean,
  requestComplete: boolean,
  resetSent: boolean,
  tfaSecret: ?string,
  tfaSecretSVG: ?string,
  tfaStatusCheckSucceeded: boolean,
};
const defaultRecord: CurrentUserType = {
  data: new UserRecord(),
  error: '',
  isAuthenticated: false,
  isLoading: false,
  isSubmitting: false,
  isValidInvite: null,
  loginError: '',
  recursiveDnsEnabled: false,
  requestComplete: false,
  resetSent: false,
  tfaSecret: null,
  tfaSecretSVG: null,
  tfaStatusCheckSucceeded: false,
};
const CurrentUserRecord: RecordFactory<CurrentUserType> = Record(defaultRecord);

export type CurrentUserState = CurrentUserRecord;
export const initialCurrentUserState: CurrentUserState = new CurrentUserRecord();

export const currentUser = (state: CurrentUserState = initialCurrentUserState, action: FetchUserAction) => {
  switch (action.type) {
    case FETCH_USER_FAILED: {
      return state
        .set('error', action.payload)
        .set('isLoading', false)
        .set('isSubmitting', false)
        .set('isAuthenticated', false)
        .set('resetSent', false)
        .set('requestComplete', true);
    }
    case FETCH_USER_REQUESTED: {
      return state.set('resetSent', false).set('isLoading', true).set('error', '').set('loginError', '');
    }
    case FETCH_USER_SUCCEEDED: {
      const data = new UserRecord(transformData(action.payload));
      return state.set('data', data).set('isLoading', false).set('isAuthenticated', true).set('requestComplete', true);
    }
    default: {
      return state;
    }
  }
};
