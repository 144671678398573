import React from 'react';

import { BenignEmailPreview, DetectionEmailPreview } from '../../../ui';

interface GetPreviewType {
  clientRecipients?: string[];
  clientUuid?: string;
  image?: string;
  isBenign?: boolean;
  loading?: boolean;
  messageId?: string;
  previewEnabled?: boolean;
}

export function GetMessagePreview({
  clientRecipients,
  clientUuid,
  image,
  loading,
  messageId,
  previewEnabled,
}: GetPreviewType) {
  /*
   If the message's 'stored_at' is null AND clientRecipients.length > 0
   we render from /previews otherwise from /mailview
   */
  if (previewEnabled)
    return <BenignEmailPreview clientRecipients={clientRecipients} clientUuid={clientUuid} messageId={messageId} />;
  return <DetectionEmailPreview image={image} loading={loading} />;
}
