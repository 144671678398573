import { styled } from '@a1s/ui';

export const StyledList = styled('ul', {
  paddingLeft: '1.15em',
  maxHeight: '100%',
  overflowX: 'auto',
  overflowY: 'auto',
  userSelect: 'none',
});

export const ThreatTypeLink = styled('a', {
  cursor: 'pointer',
  textDecoration: 'none',

  [`&:hover  span`]: {
    color: '$black',
  },
});
