// @flow

import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';
import initSentry from './utils/sentry';

if (process.env.REACT_APP_USE_AXE) {
  // eslint-disable-next-line global-require
  const axe = require('react-axe');
  const axeConf = {
    rules: [
      { id: 'heading-order', enabled: true },
      { id: 'label-title-only', enabled: true },
      { id: 'link-in-text-block', enabled: true },
      { id: 'region', enabled: true },
      { id: 'skip-link', enabled: true },
      { id: 'help-same-as-label', enabled: true },
    ],
  };
  axe(React, ReactDOM, 1000, axeConf);
}

initSentry();

const root = document.getElementById('root');

if (root !== null) {
  ReactDOM.render(<App />, root);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
