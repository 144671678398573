// @flow

import { get } from 'lodash';
// $FlowFixMe
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import AllowedPatterns from './AllowedPatterns';
import AllowedRecipients from './AllowedRecipients';

type Props = {
  allowListData: Object,
  error: boolean,
  loading: boolean,
};

export default function AllowList({ allowListData, error, loading }: Props) {
  const { t } = useTranslation('email');

  const overviewData = get(allowListData, 'totals', { current: 0, previous: 0 });
  const currentTimeline = get(allowListData, 'timeline.current', []);
  const graphData = currentTimeline.map((a) => ({
    date: a.day,
    keys: [t(a.totals[0].policyType)],
    values: [a.total],
  }));
  const sortedStackBarGraphData = graphData.sort((a, b) => new Date(a.date) - new Date(b.date));

  return (
    <>
      <Helmet title={`${t('allowList')} :: ${t('common:email')} - ${t('common:area1security')}`} />

      <>
        <AllowedPatterns
          data={{
            stackedBarGraph: sortedStackBarGraphData,
            sectionOverview: {
              current: overviewData.current,
              previous: overviewData.previous,
            },
          }}
          error={error}
          loading={loading}
        />
        <AllowedRecipients />
      </>
    </>
  );
}
