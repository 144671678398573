// @flow

import { loader } from 'graphql.macro';
import { get } from 'lodash';
// $FlowFixMe
import React, { useContext } from 'react';
import { useQuery } from 'react-apollo';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { type PropType } from '../../dataTypesAndUtils';

import { calculatePercent } from '../dataTypesAndUtils';

import { suspiciousSendersRecord } from './dataTypesAndUtils';
import SuspiciousSenders from './SuspiciousSenders';
import TopSuspiciousSendingDomains from './TopSuspiciousSendingDomains';

import { DaysBackContext } from 'screens/App';

export const suspiciousSendersQuery = loader('./queries/SuspiciousSendersQuery.graphql');

export default function SuspiciousSendersOverview({ detectionCount, detectionError, detectionLoading }: PropType) {
  const { currentInterval } = useContext(DaysBackContext);
  const { t } = useTranslation('email');

  const { data, error, loading } = useQuery(suspiciousSendersQuery, { variables: { currentInterval } });
  const {
    previousSuspiciousSenderCounts,
    previousSuspiciousSenderCountsByDate,
    suspiciousSenderCounts,
    suspiciousSenderCountsByDataType,
    suspiciousSenderCountsByDate,
    suspiciousSendersListByName,
  } = get(data, 'suspiciousSenders', suspiciousSendersRecord);

  return (
    <>
      <Helmet title={`${t('senders')} :: ${t('common:email')} - ${t('common:area1security')}`} />
      <>
        <SuspiciousSenders
          data={{
            lineGraph: {
              current: suspiciousSenderCountsByDate,
              previous: previousSuspiciousSenderCountsByDate,
            },
            ringChart: calculatePercent(suspiciousSenderCounts.suspiciousSendersTotal, detectionCount),
            sectionOverview: {
              current: get(suspiciousSenderCounts, 'suspiciousSendersTotal', 0),
              previous: get(previousSuspiciousSenderCounts, 'suspiciousSendersTotal', 0),
            },
          }}
          error={error || detectionError}
          loading={loading || detectionLoading}
        />
        <TopSuspiciousSendingDomains
          data={{
            modal: suspiciousSendersListByName,
            progress: suspiciousSenderCountsByDataType,
          }}
          error={error}
          loading={loading}
        />
      </>
    </>
  );
}
