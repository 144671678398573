// @flow

import React from 'react';
import { useTranslation } from 'react-i18next';

import { type SuspiciousTargetedRecipient } from './dataTypesAndUtils';

import BlockHeader from 'ui/molecules/BlockHeader';
import ContentBlock from 'ui/molecules/ContentBlock';
import ProgressTable from 'ui/molecules/ProgressTable';
import ProgressTablePlaceholder from 'ui/molecules/ProgressTablePlaceholder';
import withDataHandler from 'utils/withDataHandler';

type Props = {
  data: Array<SuspiciousTargetedRecipient>,
  error: boolean,
  loading: boolean,
};

const ProgressTableWithDataHandler = withDataHandler({
  empty: <ProgressTablePlaceholder />,
})(ProgressTable);

export default function TopTargetedRecipients({ data, error, loading }: Props) {
  const { t } = useTranslation('email');

  return (
    <ContentBlock
      dataTestId="top-targeted-recipients-component"
      top={
        <>
          <BlockHeader title={t('topSuspiciousTargets')} />
          <ProgressTableWithDataHandler
            /* eslint-disable no-shadow */
            // eslint-disable-next-line no-unused-vars
            calculatePercent={(data, [_, number]) =>
              Number(number) / data.reduce((a, b) => Number(a) + Number(b[1]), 0)
            }
            cellWidths={['50%', '50%']}
            data={dataForGraphTable(data)}
            isEmpty={() => loading || error || !data || data.length === 0}
            isError={error}
            isLoading={loading}
            tint
          />
        </>
      }
    />
  );
}

//
// Private functions
// -------------------------------------------------------------------------------------------------
const dataForGraphTable = (data) => {
  if (!data) return [];
  return data.map((row) => [row.address, row.count]);
};
