import React, { createContext, useContext, PropsWithChildren } from 'react';

import useAccessControl, { permissionTypes, PermissionsType } from 'utils/hooks/useAccessControl';

//
// Context set up
// -------------------------------------------------------------------------------------------------

const Context = createContext(false);

//
// Provider component
// -------------------------------------------------------------------------------------------------

export function PermissionsProvider({ children }: PropsWithChildren<{}>) {
  const { ADMIN, READ_WRITE } = permissionTypes;
  const { permissions } = useAccessControl([ADMIN, READ_WRITE] as PermissionsType[]);
  const [adminPermitted, readWritePermitted] = permissions as boolean[];

  return <Context.Provider value={adminPermitted || readWritePermitted}>{children}</Context.Provider>;
}

//
// Hooks
// -------------------------------------------------------------------------------------------------

export function usePermissions() {
  return useContext(Context);
}
