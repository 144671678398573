// @flow

// $FlowFixMe
import React, { lazy, Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { dataForGraphTable, type MaliciousLinkCountsByDataType } from './dataTypesAndUtils';

import Button from 'ui/atoms/Button';
import Loading from 'ui/atoms/Loading';
import BlockHeader from 'ui/molecules/BlockHeader';
import ContentBlock from 'ui/molecules/ContentBlock';
import Drawer from 'ui/molecules/Drawer';
import GraphTable from 'ui/molecules/GraphTable';
import PlaceholderGraphTable from 'ui/molecules/PlaceholderGraphTable';
import withDataHandler from 'utils/withDataHandler';

const DetailsTable = lazy(() => import('./DetailsTable'));

type Props = {
  data: Array<MaliciousLinkCountsByDataType>,
  error: boolean,
  loading: boolean,
};

const GraphTableWithDataHandler = withDataHandler({
  empty: <PlaceholderGraphTable />,
})(GraphTable);

export default function TopThreatTypes({ data, error, loading }: Props) {
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation('email');

  return (
    <ContentBlock
      dataTestId="top-threat-types-component"
      top={
        <>
          <BlockHeader
            action={
              <Button
                disabled={data.length === 0}
                icon="hamburger"
                muted
                onClick={() => data.length > 0 && setOpenModal(true)}
              >
                {t('viewDetails')}
              </Button>
            }
            title={t('topThreatTypes')}
          />
          <GraphTableWithDataHandler
            data={dataForGraphTable(data)}
            isEmpty={() => loading || error || data.length === 0}
            isLoading={loading}
            tint
          />
        </>
      }
    >
      <Drawer.Modal visible={openModal} onClose={() => setOpenModal(false)} size="large" paddedSides={false}>
        <Suspense fallback={<Loading />}>{openModal && <DetailsTable fetchData={openModal} />}</Suspense>
      </Drawer.Modal>
    </ContentBlock>
  );
}
