// @flow

// $FlowFixMe
import { Box } from '@a1s/ui';
import { loader } from 'graphql.macro';
import { get, snakeCase } from 'lodash';
import { rem } from 'polished';
import qs from 'qs';
// $FlowFixMe
import React, { useCallback, useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from 'react-apollo';
import { Helmet } from 'react-helmet-async';
import { useTranslation, Trans } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import styled from 'styled-components';

import LoginForm from './LoginForm';

import ConditionalRender from 'ui/atoms/ConditionalRender';
import Link from 'ui/atoms/Link';
import Logo from 'ui/atoms/Logo';
import Opacity from 'ui/atoms/Opacity';
import Content from 'ui/molecules/Content';
import ContentBlock from 'ui/molecules/ContentBlock';
import LanguageSelector from 'ui/molecules/LanguageSelector';
import { transformData, useCurrentUser } from 'utils/hooks/useCurrentUser';
import useTfaEnabled from 'utils/hooks/useTfaEnabled';

//
// GraphQL queries
// -------------------------------------------------------------------------------------------------

const authQuery = loader('./queries/Authenticate.graphql');
const emailQuery = loader('./queries/CheckEmail.graphql');
const linkQuery = loader('./queries/CloudflareLink.graphql');
const samlAuthQuery = loader('./queries/SamlAuthenticate.graphql');

//
// Styled components
// -------------------------------------------------------------------------------------------------

const ContentContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: ${rem(450)};
`;

const Header = styled.header`
  margin-bottom: ${(p) => rem(p.theme.spacing.md)};
`;

//
// Main component
// -------------------------------------------------------------------------------------------------

export default function LoginPageScreen() {
  const [email, setEmail] = useState('');
  const [loginError, setLoginError] = useState(null);
  const [tfaEmailChecked, setTFAEmailChecked] = useState(false);

  const { user, setUser } = useCurrentUser();

  const { tfaEnabled, tfaError, tfaLoading } = useTfaEnabled();
  const { t } = useTranslation('loginPage');

  const location = useLocation();
  const urlParams = qs.parse(location.search, { ignoreQueryPrefix: true });

  const [
    checkEmail,
    { called: checkEmailCalled, data: checkEmailData, error: checkEmailError, loading: checkEmailLoading },
  ] = useLazyQuery(emailQuery);

  const [authenticate, { loading: authenticateLoading }] = useMutation(authQuery);
  const [link, { loading: linkLoading }] = useMutation(linkQuery);

  const [samlAuthenticate, { called: samlCheckEmailCalled, loading: samlCheckEmailLoading }] =
    useMutation(samlAuthQuery);

  const checkAndSetEmail = useCallback(
    (emailAddress) => {
      checkEmail({ variables: { email: encodeURIComponent(emailAddress) } });
      setEmail(emailAddress);
    },
    [checkEmail, setEmail]
  );

  const isCloudflareLink = get(location, 'state.cloudflare_link', false);
  const tfaResetEmail = get(location, 'state.tfa_reset_email', false);

  useEffect(() => {
    if (!checkEmailLoading && tfaResetEmail) {
      checkAndSetEmail(tfaResetEmail);
      setTFAEmailChecked(true);
    }
  }, [checkAndSetEmail, checkEmailLoading, tfaResetEmail]);

  async function handleSsoLogin(emailAddress) {
    const input = { email: emailAddress };
    try {
      const { data } = await samlAuthenticate({ variables: { input } });
      if (data && data.authenticate && data.authenticate.redirectUrl) {
        window.location.assign(data.authenticate.redirectUrl);
      } else {
        setLoginError('errors.ssoLoginError');
      }
    } catch (error) {
      setLoginError('errors.ssoLoginError');
    }
  }

  async function handleCheckEmail(emailAddress) {
    checkAndSetEmail(emailAddress);
  }

  const enforceSaml = get(checkEmailData, 'user.enforceSaml', false);
  const qrcode = get(checkEmailData, 'user.qrcode', '');
  const showEula = get(checkEmailData, 'user.showEula', false);
  const samlEnabled = get(checkEmailData, 'user.samlEnabled', false);
  const showOnboarding = get(checkEmailData, 'user.showOnboarding', false);

  async function handleLogin(password, token, eula_accepted = true) {
    if (!checkEmailError) {
      const input = {
        email,
        password,
        token,
        ...(showEula && { eula_accepted }),
      };

      try {
        const { data } = isCloudflareLink
          ? await link({ variables: { input } })
          : await authenticate({ variables: { input } });

        if (data && data.authenticate) {
          const payload = Object.entries(data.authenticate).reduce<any>(
            (acc, [k, v]) => ({ ...acc, [snakeCase(k)]: v }),
            {}
          );
          setLoginError(null);
          setUser({
            ...user,
            ...transformData(payload),
            isAuthenticated: true,
            requestComplete: true,
          });
          window.location = '/home';
        }
      } catch (error) {
        setLoginError(error?.networkError?.result?.error || 'errors.loginError');
      }
    }
  }

  const emailVerified =
    !!email &&
    (checkEmailCalled || samlCheckEmailCalled || tfaEmailChecked) &&
    !checkEmailError &&
    !checkEmailLoading &&
    !samlCheckEmailLoading;

  return (
    <Container>
      <Helmet title={`${t('pageTitle')} - ${t('common:area1security')}`} />

      <Header>
        <Logo link={!!email} />
      </Header>

      <ConditionalRender condition={!tfaError}>
        <LoginForm
          checkEmailError={checkEmailError}
          emailVerified={emailVerified}
          enforceSaml={enforceSaml}
          isLoading={authenticateLoading || checkEmailLoading || linkLoading || tfaLoading}
          isLoadingSaml={checkEmailLoading || samlCheckEmailLoading}
          loginErrorKey={loginError}
          onCheckEmail={handleCheckEmail}
          onLogin={handleLogin}
          onSsoLogin={handleSsoLogin}
          qrcode={qrcode}
          samlEnabled={samlEnabled}
          showOnboarding={showOnboarding}
          tfaEnabled={tfaEnabled}
          urlEmail={urlParams.email}
        />
      </ConditionalRender>
    </Container>
  );
}

//
// Private components
// -------------------------------------------------------------------------------------------------

function Container({ children }: { children: any }) {
  return (
    <>
      <ContentBlock
        top={<ContentContainer>{children}</ContentContainer>}
        bottom={
          <ContentContainer>
            <Content centered>
              <p>
                <Trans i18nKey="helpText" ns="loginPage">
                  If you have any issues, contact our support at{' '}
                  <Link external to="mailto:support@area1security.com">
                    support@area1security
                  </Link>{' '}
                  or on our{' '}
                  <Link external to="https://area1security.zendesk.com">
                    Zendesk page
                  </Link>
                  .
                </Trans>
              </p>
            </Content>
          </ContentContainer>
        }
      />
      <Content>
        <Opacity value={0.5}>
          &copy;
          {` ${new Date().getFullYear()} Cloudflare Area 1 Security`}
        </Opacity>
      </Content>
      <Box css={{ bottom: rem(16), position: 'fixed', right: rem(16) }}>
        <LanguageSelector direction="up" languages={['en', 'es', 'ja', 'pt', 'zh']} />
      </Box>
    </>
  );
}
