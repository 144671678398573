import { styled } from '@a1s/ui';

export const SearchableLink = styled('button', {
  background: 'transparent',
  border: 'none',
  cursor: 'pointer',
  display: 'inline-block',
  r: '$1',
  px: '$1-5',
  py: '$1',
  textDecoration: 'none',

  '&:hover': { backgroundColor: 'rgba(108, 144, 161, 0.15)' },
});

export const UseDimensionsContainer = styled('div', {
  width: '100%',
});
