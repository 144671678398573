import { Cluster, Stack, Table, Text } from '@a1s/ui';
import React from 'react';

import { useTranslation } from 'react-i18next';

import { Badge } from '..';

import { matchColorAndThreat } from '../common';

//
// Main component
// -------------------------------------------------------------------------------------------------

interface ThreatCellProps {
  findings?: string[];
  hidden?: boolean;
}

/**
 * Displays threat information about a search result.
 */
export function ThreatCell({ findings, hidden = false }: ThreatCellProps) {
  const { t } = useTranslation('unisearch');

  return (
    <Table.Cell border="none" css={{ display: hidden === true ? 'none' : undefined }}>
      <Cluster gap="4">
        <Text color="$gray600" font="sans" size="sm" stretch="ultraCondensed" transform="uppercase" weight="semibold">
          {t('threatType')}
        </Text>
        <Stack gap="2">
          <RenderFindingList findingsList={findings} value={t('none')} />
        </Stack>
      </Cluster>
    </Table.Cell>
  );
}

//
// Private function
// -------------------------------------------------------------------------------------------------

interface FindingProps {
  findingsList?: string[];
  value: string;
}

function RenderFindingList({ findingsList, value }: FindingProps) {
  if (findingsList && findingsList.length > 0) {
    return findingsList?.map((threat) => {
      const { color, name } = matchColorAndThreat(threat);

      return (
        <Badge color={color} key={threat}>
          {name}
        </Badge>
      );
    });
  }

  return <Badge color="gray300">{value}</Badge>;
}
