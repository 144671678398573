// @flow

import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import Detections from './Detections';
import MailConfiguration from './MailConfiguration';
import UserActivity from './UserActivity';

export default function EmailOverview() {
  const { t } = useTranslation('common');

  return (
    <>
      <Helmet title={`${t('email')} :: ${t('overview')} - ${t('area1security')}`} />
      <Detections />
      <MailConfiguration />
      <UserActivity />
    </>
  );
}
