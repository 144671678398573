// @flow

import { createSelector } from 'reselect';

import { UserRecord } from '../data/user';
import { StateRecord } from '../reducers';

import type { CurrentUserState } from '../reducers/user';

import { developerEmails } from 'utils/util';

const getCurrentUser = (state: StateRecord) => state.get('currentUser');

const getCurrentUserData = createSelector(getCurrentUser, (state: CurrentUserState) => state.get('data'));

export const getCurrentUserDisplayName = createSelector(
  getCurrentUserData,
  (state: UserRecord): string => `${state.get('firstName', '')} ${state.get('lastName', '')}`
);

export const getCurrentUserEmail = createSelector(getCurrentUserData, (state: UserRecord): string =>
  state.get('email')
);

export const getCurrentUserError = createSelector(getCurrentUser, (state: CurrentUserState): string =>
  state.get('error')
);

export const getCurrentUserIsLoading = createSelector(getCurrentUser, (state: CurrentUserState) => {
  return state.get('isLoading');
});

export const getIsAuthenticated = createSelector(getCurrentUser, (state: CurrentUserState): boolean =>
  state.get('isAuthenticated')
);

export const getRequestComplete = createSelector(getCurrentUser, (state: CurrentUserState): boolean =>
  state.get('requestComplete')
);

export const getCurrentUserCustomerId = createSelector(getCurrentUserData, (user: UserRecord) =>
  user.get('customerId')
);

export const getCurrentUserPermissions = createSelector(getCurrentUserData, (state: UserRecord): string[] => {
  const rolesMap = {
    bettermailEnabled: 'bettermail:enabled',
    bettermailOnly: 'bettermail:only',
    brsEnabled: 'brs:enabled',
    directoriesEnabled: 'directories:enabled',
    delegatedAccountsEnabled: 'delegatedAccounts:enabled',
    delegatedAccountsReadOnly: 'delegatedAccounts:readOnly',
    delegatedRolesEnabled: 'delegatedRoles:enabled',
    demoEnabled: 'demo:enabled',
    dnsRpzEnabled: 'dnsRpz:enabled',
    enterpriseEnabled: 'enterprise:enabled',
    mailsearchEnabled: 'mailsearch:enabled',
    mailstreamEnabled: 'mailstream:enabled',
    mailtraceEnabled: 'mailtrace:enabled',
    maliciousBecEnabled: 'maliciousBec:enabled',
    parentUserId: 'resumeParent:enabled',
    phishguardEnabled: 'phishguard:enabled',
    recursiveDnsEnabled: 'recursiveDns:enabled',
    searchEnabled: 'search:enabled',
    settingsReadOnly: 'settings:readOnly',
  };

  // Handle the other roles-like values we get back as single columns from the DB
  const roles = Object.keys(rolesMap).reduce((acc, roleKey) => {
    if (state.get(roleKey)) acc.push(rolesMap[roleKey]);
    return acc;
  }, []);

  // Handle developer roles for WIP sections
  // TODO: re-enable sections as they're made not static
  if (developerEmails.includes(state.get('email'))) roles.push('developer');

  // Handle the actual user role from the DB
  // Then return all of them
  switch (state.get('role')) {
    case 'admin':
      roles.push('admin');
      break;
    case 'config admin':
      roles.push('configAdmin');
      break;
    case 'read write':
      roles.push('readWrite');
      break;
    default:
      roles.push('readOnly');
      break;
  }

  return roles;
});
