// @flow

export type SuspiciousSenderCountsByDate = { date: number, keys: string[], values: number[] };

export type PreviousSuspiciousSenderCountsByDate = { date: number, keys: string[], values: number[] };

type SuspiciousSenderCounts = {
  suspiciousSendersTotal: number,
  suspiciousTotal: number,
};

export type SuspiciousSendersListByName = { count?: number, threatType?: string, sender?: string };

export type SuspiciousSenderCountsByDataType = {
  count: number,
  domain: string,
};

type SuspiciousSendersType = {
  previousSuspiciousSenderCounts: SuspiciousSenderCounts,
  previousSuspiciousSenderCountsByDate: Array<PreviousSuspiciousSenderCountsByDate>,
  suspiciousSenderCounts: SuspiciousSenderCounts,
  suspiciousSenderCountsByDataType: Array<SuspiciousSenderCountsByDataType>,
  suspiciousSenderCountsByDate: Array<SuspiciousSenderCountsByDate>,
  suspiciousSendersListByName: Array<SuspiciousSendersListByName>,
};

export const suspiciousSendersRecord: SuspiciousSendersType = {
  previousSuspiciousSenderCounts: { suspiciousSendersTotal: 0, suspiciousTotal: 0 },
  previousSuspiciousSenderCountsByDate: [],
  suspiciousSenderCounts: { suspiciousSendersTotal: 0, suspiciousTotal: 0 },
  suspiciousSenderCountsByDataType: [],
  suspiciousSenderCountsByDate: [],
  suspiciousSendersListByName: [],
};
