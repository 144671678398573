import { styled } from '@a1s/ui';

export const Scrollable = styled('div', {
  bottom: 0,
  left: 0,
  overflowY: 'scroll',
  pr: '$2',
  position: 'absolute',
  right: 0,
  top: 0,
  scrollbarColor: '$gray50 $gray200',

  '&::-webkit-scrollbar': {
    height: '$size$sm',
    width: '$size$sm',
  },

  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '$gray200',
    r: '$full',
  },

  '&::-webkit-scrollbar-track': {
    backgroundColor: '$gray50',
    r: '$full',
  },
});
