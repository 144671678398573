// @flow

import os from 'os';

import * as Sentry from '@sentry/browser';
import axios from 'axios';

const initSentry = () => {
  axios
    // eslint-disable-next-line no-restricted-globals
    .get(`${location.origin}/configuration/client`)
    .then((response) => {
      let sentryEnvironment;
      // eslint-disable-next-line no-restricted-globals
      if (location.origin.includes('localhost')) {
        sentryEnvironment = 'local';
      } else {
        sentryEnvironment = response.data.REACT_APP_SENTRY_ENVIRONMENT;
      }
      const [sentryEnabled, sentryDsn, sentryRelease] = [
        response.data.REACT_APP_SENTRY_ENABLED,
        response.data.REACT_APP_SENTRY_DSN,
        response.data.REACT_APP_SENTRY_RELEASE,
      ];
      if (sentryEnabled === true) {
        /* eslint-disable no-underscore-dangle */
        Sentry.init({
          beforeBreadcrumb(breadcrumb) {
            // ignore Browser Insights metrics
            return breadcrumb.category === 'xhr' && breadcrumb.data.url.includes('cdn-cgi') ? null : breadcrumb;
          },
          dsn: sentryDsn,
          ignoreErrors: ['Invalid hook call', 'Loading chunk', 'RingChart has rendered a percentage greater than 100%'],
          release: sentryRelease,
          environment: sentryEnvironment,
        });
        Sentry.configureScope((scope) => {
          scope.setTag('host', os.hostname());
        });
        /* eslint-enable no-underscore-dangle */
      }
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log(error);
    });
};

export default initSentry;
