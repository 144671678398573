"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.client = void 0;

/**
 * These defaults are loaded into "client" group and then updated with settings
 * received from the API.
 */
const client = {
  A1S_DEFAULT_DIRECTORY: 'A1S_DEFAULT_DIRECTORY',
  GOOGLE_DIRECTORY: 'GOOGLE_DIRECTORY',
  googleDirectory: {
    clientId: '881939514839-6stskv94k1fhl601qahambl4drgicled.apps.googleusercontent.com',
    scopes: ['https://www.googleapis.com/auth/admin.directory.user.readonly', 'https://www.googleapis.com/auth/admin.directory.group.readonly', 'https://www.googleapis.com/auth/admin.directory.group.member.readonly', 'https://www.googleapis.com/auth/admin.directory.orgunit.readonly']
  },
  office365Directory: {
    url: 'https://login.microsoftonline.com/common/adminconsent',
    clientId: 'b359834b-22d7-4925-a2df-8789da513429',
    redirectUri: 'http://localhost:3000/settings/directories/manage-directories'
  },
  stage: 'local',
  webSocketChannel: 'wss://notification.snoopy.test.area1security.com/sync/notification'
};
exports.client = client;