"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.random = random;

var _nanoid = require("nanoid");

/**
 * Returns a tiny, secure, URL-friendly, unique string.
 */
function random(size) {
  return (0, _nanoid.nanoid)(size);
}