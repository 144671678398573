// @flow

import { loader } from 'graphql.macro';
import { get } from 'lodash';
import React from 'react';
import { useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';

import ConditionalRender from 'ui/atoms/ConditionalRender';
import Loading from 'ui/atoms/Loading';
import BlockHeader from 'ui/molecules/BlockHeader';
import ContentBlock from 'ui/molecules/ContentBlock';
import FormatTime from 'ui/molecules/FormatTime';
import NoData from 'ui/molecules/NoData';
import { Body, Cell, Col, Container as Table, Row } from 'ui/molecules/Table';

export const query = loader('./queries/load.graphql');

interface Props {
  messageId: string;
}

export default function ResultView({ messageId }: Props) {
  const { t } = useTranslation('searchResults');

  const { error, data, loading } = useQuery(query, {
    skip: !messageId,
    variables: { id: messageId },
  });

  const messageData = get(data, 'message', { inbound: { lines: [] }, outbound: { lines: [] } });
  const metadataStatus = get(data, 'message.responseExplained', '');
  const inboundData = get(messageData, 'inbound.lines', []);
  const outboundData = get(messageData, 'outbound.lines', []);

  const noDataMessage = metadataStatus === 'Pending database update.' ? 'stillProcessingData' : 'noData';
  const renderInboundData = inboundData.length > 0 && !loading && !error;
  const renderOutboundData = outboundData.length > 0 && !loading && !error;
  const renderNoData = !renderInboundData && !renderOutboundData;

  return (
    <>
      <BlockHeader title={t('emailData')} />

      <ConditionalRender condition={loading || renderNoData}>
        {loading ? <Loading /> : <NoData message={noDataMessage} />}
      </ConditionalRender>

      <ConditionalRender condition={renderInboundData}>
        <ContentBlock
          bottom={
            <Table spaced>
              <colgroup>
                <Col width={300} />
              </colgroup>
              <Body>
                {inboundData.map((l) => (
                  <Row key={l.lineno}>
                    <Cell verticalAlign="top">
                      <FormatTime bold date={l.ts} displayTime />
                    </Cell>
                    <Cell>{l.message}</Cell>
                  </Row>
                ))}
              </Body>
            </Table>
          }
        />
      </ConditionalRender>

      <ConditionalRender condition={renderOutboundData}>
        <ContentBlock
          bottom={
            <Table spaced>
              <colgroup>
                <Col width={300} />
              </colgroup>
              <Body>
                {outboundData.map((l) => (
                  <Row key={l.lineno}>
                    <Cell verticalAlign="top">
                      <FormatTime bold date={l.ts} displayTime />
                    </Cell>
                    <Cell>{l.message}</Cell>
                  </Row>
                ))}
              </Body>
            </Table>
          }
        />
      </ConditionalRender>
    </>
  );
}
