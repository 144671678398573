// @flow

// $FlowFixMe
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { DaysBackContext } from 'screens/App';
import Dropdown from 'ui/organisms/Dropdown';

type Props = {
  onChange?: (selected: number) => {},
};

export default function DaysBackDropdown({ onChange }: Props) {
  const { currentInterval, setDaysBackContext } = useContext(DaysBackContext);
  const { t } = useTranslation('dates');

  function handleOnOptionSelected(selected: number) {
    if (onChange) onChange(selected);

    setDaysBackContext(selected);
  }

  return (
    <Dropdown
      expanded={false}
      options={[7, 30, 90]}
      onOptionSelected={(selected) => handleOnOptionSelected(selected)}
      renderAsOption={(option) => t('daysLast', { key: option })}
    >
      {t('lastDays_plural', { count: currentInterval })}
    </Dropdown>
  );
}
