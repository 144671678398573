// @flow

import { get } from 'lodash';

// $FlowFixMe
import React, { lazy, Suspense, useContext, useState } from 'react';
import { useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';

import { placeholderData } from '../dataTypesAndUtils';

import { dataForGraphTable, insightsBlockListByTypeQuery } from './dataTypesAndUtils';

import { DaysBackContext } from 'screens/App';

import Button from 'ui/atoms/Button';
import Loading from 'ui/atoms/Loading';
import BlockHeader from 'ui/molecules/BlockHeader';
import ContentBlock from 'ui/molecules/ContentBlock';
import Drawer from 'ui/molecules/Drawer';
import GraphTable from 'ui/molecules/GraphTable';
import PlaceholderGraphTable from 'ui/molecules/PlaceholderGraphTable';
import withDataHandler from 'utils/withDataHandler';

const DetailsTable = lazy(() => import('./DetailsTable'));

const GraphTableDataHandler = withDataHandler({
  empty: <PlaceholderGraphTable data={placeholderData} />,
})(GraphTable);

export default function BlockedRecipients() {
  const { currentInterval } = useContext(DaysBackContext);
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation('email');

  const { data, error, loading } = useQuery(insightsBlockListByTypeQuery, { variables: { currentInterval, limit: 5 } });
  const blockedData = get(data, 'blockListByType.data.blockedSender', []);

  return (
    <ContentBlock
      zebraStripe={false}
      top={
        <>
          <BlockHeader
            title={t('topBlockedPatterns')}
            action={
              <Button
                disabled={blockedData.length === 0}
                icon="hamburger"
                muted
                onClick={() => blockedData.length > 0 && setOpenModal(true)}
              >
                {t('viewDetails')}
              </Button>
            }
          />
        </>
      }
      bottom={
        <GraphTableDataHandler
          colorScheme="sea"
          data={dataForGraphTable(blockedData)}
          isEmpty={() => loading || error || blockedData.length === 0}
          isLoading={loading}
        />
      }
    >
      <Drawer.Modal visible={openModal} onClose={() => setOpenModal(false)} size="large" paddedSides={false}>
        <Suspense fallback={<Loading />}>{openModal && <DetailsTable fetchData={openModal} />}</Suspense>
      </Drawer.Modal>
    </ContentBlock>
  );
}
