// @flow

import { loader } from 'graphql.macro';
import { get } from 'lodash';
// $FlowFixMe
import React, { useContext } from 'react';
import { useQuery } from 'react-apollo';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { type PropType } from '../../dataTypesAndUtils';

import { calculatePercent } from '../dataTypesAndUtils';

import { senderMismatchRecord } from './dataTypesAndUtils';
import Mismatch from './Mismatch';
import MostCommonMismatchedSenders from './MostCommonMismatchedSenders';

import { DaysBackContext } from 'screens/App';

export const senderMismatchQuery = loader('./queries/SenderMismatchesQuery.graphql');

export default function SenderMismatchOverview({ detectionCount, detectionError, detectionLoading }: PropType) {
  const { currentInterval } = useContext(DaysBackContext);
  const { t } = useTranslation('email');

  const { data, error, loading } = useQuery(senderMismatchQuery, { variables: { currentInterval } });
  const {
    spoofSenderMismatchCounts,
    spoofSenderMismatchCountsByDate,
    spoofSenderMismatchesListByName,
    previousSpoofSenderMismatchCounts,
    previousSpoofSenderMismatchCountsByDate,
  } = get(data, 'senderMismatches', senderMismatchRecord);

  return (
    <>
      <Helmet title={`${t('senderMismatches')} :: ${t('common:email')} - ${t('common:area1security')}`} />
      <>
        <Mismatch
          data={{
            lineGraph: {
              current: spoofSenderMismatchCountsByDate,
              previous: previousSpoofSenderMismatchCountsByDate,
            },
            ringChart: calculatePercent(spoofSenderMismatchCounts.spoofSenderMismatchesTotal, detectionCount),
            sectionOverview: {
              current: get(spoofSenderMismatchCounts, 'spoofSenderMismatchesTotal', 0),
              previous: get(previousSpoofSenderMismatchCounts, 'spoofSenderMismatchesTotal', 0),
            },
          }}
          error={error || detectionError}
          loading={loading || detectionLoading}
        />
        <MostCommonMismatchedSenders data={spoofSenderMismatchesListByName} error={error} loading={loading} />
      </>
    </>
  );
}
