// @flow

import { loader } from 'graphql.macro';

export const customerUsersQuery = loader('./queries/FetchCustomerUsers.graphql');
export const ssoSettingsQuery = loader('../../../SingleSignOn/queries/FetchSSO.graphql');

export type CustomerUserType = {
  childPermission: string,
  createdAt: number,
  customerId: string,
  email: string,
  isParent: boolean,
  firstName: string,
  lastName: string,
  lockoutEnds: number,
  role: string,
  userId: ?number,
  userPermissions: Array<string>,
};

export const defaultCustomerUser = {
  childPermission: '',
  createdAt: 0,
  customerId: '',
  email: '',
  isParent: false,
  firstName: '',
  lastName: '',
  lockoutEnds: 0,
  role: '',
  userId: null,
  userPermissions: [],
};

export const setIncomingDelegatedAccountsRole = (role: string) => {
  switch (role) {
    case 'none':
      return 'none';
    case 'child read only':
      return 'delegatedReadOnly';
    case 'child admin':
      return 'delegatedReadWrite';
    default:
      return 'none';
  }
};

export const setIncomingRole = (role: string) => {
  switch (role) {
    case 'admin':
      return 'admin';
    case 'config admin':
      return 'configAdmin';
    case 'read only':
      return 'readOnly';
    case 'read write':
      return 'readWrite';
    default:
      return 'readWrite';
  }
};

export const setOutgoingDelegatedAccountsRole = (role: string) => {
  switch (role) {
    case 'none':
      return '';
    case 'delegatedReadOnly':
      return 'child read only';
    case 'delegatedReadWrite':
      return 'child admin';
    default:
      return '';
  }
};

// $FlowFixMe
export const customerUsersCacheUpdate = (cache, data) => {
  try {
    // return includes whole arr of users so we don't need readQuery
    cache.writeQuery({
      data: { customerUsers: data },
      query: customerUsersQuery,
    });
  } catch (error) {
    // check Sentry for potential errors
  }
};
