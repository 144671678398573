import { Stack, Text } from '@a1s/ui';
import { loader } from 'graphql.macro';
import React, { useEffect } from 'react';
import { useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';

import { usePermissions } from 'screens/Search/lib/permissions';

const query = loader('./query.graphql');

interface BenignEmailPreviewType {
  clientRecipients?: string[];
  clientUuid?: string;
  messageId?: string;
}

interface PreviewType {
  preview: {
    email: string;
    html: string;
  };
}
interface ResponseType {
  previews: {
    previews: PreviewType[];
  };
}

export function BenignEmailPreview({ clientRecipients, clientUuid, messageId }: BenignEmailPreviewType) {
  const userPermitted = usePermissions();
  const { t } = useTranslation('unisearch');
  const [runFetchPreviews, { data, error, loading }] = useMutation<ResponseType>(query);

  useEffect(() => {
    // Early return
    if (clientRecipients?.length === 0) return;

    const requests = clientRecipients?.map((recipient: string) => ({
      message_id: messageId,
      recipient,
    }));
    const fetchPreviews = async () => {
      try {
        await runFetchPreviews({
          variables: {
            input: {
              client_id: clientUuid,
              requests,
            },
          },
        });
      } catch (e) {
        // check Sentry for potential errors
      }
    };
    fetchPreviews();
  }, [clientRecipients, clientUuid, messageId, runFetchPreviews]);

  // Early return if user doesn't have permission
  if (!userPermitted)
    return (
      <Text font="sans" size="sm" stretch="ultraCondensed">
        {t('components:NoAccess.needAccessFromAdmin')}
      </Text>
    );

  // Early return if clientRecipients is empty
  if (clientRecipients?.length === 0)
    return (
      <Text font="sans" size="sm" stretch="ultraCondensed">
        {t('noPreview')}
      </Text>
    );

  if (loading)
    return (
      <Text font="sans" size="sm" stretch="ultraCondensed">
        {t('loading')}
      </Text>
    );

  if (error)
    return (
      <Text font="sans" size="sm" stretch="ultraCondensed">
        {t('unisearch:messagePreviewError')}
      </Text>
    );

  const previews = data?.previews?.previews || [];

  return (
    <>
      {previews.map(({ preview }: PreviewType) => (
        <Stack data-testid="benign-email-preview" key={messageId}>
          <Stack>
            <Text font="sans" size="sm" stretch="ultraCondensed">
              <Text weight="medium">{t('messagePreviewId')}</Text> {messageId}
            </Text>
            {/* eslint-disable-next-line react/no-danger */}
            <div dangerouslySetInnerHTML={{ __html: preview?.html }} />
          </Stack>
        </Stack>
      ))}
    </>
  );
}
