// @flow

import hash from 'hash-sum';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import ConditionalRender from 'ui/atoms/ConditionalRender';
import PaddedContent from 'ui/atoms/PaddedContent';
import Rule from 'ui/atoms/Rule';
import StatusIcon from 'ui/atoms/StatusIcon';

import BlockHeader from 'ui/molecules/BlockHeader';
import Content from 'ui/molecules/Content';
import FormatTime from 'ui/molecules/FormatTime';
import { Body, Cell, Row, Container as Table } from 'ui/molecules/Table';
import TableHeader from 'ui/molecules/TableHeader';
import TooltippedStatusIcon from 'ui/molecules/TooltippedStatusIcon';

const StyledCell = styled(Cell)`
  vertical-align: top;
  word-wrap: break-word !important;

  ${({ centered }) =>
    centered &&
    css`
      text-align: center;
    `};
`;

type RedressActionType = {
  clientName: string,
  clientId: string,
  completedTimestamp: string,
  destination: string,
  itemCount: number,
  messageId: string,
  operation: 'PREVIEW' | 'QUARANTINE_RELEASE' | 'RETRACTION',
  recipient: string,
  startedTimestamp: string,
  status: string,
  tenantId: string,
};

type Props = {
  redressActions: RedressActionType[],
};

export default function RedressActions({ redressActions }: Props) {
  const { t } = useTranslation('searchResults');

  return (
    <>
      <BlockHeader title={t('redressActions')} />
      <Content>{t('redressPageDescription')}</Content>

      <PaddedContent pullBottom="lg" pullTop="lg">
        <Rule />
      </PaddedContent>

      <ConditionalRender condition={!!redressActions.length}>
        <Table data-testid="redress-actions-successful-table" fixed spaced zebraStripes>
          <TableHeader
            colWidths={['12%', '25%', '11%', '32%', '13%', '4%', '4%']}
            headings={[
              t('actionType'),
              t('recipient'),
              t('destination'),
              t('messageId'),
              t('startEndTime'),
              t('count'),
              t('status'),
            ]}
            textAlign={(heading) => (heading === t('count') || heading === t('status') ? 'center' : 'left')}
          />

          <Body>
            {redressActions.map((action) => {
              const {
                completedTimestamp,
                destination,
                itemCount,
                operation,
                messageId,
                recipient,
                startedTimestamp,
                status,
              } = action;

              return (
                <Row key={hash(action)}>
                  <StyledCell>{t(operation)}</StyledCell>
                  <StyledCell>{recipient}</StyledCell>
                  <StyledCell>{destination ? t(`components:MailTraceResults.${destination}`) : t('na')}</StyledCell>
                  <StyledCell>{messageId}</StyledCell>
                  <StyledCell>{renderStartEnd(startedTimestamp, completedTimestamp)}</StyledCell>
                  <StyledCell centered>{itemCount}</StyledCell>
                  <StyledCell centered>{getStatusIcon(status)}</StyledCell>
                </Row>
              );
            })}
          </Body>
        </Table>
      </ConditionalRender>
    </>
  );
}

/*
    Private Functions
*/

const renderStartEnd = (startedTimestamp: string | number, completedTimestamp: string | number) => (
  <>
    <div>
      <FormatTime date={startedTimestamp} displayTime />
    </div>
    <div>
      <FormatTime date={completedTimestamp} displayTime />
    </div>
  </>
);

const getStatusIcon = (status: string) => {
  if (status === 'OK' || status === 'SUCCESS') {
    return <StatusIcon name="success" noMargin small />;
  }
  return <TooltippedStatusIcon name="error" noMargin small tooltipContents={<p>{status}</p>} tooltipPosition="left" />;
};
