import { Box, Cluster, Stack } from '@a1s/ui';
import { loader } from 'graphql.macro';
import React, { ComponentProps, useState } from 'react';
import { useQuery, QueryResult } from 'react-apollo';
import { useTranslation } from 'react-i18next';

import { MailviewData, SearchResultRow } from '../../../types';
import { BackButton, EmailDetails, MailTrace, RawEmail, Tabs } from '../../../ui';

import { GetMessagePreview } from '.';

import { Scrollable } from 'ui-new';

//
// Main component
// -------------------------------------------------------------------------------------------------

interface DetailsProps {
  onPressBackButton?: ComponentProps<typeof BackButton>['onPress'];
  row?: SearchResultRow;
}

export function Details({ onPressBackButton, row }: DetailsProps) {
  const isBenign = row?.finalDisposition?.toLowerCase() === 'none';
  const { data: mailviewData, loading: mailviewLoading } = useRemoteData(row?.postfixIdent, isBenign);

  return (
    <Box bg="$blue200" css={{ flexGrow: 2, height: '100%', position: 'relative' }} r p="4">
      <BackButton css={{ position: 'absolute', right: '$5', top: '$4', zIndex: 999 }} onPress={onPressBackButton} />

      <Cluster css={{ height: '100%' }} gap="5">
        <EmailData
          emlPath={row?.storedAt}
          clientRecipients={row?.clientRecipients}
          clientUuid={row?.clientUuid}
          detailData={mailviewData}
          isBenign={isBenign}
          isJournaled={row?.isJournaled}
          isQuarantined={row?.isQuarantined}
          lookupKey={row?.key}
          mailviewLoading={mailviewLoading}
          searchInfo={row}
          messageId={row?.messageId}
          previewEnabled={row?.storedAt === null}
        />
      </Cluster>
    </Box>
  );
}

//
// Private components
// -------------------------------------------------------------------------------------------------

interface EmailDataProps {
  emlPath?: string;
  clientRecipients?: string[];
  clientUuid?: string;
  detailData?: MailviewData;
  isBenign: boolean;
  isJournaled?: boolean;
  isQuarantined?: boolean;
  lookupKey?: string;
  mailviewLoading?: boolean;
  messageId?: string;
  searchInfo?: SearchResultRow;
  previewEnabled?: boolean;
}

function EmailData({
  emlPath,
  clientRecipients,
  clientUuid,
  detailData,
  isBenign,
  isJournaled,
  isQuarantined,
  lookupKey,
  mailviewLoading,
  messageId,
  previewEnabled,
  searchInfo,
}: EmailDataProps) {
  const { t } = useTranslation('unisearch');
  type TabState = 'details' | 'downloads' | 'mailtrace' | 'preview' | 'raw';

  const [selected, setSelected] = useState<TabState>('details');
  const { screenshot } = detailData || {};

  const messagePreviewDisabled = clientRecipients?.length === 0;

  return (
    <Stack css={{ flexGrow: 2 }}>
      <Tabs>
        <Tabs.Tab onClick={() => setSelected('details')} selected={selected === 'details'}>
          {t('Email Details')}
        </Tabs.Tab>
        <Tabs.Tab
          disabled={messagePreviewDisabled}
          onClick={() => setSelected('preview')}
          selected={selected === 'preview'}
          title={messagePreviewDisabled ? t('noPreview') : null}
        >
          {t('emailPreview')}
        </Tabs.Tab>
        <Tabs.Tab
          disabled={isBenign}
          onClick={() => setSelected('raw')}
          selected={selected === 'raw'}
          title={isBenign ? t('noRawEmail') : null}
        >
          {t('rawEmail')}
        </Tabs.Tab>
        <Tabs.Tab onClick={() => setSelected('mailtrace')} selected={selected === 'mailtrace'}>
          {t('mailTrace')}
        </Tabs.Tab>
      </Tabs>

      <Box bg="$white" css={{ flexGrow: 1, height: '100%' }} r p="2">
        <Box css={{ height: '100%', position: 'relative' }}>
          <Scrollable>
            <Box p="2">
              {selected === 'details' && (
                <EmailDetails
                  loading={mailviewLoading}
                  mailviewHighlightData={detailData?.highlightData}
                  searchInfo={searchInfo}
                />
              )}
              {selected === 'preview' && (
                <GetMessagePreview
                  clientRecipients={clientRecipients}
                  clientUuid={clientUuid}
                  image={screenshot}
                  loading={mailviewLoading}
                  messageId={messageId}
                  previewEnabled={previewEnabled}
                />
              )}

              {selected === 'raw' && <RawEmail emlPath={emlPath} />}
              {selected === 'mailtrace' && (
                <MailTrace lookupKey={lookupKey} showOutbound={!isJournaled && !isQuarantined} />
              )}
            </Box>
          </Scrollable>
        </Box>
      </Box>
    </Stack>
  );
}

//
// Private hooks
// -------------------------------------------------------------------------------------------------

const query = loader('../queries/mailview.graphql');

interface HookResult {
  /**
   * The data that has been returned from the API
   */
  data?: MailviewData;

  /**
   * If there is a problem loading the data, the error information will be available as an error object
   */
  error: QueryResult['error'] | null;

  /**
   * Returns true if the data is currently being loaded
   */
  loading: boolean;
}

function useRemoteData(messageId: string | undefined, isBenign: boolean): HookResult {
  const { data, error, loading } = useQuery(query, {
    fetchPolicy: 'network-only',
    skip: isBenign || typeof messageId === 'undefined',
    variables: {
      id: messageId,
    },
  });

  if (error) return { data: undefined, error, loading: false };
  if (!data?.response) return { data: undefined, error: null, loading };

  return { data: data.response, error, loading };
}
