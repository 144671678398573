// @flow

import { getMonth, getYear, isEqual } from 'date-fns';
import React from 'react';

import { useTranslation } from 'react-i18next';

import Container from './Container';

type Props = {
  dataTestId?: string,
  end: Date,
  start: Date,
};

// NOTE: Do  other languages display their date ranges differently? Should this logic be moved to i18n?
export default function DatePicker({ dataTestId = 'molecule-date-picker', end, start }: Props) {
  const { t } = useTranslation('components');

  const renderDate = () => {
    if (isEqual(start, end)) return t('DatePicker.date', { date: start });

    if (getYear(start) === getYear(end)) {
      if (getMonth(start) === getMonth(end)) {
        return [t('DatePicker.dateNoYear', { date: start }), ' – ', t('DatePicker.dateNoMonth', { date: end })];
      }
      return [t('DatePicker.dateNoYear', { date: start }), ' – ', t('DatePicker.date', { date: end })];
    }
    return [t('DatePicker.date', { date: start }), ' – ', t('DatePicker.date', { date: end })];
  };

  return <Container data-testid={dataTestId}>{renderDate()}</Container>;
}
