import { Box, Cluster, Loadable, Stack, Text, TextBlock } from '@a1s/ui';
import { ApolloError } from 'apollo-client';
import { formatISO, subDays } from 'date-fns';
import gql from 'graphql-tag';
import React from 'react';
import { useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';

import { Duration } from './Middle';
import { Cell, CheckMark, Header, Link, Problem, Table } from './styled';
import { ReactComponent as TinyWarningSVG } from './tiny-warning.svg';

//
// Main component
// -------------------------------------------------------------------------------------------------

interface UpTimeStatsProps {
  duration: Duration;
}

export default function UpTimeStats({ duration }: UpTimeStatsProps) {
  const { data, loading } = useRemoteData(duration);
  const incidents = data?.incidents || [];

  let color = '$green400';
  if (incidents.length > 0) color = '$yellow500';
  if (loading) color = '$gray400';

  const { t } = useTranslation('dashboardHome');

  return (
    <Loadable loading={loading}>
      <Cluster gap>
        <Box bg={color} px py="2" rr>
          <Stack justify="center" css={{ minHeight: '100%' }}>
            <Cluster align="center">
              <Box pl="2" pr="1">
                {loading || incidents.length === 0 ? <CheckMark /> : <Problem />}
              </Box>
            </Cluster>
          </Stack>
        </Box>

        <Stack justify="space-between">
          <Text color="$gray400" font="sans" stretch="ultraCondensed">
            <TextBlock.Loadable color={color} transform="uppercase">
              {incidents.length > 0 ? t('servicesImpacted') : t('allServicesOnline')}
            </TextBlock.Loadable>{' '}
            {!loading && incidents.length > 0 && <SupportLinks detailsURL={incidents[0].shortlink} />}
          </Text>

          <Table>
            <ServiceStats
              displayErrorIndicator={!loading && !!data && hasActiveIncident(data.email, incidents)}
              label={t('Email')}
              uptimePercent={data?.email.uptimePercentage}
            />
            <ServiceStats
              displayErrorIndicator={!loading && !!data && hasActiveIncident(data.api, incidents)}
              label={t('API')}
              uptimePercent={data?.api.uptimePercentage}
            />
            <ServiceStats
              displayErrorIndicator={!loading && !!data && hasActiveIncident(data.portal, incidents)}
              label={t('Portal')}
              uptimePercent={data?.portal.uptimePercentage}
            />
          </Table>
        </Stack>
      </Cluster>
    </Loadable>
  );
}

//
// Data fetching
// -------------------------------------------------------------------------------------------------

const QUERY = gql`
  query StatusPage($start: String!, $end: String!) {
    api(start: $start, end: $end)
      @rest(
        endpoint: "statuspage"
        path: "/uptime?service=api&start={args.start}&end={args.end}"
        type: "StatusPageUptimeData"
      ) {
      id
      relatedEvents
      uptimePercentage
      warnings
    }

    email(start: $start, end: $end)
      @rest(
        endpoint: "statuspage"
        path: "/uptime?service=email&start={args.start}&end={args.end}"
        type: "StatusPageUptimeData"
      ) {
      id
      relatedEvents
      uptimePercentage
      warnings
    }

    portal(start: $start, end: $end)
      @rest(
        endpoint: "statuspage"
        path: "/uptime?service=portal&start={args.start}&end={args.end}"
        type: "StatusPageUptimeData"
      ) {
      id
      relatedEvents
      uptimePercentage
      warnings
    }

    incidents(start: $start, end: $end)
      @rest(endpoint: "statuspage", path: "/incidents", type: "StatusPageIncidentsData") {
      id
      shortlink
      status
    }
  }
`;

interface IncidentsData {
  id: string;
  shortlink: string;
  status: string;
}

interface ServiceData {
  id: string;
  relatedEvents: Array<{ id: string }>;
  uptimePercentage: number;
  warnings: string[];
}

interface HookResult {
  /**
   * The data that has been returned from the API
   */
  data?: { api: ServiceData; email: ServiceData; incidents: IncidentsData[]; portal: ServiceData };

  /**
   * If there is a problem loading the data, the error information will be available as an error object
   */
  error: ApolloError | null;

  /**
   * Returns true if the data is currently being loaded
   */
  loading: boolean;
}

/**
 * Private hook that encapsulates loading the data for UpTimeStats.
 */
function useRemoteData(duration: Duration = '30'): HookResult {
  const end = formatISO(new Date(), { representation: 'date' });
  const start = formatISO(subDays(new Date(), Number.parseInt(duration)), { representation: 'date' });

  const { data, error, loading } = useQuery(QUERY, {
    fetchPolicy: 'cache-and-network',
    pollInterval: 120000, // 2 minutes
    variables: { end, start },
  });
  return { data, error: error || null, loading };
}

//
// Private components
// -------------------------------------------------------------------------------------------------

interface ServiceStatsProps {
  displayErrorIndicator: boolean;
  label: string;
  uptimePercent?: number;
}

function ServiceStats({ displayErrorIndicator, label, uptimePercent }: ServiceStatsProps) {
  const { t } = useTranslation('dashboardHome');

  return (
    <tr>
      <Header error={displayErrorIndicator} scope="row">
        {label} {displayErrorIndicator && <TinyWarningSVG />}
      </Header>
      <Cell>
        <Text.Loadable>{t('uptime', { key: uptimePercent || '-.-' })}</Text.Loadable>
      </Cell>
    </tr>
  );
}

interface SupportLinksProps {
  detailsURL?: string;
}

function SupportLinks({ detailsURL }: SupportLinksProps) {
  const { t } = useTranslation('dashboardHome');

  const details = detailsURL ? (
    <>
      <Link href={detailsURL}>{t('View Details')}</Link>,{' '}
    </>
  ) : null;

  return (
    <>
      ({details}
      <Link href="https://area1security.zendesk.com/hc/en-us">{t('Contact Support')}</Link>)
    </>
  );
}

//
// Private functions
// -------------------------------------------------------------------------------------------------

// https://developer.statuspage.io/#operation/getPagesPageIdIncidents
const ACTIVE_INCIDENT_TYPES = ['investigating', 'identified', 'monitoring', 'in_progress'];

function activeIncidents(incidents?: IncidentsData[]): IncidentsData[] {
  if (!incidents) return [];

  return incidents.filter(({ status }) => ACTIVE_INCIDENT_TYPES.includes(status));
}

function hasActiveIncident(service: ServiceData, incidents: IncidentsData[]) {
  const activeIncidentIds = activeIncidents(incidents).map(({ id }) => id);
  const serviceRelatedEventIds = service?.relatedEvents?.map(({ id }) => id) || [];

  return activeIncidentIds.some((incidentId) => serviceRelatedEventIds.includes(incidentId));
}
