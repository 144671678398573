import { Button, Text } from '@a1s/ui';
import { loader } from 'graphql.macro';
import React, { ComponentProps, ReactElement, useMemo, useState } from 'react';
import { useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';

import { ReleaseParams } from '../../types';

import { Dialog } from 'ui-new';

interface Props {
  appearance?: ComponentProps<typeof Button>['appearance'];
  disabled?: ComponentProps<typeof Button>['disabled'];
  releaseParams: ReleaseParams[];
}

const query = loader('./query.graphql');

interface ResponseProps {
  messagePath: string;
  serverResponse: {
    /**
     * Either null or an array of email addresses that the message was released to.
     */
    delivered: string[] | null;
    failed: string[] | null;
    undelivered: string[] | null;
  };
}

export function ReleaseButton({ appearance, disabled, releaseParams }: Props) {
  const [completeDialogVisible, setCompleteDialogVisible] = useState(false);
  const [confirmDialogVisible, setConfirmDialogVisible] = useState(false);
  const [errorDialogVisible, setErrorDialogVisible] = useState(false);

  const sanitizedReleaseParams = useMemo(() => {
    return releaseParams?.filter((row: ReleaseParams) => row.clientRecipients.length !== 0);
  }, [releaseParams]);

  const { t } = useTranslation('unisearch');

  const [runMutation, { loading }] = useMutation<{ releaseMessages: { responses: ResponseProps[] } }>(query);

  const closeAlertDialog = () => {
    setCompleteDialogVisible(false);
  };

  const openConfirmDialog = () => {
    setConfirmDialogVisible(true);
  };

  const closeConfirmDialog = () => {
    setConfirmDialogVisible(false);
  };

  const closeErrorDialog = () => {
    setErrorDialogVisible(false);
  };

  const handleConfirm = async () => {
    if (sanitizedReleaseParams.length === 0) {
      setConfirmDialogVisible(false);
      return;
    }

    // eslint-disable-next-line camelcase
    type RequestParams = { envelope_to: ReleaseParams['clientRecipients']; message_path: ReleaseParams['storedAt'] };
    const request = sanitizedReleaseParams.reduce((acc, { clientRecipients, storedAt }) => {
      // eslint-disable-next-line camelcase
      return [...acc, { envelope_to: clientRecipients, message_path: storedAt }];
    }, [] as RequestParams[]);

    try {
      const { data: response } = await runMutation({
        variables: { input: { request } },
      });

      const responses = response?.releaseMessages?.responses || [];
      const releaseSucceeded = responses.every(serverResponseSucceeded);

      if (!releaseSucceeded) {
        setErrorDialogVisible(true);
      } else {
        setCompleteDialogVisible(true);
      }
    } catch (e) {
      // Sentry handles this.
    }

    setConfirmDialogVisible(false);
  };

  const buttonDisabled: boolean | undefined = sanitizedReleaseParams.length === 0 || completeDialogVisible || disabled;

  return (
    <>
      <Button appearance={appearance} disabled={buttonDisabled} onPress={openConfirmDialog} testId="release-button">
        <TextDecorator disabled={buttonDisabled}>{!completeDialogVisible ? t('release') : t('released')}</TextDecorator>
      </Button>

      <Dialog.Confirmation
        confirmButtonText={loading ? t('releasing') : t('release')}
        message={
          sanitizedReleaseParams.length > 0 ? (
            <>
              <Text as="div" font="sans" size="md" stretch="ultraCondensed" weight="medium">
                {!loading ? (
                  <>
                    {t('confirmReleasing', { count: sanitizedReleaseParams.length })}
                    <Text as="p" weight={100}>
                      {t('releasingMessage')}
                    </Text>
                  </>
                ) : (
                  t('loading')
                )}
              </Text>
            </>
          ) : (
            <Text as="p" font="sans" size="md" stretch="ultraCondensed" weight="medium">
              {t('cannotRelease')}
            </Text>
          )
        }
        handleClose={closeConfirmDialog}
        handleConfirm={handleConfirm}
        visible={confirmDialogVisible}
      />

      <Dialog.Alert
        confirmButtonText="OK"
        message={
          <Text as="p" font="sans" size="md" stretch="ultraCondensed" weight="medium">
            {t('confirmMessage')}
          </Text>
        }
        handleClose={closeAlertDialog}
        visible={completeDialogVisible}
      />

      <Dialog.Alert
        confirmButtonText="OK"
        message={
          <Text as="p" font="sans" size="md" stretch="ultraCondensed" weight="medium">
            {t('releasingIssue')}
          </Text>
        }
        handleClose={closeErrorDialog}
        visible={errorDialogVisible}
      />
    </>
  );
}

//
// Private components
// -------------------------------------------------------------------------------------------------

interface TextDecoratorType {
  children: ReactElement;
  disabled?: boolean;
}

function TextDecorator({ children, disabled }: TextDecoratorType) {
  if (disabled) return <s>{children}</s>;
  return children;
}

//
// Helper functions
// -------------------------------------------------------------------------------------------------
function serverResponseSucceeded({ serverResponse }: ResponseProps) {
  return (
    // @ts-ignore - optional chaining should take care of undefined.
    serverResponse?.delivered?.length > 0 && serverResponse.failed === null && serverResponse?.undelivered === null
  );
}
