// @flow

import { random } from '@a1s/lib';

import React from 'react';

import Container from './Container';
import Input from './Input';
import Label from './Label';

import DisabledContainer from 'ui/atoms/DisabledContainer';

type Props = {
  dataTestId?: string,
  disabled?: boolean,
  disabledTooltipContents?: React$Node,
  id?: string,
  label?: React$Node,
  block?: boolean,
};

const Radio = ({
  dataTestId = 'atom-radio',
  disabled = false,
  disabledTooltipContents,
  id = `id${random()}`,
  label,
  block = false,
  ...props
}: Props) => (
  <Container block={block} data-testid={dataTestId}>
    <DisabledContainer disabled={disabled} tooltipContents={disabledTooltipContents}>
      <Input disabled={disabled} id={id} {...props} />
      <Label disabled={disabled} htmlFor={id}>
        {label}
      </Label>
    </DisabledContainer>
  </Container>
);

export default Radio;
