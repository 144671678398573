// @flow

import { camelCase, snakeCase } from '@a1s/lib';
import * as Sentry from '@sentry/browser';
import { defaultDataIdFromObject, InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { createHttpLink } from 'apollo-link-http';
import { RestLink } from 'apollo-link-rest';
import { get } from 'lodash';

import { csrfAxios } from '../setupAxios';
import traceId from '../utils/traceId';
import { b64DecodeUnicode } from '../utils/util';

import resolvers from './resolvers';

import {
  setIncomingDelegatedAccountsRole,
  setIncomingRole,
} from 'screens/Settings/screens/UserManagement/screens/Users/dataTypesAndUtils';
import getPermissionLevel from 'utils/permissions';

const ALLOWED_AUTH_OPERATIONS = ['Authenticate', 'PostInvite', 'UpdatePassword'];

const DENORMALIZE_IGNORE_LIST = [
  'Anonymity',
  'Compromised',
  'IgnoreAlexa',
  'Malicious',
  'Parking Server',
  'Security Vendor',
  'Suspicious',
  'Targeted',
  'Universal',
];

// regex for space and period
const NORMALIZE_IGNORE_REGEX = /\s|\./;

const cache = new InMemoryCache({
  dataIdFromObject: (object) => {
    // eslint-disable-next-line no-underscore-dangle
    switch (object.__typename) {
      case 'CurrentUser':
        return object.userId;
      case 'DelegatedAccount':
        return object.customerId;
      case 'SsoSettings':
        return object.customerId;
      case 'Users':
        return object.userId;
      default:
        return defaultDataIdFromObject(object); // fall back to default handling
    }
  },
});

class ApolloErrorLink extends Error {
  constructor(...params) {
    super(...params);
    this.name = 'ApolloErrorLink';
  }
}

const errorLink = onError(({ forward, networkError, operation }) => {
  if (networkError) {
    // report error to Sentry
    try {
      throw new ApolloErrorLink(
        `Operation ${operation.operationName} failed with status code ${
          networkError.statusCode || 'unknown'
        }. Reason: ${JSON.stringify(networkError)}`
      );
    } catch (error) {
      Sentry.withScope((scope) => {
        scope.setExtra('Network error', networkError);
        if (operation) {
          scope.setExtra('Query name', operation.operationName);

          // DetectionSearch and MailTrace don't have input key
          // 'search' value needs to be decoded for readability
          if (operation.operationName === 'DetectionSearch' || operation.operationName === 'MailTrace') {
            Object.keys(operation.variables).forEach((item) => {
              if (item === 'search') {
                scope.setExtra(item, b64DecodeUnicode(operation.variables[item]));
              } else {
                scope.setExtra(item, operation.variables[item]);
              }
            });
          }

          if (operation.variables.input) {
            Object.keys(operation.variables.input).forEach((item) =>
              scope.setExtra(item, operation.variables.input[item])
            );
          } else {
            // some mutations don't have input key (ex.: Login or DeleteSomethingMutation)
            Object.keys(operation.variables).forEach((item) => {
              scope.setExtra(item, operation.variables[item]);
            });
          }
        }
        Sentry.captureException(error);
      });
    }

    switch (networkError.statusCode) {
      case 400:
      case 409:
      case 422:
        break;
      case 403:
        if (window.location.pathname === '/users/login') {
          break;
        }
        if (!ALLOWED_AUTH_OPERATIONS.includes(operation.operationName)) {
          window.location = '/users/login';
        }
        break;
      default:
        break;
    }
  }
  forward(operation);
});

const restLink = new RestLink({
  endpoints: {
    actors: {
      uri: '/api',
      responseTransformer: async (response) =>
        response.json().then((data) => Object.keys(data).map((id) => ({ id, ...data[id] }))),
    },
    actions: {
      uri: '/api',
    },
    allowLists: {
      uri: '/api',
      responseTransformer: async (response) => {
        const data = await response.json();
        return {
          allowLists: data.rows.map((d) => ({
            ...d,
            __typename: 'AllowList',
          })),
          numPages: data.num_pages,
        };
      },
    },
    batchBlockedSenders: {
      uri: '/api',
      responseTransformer: async (response) => {
        const data = await response.json();
        return {
          failures: data.failures.map((e) => ({ ...e, __typename: 'BlockedSenderFailure' })),
          // eslint-disable-next-line camelcase
          blacklists: data.blacklists.map(({ comments, created_at, id, is_regex, pattern }) => ({
            comments,
            created_at,
            id,
            is_regex,
            pattern,
            __typename: 'BlockedSender',
          })),
        };
      },
    },
    businessDetections: {
      uri: '/api',
      responseTransformer: async (response) => {
        const data = await response.json();
        return {
          ...data,
          // adding a fake id enables auto update
          id: 1,
        };
      },
    },
    cfLink: {
      uri: '/api/cf-auth/link',
      responseTransformer: async (response) => {
        const data = await response.json();
        return { ...data, __typename: 'CurrentUser' };
      },
    },
    checkEmail: {
      uri: '/check_email',
    },
    // this is needed because the prefix is not /api
    checkTfa: {
      uri: '/login',
      responseTransformer: async (response) => {
        const data = await response.json();
        return { ...data, __typename: 'TFACheck' };
      },
    },
    currentUser: {
      uri: '/api',
      responseTransformer: async (response) =>
        response.json().then((data) => ({
          ...data,
          child_permission: getPermissionLevel(data.user_permissions, 'child'),
          parent_permission: getPermissionLevel(data.customer_permissions, 'parent'),
        })),
    },
    customerUsers: {
      uri: '/api',
      responseTransformer: async (response) => {
        const data = await response.json();
        return data.map((user) => ({
          ...user,
          role: setIncomingRole(user.role),
          child_permission: setIncomingDelegatedAccountsRole(getPermissionLevel(user.user_permissions, 'child')),
        }));
      },
    },
    dashboardCampaigns: {
      uri: '/api',
      responseTransformer: async (response) => {
        const data = await response.json();
        return {
          campaigns: data.campaigns.map(({ actor, ...rest }) => ({ id: actor, __typename: 'Campaign', ...rest })),
          targetCountByCountry: data.target_count_by_country.map((t) => ({
            countryName: t.key,
            count: t.count,
            __typename: 'TargetCountry',
          })),
        };
      },
    },
    detections: {
      uri: '/api/detections',
    },
    directories: {
      uri: '/api',
      responseTransformer: async (response) =>
        response.json().then((data) =>
          Object.keys(data).map((id) => ({
            id,
            ...data[id],
            connector: data[id].connector ? data[id].connector : { id: null, name: null },
          }))
        ),
    },

    directoryAccessUrl: {
      uri: '/api',
      responseTransformer: async (response) => {
        const data = await response.json();
        return {
          ...data,
          // adding a fake id enables auto update
          id: 1,
        };
      },
    },
    directoryStatus: {
      uri: '/api',
      responseTransformer: async (response) => {
        if (!response || !response.json) return null;
        const data = await response.json();
        if (!data) return null;

        return { ...data, __typename: 'DirectoryData' };
      },
    },
    directoryTenant: {
      uri: '/api',
      responseTransformer: async (response) => {
        if (!response || !response.json) return null;
        const data = await response.json();
        if (!data) return null;

        const directories = data.directory_ids.map((id) => ({ id, __typename: 'Directory' }));
        return { ...data, directories, __typename: 'DirectoryTenant' };
      },
    },
    displayNames: {
      uri: '/api/snoopy',
      responseTransformer: async (response) => {
        const data = await response.json();
        return {
          displayNames: data.data.map((d) => ({
            ...d,
            __typename: 'DisplayName',
          })),
          numPages: data.num_pages,
        };
      },
    },
    facets: {
      uri: '/api',
      responseTransformer: async (response) => {
        const data = await response.json();
        return { ...data, id: 1 };
      },
    },
    industryRollups: {
      uri: '/api',
      responseTransformer: async (response) =>
        response.json().then((data) => data.map(({ name, ...rest }) => ({ id: name, ...rest }))),
    },
    insights: {
      uri: '/api/insights',
    },
    login: {
      uri: '/login',
      responseTransformer: async (response) => {
        const data = await response.json();
        return { ...data, __typename: 'CurrentUser' };
      },
    },
    mailReporting: {
      uri: '/api',
      responseTransformer: async (response) => {
        const data = await response.json();
        const detectionStats = Object.keys(data).map((key) => ({ [key]: data[key] }));
        const last30 = detectionStats.pop();
        const sortedMonths = monthsSorted(detectionStats);
        if (last30) {
          sortedMonths[0] = last30;
        }
        return {
          totalmailReporting: sortedMonths,
        };
      },
    },
    mailsearch: {
      uri: '/api/search/mailsearch',
    },
    mailtrace: {
      uri: '/api/search/mailtrace',
    },
    mailtraceLoad: {
      uri: '/api/search/mailtrace',
      responseTransformer: async (response) => {
        const data = await response.json();

        /*
          Seeing `get() || []` might seem weird, but it's needed because when there isn't any outbound/inbound data the keys are still there
          but populated with `null`.
          ie:
          "outbound": {"postfixId": null, "lines": null, "summary": null}
        */
        const inboundLines = get(data, 'inbound.lines', []) || [];
        const inboundPostfixId = get(data, 'inbound.postfixId', null);
        const outboundLines = get(data, 'outbound.lines', []) || [];
        const outboundPostfixId = get(data, 'outbound.postfixId', null);

        return {
          inbound: {
            lines: inboundLines.map((l) => ({ ...l, __typename: 'MailTraceLoadLine' })),
            postfixId: inboundPostfixId,
            __typename: 'MailTraceLoadBound',
          },
          outbound: {
            lines: outboundLines.map((l) => ({ ...l, __typename: 'MailTraceLoadLine' })),
            postfixId: outboundPostfixId,
            __typename: 'MailTraceLoadBound',
          },
          responseExplained: data.responseExplained,
        };
      },
    },
    mailview: {
      uri: '/api/mailview',
    },
    praReport: {
      uri: '/api/pra',
    },
    resetPassword: {
      uri: '/api/users/requestreset',
    },
    resetUser2fa: {
      uri: '/api',
      responseTransformer: async (response) => {
        const data = await response.json();
        return {
          ...data,
          role: setIncomingRole(data.role),
          child_permission: setIncomingDelegatedAccountsRole(getPermissionLevel(data.user_permissions, 'child')),
        };
      },
    },
    samlLogin: {
      uri: '/saml_login',
    },
    ssoSettings: {
      uri: '/api',
      responseTransformer: async (response) => {
        const data = await response.json();
        return {
          ...data,
          // adding a fake id enables auto update
          id: 1,
        };
      },
    },
    statuspage: {
      uri: '/api/statuspage',
    },
    textAddOns: {
      uri: '/api',
      responseTransformer: async (response) => {
        const data = await response.json();
        return {
          ...data,
          // adding a fake id enables auto update
          id: 1,
        };
      },
    },
    thirdPartySaml: {
      uri: '/third_party_saml',
    },
    thwartedAttacksCount: {
      uri: '/api/aggregates/dashboard_counts',
      responseTransformer: async (response) => {
        const data = await response.json();
        return { ...data };
      },
    },
    trustedDomains: {
      uri: '/api',
      responseTransformer: async (response) => {
        const data = await response.json();
        return {
          allowLists: data.rows.map((d) => ({
            ...d,
            __typename: 'TrustedDomain',
          })),
          numPages: data.num_pages,
        };
      },
    },
    webhooksDetails: {
      uri: '/api',
      responseTransformer: async (response) => {
        const data = await response.json();
        return {
          details: data,
          __typename: 'WebhooksDetailData',
        };
      },
    },
    webhooksSummary: {
      uri: '/api',
      responseTransformer: async (response) => {
        const data = await response.json();
        return {
          summaries: data,
          __typename: 'WebhooksSummaries',
        };
      },
    },
  },
  fieldNameDenormalizer: (key) => {
    return DENORMALIZE_IGNORE_LIST.includes(key) ? key : snakeCase(key);
  },
  // Don't camelCase keys with a space. Account delegation -> create customer accepts an error key that could be
  // a customer name or an email address as an i18n key to display an error message.
  // Eg, Customer Xyz becomes customerXyz otherwise
  fieldNameNormalizer: (key) => (NORMALIZE_IGNORE_REGEX.test(key) ? key : camelCase(key)),
  headers: {
    'X-TRACE-ID': traceId(),
  },
  // TODO: can we get rid of this so we don't need to add endpoints for things like `login` and `checkEmail`
  uri: '/api',
});

const getCsrfToken = async () => {
  const {
    data: { token },
  } = await csrfAxios.post('/create_csrf_token');
  return token;
};

const csrfLink = setContext(async (request) => {
  const { definitions } = request.query;
  if (definitions.length > 0) {
    const { operation } = definitions.find((definition) => definition.kind === 'OperationDefinition');
    if (operation === 'mutation') {
      const token = await getCsrfToken();
      return {
        headers: {
          'X-CSRF-TOKEN': token,
          'X-TRACE-ID': traceId(),
        },
      };
    }
  }

  return {};
});

const httpLink = createHttpLink({ uri: '/graphql', headers: { 'X-TRACE-ID': traceId() } });

const client = new ApolloClient({
  cache,
  link: ApolloLink.from([errorLink, csrfLink, restLink, httpLink]),
  resolvers,
});

export default client;

/*
  private functions
*/

const monthsSorted = (data) =>
  data.sort((a, b) => {
    const date1 = new Date(Object.keys(a)[0].split('/')[1], Object.keys(a)[0].split('/')[0]);
    const date2 = new Date(Object.keys(b)[0].split('/')[1], Object.keys(b)[0].split('/')[0]);
    if (date1 > date2) return -1;
    if (date1 < date2) return 1;
    return 0;
  });
