import { StackedBarChart, StackedBarChartProps } from '@a1s/ui';
import React from 'react';

import { APIData } from '.';

//
// Main component
// -------------------------------------------------------------------------------------------------

interface TimelineChartProps {
  data?: APIData;
  width?: number;
}

export default function TimelineChart({ data, width = 315 }: TimelineChartProps) {
  return (
    <StackedBarChart.Loadable
      data={data?.timeline?.current as StackedBarChartProps['data']}
      height="240"
      testId="detection-stats-stacked-bar-chart"
      width={String(width)}
    />
  );
}
