// @flow

import marked from 'marked';
import React from 'react';

import Content from './Content';

type Props = { dataTestId?: string, children: string };

const MarkedDown = ({ dataTestId = 'molecule-marked-down', children }: Props) => (
  <Content dangerouslySetInnerHTML={{ __html: children && marked(children.toString()) }} data-testid={dataTestId} />
);

export default MarkedDown;
