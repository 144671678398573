// @flow

import { rem } from 'polished';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Label, type MailTraceParams, type SearchComponentProps } from '.';

import Input from 'ui/atoms/Input';
import DateRangePicker from 'ui/molecules/DateRangePicker';

//
// Styled components
// -------------------------------------------------------------------------------------------------

const Bottom = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  min-height: ${rem(38)};
  order: 6;
  padding: 0 ${(p) => rem(p.theme.spacing.lg)};
  width: 100%;
`;

const Divider = styled.span.attrs({ role: 'presentation' })`
  background: ${({ theme }) => theme.colors.azureishWhite};
  display: block;
  flex-grow: 1;
  order: 6;
  height: ${rem(1)};
  margin: 0 ${(p) => rem(p.theme.spacing.lg)};
  pointer-events: none;
  width: 100%;
`;

const GrowingInput = styled(Input)`
  flex-grow: 1;
`;

const Top = styled.div`
  display: flex;
  flex-grow: 1;
  order: 3;
`;

//
// Main component
// -------------------------------------------------------------------------------------------------

type Props = {
  ...SearchComponentProps,
  handleChange: Function,
  setFieldValue: Function,
  values: MailTraceParams,
};

export default function MailTrace(props: Props) {
  const { t } = useTranslation('searchResults');

  const { handleChange, onBlur, onFocus, setFieldValue, values } = props;

  function handleBlur(key: string) {
    return () => onBlur && onBlur(key);
  }

  function handleFocus(key: string) {
    return () => onFocus && onFocus(key);
  }

  const { alertId, dateRange, domain, messageId, recipient, sender, subject } = values;

  return (
    <>
      <Top>
        <Label htmlFor="global-search-subject">{t('subjectLabel')}</Label>
        <GrowingInput
          autocomplete="off"
          autocorrect="off"
          autocapitalize="off"
          id="global-search-subject"
          name="subject"
          onBlur={handleBlur('subject')}
          onChange={handleChange}
          onFocus={handleFocus('subject')}
          placeholder={t('substringPlaceholder')}
          value={subject}
        />

        <Label>{t('dateRange')}</Label>
        <DateRangePicker
          name="dateRange"
          onBlur={handleBlur('dateRange')}
          onChange={(dates) => {
            if (Array.isArray(dates)) {
              setFieldValue('dateRange', dates);
            }
          }}
          onFocus={handleFocus('dateRange')}
          value={dateRange || [new Date(), new Date()]}
        />
      </Top>

      <Divider />

      <Bottom>
        <Label htmlFor="global-search-recipient">{t('recipientLabel')}</Label>
        <GrowingInput
          autocomplete="off"
          autocorrect="off"
          autocapitalize="off"
          id="global-search-recipient"
          name="recipient"
          onBlur={handleBlur('recipient')}
          onChange={handleChange}
          onFocus={handleFocus('recipient')}
          placeholder={t('exactPlaceholder')}
          value={recipient}
        />

        <Label htmlFor="global-search-sender">{t('senderLabel')}</Label>
        <GrowingInput
          autocomplete="off"
          autocorrect="off"
          autocapitalize="off"
          id="global-search-sender"
          name="sender"
          onBlur={handleBlur('sender')}
          onChange={handleChange}
          onFocus={handleFocus('sender')}
          placeholder={t('exactPlaceholder')}
          value={sender}
        />

        <Label htmlFor="global-search-message-id">{t('messageIDLabel')}</Label>
        <GrowingInput
          autocomplete="off"
          autocorrect="off"
          autocapitalize="off"
          id="global-search-message-id"
          name="messageId"
          onBlur={handleBlur('messageId')}
          onChange={handleChange}
          onFocus={handleFocus('messageId')}
          placeholder={t('exactPlaceholder')}
          value={messageId}
        />
      </Bottom>

      <Divider />

      <Bottom>
        <Label htmlFor="global-search-alert-id">{t('alertIdLabel')}</Label>
        <GrowingInput
          autocomplete="off"
          autocorrect="off"
          autocapitalize="off"
          id="global-search-alert-id"
          name="alertId"
          onBlur={handleBlur('alertId')}
          onChange={handleChange}
          onFocus={handleFocus('alertId')}
          placeholder={t('exactPlaceholder')}
          value={alertId}
        />

        <Label htmlFor="global-search-domain">{t('domainLabel')}</Label>
        <GrowingInput
          autocomplete="off"
          autocorrect="off"
          autocapitalize="off"
          id="global-search-domain"
          name="domain"
          onBlur={handleBlur('domain')}
          onChange={handleChange}
          onFocus={handleFocus('domain')}
          placeholder={t('exactPlaceholder')}
          value={domain}
        />
      </Bottom>
    </>
  );
}
