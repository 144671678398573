// @flow

/*
  Private functions
*/
export default async function downloadEml(filename: string, text: string) {
  const u8arr = await encode(text);
  const url = window.URL.createObjectURL(new Blob([u8arr], { type: 'application/octet-stream' }));
  const element = document.createElement('a');

  element.setAttribute('href', url);
  element.setAttribute('download', `${filenameFromPath(filename)}.infected`);
  element.style.display = 'none';

  if (document.body !== null) {
    document.body.appendChild(element);
    element.click();
    // $FlowFixMe - already doing a document.body check above
    document.body.removeChild(element);
  }
}

export async function encode(text: string): any {
  if (typeof TextEncoder !== 'undefined') {
    // $FlowFixMe
    return new TextEncoder('utf-8').encode(text);
  }

  const { manualEncoder } = await import('./manualEncoder');
  return manualEncoder(text);
}

const filenameFromPath = (path: string): string => {
  return path.replace(/kubrick_(.*)_emails/, 'email');
};
