import { ApolloError } from 'apollo-client';
import { loader } from 'graphql.macro';
import { useQuery } from 'react-apollo';

import { FIFTEEN_MINUTE_POLL_INTERVAL } from '../../lib';

import { Duration } from 'utils/duration';

//
// Data fetching and its types
// -------------------------------------------------------------------------------------------------

const placeholderData = {
  topFolders: [
    {
      name: 'Inbox',
      total: 0,
    },
    {
      name: 'Spam',
      total: 0,
    },
    {
      name: 'other',
      total: 0,
    },
  ],
  totals: {
    auto: 0,
    read: 0,
    unread: 0,
    success: 0,
    failed: 0,
    manual: 0,
    attempts: 0,
  },
};

export interface TotalsData {
  attempts: number;
  auto: number;
  failed: number;
  manual: number;
  read: number;
  success: number;
  unread: number;
}

export interface TopFoldersData {
  name: string;
  total: number;
}

export interface APIData {
  topFolders: TopFoldersData[];
  totals: TotalsData;
}

interface HookResult {
  /**
   * The data that has been returned from the API
   */
  data: APIData;

  /**
   * If there is a problem loading the data, the error information will be available as an error object
   */
  error: ApolloError | null;

  /**
   * Returns true if the data is currently being loaded
   */
  loading: boolean;
}

const query = loader('./load.graphql');

interface UseRemoteData {
  duration: Duration;
  top: number;
}

/**
 * Private hook that encapsulates loading the data for the `RetractionsPanel`. It returns placeholder data until the API data is loaded.
 * Poll the endpoint every 15 minutes.
 */

export default function useRemoteData({ duration = '30', top = 3 }: UseRemoteData): HookResult {
  const { data, error, loading } = useQuery(query, {
    pollInterval: FIFTEEN_MINUTE_POLL_INTERVAL,
    variables: { duration, top },
  });

  if (loading) return { data: placeholderData, error: null, loading: true };
  if (error) return { data: placeholderData, error, loading: false };

  if (!data?.insightsRetractionStats?.data) return { data: placeholderData, error: null, loading: false };

  return { data: data?.insightsRetractionStats?.data, error: null, loading: false };
}
