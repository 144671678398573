// @flow

import { loader } from 'graphql.macro';
import { get } from 'lodash';
// $FlowFixMe
import React, { useContext } from 'react';
import { useQuery } from 'react-apollo';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import GeoOrigins from './GeoOrigins';

import { DaysBackContext } from 'screens/App';

export const threatOriginsMapQuery = loader('./queries/InsightsThreatOriginsMap.graphql');

export default function MaliciousOriginsOverview() {
  const { currentInterval } = useContext(DaysBackContext);
  const { t } = useTranslation('email');

  const { data, error, loading } = useQuery(threatOriginsMapQuery, { variables: { duration: currentInterval } });
  const locations = get(data, 'insightsThreatOriginsMap.data[0].byLocationCounts', [
    { count: 0, geohash: '', lat: 0, lng: 0, location: '' },
  ]);

  return (
    <>
      <Helmet title={`${t('targets')} :: ${t('common:email')} - ${t('common:area1security')}`} />
      <GeoOrigins data={locations} error={error} loading={loading} />
    </>
  );
}
