// @flow

import { camelCase } from '@a1s/lib';
import { loader } from 'graphql.macro';
import { get } from 'lodash';

// $FlowFixMe
import React, { lazy, Suspense, useContext, useState } from 'react';
import { useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';

import { placeholderData } from '../dataTypesAndUtils';

import { dataForGraphTable } from './dataTypesAndUtils';

import { DaysBackContext } from 'screens/App';

import Button from 'ui/atoms/Button';
import Loading from 'ui/atoms/Loading';
import ContentBlock from 'ui/molecules/ContentBlock';
import Drawer from 'ui/molecules/Drawer';
import GraphTable from 'ui/molecules/GraphTable';
import PlaceholderGraphTable from 'ui/molecules/PlaceholderGraphTable';
import Dropdown from 'ui/organisms/Dropdown';
import Split from 'ui/templates/Split';
import withDataHandler from 'utils/withDataHandler';

export const insightsAllowListByTypeQuery = loader('./queries/InsightsAllowListByType.graphql');

const DetailsTable = lazy(() => import('./DetailsTable'));

const allowTypes = ['Allowed Recipients', 'Allowed Senders', 'Acceptable Senders'];

const GraphTableWithDataHandler = withDataHandler({
  empty: <PlaceholderGraphTable data={placeholderData} />,
})(GraphTable);

export default function AllowedRecipients() {
  const { currentInterval } = useContext(DaysBackContext);
  const [openModal, setOpenModal] = useState(false);
  const [selected, setSelected] = useState('Allowed Recipients');
  const { t } = useTranslation('email');

  const { data, error, loading } = useQuery(insightsAllowListByTypeQuery, { variables: { currentInterval, limit: 5 } });
  const allowedTypesData = get(data, 'allowListByType.data', {});

  let allAllowedTypes = [];
  Object.keys(allowedTypesData).forEach((type) => {
    // eslint-disable-next-line
    allAllowedTypes = [
      ...allAllowedTypes,
      ...allowedTypesData[type].map((entry) => ({ ...{ count: entry.count, pattern: entry.name, patternType: type } })),
    ];
  });

  return (
    <ContentBlock
      zebraStripe={false}
      top={
        <>
          <Split>
            <Dropdown
              expanded={false}
              icon="caret"
              /* eslint-disable no-shadow */
              onOptionSelected={(selected) => setSelected(t(`${selected}`))}
              options={allowTypes}
              renderAsOption={(allowTypes) => t(camelCase(allowTypes))}
              size="large"
            >
              {t(camelCase(selected))}
            </Dropdown>
            <Button
              disabled={Object.keys(allowedTypesData).length === 0}
              icon="hamburger"
              muted
              onClick={() => Object.keys(allowedTypesData).length > 0 && setOpenModal(true)}
            >
              {t('viewDetails')}
            </Button>
          </Split>
        </>
      }
      bottom={
        <GraphTableWithDataHandler
          colorScheme="graphite"
          data={dataForGraphTable(allAllowedTypes, selected)}
          isEmpty={() => loading || error || dataForGraphTable(allAllowedTypes, selected).length === 0}
          isLoading={loading}
          sortOtherLast
        />
      }
    >
      <Drawer.Modal visible={openModal} onClose={() => setOpenModal(false)} size="large" paddedSides={false}>
        <Suspense fallback={<Loading />}>{openModal && <DetailsTable fetchData={openModal} />}</Suspense>
      </Drawer.Modal>
    </ContentBlock>
  );
}
