import { encodeBase64 } from '@a1s/lib';
import { subDays } from 'date-fns';
import { Parser } from 'json2csv';
import { ComponentProps } from 'react';

import { SearchResultRow } from '../types';
import { ModalHeader } from '../ui';

export * from './permissions';

//
// Configuration
// -------------------------------------------------------------------------------------------------

const parser = new Parser<SearchResultRow>({
  fields: [
    { label: 'Tracking Value', value: 'trackingValueSent' },
    { label: 'Final Disposition', value: 'finalDisposition' },
    { label: 'Client Name', value: 'clientName' },
    { label: 'From', value: 'from' },
    { label: 'From Name', value: 'fromName' },
    { label: 'Envelope From', value: 'envelopeFrom' },
    { label: 'To', value: (row: SearchResultRow) => row.to?.join(), default: '-' },
    { label: 'To name', value: (row: SearchResultRow) => row.toName?.join(), default: '-' },
    { label: 'Envelope To', value: (row: SearchResultRow) => row.envelopeTo.join() },
    { label: 'BCC', value: (row: SearchResultRow) => row.bccRecipient?.join(), default: '-' },
    { label: 'CC', value: (row: SearchResultRow) => row.cc, default: '-' },
    { label: 'Client Recipients', value: (row: SearchResultRow) => row.clientRecipients.join() },
    { label: 'Subject', value: 'subject' },
    { label: 'TS', value: 'ts' },
    { label: 'Sent Date', value: 'sentDate' },
    {
      label: 'Findings',
      value: (row: SearchResultRow) =>
        row.findings?.map((f, index) => {
          return `Finding No:${index + 1} Name:${f.name} Detection:${f.detection} Field:${f.field} Portion:${
            f.portion
          } Attachment:${f.attachment} Value:${f.value} Reason:${f.reason} Version:${f.version} Action:${
            f.action
          } Score:${f.score} Detail:${f.detail} Diagnostic:${f.diagnostic}`;
        }),
    },
    { label: 'Threat Cats Blocking', value: 'threatCatsBlocking' },
    { label: 'Postfix Indent', value: 'postfixIdent' },
    { label: 'Reply To', value: 'replyto' },
    { label: 'Phish Submission', value: 'phishSubmission' },
    { label: 'Delivery Mode', value: 'deliveryMode' },
    { label: 'Originating IP', value: 'xOriginatingIp' },
    { label: 'Server IP', value: 'smtpHeloServerIp' },
    { label: 'Previous Hop IP', value: 'smtpPreviousHopIp' },
    { label: 'Journaling Message ID', value: 'smtpJournalingMessageId' },
    { label: 'Message ID', value: 'messageId' },
    { label: 'Key', value: 'key' },
    { label: 'Is Journaled', value: 'isJournaled' },
    { label: 'Is Quarantined', value: 'isQuarantined' },
    { label: 'Vendor Misses', value: 'vendorMisses' },
    { label: 'HTML Hash', value: 'htmltextStructureHash' },
    {
      label: 'Detection Reasons',
      value: (row: SearchResultRow) => row.detectionReasons.join(),
      default: '-',
    },
    {
      label: 'Redress Actions',
      value: (row: SearchResultRow) => row.redressedActions.join(),
      default: '-',
    },
  ],
});

//
// Exported functions
// -------------------------------------------------------------------------------------------------

/**
 * Turns detection data into a CSV string
 */
export function detectionsToCSV(data: SearchResultRow[]): string {
  return parser.parse(data);
}

/**
 * Iterates over an array of search results rows and appends a Z to the end of `ts` if one isn't already present.
 * This allows the message `ts` to be displayed in user's local time.
 * The Z on the end means UTC, that is, an offset-from-UTC of zero hours-minutes-seconds.
 * Helpful link: https://stackoverflow.com/questions/58847869/utc-vs-iso-format-for-time
 */
export function fixMessagesTs(messages: SearchResultRow[]) {
  return messages.map(({ ts: originalTs, ...rest }) => {
    const ts = originalTs.includes('Z') ? originalTs : `${originalTs}Z`;
    return { ...rest, ...{ ts } };
  });
}

export function toVariables(date: Date, search: ComponentProps<typeof ModalHeader>['params']) {
  const [start, end] = Array.isArray(search.daysBack)
    ? search.daysBack
    : [subDays(date, Number(search.daysBack)), date];

  const concatSearchTerm =
    search.finalDisposition !== ''
      ? `${search.searchTerm || ''} final_disposition:${search.finalDisposition}`
      : search.searchTerm || '';

  const vars = { end, limit: 50, offset: 0, search: encodeBase64(concatSearchTerm), start };

  if (search.searchMode === 'freeform')
    return {
      ...vars,
      detections_only: search.searchType === 'detection-only',
    };

  return {
    ...vars,
    alert_id: undefinedIfEmpty(search.alertId),
    detections_only: search.searchType === 'detection-only',
    domain: undefinedIfEmpty(search.domain),
    message_id: undefinedIfEmpty(search.messageId),
    recipient: undefinedIfEmpty(search.recipient),
    redress: undefinedIfEmpty(search.redress),
    sender: undefinedIfEmpty(search.sender),
    subject: undefinedIfEmpty(search.subject),
  };
}

//
// Private functions
// -------------------------------------------------------------------------------------------------

function undefinedIfEmpty(s: null | string | undefined) {
  if (s === '') return undefined;
  return s;
}
