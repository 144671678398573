// @flow

/**
 * TooltippedStatusIcon Component
 *
 * Renders a hoverable status icon with tooltip contents
 */

// $FlowFixMe
import React, { useState, type Node } from 'react';

import Container from './Container';

import StatusIcon, { type IconName } from 'ui/atoms/StatusIcon';
import Tooltip from 'ui/atoms/Tooltip';

type Props = {
  dataTestId?: string,
  children?: Node,
  name: IconName,
  tooltipContents: Node,
  tooltipPosition?: 'bottom' | 'left' | 'right' | 'top',
  wide?: boolean,
};

export default function TooltippedStatusIcon({
  dataTestId = 'molecule-tooltipped-status-icon',
  children,
  name,
  tooltipContents,
  tooltipPosition = 'bottom',
  wide = false,
  ...rest
}: Props) {
  const [anchor, setAnchor] = useState(null);
  const [tooltipShown, setTooltipShown] = useState(false);

  let timeoutId: TimeoutID;

  const hideTooltip = () => {
    timeoutId = setTimeout(() => setTooltipShown(false), 1000);
  };

  const showTooltip = () => {
    clearTimeout(timeoutId);
    setTooltipShown(true);
  };

  return (
    <Container data-testid={dataTestId}>
      <StatusIcon
        onBlur={hideTooltip}
        onFocus={showTooltip}
        onMouseOut={hideTooltip}
        onMouseOver={showTooltip}
        ref={(ref) => setAnchor(ref)}
        name={name}
        {...rest}
      />

      {tooltipContents && (
        <Tooltip
          anchor={anchor}
          onBlur={hideTooltip}
          onFocus={showTooltip}
          onMouseOut={hideTooltip}
          onMouseOver={showTooltip}
          position={tooltipPosition}
          visible={tooltipShown}
          wide={wide}
        >
          {tooltipContents}
        </Tooltip>
      )}
    </Container>
  );
}
