"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TextCenterer = exports.RootSVG = exports.HiddenRect = exports.FillPath = exports.BorderPath = exports.BaseContainer = void 0;

var _styled = require("../../styled");

const BaseContainer = (0, _styled.styled)('div', {
  overflow: 'hidden'
});
exports.BaseContainer = BaseContainer;
const BorderPath = (0, _styled.styled)('path', {
  fill: 'none',
  stroke: '$black',
  strokeWidth: '0.1em'
});
exports.BorderPath = BorderPath;
const FillPath = (0, _styled.styled)('path', {
  fill: 'none',
  stroke: '$gray300',
  strokeWidth: '0.05em'
});
exports.FillPath = FillPath;
const HiddenRect = (0, _styled.styled)('rect', {
  fill: 'transparent'
});
exports.HiddenRect = HiddenRect;
const RootSVG = (0, _styled.styled)('svg', {
  height: '100%',
  display: 'block',
  width: '100%'
});
exports.RootSVG = RootSVG;
const TextCenterer = (0, _styled.styled)('g', {
  '& > text': {
    fontFamily: '$virgil',
    fontSize: '$lg'
  }
});
exports.TextCenterer = TextCenterer;