import { Dispositions } from '../types';

export function matchColorAndDisposition(disposition?: Dispositions) {
  switch (disposition) {
    case 'BULK':
      return 'teal300';
    case 'MALICIOUS':
      return 'pink500';
    case 'MALICIOUS-BEC':
      return 'pink800';
    case 'NONE':
      return 'gray300';
    case 'SPAM':
      return 'teal500';
    case 'SPOOF':
      return 'pink200';
    case 'SUSPICIOUS':
      return 'pink400';
    default:
      return 'gray500';
  }
}

export function matchColorAndThreat(threat: string): { name: string; color: string } {
  switch (threat) {
    case 'AccountCompromise':
    case 'ASNReputation':
    case 'DomainAge':
    case 'DomainReputation':
    case 'DomainBehavior':
    case 'NameserverReputation':
    case 'VoicePhishing':
      return {
        name: threatToPascalCase(threat),
        color: 'red300',
      };

    case 'Attachment':
    case 'BEC':
    case 'BECType1':
    case 'Commandexecution':
    case 'Dropper':
    case 'Scam':
      return {
        name: threatToPascalCase(threat),
        color: 'yellow400',
      };

    case 'BrandImpersonation':
    case 'CredentialHarvester':
    case 'IdentityDeception':
    case 'Link':
      return {
        name: threatToPascalCase(threat),
        color: 'purple500',
      };

    case 'IPBehavior':
    case 'IPPolicy':
    case 'IPReputation':
      return {
        name: threatToPascalCase(threat),
        color: 'blue250',
      };

    default:
      return {
        name: threatToPascalCase(threat),
        color: 'yellow600',
      };
  }
}

export function threatToPascalCase(threat: string): string {
  if (typeof threat !== 'string') {
    return 'Not specified';
  }

  return threat.replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3');
}
