// @flow

import { loader } from 'graphql.macro';
import { get } from 'lodash';
// $FlowFixMe
import React, { useContext } from 'react';
import { useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';

import {
  defaultEmailCountsMarshall,
  defaultDetections,
  defaultDetectionsTimeline,
  overviewTotalPhishForLineGraph,
} from './dataTypesAndUtils';

import { DaysBackContext } from 'screens/App';

// $FlowFixMe - because AbbreviatedNumber is a typescript file
import { AbbreviatedNumber } from 'ui-new';

import ConditionalRender from 'ui/atoms/ConditionalRender';
import Loading from 'ui/atoms/Loading';
import DataTable from 'ui/molecules/DataTable';
import DispositionBadge from 'ui/molecules/DispositionBadge';
import PercentChange from 'ui/molecules/PercentChange';
import PlaceholderLineGraph from 'ui/molecules/PlaceholderLineGraph';
import SearchLink from 'ui/molecules/SearchLink';
import TabView from 'ui/molecules/TabView';
import LineGraph from 'ui/organisms/LineGraph';
import { type Tint } from 'ui/organisms/LineGraph/util';
import SectionOverview from 'ui/organisms/SectionOverview';
import { SectionOption } from 'ui/organisms/SectionSelect';
import Stack from 'ui/templates/Stack';
import useGlobalSearch from 'utils/hooks/useGlobalSearch';

export const fetchDashboardCountsQuery = loader('./queries/DashboardCountsFetch.graphql');
export const insightsDetectionStatsQuery = loader('./queries/InsightsDetectionStatsQuery.graphql');

type DetectionType = {
  bulk: number,
  malicious: number,
  maliciousBec: number,
  none: number,
  spam: number,
  spoof: number,
  suspicious: number,
};

type LineGraphTypes =
  | ''
  | 'mailBec'
  | 'mailBulk'
  | 'mailMalicious'
  | 'mailSpam'
  | 'mailSpoof'
  | 'mailSuspicious'
  | string;

type Props = {
  becEnabled: boolean,
  detectionLoading: boolean,
  selectedDetection: string,
  setSelectedDetection: Function,
};

export default function OverviewPhishLineGraph({
  becEnabled,
  detectionLoading,
  selectedDetection,
  setSelectedDetection,
}: Props) {
  const { currentInterval } = useContext(DaysBackContext);
  const searchDetections = useGlobalSearch('detectionSearch');
  const { t } = useTranslation('email');

  const {
    data: dashboardData,
    error: fetchDashboardCountsError,
    loading: fetchDashboardCountsLoading,
  } = useQuery(fetchDashboardCountsQuery);

  const {
    data: detectionStatsData,
    error: detectionStatsError,
    loading: detectionStatsLoading,
  } = useQuery(insightsDetectionStatsQuery, {
    pollInterval: 900000, // 15 minutes in milliseconds,
    variables: { duration: currentInterval },
  });

  const totalDetections = get(detectionStatsData, 'insightsDetectionStats.data', [
    { totalEmailsProcessedPrevious: 0 },
    { totalEmailsProcessed: 0 },
    { breakdown: { current: defaultDetections, previous: defaultDetections } },
    { timeline: { current: [defaultDetectionsTimeline], previous: [defaultDetectionsTimeline] } },
  ]);
  const currentDetections = totalDetections.find((d) => 'totalEmailsProcessed' in d)?.totalEmailsProcessed;
  const previousDetections = totalDetections.find(
    (d) => 'totalEmailsProcessedPrevious' in d
  )?.totalEmailsProcessedPrevious;
  const breakdown = totalDetections.find((d) => 'breakdown' in d)?.breakdown || {
    current: defaultDetections,
    previous: defaultDetections,
  };
  const totals = { current: currentDetections, previous: previousDetections };

  const emailCountsMarshallData = get(dashboardData, 'dashboardCounts.emailCountsMarshall', defaultEmailCountsMarshall);
  const previousEmailCountsMarshallData = get(
    dashboardData,
    'dashboardCounts.previousEmailCountsMarshall',
    defaultEmailCountsMarshall
  );

  const renderGroupName = (graphType: LineGraphTypes, isLineGraph?: boolean) => {
    switch (graphType) {
      case 'mailBec':
        return (
          <SearchLink handleSearch={() => searchDetections({ query: 'final_disposition:malicious-bec' })}>
            <DispositionBadge disposition="malicious-bec" />
          </SearchLink>
        );
      case 'mailBulk':
        return (
          <SearchLink handleSearch={() => searchDetections({ query: 'final_disposition:bulk' })}>
            <DispositionBadge disposition="bulk" />
          </SearchLink>
        );
      case 'mailMalicious':
        return (
          <SearchLink handleSearch={() => searchDetections({ query: 'final_disposition:malicious' })}>
            <DispositionBadge disposition="malicious" />
          </SearchLink>
        );
      case 'mailSpam':
        return (
          <SearchLink handleSearch={() => searchDetections({ query: 'final_disposition:spam' })}>
            <DispositionBadge disposition="spam" />
          </SearchLink>
        );
      case 'mailSpoof':
        return (
          <SearchLink handleSearch={() => searchDetections({ query: 'final_disposition:spoof' })}>
            <DispositionBadge disposition="spoof" />
          </SearchLink>
        );
      case 'mailSuspicious':
        return (
          <SearchLink handleSearch={() => searchDetections({ query: 'final_disposition:suspicious' })}>
            <DispositionBadge disposition="suspicious" />
          </SearchLink>
        );
      default:
        return <DispositionBadge>{t(isLineGraph ? 'totalEmails' : 'totalPhish')}</DispositionBadge>;
    }
  };

  const data = {
    '': {
      current: overviewTotalPhishForLineGraph(emailCountsMarshallData[currentInterval], becEnabled, ''),
      previous: overviewTotalPhishForLineGraph(previousEmailCountsMarshallData[currentInterval], becEnabled, ''),
    },
    mailMalicious: {
      current: overviewTotalPhishForLineGraph(emailCountsMarshallData[currentInterval], becEnabled, 'mailMalicious'),
      previous: overviewTotalPhishForLineGraph(
        previousEmailCountsMarshallData[currentInterval],
        becEnabled,
        'mailMalicious'
      ),
    },
    ...(becEnabled
      ? {
          mailBec: {
            current: overviewTotalPhishForLineGraph(emailCountsMarshallData[currentInterval], becEnabled, 'mailBec'),
            previous: overviewTotalPhishForLineGraph(
              previousEmailCountsMarshallData[currentInterval],
              becEnabled,
              'mailBec'
            ),
          },
        }
      : {}),
    mailSpam: {
      current: overviewTotalPhishForLineGraph(emailCountsMarshallData[currentInterval], becEnabled, 'mailSpam'),
      previous: overviewTotalPhishForLineGraph(
        previousEmailCountsMarshallData[currentInterval],
        becEnabled,
        'mailSpam'
      ),
    },
    mailBulk: {
      current: overviewTotalPhishForLineGraph(emailCountsMarshallData[currentInterval], becEnabled, 'mailBulk'),
      previous: overviewTotalPhishForLineGraph(
        previousEmailCountsMarshallData[currentInterval],
        becEnabled,
        'mailBulk'
      ),
    },
    mailSuspicious: {
      current: overviewTotalPhishForLineGraph(emailCountsMarshallData[currentInterval], becEnabled, 'mailSuspicious'),
      previous: overviewTotalPhishForLineGraph(
        previousEmailCountsMarshallData[currentInterval],
        becEnabled,
        'mailSuspicious'
      ),
    },
    mailSpoof: {
      current: overviewTotalPhishForLineGraph(emailCountsMarshallData[currentInterval], becEnabled, 'mailSpoof'),
      previous: overviewTotalPhishForLineGraph(
        previousEmailCountsMarshallData[currentInterval],
        becEnabled,
        'mailSpoof'
      ),
    },
  };

  const hasData =
    overviewTotalPhishForLineGraph(emailCountsMarshallData[currentInterval], becEnabled, '').length !== 0 &&
    !fetchDashboardCountsLoading &&
    !fetchDashboardCountsError &&
    detectionStatsData &&
    !detectionStatsLoading &&
    !detectionStatsError;

  function Graph() {
    const groups = Object.keys(data);
    const tint = deriveTintFromGroupSelected(selectedDetection);

    return (
      <>
        <Stack spaced>
          {groups.map((group) => {
            const percentChange = calculatePercentChange(breakdown, totals, group);

            if (typeof renderGroupName === 'undefined') return null;

            return (
              <SectionOption
                key={group || 'defaultKey'}
                name={group}
                onSelect={() => setSelectedDetection(group)}
                selected={selectedDetection === group}
              >
                <SectionOverview
                  currentValue={
                    <AbbreviatedNumber value={group === '' ? totals.current : breakdown.current[conversion[group]]} />
                  }
                  formatValue="number"
                  size="small"
                  title={renderGroupName(group, true)}
                >
                  <PercentChange boxed>{Number.isNaN(percentChange) ? 0 : percentChange}</PercentChange>
                </SectionOverview>
              </SectionOption>
            );
          })}
        </Stack>
        <ConditionalRender condition={!detectionLoading} fallback={<Loading />}>
          <LineGraphDisplay group={selectedDetection} tint={tint} />
        </ConditionalRender>
      </>
    );
  }

  type GraphDisplayProps = {
    group: string,
    tint: Tint,
  };

  function LineGraphDisplay({ group, tint }: GraphDisplayProps) {
    const { current, previous } = data[group];

    if (!current.reduce((acc, val) => acc + val.value, 0) && !previous.reduce((acc, val) => acc + val.value, 0))
      return <PlaceholderLineGraph />;

    return <LineGraph data={current} previousData={previous} tint={tint} />;
  }

  function Table() {
    // Combine the previous and current data together to be displayed on the table as a list of dates.
    // $FlowFixMe
    function reduce(acc, [group, { current, previous }]) {
      return {
        ...acc,
        [group]: [...current, ...previous].sort(sort),
      };
    }

    function sort(a, b) {
      const aDate = new Date(a.date);
      const bDate = new Date(b.date);
      return bDate - aDate;
    }

    const combinedData = Object.entries(data).reduce(reduce, {});

    return <DataTable data={combinedData} renderHeader={renderGroupName} />;
  }

  function renderTab(index) {
    return index === 0 ? <Graph /> : <Table />;
  }

  return (
    <>
      <ConditionalRender condition={fetchDashboardCountsLoading}>
        <Loading />
      </ConditionalRender>

      <ConditionalRender condition={hasData}>
        <TabView
          dataTestId="total-phish-tab-view"
          renderTab={renderTab}
          tabs={[t('graph'), t('table')]}
          tabsLocation="top"
        />
      </ConditionalRender>
    </>
  );
}

//
// Private functions
// -------------------------------------------------------------------------------------------------

const conversion = {
  mailBulk: 'bulk',
  mailMalicious: 'malicious',
  mailBec: 'maliciousBec',
  mailSpam: 'spam',
  mailSpoof: 'spoof',
  mailSuspicious: 'suspicious',
};

type BreakdownDataType = {
  current: DetectionType,
  previous: DetectionType,
};

type TotalsType = {
  current: number,
  previous: number,
};

const calculatePercentChange = (breakdownData: BreakdownDataType, totals: TotalsType, type: string) => {
  const { current, previous } = breakdownData;

  // avoid displaying infinity percent
  if (totals.previous === 0 || previous[conversion[type]] === 0) {
    return 0;
  }
  if (type === '') {
    return (totals.current - totals.previous) / totals.previous;
  }

  return (current[conversion[type]] - previous[conversion[type]]) / previous[conversion[type]];
};

function deriveTintFromGroupSelected(selected: string): Tint {
  switch (selected) {
    case 'mailBulk':
      return 'bulk';
    case 'mailMalicious':
      return 'malicious';
    case 'mailBec':
      return 'malicious-bec';
    case 'mailSuspicious':
      return 'suspicious';
    case 'mailSpam':
      return 'spam';
    case 'mailSpoof':
      return 'spoof';
    default:
      return 'none';
  }
}
