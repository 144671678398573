"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.num = num;

/**
 * Accepts a string to parse as a base-10 number *or* a number. If given a number it returns the given value.
 * Useful for accepting React  component props as both strings and numbers.
 */
function num(value) {
  return typeof value === 'string' ? Number.parseInt(value, 10) : value;
}