// @flow

import React from 'react';

import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import TopDomainSimilaritySpoofs from './TopDomainSimilaritySpoofs';

export default function SimilarDomainsOverview() {
  const { t } = useTranslation('email');

  return (
    <>
      <Helmet title={`${t('similarDomains')} :: ${t('common:email')} - ${t('common:area1security')}`} />
      <TopDomainSimilaritySpoofs />
    </>
  );
}
