// @flow

import { loader } from 'graphql.macro';
import { get } from 'lodash';
// $FlowFixMe
import React, { useContext } from 'react';
import { useQuery } from 'react-apollo';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { type PropType } from '../../dataTypesAndUtils';

import { calculatePercent } from '../dataTypesAndUtils';

import { maliciousSendersRecord } from './dataTypesAndUtils';
import MaliciousSenders from './MaliciousSenders';
import TopMaliciousSendingDomains from './TopMaliciousSendingDomains';

import { DaysBackContext } from 'screens/App';

export const maliciousSendersQuery = loader('./queries/MaliciousSendersQuery.graphql');

export default function MaliciousSendersOverview({ detectionCount, detectionError, detectionLoading }: PropType) {
  const { currentInterval } = useContext(DaysBackContext);
  const { t } = useTranslation('email');

  const { data, error, loading } = useQuery(maliciousSendersQuery, { variables: { currentInterval } });
  const {
    maliciousSenderCounts,
    maliciousSenderCountsByDataType,
    maliciousSenderCountsByDate,
    maliciousSendersListByName,
    previousMaliciousSenderCounts,
    previousMaliciousSenderCountsByDate,
  } = get(data, 'maliciousSenders', maliciousSendersRecord);

  return (
    <>
      <Helmet title={`${t('senders')} :: ${t('common:email')} - ${t('common:area1security')}`} />
      <>
        <MaliciousSenders
          data={{
            lineGraph: {
              current: maliciousSenderCountsByDate,
              previous: previousMaliciousSenderCountsByDate,
            },
            ringChart: calculatePercent(maliciousSenderCounts.maliciousSendersTotal, detectionCount),
            sectionOverview: {
              current: get(maliciousSenderCounts, 'maliciousSendersTotal', 0),
              previous: get(previousMaliciousSenderCounts, 'maliciousSendersTotal', 0),
            },
          }}
          error={error || detectionError}
          loading={loading || detectionLoading}
        />
        <TopMaliciousSendingDomains
          data={{
            drawer: maliciousSendersListByName,
            progress: maliciousSenderCountsByDataType,
          }}
          error={error}
          loading={loading}
        />
      </>
    </>
  );
}
