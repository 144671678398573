"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _Badge = require("./Badge");

Object.keys(_Badge).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _Badge[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Badge[key];
    }
  });
});

var _Button = require("./Button");

Object.keys(_Button).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _Button[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Button[key];
    }
  });
});

var _Dropdown = require("./Dropdown");

Object.keys(_Dropdown).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _Dropdown[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Dropdown[key];
    }
  });
});

var _InfoTip = require("./InfoTip");

Object.keys(_InfoTip).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _InfoTip[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _InfoTip[key];
    }
  });
});

var _LinearGradient = require("./LinearGradient");

Object.keys(_LinearGradient).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _LinearGradient[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _LinearGradient[key];
    }
  });
});

var _Loadable = require("./Loadable");

Object.keys(_Loadable).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _Loadable[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Loadable[key];
    }
  });
});

var _MaxHeight = require("./MaxHeight");

Object.keys(_MaxHeight).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _MaxHeight[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _MaxHeight[key];
    }
  });
});

var _PercentageChange = require("./PercentageChange");

Object.keys(_PercentageChange).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _PercentageChange[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _PercentageChange[key];
    }
  });
});

var _Placeholder = require("./Placeholder");

Object.keys(_Placeholder).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _Placeholder[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Placeholder[key];
    }
  });
});

var _Radio = require("./Radio");

Object.keys(_Radio).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _Radio[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Radio[key];
    }
  });
});

var _Separator = require("./Separator");

Object.keys(_Separator).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _Separator[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Separator[key];
    }
  });
});

var _Slider = require("./Slider");

Object.keys(_Slider).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _Slider[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Slider[key];
    }
  });
});

var _Table = require("./Table");

Object.keys(_Table).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _Table[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Table[key];
    }
  });
});

var _TabList = require("./TabList");

Object.keys(_TabList).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _TabList[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _TabList[key];
    }
  });
});

var _TextBlock = require("./TextBlock");

Object.keys(_TextBlock).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _TextBlock[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _TextBlock[key];
    }
  });
});

var _TinyButton = require("./TinyButton");

Object.keys(_TinyButton).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _TinyButton[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _TinyButton[key];
    }
  });
});

var _ToggleSwitch = require("./ToggleSwitch");

Object.keys(_ToggleSwitch).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _ToggleSwitch[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _ToggleSwitch[key];
    }
  });
});

var _Tooltip = require("./Tooltip");

Object.keys(_Tooltip).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _Tooltip[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Tooltip[key];
    }
  });
});

var _WorldMap = require("./WorldMap");

Object.keys(_WorldMap).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _WorldMap[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _WorldMap[key];
    }
  });
});