// @flow

import { loader } from 'graphql.macro';
import { get } from 'lodash';
// $FlowFixMe
import React, { useContext } from 'react';
import { useQuery } from 'react-apollo';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { type PropType } from '../../dataTypesAndUtils';

import { calculatePercent } from '../dataTypesAndUtils';

import MaliciousAttachments from './MaliciousAttachments';
import TopMaliciousFileTypes from './TopMaliciousFileTypes';

import { DaysBackContext } from 'screens/App';

export const insightsMaliciousAttachmentsQuery = loader('./queries/InsightsMaliciousAttachments.graphql');

export default function MaliciousAttachmentsOverview({ detectionCount, detectionError, detectionLoading }: PropType) {
  const { currentInterval } = useContext(DaysBackContext);
  const { t } = useTranslation('email');

  const {
    data: insightsData,
    error,
    loading,
  } = useQuery(insightsMaliciousAttachmentsQuery, { variables: { currentInterval } });

  const extensions = get(insightsData, 'maliciousAttachments.data', {
    totals: { current: 0, previous: 0 },
    timeline: { current: [{ total: 0, day: '' }], previous: [{ total: 0, day: '' }] },
  });
  const sectionOverviewData = get(extensions, 'totals', { current: 0, previous: 0 });
  const lineGraphData = get(extensions, 'timeline', { current: [], previous: [] });

  return (
    <>
      <Helmet title={`${t('attachments')} :: ${t('common:email')} - ${t('common:area1security')}`} />
      <>
        <MaliciousAttachments
          data={{
            lineGraph: {
              current: lineGraphData.current,
              previous: lineGraphData.previous,
            },
            ringChart: calculatePercent(sectionOverviewData.current, detectionCount),
            sectionOverview: {
              current: sectionOverviewData.current,
              previous: sectionOverviewData.previous,
            },
          }}
          error={error || detectionError}
          loading={loading || detectionLoading}
        />
        <TopMaliciousFileTypes />
      </>
    </>
  );
}
