"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initializer = initializer;
exports.reducer = reducer;

//
// Typescript types
// -------------------------------------------------------------------------------------------------

/**
 * Internal sate for the `DatePicker` component
 */

/**
 * Internal actions for the `Dropdown` component.
 */
//
// Component state management
// -------------------------------------------------------------------------------------------------

/**
 * Lazily initialize's the `DateSelector` component's state. This function is meant to be used as the 3rd argument of
 * React's `useReducer` hook.
 */
function initializer() {
  return {
    isExpanded: false
  };
}
/**
 * Reducer function for `Dropdown` component's state.
 */


function reducer(state, action) {
  switch (action.type) {
    case 'collapse-picker':
      return { ...state,
        isExpanded: false
      };

    case 'expand-picker':
      return { ...state,
        isExpanded: true
      };

    default:
      return state;
  }
}