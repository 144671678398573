/* eslint-disable no-unused-vars */

import { fontImports, rootStyles, Box, Center } from '@a1s/ui';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

import {
  DetectionStatsPanel,
  DomainProximityPanel,
  EmailLinkIsolation,
  MaliciousThreatTypePanel,
  OrgSpoofPanel,
  RetractionsPanel,
  SystemStatsPanel,
  ThreatOriginsPanel,
  TopBECTargetsPanel,
} from './panels';

import { NamedGrid } from 'ui-new';

//
// Main component
// -------------------------------------------------------------------------------------------------

export default function DashboardScreen() {
  const retractionMetricsEnabled = useEndpointValue<boolean>('/api/horizonator/retraction_check', 'authorized', false);

  // TODO: Move these to the main `<App>` component
  fontImports();
  rootStyles();

  const layout = getLayout(retractionMetricsEnabled);
  const columns = { '@lg': '1fr 1fr 1fr 1fr' };
  const rows = { '@lg': `1fr min-content auto min-content 1fr` };

  return (
    <Center max="7xl">
      <Box px="10" py="12">
        <NamedGrid columns={columns} gap="$12" layout={layout} rows={rows}>
          <NamedGrid.Area name="system-stats">
            <SystemStatsPanel />
          </NamedGrid.Area>

          <NamedGrid.Area grow name="detection-stats">
            <DetectionStatsPanel />
          </NamedGrid.Area>

          {retractionMetricsEnabled && (
            <NamedGrid.Area name="retractions">
              <RetractionsPanel />
            </NamedGrid.Area>
          )}

          <NamedGrid.Area name="email-link-isolation">
            <EmailLinkIsolation />
          </NamedGrid.Area>

          <NamedGrid.Area name="top-bec-targets">
            <TopBECTargetsPanel />
          </NamedGrid.Area>

          <NamedGrid.Area name="domain-proximity">
            <DomainProximityPanel />
          </NamedGrid.Area>

          <NamedGrid.Area name="org-spoof">
            <OrgSpoofPanel />
          </NamedGrid.Area>

          <NamedGrid.Area name="malicious-threat-types">
            <MaliciousThreatTypePanel />
          </NamedGrid.Area>

          <NamedGrid.Area name="threat-origins">
            <ThreatOriginsPanel />
          </NamedGrid.Area>
        </NamedGrid>
      </Box>
    </Center>
  );
}

//
// Private functions
// -------------------------------------------------------------------------------------------------

function getLayout(retractionMetricsEnabled: boolean) {
  return {
    '@sm': `
      "system-stats"
      "detection-stats"
      "retractions"
      "email-link-isolation"
      "top-bec-targets"
      "malicious-threat-types"
      "domain-proximity"
      "org-spoof"
      "threat-origins"
    `,
    '@md': `
      "system-stats           system-stats"
      "detection-stats        detection-stats"
      "retractions            retractions"
      "email-link-isolation   top-bec-targets"
      "malicious-threat-types domain-proximity"
      "malicious-threat-types org-spoof"
      "threat-origins         threat-origins"
    `,
    '@lg': retractionMetricsEnabled
      ? `
      "system-stats           system-stats    detection-stats detection-stats"
      "retractions            retractions     detection-stats detection-stats"
      "retractions            retractions     malicious-threat-types threat-origins"
      "email-link-isolation   top-bec-targets malicious-threat-types threat-origins"
      "domain-proximity       org-spoof       malicious-threat-types threat-origins"
    `
      : `
    "system-stats           system-stats    detection-stats detection-stats"
    "email-link-isolation   top-bec-targets detection-stats detection-stats"
    "email-link-isolation   top-bec-targets malicious-threat-types threat-origins"
    "domain-proximity       org-spoof       malicious-threat-types threat-origins"
  `,
  };
}

//
// Private hooks
// -------------------------------------------------------------------------------------------------

function useEndpointValue<T>(endpoint: string, key: string, initial: T): T {
  const [value, setValue] = useState<T>(initial);

  useEffect(() => {
    axios.get<Record<typeof key, T>>(endpoint).then(({ data }) => setValue(data?.[key]));
  }, [endpoint, key]); // eslint-disable-line react-hooks/exhaustive-deps

  return value;
}
