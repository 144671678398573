// @flow

import { Formik } from 'formik';
import { rem } from 'polished';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ReactComponent as CloseSVG } from './close.svg';
import DetectionSearch from './DetectionSearch';
import MailTrace from './MailTrace';
import { ReactComponent as SearchSVG } from './search.svg';

import ConditionalRender from 'ui/atoms/ConditionalRender';
import Dropdown from 'ui/organisms/Dropdown';

//
// Styled components
// -------------------------------------------------------------------------------------------------

const CloseIcon = styled(CloseSVG).attrs({ role: 'button' })`
  cursor: pointer;
  height: ${rem(16)};
  flex-shrink: 0;
  margin-right: ${({ theme }) => rem(theme.spacing.sm)};
  order: 4;
  width: ${rem(16)};

  path {
    fill: ${({ theme }) => theme.colors.independence};
    transition: fill 150ms ${({ theme }) => theme.timing.easeOutCirc};
  }

  &:hover path {
    fill: ${({ theme }) => theme.colors.jet};
  }
`;

const Container = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.antiFlashWhite};
  border-radius: ${rem(4)};
  display: flex;
  flex-wrap: wrap;
  font-size: ${rem(12)};
  line-height: ${rem(36)};
  min-height: ${rem(36)};
  width: 100%;
  z-index: 2;
`;

const Divider = styled.span.attrs({ role: 'presentation' })`
  background: ${({ theme }) => theme.colors.azureishWhite};
  display: block;
  height: ${rem(24)};
  margin-right: ${({ theme }) => rem(theme.spacing.md)};
  pointer-events: none;
  width: ${rem(1)};
`;

const HiddenButton = styled.button.attrs({ type: 'submit' })`
  clip-path: circle(0);
  display: inline-block;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const Label = styled.label`
  color: ${({ theme }) => theme.colors.independence};
  font-size: ${rem(12)};
  flex-shrink: 0;
  margin-right: ${({ theme }) => rem(theme.spacing.xs)};
`;

const SearchTypeSelectWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  height: ${rem(36)} !important;
  margin-left: ${rem(-7)};
  order: 2;
`;

const SearchIcon = styled(SearchSVG)`
  height: ${rem(24)};
  flex-shrink: 0;
  margin-left: ${rem(2)};
  order: 1;
  position: relative;
  width: ${rem(24)};
  z-index: 1;

  /* stylelint-disable no-descending-specificity */
  path {
    fill: ${(p) => p.theme.colors.jet};
  }
`;

//
// Main component
// -------------------------------------------------------------------------------------------------

/**
 * A range of dates, consistening of a start date and an end date, represented as a two-value array.
 */
type DateRange = [Date, Date];

/**
 * Search params for when `searchType` is set to `"detectionSearch"`.
 */
export interface DetectionSearchParams {
  dateRange?: DateRange;
  query?: string;
}

/**
 * Search params for when `searchType` is set to `"mailTrace"`.
 */
export interface MailTraceParams {
  alertId?: string;
  dateRange?: DateRange;
  domain?: string;
  messageId?: string;
  query?: string;
  recipient?: string;
  sender?: string;
  subject?: string;
}

/**
 * The `MultiSearch` component can accept different params object depending on what its `searchType` prop
 * is set to.
 */

export type SearchParams = DetectionSearchParams & MailTraceParams;

/**
 * The `MultiSearch` component can run different types of seearchs.
 */
export type SearchType = 'detectionSearch' | 'mailTrace';

/**
 * Props that are passed on to the actual search components.
 */
export interface SearchComponentProps {
  onBlur: Function;
  onFocus: (searchParamsKey: string) => void;
  onSearchParamsChange?: (searchParams: SearchParams, key: string) => void;
  onSubmit: (searchParams: SearchParams) => void;
  searchParams?: SearchParams;
}

/**
 * Props for the dropdown select for changing the search type.
 */
interface SearchTypeSelectProps {
  onSearchTypeChange: (searchType: SearchType) => void;
  searchType: SearchType;
}

type BaseProps = SearchComponentProps & SearchTypeSelectProps;
interface Props extends BaseProps {
  onClose: () => void;
}

export default function MultiSearch({ onClose, onSearchTypeChange, searchType, ...rest }: Props) {
  return (
    <ConditionalRender
      condition={searchType === 'detectionSearch'}
      fallback={
        <MailtraceForm onClose={onClose} onSearchTypeChange={onSearchTypeChange} searchType={searchType} {...rest} />
      }
    >
      <Container>
        <SearchIcon />
        <SearchTypeSelect onSearchTypeChange={onSearchTypeChange} searchType={searchType} />

        <DetectionSearch {...rest} />

        <CloseIcon aria-label="Close" onClick={onClose} />
      </Container>
    </ConditionalRender>
  );
}

//
// Private components
// -------------------------------------------------------------------------------------------------

function SearchTypeSelect({ onSearchTypeChange, searchType }: SearchTypeSelectProps) {
  const { t } = useTranslation('components');

  function renderAsOption(option: SearchType) {
    return t(`MultiSearch.${option}`);
  }

  const props = { onOptionSelected: onSearchTypeChange, options: ['detectionSearch', 'mailTrace'], renderAsOption };

  return (
    <SearchTypeSelectWrapper>
      <Dropdown dataTestId="search-type-selector" noBackground noMargin {...props}>
        {t(`MultiSearch.${searchType}`)}
      </Dropdown>
      <Divider />
    </SearchTypeSelectWrapper>
  );
}

function MailtraceForm(props: Props) {
  const { onClose, onSearchTypeChange, onSubmit, searchParams, searchType, ...rest } = props;

  const initialValues = {
    alertId: '',
    dateRange: new Date(),
    domain: '',
    messageId: '',
    recipient: '',
    sender: '',
    subject: '',
  };

  return (
    <Formik
      initialValues={{ initialValues, ...searchParams }}
      onSubmit={(values) => onSubmit(values)}
      validateOnChange={false}
    >
      {(formikProps: any) => {
        return (
          <form onSubmit={formikProps.handleSubmit}>
            <Container>
              <SearchIcon />
              <SearchTypeSelect onSearchTypeChange={onSearchTypeChange} searchType={searchType} />

              <MailTrace {...formikProps} {...rest} />

              <CloseIcon aria-label="Close" onClick={onClose} />
              <HiddenButton>Search</HiddenButton>
            </Container>
          </form>
        );
      }}
    </Formik>
  );
}
