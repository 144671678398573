"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledContainer = void 0;

var _styled = require("../../styled");

var _utils = require("../../utils");

const StyledContainer = (0, _styled.styled)('span', {
  alignItems: 'center',
  borderColor: 'currentColor',
  borderRadius: '$1',
  borderStyle: 'solid',
  borderWidth: '$1',
  display: 'inline-flex',
  fontFamily: '$sans',
  fontSize: '$sm',
  fontStretch: '50%',
  fontWeight: 400,
  lineHeight: 1,
  p: '$1',
  textTransform: 'uppercase',
  variants: {
    bg: (0, _utils.tokenVariant)('bg', _styled.theme.colors),
    color: (0, _utils.tokenVariant)('color', _styled.theme.colors),

    /**
     * Sets the font family of the text.
     * @type $fonts
     */
    font: (0, _utils.tokenVariant)('fontFamily', _styled.theme.fonts)
  }
});
exports.StyledContainer = StyledContainer;