// @flow

import { loader } from 'graphql.macro';

export const detectionAllowListQuery = loader('./queries/DetectionsAllowListQuery.graphql');
export const insightsAllowListByTypeQuery = loader('./queries/InsightsAllowListByType.graphql');

export type AllowListList = { count: number, pattern: string, patternType: string };

export type AllowListCountsByDate = { date?: number, keys?: string[], values?: number[] };

type GraphTableReturnType = {
  label: string,
  value: number,
};

export function dataForGraphTable(list: Array<AllowListList>, allowType: string): Array<GraphTableReturnType> {
  const PATTERN = {
    'Allowed Recipients': 'allowedRecipient',
    'Allowed Senders': 'allowedSender',
    'Acceptable Senders': 'clientWhitelisted',
  };
  if (list) {
    return list
      .filter((row) => row.patternType === PATTERN[allowType])
      .map((row) => ({ label: row.pattern, value: row.count }));
  }
  return [];
}
