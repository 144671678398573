// @flow

import { ApolloError } from 'apollo-client';
import { loader } from 'graphql.macro';
import { get, startCase } from 'lodash';
// $FlowFixMe
import React, { useContext } from 'react';
import { useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';

import {
  defaultEmailReport,
  defaultInsightsSpoofData,
  defaultUserOverview,
  MAX_CAPPED_VALUE,
  type CurrentIntervalValue,
} from './dataTypesAndUtils';

import { DaysBackContext } from 'screens/App';
// $FlowFixMe
import { APIData } from 'screens/Dashboard/panels/OrgSpoofPanel';

import Button from 'ui/atoms/Button';
import ConditionalRender from 'ui/atoms/ConditionalRender';
import Link from 'ui/atoms/Link';
import Loading from 'ui/atoms/Loading';
import Rule from 'ui/atoms/Rule';
import BlockHeader from 'ui/molecules/BlockHeader';
import Content from 'ui/molecules/Content';
import ContentBlock from 'ui/molecules/ContentBlock';
import NoData from 'ui/molecules/NoData';
import { Body, Cell, Row, Container as Table } from 'ui/molecules/Table';
import TableHeader from 'ui/molecules/TableHeader';
import SectionOverview from 'ui/organisms/SectionOverview';
import SectionProgress from 'ui/organisms/SectionProgress';
import Split from 'ui/templates/Split';
import Stack from 'ui/templates/Stack';
import TwoColumn from 'ui/templates/TwoColumn';

export const fetchInsightsOrgSpoofs = loader('./queries/FetchInsightsOrgSpoofs.graphql');
export const fetchEmailReportQuery = loader('./queries/EmailReportFetch.graphql');
export const fetchUserOverviewQuery = loader('./queries/UserOverviewFetch.graphql');

export default function UserActivity() {
  const { currentInterval } = useContext(DaysBackContext);
  const { t } = useTranslation('email');

  const {
    data: insightsSpoofData,
    error: fetchInsightsError,
    loading: fetchInsightsLoading,
  } = useRemoteData(currentInterval);

  const {
    data: fetchEmailData,
    error: fetchEmailError,
    loading: fetchEmailLoading,
  } = useQuery(fetchEmailReportQuery, {
    variables: { currentInterval: determineReportInterval(currentInterval) },
  });

  const {
    data: fetchUserData,
    error: fetchUserError,
    loading: fetchUserLoading,
  } = useQuery(fetchUserOverviewQuery, {
    variables: { currentInterval },
  });

  const { recipientCount, senderCount } = get(fetchEmailData, 'emailReport', defaultEmailReport);
  const { phishRecipients, phishSenders } = get(fetchUserData, 'userOverview', defaultUserOverview);

  const { classicSpoofCount, nameSpoofCount, topNames, topTargets } = insightsSpoofData || defaultInsightsSpoofData;

  const userOverviewTotalSpoofs = nameSpoofCount + classicSpoofCount;

  const tableData = topNames.map(({ count, name }, i) => [
    [count, name],
    [topTargets[i] && topTargets[i].count, topTargets[i] && topTargets[i].name],
  ]);

  const renderNoData =
    !fetchUserLoading && !fetchInsightsLoading && (!tableData.length || fetchUserError || !!fetchInsightsError);

  return (
    <ContentBlock
      top={
        <>
          <BlockHeader
            title={t('organizationalSpoofs')}
            action={
              <Link to="/email/user-activity">
                <Button>{t('userDetails')}</Button>
              </Link>
            }
          />
          <Content>{t('organizationalSpoofsCopy')}</Content>
          <Rule margin="lg" />
          <Split>
            <SectionOverview
              currentValue={userOverviewTotalSpoofs}
              dataTestId="total-spoofed-detection"
              dimmed={fetchUserLoading}
              formatValue="number"
              title={t('totalSpoofedDetection')}
            />
            <SectionOverview
              currentValue={nameSpoofCount}
              dataTestId="name-spoofs"
              dimmed={fetchUserLoading}
              formatValue="number"
              title={t('nameSpoofs')}
            />
            <SectionOverview
              currentValue={classicSpoofCount}
              dataTestId="classic-spoofs"
              dimmed={fetchUserLoading}
              formatValue="number"
              title={t('classicSpoofs')}
            />
          </Split>
        </>
      }
      bottom={
        <TwoColumn bordered spaced>
          <SectionProgress
            barLabel={t('common:targeted')}
            cappedValue={MAX_CAPPED_VALUE}
            dataTestId="total-email-recipients"
            dimmed={(fetchEmailLoading && fetchUserLoading) || fetchEmailError}
            heading={t('totalEmailRecipients')}
            valueLabel={t('targetedRecipients')}
            valueMax={recipientCount}
            valueNow={phishRecipients}
          />
          <SectionProgress
            barLabel={t('common:attackers')}
            cappedValue={MAX_CAPPED_VALUE}
            dataTestId="total-email-senders"
            dimmed={(fetchUserLoading && fetchEmailLoading) || fetchEmailError}
            heading={t('totalEmailSenders')}
            valueLabel={t('phishSenders')}
            valueMax={senderCount}
            valueNow={phishSenders}
          />
        </TwoColumn>
      }
      dataTestId="user-activity-card"
    >
      <Table data-testid="spoofed-name-and-targets-table" spaced zebraStripes>
        <ConditionalRender condition={fetchUserLoading || fetchInsightsLoading || renderNoData}>
          <Body>
            <Row />
            <Row>
              <Cell colSpan="2">{renderNoData ? <NoData /> : <Loading />}</Cell>
            </Row>
          </Body>
        </ConditionalRender>

        <ConditionalRender
          condition={
            !fetchUserLoading && !fetchInsightsLoading && !fetchUserError && !fetchInsightsError && !!tableData.length
          }
        >
          <TableHeader colWidths={['50%', '50%']} headings={['topNames', 'topTargets']} ns="email" />
          <Body>
            {tableData.map((row) => {
              const [names, targets] = row;

              return (
                <Row key={names[1]}>
                  <Cell>
                    <Stack spaced>
                      <span>{names[0]}</span>
                      <span>{startCase(names[1])}</span>
                    </Stack>
                  </Cell>
                  <Cell>
                    <Stack spaced>
                      <span>{targets[0]}</span>
                      <span>{targets[1]}</span>
                    </Stack>
                  </Cell>
                </Row>
              );
            })}
          </Body>
        </ConditionalRender>
      </Table>
    </ContentBlock>
  );
}

/* Private functions */

function determineReportInterval(currentInterval: CurrentIntervalValue) {
  switch (currentInterval) {
    case 7:
      return 'weekly';
    case 90:
      return 'quarterly';
    default:
      return 'monthly';
  }
}

interface HookResult {
  /**
   * The data that has been returned from the API
   */
  data?: APIData;

  /**
   * If there is a problem loading the data, the error information will be available as an error object
   */
  error: ApolloError | null;

  /**
   * Returns true if the data is currently being loaded
   */
  loading: boolean;
}

const NUM_SPOOFS = 5;

/**
 * Private hook
 */
function useRemoteData(duration): HookResult {
  const { data, error, loading } = useQuery(fetchInsightsOrgSpoofs, {
    variables: { duration },
  });

  if (loading) return { data: undefined, error: null, loading: true };
  if (error) return { data: undefined, error, loading: false };

  if (!data?.insightsOrgSpoofs?.data) return { data: undefined, error: null, loading: false };

  const topNames = data.insightsOrgSpoofs.data.topNames.sort(sortByCount).slice(0, NUM_SPOOFS);
  const sortedNames = [
    ...topNames.filter((n) => n.name !== 'other'),
    ...topNames.filter((o) => o.name === 'other').filter(Boolean),
  ];

  const topTargets = data.insightsOrgSpoofs.data.topTargets.sort(sortByCount).slice(0, NUM_SPOOFS);
  const sortedTargets = [
    ...topTargets.filter((e) => e.name !== 'other'),
    ...topTargets.filter((f) => f.name === 'other').filter(Boolean),
  ];

  return {
    data: { ...data.insightsOrgSpoofs.data, topNames: sortedNames, topTargets: sortedTargets },
    error: null,
    loading: false,
  };
}

//
// Private function
// -------------------------------------------------------------------------------------------------

interface CountValue {
  count: number;
  name: string;
}

function sortByCount(a: CountValue, b: CountValue) {
  return b.count - a.count;
}
