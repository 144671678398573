// @flow

import { get } from 'lodash';
// $FlowFixMe
import React, { lazy, Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { mungeProgressTableData } from '../dataTypesAndUtils';

import { type MaliciousSendersListByName, type MaliciousSenderCountsByDataType } from './dataTypesAndUtils';

import Button from 'ui/atoms/Button';
import Loading from 'ui/atoms/Loading';
import BlockHeader from 'ui/molecules/BlockHeader';
import ContentBlock from 'ui/molecules/ContentBlock';
import Drawer from 'ui/molecules/Drawer';
import ProgressTable from 'ui/molecules/ProgressTable';
import ProgressTablePlaceholder from 'ui/molecules/ProgressTablePlaceholder';
import withDataHandler from 'utils/withDataHandler';

const DetailsTable = lazy(() => import('./DetailsTable'));

type Data = {
  drawer: Array<MaliciousSendersListByName>,
  progress: Array<MaliciousSenderCountsByDataType>,
};

type Props = {
  data: Data,
  error: boolean,
  loading: boolean,
};

const ProgressTableWithDataHandler = withDataHandler({
  empty: <ProgressTablePlaceholder />,
})(ProgressTable);

export default function TopMaliciousSendingDomains({ data, error, loading }: Props) {
  const [openModal, setOpenModal] = useState(false);

  const drawer = get(data, 'drawer', []);
  const progress = get(data, 'progress', []);

  const { t } = useTranslation('email');

  return (
    <ContentBlock
      dataTestId="top-malicious-sending-domains-component"
      top={
        <>
          <BlockHeader
            title={t('topMaliciousSendingDomains')}
            action={
              <Button
                disabled={drawer.length === 0}
                icon="hamburger"
                muted
                onClick={() => drawer.length > 0 && setOpenModal(true)}
              >
                {t('viewDetails')}
              </Button>
            }
          />
          <ProgressTableWithDataHandler
            /* eslint-disable no-shadow */
            // eslint-disable-next-line no-unused-vars
            calculatePercent={(d, [_, number]) => Number(number) / d.reduce((a, b) => Number(a) + Number(b[1]), 0)}
            cellWidths={['50%', '50%']}
            data={mungeProgressTableData(progress)}
            isEmpty={() => loading || error || progress.length === 0}
            isLoading={loading}
          />
        </>
      }
    >
      <Drawer.Modal onClose={() => setOpenModal(false)} paddedSides={false} size="large" visible={openModal}>
        <Suspense fallback={<Loading />}>
          {openModal && <DetailsTable data={drawer} error={error} loading={loading} />}
        </Suspense>
      </Drawer.Modal>
    </ContentBlock>
  );
}
