// @flow

import { random } from '@a1s/lib';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { type EmailCountsMarshallTypeRow } from './dataTypesAndUtils';

import Button from 'ui/atoms/Button';
import Right from 'ui/atoms/Right';
import { Cell, Row } from 'ui/molecules/Table';

type DataType = {
  last30Days: EmailCountsMarshallTypeRow,
  [data: string]: EmailCountsMarshallTypeRow,
};

type Props = {
  becEnabled: boolean,
  onRequestOpen: (_event: SyntheticMouseEvent<any>, detectionsKey: Object) => any,
  row: DataType,
};

export default function DetectionStatisticsTableRow({ becEnabled, onRequestOpen, row }: Props) {
  const { t } = useTranslation('email');

  const months = Object.keys(row)[0];
  const { mailBec, mailBulk, mailMalicious, mailSpam, mailSpoof, mailSuspicious, mailTotal } = row[months].totals;
  const [month, year] = months.split('/');

  const headings = [
    mailTotal,
    mailMalicious,
    ...(becEnabled ? [mailBec] : []),
    mailSpam,
    mailBulk,
    mailSuspicious,
    mailSpoof,
  ];

  return (
    <Row data-testid={`detection-details-row-${month}`} key={month}>
      <Cell>
        <Button clickableText="blue" detectionsKey={row} onClick={(e) => onRequestOpen(e, row)}>
          {months === 'last30Days'
            ? t(`common:${months}`)
            : t('components:DatePicker.dateMonthYear', { date: new Date(Number(year), Number(month) - 1) })}
        </Button>
      </Cell>
      {headings.map((val = 0) => (
        <Cell key={`id${random()}`}>
          <Right>{t('components:Statistic.number', { value: val })}</Right>
        </Cell>
      ))}
    </Row>
  );
}
