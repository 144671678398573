// @flow

import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import { endOfDay, endOfMonth, startOfDay, startOfMonth, startOfToday, subDays, subMonths, subYears } from 'date-fns';
import { defer } from 'lodash';
import { lighten, rem, rgba } from 'polished';
// $FlowFixMe
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

//
// Styled components
// -------------------------------------------------------------------------------------------------

const Container = styled.div`
  align-items: center;
  display: flex;
  line-height: 32px;
  margin: 0 ${(p) => rem(p.theme.spacing.sm)};
`;

const Picker = styled(DateTimeRangePicker)`
  flex-grow: 20;
  width: auto;

  .react-datetimerange-picker__wrapper {
    border: 0;
  }

  .react-datetimerange-picker__inputGroup {
    &:first-of-type {
      margin-right: ${(p) => rem(p.theme.spacing.sm)};
    }

    &:last-of-type {
      margin-left: ${(p) => rem(p.theme.spacing.sm)};
    }
  }

  .react-datetimerange-picker__wrapper,
  .react-datetimerange-picker__inputGroup__leadingZero,
  button,
  input,
  select {
    color: ${({ theme }) => theme.colors.grayBlue};
    font-family: ${({ theme }) => theme.fonts.roboto};
    font-size: ${rem(12)};
  }

  input,
  select {
    background-color: inherit !important;
  }

  select {
    appearance: none;
  }

  .react-calendar {
    background-color: ${({ theme }) => theme.colors.ghostWhite};
    border: ${({ theme }) => theme.colors.glitter} solid ${rem(1)};
    border-radius: 0 ${rem(6)} ${rem(6)} 0;
    box-shadow: ${({ theme }) => rgba(theme.colors.jet, 0.08)} 0 ${rem(4)} ${rem(8)};
    position: relative;
    /* transform: translateX(${rem(120)}); */
    z-index: 1;
  }

  .react-calendar__navigation__arrow:enabled:hover,
  .react-calendar__navigation__label:enabled:hover {
    background-color: ${({ theme }) => theme.colors.azureishWhite};
    color: ${({ theme }) => theme.colors.lightSlateGray};
  }

  .react-calendar__month-view__weekdays,
  abbr {
    font-family: ${({ theme }) => theme.fonts.roboto};
    font-size: ${rem(11)};
    text-decoration: none;
  }

  .react-calendar__tile--hasActive {
    background: ${(p) => p.theme.colors.deepSkyBlue};
    color: ${(p) => p.theme.colors.white};
  }

  .react-calendar__tile--rangeStart {
    border-bottom-left-radius: ${rem(2)};
    border-top-left-radius: ${rem(2)};
  }

  .react-calendar__tile--rangeEnd {
    border-bottom-right-radius: ${rem(2)};
    border-top-right-radius: ${rem(2)};
  }

  .react-calendar__tile--active {
    background: ${(p) => lighten(0.2, p.theme.colors.deepSkyBlue)};
    color: ${(p) => p.theme.colors.white};
  }

  ${({ hideTime }) =>
    hideTime &&
    css`
      .react-datetimerange-picker__inputGroup__leadingZero,
      .react-datetime-picker__inputGroup__divider:nth-of-type(4) {
        display: none !important;
      }

      .react-datetimerange-picker__inputGroup__amPm {
        display: none !important;
      }

      .react-datetimerange-picker__inputGroup__hour {
        display: none !important;
      }

      .react-datetimerange-picker__inputGroup__minute {
        display: none !important;
      }
    `}
`;

const Shortcuts = styled.div`
  background-color: ${({ theme }) => lighten(0.008, theme.colors.ghostWhite)};
  border: ${({ theme }) => theme.colors.glitter} solid ${rem(1)};
  border-radius: ${rem(6)} 0 0 ${rem(6)};
  box-shadow: ${({ theme }) => rgba(theme.colors.jet, 0.08)} 0 ${rem(4)} ${rem(8)};
  display: none;
  padding: ${(p) => rem(p.theme.spacing.md)} ${(p) => rem(p.theme.spacing.sm)};
  position: fixed;
  width: ${rem(121)};
  z-index: 10;

  ${(p) =>
    p.isOpen &&
    css`
      display: block;
    `};
`;

const Shortcut = styled.button`
  background-color: transparent;
  color: ${(p) => p.theme.colors.darkSlateGray};
  cursor: pointer;
  display: block;
  font-family: inherit;
  font-size: ${rem(11)};
  margin-bottom: ${(p) => rem(p.theme.spacing.md)};
  border: 0;
  padding: 0;

  &:hover {
    color: ${(p) => p.theme.colors.lightSlateGray};
  }
`;

//
// Main component
// -------------------------------------------------------------------------------------------------

type DateRange = [Date, Date];

type GetRange = () => DateRange;

interface Props {
  dataTestId?: string;
  hideTime?: boolean;
  onChange: (value: DateRange) => void;
  shortcuts?: boolean;
  value: DateRange;
}

export default function DateRangePicker({
  dataTestId = 'molecule-dateRangePicker',
  hideTime = false,
  onChange,
  shortcuts = true,
  value,
  ...props
}: Props) {
  const calRef = useRef(null);
  const shortcutsRef = useRef(null);
  const [isOpen, setIsOpen] = useState();
  const { i18n, t } = useTranslation('components');

  function handleCalendarClose() {
    setIsOpen(false);
  }

  function handleCalendarOpen() {
    setIsOpen(true);
    layout(calRef.current, shortcutsRef.current);
  }

  function handleMouseDown(getRange: GetRange) {
    return (event) => {
      event.stopPropagation();
      onChange(getRange.call());
    };
  }

  function handleMouseUp() {
    defer(() => layout(calRef.current, shortcutsRef.current));
  }

  return (
    <Container data-testid={dataTestId} onMouseUp={handleMouseUp}>
      {shortcuts && (
        <Shortcuts isOpen={isOpen} ref={shortcutsRef}>
          <Shortcut onMouseDown={handleMouseDown(today)}>{t('DateRangePicker.today')}</Shortcut>
          <Shortcut onMouseDown={handleMouseDown(yesterday)}>{t('DateRangePicker.yesterday')}</Shortcut>
          <Shortcut onMouseDown={handleMouseDown(last7Days)}>{t('DateRangePicker.last7Days')}</Shortcut>
          <Shortcut onMouseDown={handleMouseDown(last30Days)}>{t('DateRangePicker.last30Days')}</Shortcut>
          <Shortcut onMouseDown={handleMouseDown(last90Days)}>{t('DateRangePicker.last90Days')}</Shortcut>
          <Shortcut onMouseDown={handleMouseDown(thisMonth)}>{t('DateRangePicker.thisMonth')}</Shortcut>
          <Shortcut onMouseDown={handleMouseDown(lastMonth)}>{t('DateRangePicker.lastMonth')}</Shortcut>
        </Shortcuts>
      )}

      <Picker
        disableClock
        hideTime={hideTime}
        calendarIcon={null}
        clearIcon={null}
        locale={i18n.language}
        maxDate={startOfToday()}
        minDate={new Date(subYears(new Date(), 1))}
        onChange={onChange}
        onCalendarClose={handleCalendarClose}
        onCalendarOpen={handleCalendarOpen}
        ref={calRef}
        value={value}
        {...props}
      />
    </Container>
  );
}

//
// Private functions
// -------------------------------------------------------------------------------------------------

function last7Days() {
  const start = subDays(new Date(), 7);
  const end = new Date();
  return [startOfDay(start), endOfDay(end)];
}

function last30Days() {
  const start = subDays(new Date(), 30);
  const end = new Date();
  return [startOfDay(start), endOfDay(end)];
}

function last90Days() {
  const start = subDays(new Date(), 90);
  const end = new Date();
  return [startOfDay(start), endOfDay(end)];
}

function lastMonth() {
  const date = subMonths(new Date(), 1);
  return [startOfMonth(date), endOfMonth(date)];
}

function layout(calEl, shortcutsEl) {
  if (calEl && calEl.wrapper && shortcutsEl) {
    const [element] = calEl.wrapper.getElementsByClassName('react-calendar');
    if (element) {
      const elementRect = element.getBoundingClientRect();

      /* eslint-disable no-param-reassign */
      shortcutsEl.style.height = `${elementRect.height}px`;
      shortcutsEl.style.left = `${elementRect.left - 120}px`;
      shortcutsEl.style.top = `${elementRect.top}px`;
      /* eslint-enable no-param-reassign */
    }
  }
}

function thisMonth() {
  const date = new Date();
  return [startOfMonth(date), endOfDay(date)];
}

function today() {
  const date = new Date();
  return [startOfDay(date), endOfDay(date)];
}

function yesterday() {
  const date = subDays(new Date(), 1);
  return [startOfDay(date), endOfDay(date)];
}
