import { Box, Text } from '@a1s/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { usePermissions } from '../../lib/permissions';

//
// Main component
// -------------------------------------------------------------------------------------------------

interface Props {
  image?: string;
  loading?: boolean;
}

export function DetectionEmailPreview({ image, loading }: Props) {
  const userPermitted = usePermissions();
  const { t } = useTranslation('common');

  // Early return is user doesn't have permissions
  if (!userPermitted)
    return (
      <Text font="sans" size="sm" stretch="ultraCondensed">
        {t('components:NoAccess.needAccessFromAdmin')}
      </Text>
    );

  if (loading)
    return (
      <Text font="sans" size="sm" stretch="ultraCondensed">
        {t('loading')}
      </Text>
    );

  return (
    <Box css={{ maxWidth: '100%', '& > img': { width: '100%' } }} data-testid="detection-email-preview">
      <img alt="Email Preview" src={`data:image/png;base64,${image}`} />
    </Box>
  );
}
