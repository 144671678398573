import { styled } from '@a1s/ui';
import { endOfDay, startOfDay, subDays } from 'date-fns';
import React, { MouseEvent, PropsWithChildren } from 'react';

import { useDuration } from 'utils/duration';
import useGlobalSearch from 'utils/hooks/useGlobalSearch';

interface SearchableProps {
  searchType: 'detectionSearch' | 'mailTrace';
  target: string;
}

export function Searchable({ children, searchType = 'mailTrace', target }: PropsWithChildren<SearchableProps>) {
  const duration = useDuration();
  const searchDetections = useGlobalSearch(searchType);

  function handleClick(event: MouseEvent<HTMLElement>) {
    event.preventDefault();
    const range = [startOfDay(subDays(new Date(), Number.parseInt(duration, 10))), endOfDay(new Date())];
    searchDetections({ dateRange: [range[0], range[1]], recipient: target });
  }

  if (target.toLowerCase() === 'other') return <>{children}</>;

  return (
    <SearchableLink data-testid="searchable-link" onClick={handleClick}>
      {children}
    </SearchableLink>
  );
}

//
// Styled component
// -------------------------------------------------------------------------------------------------

const SearchableLink = styled('a', {
  cursor: 'pointer',
  textDecoration: 'none',

  [`&:hover  span`]: {
    color: '$black',
  },
});
