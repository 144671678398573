// @flow

// $FlowFixMe
import React, { lazy, Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'ui/atoms/Button';
import Loading from 'ui/atoms/Loading';
import BlockHeader from 'ui/molecules/BlockHeader';
import ContentBlock from 'ui/molecules/ContentBlock';
import Drawer from 'ui/molecules/Drawer';
import ProgressTable from 'ui/molecules/ProgressTable';
import ProgressTablePlaceholder from 'ui/molecules/ProgressTablePlaceholder';
import withDataHandler from 'utils/withDataHandler';

const DetailsTable = lazy(() => import('./DetailsTable'));

type MaliciousData = {
  [string]: number,
};

type Props = {
  data: MaliciousData,
  error: boolean,
  loading: boolean,
};

const ProgressTableWithDataHandler = withDataHandler({
  empty: <ProgressTablePlaceholder />,
})(ProgressTable);

export default function MostCommonTopLevelDomains({ data, error, loading }: Props) {
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation('email');

  return (
    <ContentBlock
      dataTestId="most-common-top-level-domains-component"
      top={
        <>
          <BlockHeader
            title={t('mostCommonTopLevelDomains')}
            action={
              <Button
                disabled={Object.keys(data).length === 0}
                icon="hamburger"
                muted
                onClick={() => Object.keys(data).length > 0 && setOpenModal(true)}
              >
                {t('viewDetails')}
              </Button>
            }
          />
          <ProgressTableWithDataHandler
            /* eslint-disable no-shadow */
            // eslint-disable-next-line no-unused-vars
            calculatePercent={(data, [_, number]) =>
              Number(number) / data.reduce((a, b) => Number(a) + Number(b[1]), 0)
            }
            cellWidths={['50%', '50%']}
            data={dataForGraphTable(data)}
            isEmpty={() => loading || error || !data || Object.keys(data).length === 0}
            isError={error}
            isLoading={loading}
            tint
          />
        </>
      }
    >
      <Drawer.Modal visible={openModal} onClose={() => setOpenModal(false)} size="large" paddedSides={false}>
        <Suspense fallback={<Loading />}>{openModal && <DetailsTable fetchData={openModal} />}</Suspense>
      </Drawer.Modal>
    </ContentBlock>
  );
}

const dataForGraphTable = (data) => {
  if (!data) return [];
  return Object.keys(data)
    .map((d) => ({ domain: d, count: data[d] }))
    .sort((a, b) => b.count - a.count)
    .slice(0, 10)
    .map((row) => [row.domain, row.count]);
};
