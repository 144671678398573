import { Cluster } from '@a1s/ui';
import React, { useEffect, useState, ComponentProps } from 'react';

import { SearchResultRow } from '../../types';
import { Main, SectionHeader } from '../../ui';
import { getIdsForCheckboxes } from '../../ui/MetaCell';

import { Details, Results } from './ui';

//
// Main component
// -------------------------------------------------------------------------------------------------

type CheckedRows = Exclude<ComponentProps<typeof Results>['checked'], undefined>;

interface AllMailSearchProps {
  params: ComponentProps<typeof Results>['search'];
}

export function AllMailSearch({ params }: AllMailSearchProps) {
  const [checked, setChecked] = useState<CheckedRows>([]);
  const [detail, setDetail] = useState<SearchResultRow | null>(null);
  const [counts, setCounts] = useState<ComponentProps<typeof SectionHeader>['searchCounts'] | undefined>(undefined);

  // Uncheck all rows when a new search is run
  const deps = JSON.stringify(params);
  useEffect(() => {
    setChecked([]);
  }, [deps]);

  function handleSelectAll(all: boolean) {
    if (all) {
      const ids = getIdsForCheckboxes();
      setChecked(ids);
    } else {
      setChecked([]);
    }
  }

  return (
    <Main aria-hidden={!!detail}>
      <Main.Header>
        <SectionHeader
          dateRange={params.daysBack}
          messageIds={checked}
          onSelectAll={handleSelectAll}
          params={params}
          searchCounts={counts}
        />
      </Main.Header>

      <Main.Body>
        <Cluster css={{ height: '100%' }} gap="5">
          <Results
            checked={checked}
            collapsed={!!detail}
            onPressViewButton={setDetail}
            onRowCheck={setChecked}
            onSearchFinished={setCounts}
            search={params}
            selectedMessageId={detail?.messageId}
          />
          {!!detail && <Details onPressBackButton={() => setDetail(null)} row={detail} />}
        </Cluster>
      </Main.Body>
    </Main>
  );
}
