// @flow

import { get } from 'lodash';
// $FlowFixMe
import React, { lazy, Suspense, useContext, useState } from 'react';
import { useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';

import { dataForGraphTable, insightSimilarDomains } from './dataTypesAndUtils';

import { DaysBackContext } from 'screens/App';
import Button from 'ui/atoms/Button';
import ConditionalRender from 'ui/atoms/ConditionalRender';
import Loading from 'ui/atoms/Loading';
import PaddedContent from 'ui/atoms/PaddedContent';
import BlockHeader from 'ui/molecules/BlockHeader';
import ContentBlock from 'ui/molecules/ContentBlock';
import Drawer from 'ui/molecules/Drawer';
import GraphTable from 'ui/molecules/GraphTable';
import PlaceholderGraphTable from 'ui/molecules/PlaceholderGraphTable';
import SectionOverview from 'ui/organisms/SectionOverview';
import Split from 'ui/templates/Split';
import withDataHandler from 'utils/withDataHandler';

const DetailsTable = lazy(() => import('./DetailsTable'));

const GraphTableWithDataHandler = withDataHandler({
  empty: <PlaceholderGraphTable />,
})(GraphTable);

export default function TopDomainSimilaritySpoofs() {
  const { currentInterval } = useContext(DaysBackContext);
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation('email');

  const { data, loading, error } = useQuery(insightSimilarDomains, { variables: { currentInterval, limit: 10 } });
  const allDomains = get(data, 'similarDomains', []);
  const graphTable = get(allDomains, 'data.top.current', []);
  const sectionOverviewCurrent = get(allDomains, 'data.totals.current', 0);
  const sectionOverviewPrevious = get(allDomains, 'data.totals.previous', 0);

  return (
    <ContentBlock
      top={
        <>
          <BlockHeader
            title={t('domainSimilaritySpoofs')}
            action={
              <Button
                disabled={graphTable.length === 0}
                icon="hamburger"
                muted
                onClick={() => graphTable.length > 0 && setOpenModal(true)}
              >
                {t('viewDetails')}
              </Button>
            }
          >
            <Split>
              <SectionOverview
                formatValue="number"
                currentValue={sectionOverviewCurrent}
                dimmed={loading}
                previousValue={sectionOverviewPrevious}
              />
            </Split>
          </BlockHeader>
          <PaddedContent pushTop="xl">
            <GraphTableWithDataHandler
              data={dataForGraphTable(graphTable)}
              dataTestId="graph-table-domain-similarity"
              isEmpty={() => loading || error || graphTable.length === 0}
              isLoading={loading}
              tint
            />
          </PaddedContent>
        </>
      }
    >
      <Drawer.Modal visible={openModal} onClose={() => setOpenModal(false)} size="large" paddedSides={false}>
        <Suspense fallback={<Loading />}>
          <ConditionalRender condition={openModal}>
            <DetailsTable shouldFetchData={openModal} />
          </ConditionalRender>
        </Suspense>
      </Drawer.Modal>
    </ContentBlock>
  );
}
