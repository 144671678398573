// @flow

import { loader } from 'graphql.macro';
import { get } from 'lodash';
import { rem } from 'polished';
import React from 'react';
import { useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import downloadEml from './utils';

import Button from 'ui/atoms/Button';
import ConditionalRender from 'ui/atoms/ConditionalRender';
import Loading from 'ui/atoms/Loading';
import BlockHeader from 'ui/molecules/BlockHeader';
import NoData from 'ui/molecules/NoData';

const query = loader('./queries/load.graphql');

const Textarea = styled.textarea.attrs({ readOnly: true })`
  font-family: ${(p) => p.theme.fonts.roboto};
  height: calc(100% - ${rem(132)});
  margin-top: ${(p) => rem(p.theme.spacing.lg)};
  width: 100%;
`;

interface Props {
  path: string;
}

export default function EmlView({ path }: Props) {
  const { data, loading } = useQuery(query, {
    skip: !path,
    variables: { path, renderImages: false, waitSeconds: 300 },
  });

  const mailData = get(data, 'mail', { email: '' });

  const emailData = get(mailData, 'email', '');

  const { t } = useTranslation('searchResults');

  return (
    <>
      <BlockHeader title={t('emlData')} />
      <ConditionalRender condition={!loading} fallback={<Loading />}>
        <ConditionalRender condition={mailData} falback={<NoData message="noResults" />}>
          <>
            <Button icon="download" noMargin onClick={() => downloadEml(path, emailData)}>
              {t('download')}
            </Button>
            <Textarea data-testid="raw-section" value={emailData} />
          </>
        </ConditionalRender>
      </ConditionalRender>
    </>
  );
}
