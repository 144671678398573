"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.transformKeys = transformKeys;

var _isObject = _interopRequireDefault(require("lodash/isObject"));

var _mapKeys = _interopRequireDefault(require("lodash/mapKeys"));

var _mapValues = _interopRequireDefault(require("lodash/mapValues"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * Returns a new object with all of the keys of the given transformed by the given function.
 *
 * @param value - The object to iterate over.
 * @param transform - The function invoked per iteration to transform a key.
 * @return Returns the new mapped object.
 *
 * @example <caption>Use with `camelCase` to transform keys</caption>
 * ```js
 * import { camelCase, transformKeys } from `@a1s/lib`;
 * // Returns { fooBar: true, barBaz: false }
 * transformKeys({ 'foo-bar': true, bar_baz: false }, camelCase)
 * ```
 */
function transformKeys(object, transform) {
  const mapped = (0, _mapKeys.default)(object, (_, key) => transform(key));
  return (0, _mapValues.default)(mapped, val => isRecord(val) ? transformKeys(val, transform) : val);
} //
// Private function
// -------------------------------------------------------------------------------------------------

/**
 * Returns try if the given value is a `Record`.
 */


function isRecord(value) {
  return (0, _isObject.default)(value);
}