// @flow

import { Formik } from 'formik';
import { loader } from 'graphql.macro';
import { get } from 'lodash';
import { rem } from 'polished';
// $FlowFixMe
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
// $FlowFixMe
import { useDispatch } from 'redux-react-hook';
import styled from 'styled-components';

import { fetchUser } from 'store/actions/user';
import Button from 'ui/atoms/Button';
import ConditionalRender from 'ui/atoms/ConditionalRender';
import Form from 'ui/atoms/Form';
import Label from 'ui/atoms/Label';
import Loading from 'ui/atoms/Loading';
import PaddedContent from 'ui/atoms/PaddedContent';
import Content from 'ui/molecules/Content';
import ErrorMessage from 'ui/molecules/ErrorMessage';
import Eula from 'ui/molecules/Eula';
import Field from 'ui/molecules/Field';
import Info from 'ui/molecules/Info';
import { Body, Cell, Row, Container as Table } from 'ui/molecules/Table';
import TextField from 'ui/molecules/TextField';
import PasswordInput from 'ui/organisms/PasswordInput';
import useTfaEnabled from 'utils/hooks/useTfaEnabled';
import validations from 'utils/validations';

export const FetchTfaSecret = loader('./queries/FetchTfaSecret.graphql');
const PostInviteMutation = loader('./queries/PostInvite.graphql');

const Centered = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => rem(theme.spacing.md)};
`;

const Image = styled.div`
  border: ${({ theme }) => theme.colors.antiFlashWhite} solid ${rem(2)};
  border-radius: ${rem(4)};

  img {
    display: block;
    max-width: 100%;
  }
`;

const StyledLabel = styled(Label)`
  margin-right: ${({ theme }) => rem(theme.spacing.xs)} !important;
`;

interface InviteFormProps {
  showOnboarding: boolean;
}

export default function InviteForm({ showOnboarding = false }: InviteFormProps) {
  const [eulaAccepted, setEulaAccepted] = useState(true);
  const dispatch = useDispatch();
  const { t } = useTranslation('invitePage');
  const history = useHistory();
  const { token } = useParams();
  const { tfaEnabled, tfaError, tfaLoading } = useTfaEnabled();

  const { data, loading: fetchSecretLoading } = useQuery(FetchTfaSecret, {
    fetchPolicy: 'network-only',
    skip: !showOnboarding || !tfaEnabled || tfaLoading || tfaError,
  });

  const qrcode = get(data, 'tfaSecret.qrcode', '');
  const showEula = get(data, 'tfaSecret.showEula', false);

  useEffect(() => {
    // If showEula is false it's because the user has already accepted it; set the necessary state for that.
    if (showEula) {
      setEulaAccepted(false);
    }
  }, [showEula]);

  const [postInvite, { called, error, loading }] = useMutation(PostInviteMutation);

  if (called && !loading && !error) history.push('/');

  const handleFormSubmit = async (values) => {
    const { password, passwordConfirm, tfaCode } = values;
    const input = {
      confirmation: passwordConfirm,
      eulaAccepted,
      ip: null,
      password,
      token: tfaCode,
    };
    try {
      await postInvite({ variables: { input, token } });
      dispatch(fetchUser());
    } catch (e) {
      // check Sentry for potential errors
    }
  };

  const initialValues = { password: '', passwordConfirm: '', tfaCode: '' };

  const renderError = () => {
    if (error) return `common:forms.errors.${error?.networkError?.result?.error || 'serverError'}`;
    if (tfaError) return `common:forms.errors.${tfaError?.networkError?.result?.error || 'serverError'}`;
    return 'common:forms.errors.invalidOtp';
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => handleFormSubmit(values)}
      validate={validate}
      validateOnChange={false}
    >
      {(props: any) => {
        const { errors, handleChange, handleSubmit, setErrors } = props;
        const errorKeys = Object.keys(errors);

        return (
          <Form onSubmit={handleSubmit}>
            <ConditionalRender condition={errorKeys.length > 0}>
              <ErrorMessage>
                {errorKeys.map((msgKey) => (
                  <>
                    <p key={msgKey}>
                      {t(String(`common:${errors[msgKey]}`), {
                        key: t(msgKey),
                        min: 8,
                        max: 50,
                      })}
                    </p>
                  </>
                ))}
                <ConditionalRender condition={errors.passwordLabel}>
                  <Trans i18nKey="passwordRequirements" ns="user">
                    <>
                      <p>Passwords must contain</p>
                      <p>
                        8-50 characters with:
                        <br />
                        at least 1 number
                        <br />
                        at least 1 uppercase letter
                        <br />
                        at least 1 lowercase letter
                        <br />
                        at least 1 symbol
                      </p>
                    </>
                  </Trans>
                </ConditionalRender>
              </ErrorMessage>
            </ConditionalRender>
            <ConditionalRender condition={error || tfaError}>
              <PaddedContent pushTop="lg">
                <ErrorMessage>{t(renderError())}</ErrorMessage>
              </PaddedContent>
            </ConditionalRender>
            <Table>
              <Body>
                <Row>
                  <PaddedContent pushTop="md" pushBottom="md">
                    <Cell width="55%">
                      <StyledLabel htmlFor="passwordLabel">{t('passwordLabel')}</StyledLabel>
                      <Info tooltipPosition="right">
                        <Trans i18nKey="passwordRequirements" ns="user">
                          <>
                            <p>Passwords must contain</p>
                            <p>
                              8-50 characters with:
                              <br />
                              at least 1 number
                              <br />
                              at least 1 uppercase letter
                              <br />
                              at least 1 lowercase letter
                              <br />
                              at least 1 symbol
                            </p>
                          </>
                        </Trans>
                      </Info>
                    </Cell>
                  </PaddedContent>
                  <Cell>
                    <PasswordInput
                      name="password"
                      onChange={(value) => {
                        setErrors({});
                        handleChange(value);
                      }}
                    />
                  </Cell>
                </Row>
                <Row>
                  <Cell width="30%">
                    <Label htmlFor="passwordConfirmLabel">{t('passwordConfirmLabel')}</Label>
                  </Cell>
                  <Cell>
                    <PasswordInput
                      name="passwordConfirm"
                      onChange={(value) => {
                        setErrors({});
                        handleChange(value);
                      }}
                    />
                  </Cell>
                </Row>
              </Body>
            </Table>

            <ConditionalRender condition={tfaLoading || fetchSecretLoading}>
              <Loading />
            </ConditionalRender>

            <ConditionalRender condition={tfaEnabled}>
              <ConditionalRender condition={qrcode}>
                <PaddedContent pushBottom="sm" pushTop="md">
                  <>
                    <Field>
                      <Content>{t('tfaCopy')}</Content>
                    </Field>
                    <Field>
                      <Image>
                        <img alt="SVG" src={`data:image/svg+xml;utf8,${qrcode}`} />
                      </Image>
                    </Field>
                  </>
                </PaddedContent>
              </ConditionalRender>
              <PaddedContent pushBottom="sm" pushTop="md">
                <TextField
                  autoComplete="off"
                  label={t('tfaLabel')}
                  name="tfaCode"
                  onChange={handleChange}
                  info={
                    <Info tooltipPosition="right">
                      <p>{t('tfaTooltipTitle')}</p>
                      <p>{t('tfaTooltipDescription')}</p>
                    </Info>
                  }
                />
              </PaddedContent>
            </ConditionalRender>

            <ConditionalRender condition={!!showEula}>
              <Eula checked={eulaAccepted} setEulaAccepted={setEulaAccepted} />
            </ConditionalRender>

            <Centered>
              <Button icon={loading && 'spinner'} disabled={!eulaAccepted || errorKeys.length > 0} type="submit">
                {t('save')}
              </Button>
            </Centered>
          </Form>
        );
      }}
    </Formik>
  );
}

type FormValues = { password: string, passwordConfirm: string, tfaCode?: ?string };

function validate(values: FormValues) {
  const errors = {};

  if (!validations.presence(values.password)) errors.password = 'forms.errors.blank';
  if (!validations.presence(values.passwordConfirm)) errors.passwordConfirm = 'forms.errors.blank';
  if (!validations.matches(values.password, values.passwordConfirm)) errors.password = 'forms.errors.match';
  if (!validations.minLength(8, values.password)) errors.password = 'forms.errors.minLength';
  if (!validations.maxLength(50, values.password)) errors.password = 'forms.errors.maxLength';
  if (
    !(
      values.password.match(/\d/) !== null &&
      values.password.match(/[A-Z]/) !== null &&
      values.password.match(/[a-z]/) !== null &&
      values.password.match(/[\W_]/) !== null
    )
  )
    errors.passwordLabel = 'forms.errors.format';

  return errors;
}
