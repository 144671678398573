// @flow

import { useDebounce } from '@a1s/hooks';
import { loader } from 'graphql.macro';
import { get } from 'lodash';
import React from 'react';
import { useQuery } from 'react-apollo';

import SearchSuggestions from 'ui/molecules/SearchSuggestions';
import { utf8ToBase64 } from 'utils/util';

const Autocomplete = loader('./queries/Autocomplete.graphql');

//
// Main component
// -------------------------------------------------------------------------------------------------

interface Props {
  onSelect: (suggestion: string) => void;
  query?: string;
  shouldShow?: boolean;
}

export default function Suggestions({ onSelect, query = '', shouldShow = true }: Props) {
  const debouncedQuery = useDebounce(query, 150, 250);

  const { data, loading } = useQuery(Autocomplete, {
    fetchPolicy: 'network-only',
    skip: query.length < 3,
    variables: { search: utf8ToBase64(debouncedQuery) },
  });

  if (query === '' || !shouldShow) return null;

  return <SearchSuggestions loading={loading} onSelect={onSelect} suggestions={get(data, 'suggestions.matches')} />;
}
