"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.decodeBase64 = decodeBase64;

/**
 * Decodes a string that has been Base64 encoded.
 *
 * @param string - The string to deencode.
 */
function decodeBase64(str) {
  return decodeURIComponent(escape(window.atob(str)));
}