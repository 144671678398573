// @flow

import { isFunction } from 'lodash';
import { rem, rgba } from 'polished';
// $FlowFixMe
import React, { useEffect } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  align-content: stretch;
  background-color: ${(p) => rgba(p.theme.colors.white, 0.98)};
  backdrop-filter: blur(10px);
  bottom: 0;
  display: flex;
  left: 0;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  right: 0;
  top: ${rem(56)};
  transition: opacity 250ms ${({ theme }) => theme.timing.easeOutCirc},
    transform 250ms ${({ theme }) => theme.timing.easeOutCirc}, visibility 0s linear 0s;
  will-change: opacity, transform;
  z-index: 10;

  &[aria-hidden='true'] {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
    transition: opacity 150ms ${({ theme }) => theme.timing.easeInOutCirc},
      transform 150ms ${({ theme }) => theme.timing.easeInOutCirc}, visibility 0s linear 150ms;
    visibility: hidden;
  }
`;

const Content = styled.div`
  flex-grow: 1;
`;

interface Props {
  children: Function;
  dataTestId?: string;
  hidden?: boolean;
}

export default function Overlay({ children, dataTestId = 'atom-overlay', hidden = false, ...rest }: Props) {
  useEffect(() => {
    if (document.body) {
      document.body.style.overflow = hidden ? 'visible' : 'hidden';
      // $FlowFixMe
      document.body.dataset.isOverlayOpen = (!hidden).toString();
    }
  }, [hidden]);

  return (
    <Container aria-hidden={hidden} data-testid={dataTestId} {...rest}>
      <Content>{isFunction(children) ? children() : children}</Content>
    </Container>
  );
}
