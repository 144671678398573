"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.usePrefersDarkColorScheme = usePrefersDarkColorScheme;

var _react = require("react");

/**
 * Returns, as a stateful value, `true` if the user's browser reports that they'd prefer a dark scheme. The stateful
 * value is updated when the preference changes. For example, some users have their OS change to using a dark color
 * scheme at a night and a light scheme in the day.
 */
function usePrefersDarkColorScheme() {
  const matcher = window.matchMedia('(prefers-color-scheme: dark)');
  const [isDarkMode, setIsDarkMode] = (0, _react.useState)(matcher.matches);
  (0, _react.useEffect)(() => {
    function handleChange(event) {
      setIsDarkMode(event.matches);
    }

    matcher.addEventListener('change', handleChange);
    return () => {
      matcher.removeEventListener('change', handleChange);
    };
  });
  return isDarkMode;
}