"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _styledComponents = require("styled-components");

const FontStack = (0, _styledComponents.createGlobalStyle)`
  /** Variable fonts */
  @font-face {
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 100 700;
    src: url(${require('@a1s/theme/dist/RobotoMono-VariableFont_wght.woff2')}) format('woff2-variations'),
      url(${require('@a1s/theme/dist/RobotoMono-VariableFont_wght.woff')}) format('woff-variations');
  }

  @font-face {
    font-family: 'Roboto Mono';
    font-style: italic;
    font-weight: 100 700;
    src: url(${require('@a1s/theme/dist/RobotoMono-Italic-VariableFont_wght.woff2')}) format('woff2-variations'),
      url(${require('@a1s/theme/dist/RobotoMono-Italic-VariableFont_wght.woff')}) format('woff-variations');
  }

  /** Traditional, static weight fonts */

  @font-face {
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 100;
    src: url(${require('@a1s/theme/dist/RobotoMono-Thin.woff2')}) format('woff2'),
      url(${require('@a1s/theme/dist/RobotoMono-Thin.woff')}) format('woff');
  }

  @font-face {
    font-family: 'Roboto Mono';
    font-style: italic;
    font-weight: 100;
    src: url(${require('@a1s/theme/dist/RobotoMono-Thin.woff2')}) format('woff2'),
      url(${require('@a1s/theme/dist/RobotoMono-Thin.woff')}) format('woff');
  }

  @font-face {
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 200;
    src: url(${require('@a1s/theme/dist/RobotoMono-ExtraLight.woff2')}) format('woff2'),
      url(${require('@a1s/theme/dist/RobotoMono-ExtraLight.woff')}) format('woff');
  }

  @font-face {
    font-family: 'Roboto Mono';
    font-style: italic;
    font-weight: 200;
    src: url(${require('@a1s/theme/dist/RobotoMono-ExtraLight.woff2')}) format('woff2'),
      url(${require('@a1s/theme/dist/RobotoMono-ExtraLight.woff')}) format('woff');
  }

  @font-face {
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 300;
    src: url(${require('@a1s/theme/dist/RobotoMono-Light.woff2')}) format('woff2'),
      url(${require('@a1s/theme/dist/RobotoMono-Light.woff')}) format('woff');
  }

  @font-face {
    font-family: 'Roboto Mono';
    font-style: italic;
    font-weight: 300;
    src: url(${require('@a1s/theme/dist/RobotoMono-Light.woff2')}) format('woff2'),
      url(${require('@a1s/theme/dist/RobotoMono-Light.woff')}) format('woff');
  }

  @font-face {
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 400;
    src: url(${require('@a1s/theme/dist/RobotoMono-Regular.woff2')}) format('woff2'),
      url(${require('@a1s/theme/dist/RobotoMono-Regular.woff')}) format('woff');
  }

  @font-face {
    font-family: 'Roboto Mono';
    font-style: italic;
    font-weight: 400;
    src: url(${require('@a1s/theme/dist/RobotoMono-Italic.woff2')}) format('woff2'),
      url(${require('@a1s/theme/dist/RobotoMono-Italic.woff')}) format('woff');
  }

  @font-face {
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 500;
    src: url(${require('@a1s/theme/dist/RobotoMono-Medium.woff2')}) format('woff2'),
      url(${require('@a1s/theme/dist/RobotoMono-Medium.woff')}) format('woff');
  }

  @font-face {
    font-family: 'Roboto Mono';
    font-style: italic;
    font-weight: 500;
    src: url(${require('@a1s/theme/dist/RobotoMono-Medium.woff2')}) format('woff2'),
      url(${require('@a1s/theme/dist/RobotoMono-Medium.woff')}) format('woff');
  }

  @font-face {
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 600;
    src: url(${require('@a1s/theme/dist/RobotoMono-SemiBold.woff2')}) format('woff2'),
      url(${require('@a1s/theme/dist/RobotoMono-SemiBold.woff')}) format('woff');
  }

  @font-face {
    font-family: 'Roboto Mono';
    font-style: italic;
    font-weight: 600;
    src: url(${require('@a1s/theme/dist/RobotoMono-SemiBold.woff2')}) format('woff2'),
      url(${require('@a1s/theme/dist/RobotoMono-SemiBold.woff')}) format('woff');
  }

  @font-face {
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 700;
    src: url(${require('@a1s/theme/dist/RobotoMono-Bold.woff2')}) format('woff2'),
      url(${require('@a1s/theme/dist/RobotoMono-Bold.woff')}) format('woff');
  }

  @font-face {
    font-family: 'Roboto Mono';
    font-style: italic;
    font-weight: 700;
    src: url(${require('@a1s/theme/dist/RobotoMono-Bold.woff2')}) format('woff2'),
      url(${require('@a1s/theme/dist/RobotoMono-Bold.woff')}) format('woff');
  }
`;
var _default = FontStack;
exports.default = _default;