// @flow

import { get } from 'lodash';
// $FlowFixMe
import React, { useContext } from 'react';
import { useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';

import { maliciousTargetsRecord, maliciousTargetsQuery } from './dataTypesAndUtils';

import { DaysBackContext } from 'screens/App';
import BlockHeader from 'ui/molecules/BlockHeader';
import ContentBlock from 'ui/molecules/ContentBlock';
import ProgressTable from 'ui/molecules/ProgressTable';
import ProgressTablePlaceholder from 'ui/molecules/ProgressTablePlaceholder';
import withDataHandler from 'utils/withDataHandler';

const ProgressTableWithDataHandler = withDataHandler({
  empty: <ProgressTablePlaceholder />,
})(ProgressTable);

export default function TopMaliciousRecipients() {
  const { t } = useTranslation('email');
  const { currentInterval } = useContext(DaysBackContext);
  const { data: maliciousData, error, loading } = useQuery(maliciousTargetsQuery, { variables: { currentInterval } });
  const { maliciousTargetedRecipients: data } = get(maliciousData, 'maliciousTargets', maliciousTargetsRecord);

  return (
    <ContentBlock
      dataTestId="top-targeted-recipients-component"
      top={
        <>
          <BlockHeader title={t('topMaliciousTargets')} />
          <ProgressTableWithDataHandler
            /* eslint-disable no-shadow */
            // eslint-disable-next-line no-unused-vars
            calculatePercent={(data, [_, number]) =>
              Number(number) / data.reduce((a, b) => Number(a) + Number(b[1]), 0)
            }
            cellWidths={['50%', '50%']}
            data={dataForGraphTable(data)}
            isEmpty={() => loading || error || !data || data.length === 0}
            isError={error}
            isLoading={loading}
            tint
          />
        </>
      }
    />
  );
}

//
// Private functions
// -------------------------------------------------------------------------------------------------
const dataForGraphTable = (data) => {
  if (!data) return [];
  return data.map((row) => [row.address, row.count]);
};
