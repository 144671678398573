// @flow

import { subDays } from 'date-fns';
import { loader } from 'graphql.macro';
import { get } from 'lodash';

// $FlowFixMe
import React, { useContext, useMemo, useRef, useState } from 'react';

import { useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';

import ResultsTable from './ResultsTable';

import { DaysBackContext } from 'screens/App';
import { pathBuilder } from 'screens/App/Header/hooks';
import Button from 'ui/atoms/Button';

import ConditionalRender from 'ui/atoms/ConditionalRender';
import Loading from 'ui/atoms/Loading';
import SearchInput from 'ui/atoms/SearchInput';
import BlockHeader from 'ui/molecules/BlockHeader';
import ContentBlock from 'ui/molecules/ContentBlock';
import NoData from 'ui/molecules/NoData';
import Toast from 'ui/molecules/Toast';
import Split from 'ui/templates/Split';
import { utf8ToBase64 } from 'utils/util';

export const unisearchQuarantineSearchQuery = loader('./queries/UnisearchQuarantineSearch.graphql');

const QUARANTINE_QUERY = 'nondelivery_drop_by_domain_configuration';

export default function SecondCard() {
  const { currentInterval } = useContext(DaysBackContext);
  const [loadingMore, setLoadingMore] = useState(false);
  const [searchFilter, setSearchFilter] = useState();
  const { t } = useTranslation('common');

  const searchInputRef = useRef(null);

  const date = useMemo(() => new Date(), []);

  const {
    data: unisearchQuarantineData,
    error: adminQuarantineError,
    fetchMore: unisearchFetchMore,
    loading: adminQuarantineLoading,
  } = useQuery(unisearchQuarantineSearchQuery, {
    fetchPolicy: 'network-only',
    variables: {
      enable_redress: true,
      end: date,
      limit: 25,
      offset: 0,
      pathBuilder,
      search: utf8ToBase64([QUARANTINE_QUERY, searchFilter].join(' ')),
      start: subDays(date, currentInterval),
    },
  });

  const adminQuarantineResults = get(unisearchQuarantineData, 'unisearchResults.messages', []);

  async function loadMore() {
    setLoadingMore(true);

    try {
      await unisearchFetchMore({
        updateQuery: (prev, { fetchMoreResult }) => {
          setLoadingMore(false);
          if (!fetchMoreResult) return prev;

          return {
            unisearchResults: {
              messages: [
                ...(prev.unisearchResults.messages || []),
                ...(fetchMoreResult.unisearchResults.messages || []),
              ],
              __typename: 'UnisearchQuarantineSearchResult',
            },
          };
        },
        variables: {
          limit: 15,
          offset: adminQuarantineResults.length,
        },
      });
    } catch (e) {
      // check Sentry for potential errors
    }
  }

  const handleFilterInputKeyUp = (event) => {
    if (event.key === 'Enter') setSearchFilter(event.target.value);
  };

  const handleFilterButtonClick = (e) => {
    e.preventDefault();
    setSearchFilter(searchInputRef.current.ref.value);
  };

  const hasData =
    !adminQuarantineError &&
    !adminQuarantineLoading &&
    unisearchQuarantineData &&
    unisearchQuarantineData.unisearchResults;

  return (
    <>
      <ContentBlock
        top={
          <>
            <BlockHeader
              action={
                <Split>
                  <SearchInput
                    expandTo={450}
                    onClear={() => setSearchFilter('')}
                    onKeyUp={handleFilterInputKeyUp}
                    ref={searchInputRef}
                    small
                  />
                  <Button onClick={handleFilterButtonClick}>{t('filter')}</Button>
                </Split>
              }
              dataTestId="admin-quarantine-table-header"
              title={t('email:adminQuarantine')}
            />
          </>
        }
        dataTestId="admin-quaranatine-table"
        zebraStripe={false}
      >
        <ConditionalRender condition={!adminQuarantineLoading && (!hasData || adminQuarantineError)}>
          <NoData />
        </ConditionalRender>
        <ConditionalRender condition={adminQuarantineLoading}>
          <Loading />
        </ConditionalRender>
        <ConditionalRender condition={hasData}>
          <ResultsTable data={adminQuarantineResults} loadMore={loadMore} />
        </ConditionalRender>
      </ContentBlock>
      <Toast open={loadingMore} variant="success">
        {t('loadingMore')}
      </Toast>
    </>
  );
}
