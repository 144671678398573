"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sizes = void 0;

var _space = require("./space");

const sizes = { ..._space.space,
  none: 'none',
  0: '0rem',
  '2xs': '2rem',
  xs: '4rem',
  sm: '8rem',
  md: '12rem',
  lg: '24rem',
  xl: '36rem',
  '2xl': '42rem',
  '3xl': '48rem',
  '4xl': '56rem',
  '5xl': '64rem',
  '6xl': '80rem',
  '7xl': '120rem',
  full: '100%',
  min: 'min-content',
  max: 'max-content',
  prose: '72ch'
};
exports.sizes = sizes;