// @flow

// $FlowFixMe
import { useContext, useEffect, useState } from 'react';

import { GlobalSearchContext } from 'screens/App';
import type { SearchParams, SearchType } from 'screens/App/Header/MultiSearch';

export default function useGlobalSearch(searchType: SearchType) {
  const { globalSearchConnector } = useContext(GlobalSearchContext);
  const [searchTypeName, setSearchTypeName] = useState(null);

  useEffect(() => {
    setSearchTypeName(searchType);
  }, [searchType]);

  const performSearch = (params: SearchParams) => {
    if (typeof globalSearchConnector === 'function') globalSearchConnector(searchTypeName, params);
  };

  return (params: SearchParams) => performSearch(params);
}
