// @flow

import { rem, rgba } from 'polished';
// $FlowFixMe
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

//
// Constants
// -------------------------------------------------------------------------------------------------

const SUGGESTION_HEIGHT = 35;

//
// Styled components
// -------------------------------------------------------------------------------------------------

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.ghostWhite};
  border: ${({ theme }) => theme.colors.glitter} solid ${rem(1)};
  border-radius: ${rem(6)};
  box-shadow: ${({ theme }) => rgba(theme.colors.jet, 0.08)} 0 ${rem(4)} ${rem(8)};
  float: left;
  max-height: 50vh;
  right: ${rem(0)};
  position: relative;
  margin-top: ${rem(-5)};
  overflow-x: hidden;
  overflow-y: auto;
  transition: opacity 150ms ${({ theme }) => theme.timing.easeOutCirc},
    transform 150ms ${({ theme }) => theme.timing.easeOutBack}, visibility 0s linear 0s;
  width: 100%;
  z-index: 998;

  &[aria-hidden='true'] {
    opacity: 0;
    transform: translate3d(0, ${rem(-8)}, 0);
    transition: opacity 150ms ${({ theme }) => theme.timing.easeOutCirc},
      transform 150ms ${({ theme }) => theme.timing.easeOutBack}, visibility 0s linear 150ms;
    visibility: hidden;
  }
`;

const HelpText = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-top: 1px solid ${({ theme }) => theme.colors.antiFlashWhite};
  bottom: 0;
  font-family: 'roboto mono', monospace;
  font-size: ${rem(12)};
  font-weight: 500;
  letter-spacing: ${rem(0.43)};
  padding: ${({ theme }) => rem(theme.spacing.sm)};
  position: sticky;
  width: 100%;
  opacity: 0.85;

  span {
    background-color: ${({ theme }) => theme.colors.dimGray};
    border: 1px solid ${({ theme }) => theme.colors.dimGray};
    border-radius: ${rem(4)};
    color: ${({ theme }) => theme.colors.ghostWhite};
    padding: 0 ${({ theme }) => rem(theme.spacing.xs)};
    margin: 0;
  }
`;

const Loading = styled.span.attrs({ children: '.......' })`
  animation: type 750ms linear 0s infinite;
  color: inherit;
  display: block;
  font-size: ${rem(12)};
  font-weight: 700;
  letter-spacing: ${rem(1)};
  overflow: hidden;
  padding: ${({ theme }) => rem(theme.spacing.sm)};
  white-space: no-wrap;
  width: ${rem(25)};

  @keyframes type {
    from {
      width: 0;
    }
  }
`;

const Suggestion = styled.div`
  align-items: center;
  border-top: ${({ theme }) => theme.colors.glitter} solid ${rem(1)};
  color: ${({ theme }) => theme.colors.grayBlue};
  cursor: default;
  font-size: ${rem(12)};
  font-weight: 700;
  display: flex;
  overflow: hidden;
  padding: ${({ theme }) => rem(theme.spacing.sm)};
  text-overflow: ellipsis;
  white-space: nowrap;

  &:first-child {
    border-top: 0;
  }

  svg {
    flex-shrink: 0;
    height: ${rem(16)};
    margin-right: ${(p) => rem(p.theme.spacing.sm)};
    width: ${rem(16)};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.darkSlateGray};

    svg path {
      fill: ${({ theme }) => theme.colors.darkSlateGray};
    }
  }

  &[aria-selected='true'] {
    background-color: ${({ theme }) => theme.colors.grayBlue};
    color: ${(p) => p.theme.colors.ghostWhite};

    svg path {
      fill: ${({ theme }) => theme.colors.ghostWhite};
    }
  }
`;

//
// Main component
// -------------------------------------------------------------------------------------------------

interface Props {
  loading?: boolean;
  onSelect?: (suggestion: string) => void;
  suggestions?: ?(string[]);
}

export default function SearchSuggestions({ loading = false, onSelect, suggestions }: Props) {
  const containerRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const handleKeyDown = useCallback(
    (event: any) => {
      if (!!containerRef.current && !!suggestions) {
        const scrollStart = Math.round(containerRef.current.offsetHeight / SUGGESTION_HEIGHT);

        if (event.key === 'ArrowDown') {
          if (selectedIndex !== null) {
            if (selectedIndex === 0) containerRef.current.scrollTop = 0;
            if (selectedIndex >= scrollStart - 3) containerRef.current.scrollTop += SUGGESTION_HEIGHT;
            setSelectedIndex(suggestions.length > selectedIndex + 1 ? selectedIndex + 1 : suggestions.length - 1);
          } else {
            setSelectedIndex(0);
          }
        } else if (event.key === 'ArrowUp') {
          if (selectedIndex <= suggestions.length - scrollStart + 2)
            containerRef.current.scrollTop -= SUGGESTION_HEIGHT;
          setSelectedIndex(selectedIndex && selectedIndex > 0 ? selectedIndex - 1 : 0);
        } else if (event.key === 'Enter') {
          if (onSelect) onSelect(suggestions[selectedIndex]);
        }
      }
    },
    [onSelect, selectedIndex, suggestions]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  useEffect(() => setSelectedIndex(null), [suggestions]);

  return (
    <Container ref={containerRef}>
      {loading && <Loading />}

      {suggestions && (
        <>
          {suggestions.map((suggestion, index) => (
            <Suggestion
              aria-selected={index === selectedIndex}
              key={suggestion}
              onClick={() => onSelect && onSelect(suggestion)}
            >
              {suggestion}
            </Suggestion>
          ))}
          <HelpText>
            &darr;&uarr; to navigate &crarr; to select <span>esc</span> to exit
          </HelpText>
        </>
      )}
    </Container>
  );
}
