"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.tokenVariant = tokenVariant;
exports.tokenize = tokenize;

/**
 * Helper function for adding all of the theme's tokens of a certain type as a Stitches variant so they can be
 * used as a prop value for the component.
 * @param css - the CSS property to assign the variant to
 * @param tokens - the tokens from the theme that can be assigned to the CSS property in the first argument
 * @param scope - Optional additional scope for the variant
 * @param scale - Optional additional theme scale for the token
 */
function tokenVariant(css, tokens, scope, scale = '') {
  const names = Object.keys(tokens);
  return names.reduce((acc, token) => {
    if (scope) return { ...acc,
      [token]: {
        [scope]: {
          [css]: `${scale}$${token}`
        }
      }
    };
    return { ...acc,
      [token]: {
        [css]: `${scale}$${token}`
      }
    };
  }, {});
}
/**
 * Returns the given value as a prefix theme token.
 *
 *      tokenize("blue500") // => "$blue500"
 *
 * @param value - value to tokenize
 * @param prefix - override the prefix of the token
 * @returns
 */


function tokenize(value, prefix = '$') {
  return `${prefix}${value}`;
}