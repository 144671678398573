"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initializer = initializer;
exports.reducer = reducer;

var _fuse = _interopRequireDefault(require("fuse.js"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
// Component state management
// -------------------------------------------------------------------------------------------------

/**
 * Lazily initialize's the `Dropdown` component's state. This function is meant to be used as the 3rd argument of
 * React's `useReducer` hook.
 */
function initializer(options) {
  return {
    allOptions: options,
    highlightedFilteredOptionIndex: 0,
    filteredOptions: options,
    fuse: new _fuse.default(options, {
      keys: ['text'],
      threshold: 0.2
    }),
    isExpanded: false,
    isPlaceholderVisible: true,
    searchText: '',
    selectedKeys: []
  };
}
/**
 * Reducer function for `Dropdown` component's state.
 */


function reducer(state, action) {
  switch (action.type) {
    case 'change-search-text':
      if (action.payload.length === 0) {
        return { ...state,
          filteredOptions: state.allOptions,
          highlightedFilteredOptionIndex: findNextUnselected(state.filteredOptions, state.selectedKeys, 0),
          isPlaceholderVisible: state.selectedKeys.length === 0,
          searchText: ''
        };
      }

      return { ...state,
        filteredOptions: state.fuse.search(action.payload).map(r => r.item),
        highlightedFilteredOptionIndex: findNextUnselected(state.filteredOptions, state.selectedKeys, 0),
        isPlaceholderVisible: false,
        searchText: action.payload
      };

    case 'collapse-options':
      return { ...state,
        isExpanded: false,
        isPlaceholderVisible: state.selectedKeys.length === 0,
        searchText: ''
      };

    case 'expand-options':
      return { ...state,
        highlightedFilteredOptionIndex: findNextUnselected(state.filteredOptions, state.selectedKeys, 0),
        filteredOptions: state.allOptions,
        isExpanded: true
      };

    case 'highlight-next-option':
      if (state.highlightedFilteredOptionIndex !== state.filteredOptions.length - 1) {
        return { ...state,
          highlightedFilteredOptionIndex: findNextUnselected(state.filteredOptions, state.selectedKeys, state.highlightedFilteredOptionIndex + 1)
        };
      }

      return state;

    case 'highlight-previous-option':
      if (state.highlightedFilteredOptionIndex > 0) {
        return { ...state,
          highlightedFilteredOptionIndex: findPreviousUnselected(state.filteredOptions, state.selectedKeys, state.highlightedFilteredOptionIndex - 1)
        };
      }

      return state;

    case 'highlight-top-option':
      return { ...state,
        highlightedFilteredOptionIndex: findNextUnselected(state.filteredOptions, state.selectedKeys, 0)
      };
      break;

    case 'set-selected-keys':
      return { ...state,
        filteredOptions: state.allOptions,
        highlightedFilteredOptionIndex: findNextUnselected(state.filteredOptions, action.payload, 0),
        isPlaceholderVisible: action.payload.length === 0,
        searchText: '',
        selectedKeys: action.payload
      };

    default:
      return state;
  }
} //
// Private functions
// -------------------------------------------------------------------------------------------------


function findNextUnselected(options, selected, index) {
  const current = options[index];
  if (!current) return index;
  if (!selected.includes(current.key)) return index; // Make sure that if we've somehow arrowed passed the last item of the list that we back up
  // and select the last available.

  if (index >= options.length - 1) return findPreviousUnselected(options, selected, index - 1);
  return findNextUnselected(options, selected, index + 1);
}

function findPreviousUnselected(options, selected, index) {
  const current = options[index];
  if (!current) return index;
  if (!selected.includes(current.key)) return index; // Make sure the if we've arrowed passed the first item of the list the go forward through the options again
  // and select the first available.

  if (index < 0) return findNextUnselected(options, selected, index + 1);
  return findPreviousUnselected(options, selected, index - 1);
}