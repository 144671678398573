import { Cluster, Stack, Text } from '@a1s/ui';
import { format, parseISO } from 'date-fns';
import { TFunction } from 'i18next';
import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { Badge, Details, IconBackground } from '..';

import { defang } from '../../../shared/dataTypeAndUtils';

import {
  HighlightDataType as MailviewHighlightData,
  HighlightDataLinkType,
  SearchResultRow,
  ValidationType,
  RedressOperationTypes,
} from '../../types';
import { matchColorAndDisposition, matchColorAndThreat, threatToPascalCase } from '../common';
import { ReactComponent as CheckSVG } from '../StatusCell/check.svg';
import { ReactComponent as ErrorSVG } from '../StatusCell/error.svg';
import { ReactComponent as NullSVG } from '../StatusCell/na.svg';
import { ReactComponent as TriangleSVG } from '../StatusCell/triangle.svg';

import { StyledList, ThreatTypeLink } from './styled';

import { InlineTable } from 'ui-new';

import MarkedDown from 'ui/molecules/MarkedDown';

//
// Main container
// -------------------------------------------------------------------------------------------------

interface Props {
  isBenign?: boolean;
  loading: boolean;
  mailviewData?: MailviewHighlightData;
  searchInfo?: SearchResultRow;
}

export function DetailMetadata({ isBenign, loading, mailviewData, searchInfo }: Props) {
  const { t } = useTranslation('unisearch');

  const links = mailviewData?.links || [{ text: '-', href: '-' }];
  const senderInfo = mailviewData?.senderInfo || {
    ip: '_',
    asName: '_',
    asNumber: 0,
    geo: '_',
    pld: '_',
  };
  const { ip, asName, asNumber, geo, pld } = senderInfo;
  const unformattedSysNumber = asNumber && asNumber.toString().split(',').join();

  const threatCategories = mailviewData?.threatCategories || [];

  const validation = mailviewData?.validation || { dkim: 'none', dmarc: 'none', spf: 'none' };
  const { dkim, dmarc, spf } = validation;

  const {
    clientRecipients,
    finalDisposition = 'NONE',
    from,
    fromName,
    isQuarantined,
    messageId,
    redressedActions = [],
    subject,
    ts,
  } = searchInfo || {};

  const lastRedressedAction: string = redressedActions?.[0]?.operation;

  return (
    <Stack gap="8">
      <InlineTable css={{ width: 'fit-content' }}>
        <tbody>
          <InlineTable.Row>
            <InlineTable.Cell>
              <HeaderText>{t('from')}</HeaderText>
            </InlineTable.Cell>
            <InlineTable.Cell>
              <CellText>{`${fromName || ''} <${from}>`}</CellText>
            </InlineTable.Cell>
          </InlineTable.Row>

          <InlineTable.Row>
            <InlineTable.Cell colSpan={2} css={{ height: '$space$3' }} />
          </InlineTable.Row>

          <InlineTable.Row>
            <InlineTable.Cell>
              <HeaderText>{t('subject')}</HeaderText>
            </InlineTable.Cell>
            <InlineTable.Cell>
              <CellText>{subject}</CellText>
            </InlineTable.Cell>
          </InlineTable.Row>

          <InlineTable.Row>
            <InlineTable.Cell>
              <HeaderText>{t('date')}</HeaderText>
            </InlineTable.Cell>
            <InlineTable.Cell>
              <CellText>{formatTimestamp(ts)}</CellText>
            </InlineTable.Cell>
          </InlineTable.Row>

          <InlineTable.Row>
            <InlineTable.Cell>
              <HeaderText>{t('to')}</HeaderText>
            </InlineTable.Cell>
            <InlineTable.Cell>
              <CellText>{clientRecipients?.join(', ') || t('undisclosedRecipients')}</CellText>
            </InlineTable.Cell>
          </InlineTable.Row>

          <InlineTable.Row>
            <InlineTable.Cell>
              <HeaderText>{t('messageId')}</HeaderText>
            </InlineTable.Cell>
            <InlineTable.Cell allowBreaks>
              <CellText>{messageId}</CellText>
            </InlineTable.Cell>
          </InlineTable.Row>
        </tbody>
      </InlineTable>

      <InlineTable css={{ width: 'fit-content' }} gap="0 $space$4">
        <tbody>
          <InlineTable.Row>
            <InlineTable.Cell>
              <HeaderText>{t('disposition')}</HeaderText>
            </InlineTable.Cell>
            <InlineTable.Cell>
              <Badge color={matchColorAndDisposition(finalDisposition)}>{t(finalDisposition)}</Badge>
            </InlineTable.Cell>
          </InlineTable.Row>
          <InlineTable.Row>
            <InlineTable.Cell>
              <HeaderText>{t('status')}</HeaderText>
            </InlineTable.Cell>
            <RenderBadge color="gray500" value={isQuarantined ? t('quarantined') : t('delivered')} />
            {!!lastRedressedAction && <RenderBadge color="gray500" value={t(lastRedressedAction)} />}
          </InlineTable.Row>
          <InlineTable.Row>
            <InlineTable.Cell preventBreaks>
              <HeaderText>{t('threatType')}</HeaderText>
            </InlineTable.Cell>
            <InlineTable.Cell>
              <Cluster gap="1">
                <RenderThreatCategories threatCategories={threatCategories} />
              </Cluster>
            </InlineTable.Cell>
          </InlineTable.Row>

          {isBenign ? null : (
            <InlineTable.Row>
              <InlineTable.Cell>
                <HeaderText>{t('Validation')}</HeaderText>
              </InlineTable.Cell>
              <InlineTable.Cell valign="bottom">
                <Cluster gap="8" align="center">
                  <MatchValidationIcon label="SPF" val={spf} />
                  <MatchValidationIcon label="DKIM" val={dkim} />
                  <MatchValidationIcon label="DMARC" val={dmarc} />
                </Cluster>
              </InlineTable.Cell>
            </InlineTable.Row>
          )}
        </tbody>
      </InlineTable>

      {isBenign ? null : (
        <Details title={t('detectionDetails:senderDetails')}>
          <InlineTable>
            <tbody>
              <InlineTable.Row>
                <InlineTable.Cell>
                  <HeaderText>{t('ipAddress')}</HeaderText>
                </InlineTable.Cell>
                <InlineTable.Cell>
                  <Text.Loadable loading={loading} placeholder="xxx.xxx.xxx.xxx" {...textProps}>
                    {ip}
                  </Text.Loadable>
                </InlineTable.Cell>
              </InlineTable.Row>

              <InlineTable.Row>
                <InlineTable.Cell>
                  <HeaderText>{t('country')}</HeaderText>
                </InlineTable.Cell>
                <InlineTable.Cell>
                  <Text.Loadable loading={loading} placeholder="-/-/-" {...textProps}>
                    {geo}
                  </Text.Loadable>
                </InlineTable.Cell>
              </InlineTable.Row>

              <InlineTable.Row>
                <InlineTable.Cell>
                  <HeaderText>{t('regDomain')}</HeaderText>
                </InlineTable.Cell>
                <InlineTable.Cell>
                  <Text.Loadable loading={loading} placeholder="www.domain.com" {...textProps}>
                    {pld}
                  </Text.Loadable>
                </InlineTable.Cell>
              </InlineTable.Row>
            </tbody>
          </InlineTable>
          <InlineTable gap="0 $space$4">
            <tbody>
              <InlineTable.Row>
                <InlineTable.Cell>
                  <HeaderText>{t('autonomousSysName')}</HeaderText>
                </InlineTable.Cell>
                <InlineTable.Cell>
                  <Text.Loadable loading={loading} placeholder="NAME" {...textProps}>
                    {asName}
                  </Text.Loadable>
                </InlineTable.Cell>
              </InlineTable.Row>
              <InlineTable.Row>
                <InlineTable.Cell>
                  <HeaderText>{t('autonomousSysNumber')}</HeaderText>
                </InlineTable.Cell>
                <InlineTable.Cell>
                  <Text.Loadable loading={loading} placeholder="xxxxx" {...textProps}>
                    {unformattedSysNumber}
                  </Text.Loadable>
                </InlineTable.Cell>
              </InlineTable.Row>
            </tbody>
          </InlineTable>
        </Details>
      )}

      {isBenign || !links || links.length === 0 ? null : (
        <Details title={t('email:links')}>
          <InlineTable gap="0 $space$4">
            <tbody>
              <InlineTable.Row>
                <InlineTable.Cell css={{ wordBreak: 'break-word' }}>
                  <StyledList>
                    <RenderLinks links={links} loading={loading} textProps={textProps} />
                  </StyledList>
                </InlineTable.Cell>
              </InlineTable.Row>
            </tbody>
          </InlineTable>
        </Details>
      )}

      {redressedActions?.length > 0 && (
        <Details title={t('Action Log')}>
          <InlineTable gap="0 $space$2">
            <InlineTable.Row
              css={{
                backgroundColor: '$gray100',
                borderRadius: '$4',
                maxHeight: '100%',
                maxWidth: 300,
              }}
            >
              {getActionLogs(redressedActions).map((log: ActionType) => (
                <InlineTable.Row>
                  <InlineTable.Cell css={{ fontSize: '0.75em', fontWeight: 600, paddingLeft: '0.5em' }}>
                    {log.completedTimestamp}
                  </InlineTable.Cell>
                  <InlineTable.Cell css={{ fontSize: '0.75em', fontWeight: 400 }}>
                    {formatAction(log, t)}
                  </InlineTable.Cell>
                </InlineTable.Row>
              ))}
            </InlineTable.Row>
          </InlineTable>
        </Details>
      )}

      {threatCategories?.map((threat) => {
        return (
          <Details id={threat.value} title={threatToPascalCase(threat.value)}>
            <InlineTable gap="0 $space$2">
              <InlineTable.Row>
                <InlineTable.Cell>
                  <MarkedDown>{threat.description || t('noDescription')}</MarkedDown>
                </InlineTable.Cell>
              </InlineTable.Row>
            </InlineTable>
          </Details>
        );
      })}
    </Stack>
  );
}

//
// Private functions
// -------------------------------------------------------------------------------------------------

interface ActionType {
  completedTimestamp: string;
  folder: string;
  operation: RedressOperationTypes;
  requestedBy: string;
}

function formatAction(action: ActionType, t: TFunction) {
  const { folder, operation, requestedBy } = action;

  // this is temp before we get data from Michele - KLP
  if (!folder || !requestedBy) {
    return `${t(operation)}`;
  }

  if (operation === 'RETRACTION') {
    return `${t(operation)} ${t('from')} ${folder} ${t('by')} ${requestedBy}`;
  }

  return `${t(operation)} ${t('by')} ${requestedBy}`;
}

function formatTimestamp(timestamp?: string) {
  if (!timestamp) return null;
  const date = parseISO(timestamp);
  return format(date, 'LL/dd/yyyy kk:mm:ss O');
}

function getActionLogs(actionsArr: SearchResultRow['redressedActions'] = []) {
  return actionsArr.map((action) => ({
    completedTimestamp: format(new Date(action.completedTimestamp), 'LL/dd/yy@KKmm'),
    folder: action.properties.folder,
    operation: action.operation,
    requestedBy: action.properties.requestedBy,
  }));
}

//
// Private components
// -------------------------------------------------------------------------------------------------

const textProps = { color: '$gray600', font: 'sans', size: 'sm', stretch: 'ultraCondensed' } as const;
const headerTextProps = { ...textProps, transform: 'uppercase', weight: 'semibold' } as const;

function CellText({ children }: PropsWithChildren<{}>) {
  return <Text {...textProps}>{children}</Text>;
}

function HeaderText({ children }: PropsWithChildren<{}>) {
  return (
    <Text {...headerTextProps} css={{ paddingRight: '$3' }}>
      {children}
    </Text>
  );
}

interface RenderBadgeProps {
  color?: string;
  value: string;
}

function RenderBadge({ color = 'gray300', value }: RenderBadgeProps) {
  return (
    <InlineTable.Cell>
      <Badge color={color}>{value}</Badge>
    </InlineTable.Cell>
  );
}

interface RenderThreatCategoriesProps {
  threatCategories: MailviewHighlightData['threatCategories'];
}

function RenderThreatCategories({ threatCategories }: RenderThreatCategoriesProps) {
  const { t } = useTranslation('unisearch');

  if (threatCategories?.length === 0) return <Badge color="gray300">{t('none')}</Badge>;

  return (
    <>
      {threatCategories?.map((threat) => {
        const { color, name } = matchColorAndThreat(threat.value);

        return (
          <ThreatTypeLink href={`#${threat.value}`} key={threat.value}>
            <Badge color={color} key={threat.value}>
              {name}
            </Badge>
          </ThreatTypeLink>
        );
      })}
    </>
  );
}

interface LinksProps {
  links?: HighlightDataLinkType[];
  loading: boolean;
  textProps: { [key: string]: string };
}

function RenderLinks({ links = [], loading, textProps: props }: LinksProps) {
  return (
    <>
      {links?.map((link, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <li key={index}>
          <Text.Loadable loading={loading} placeholder="https://link.com" weight="medium" {...props}>
            {defang(link.href)}
          </Text.Loadable>
        </li>
      ))}
    </>
  );
}

//
// Private functions
// -------------------------------------------------------------------------------------------------

interface MatchValidationIconProps {
  label: 'DKIM' | 'DMARC' | 'SPF';
  val: ValidationType['dkim'] | ValidationType['dmarc'] | ValidationType['spf'] | string;
}

function MatchValidationIcon({ label, val }: MatchValidationIconProps) {
  const { t } = useTranslation('unisearch');

  if (val === null) {
    return (
      <Cluster align="center" css={{ flexFlow: 'nowrap' }} gap="2">
        <IconBackground color="#CCCCCC">
          <NullSVG />
        </IconBackground>
        <Text
          color="$gray600"
          css={{ fontWeight: '700' }}
          font="sans"
          size="sm"
          stretch="ultraCondensed"
          transform="uppercase"
        >
          {label}
        </Text>
        <Text.Title css={{ color: '$gray200' }} placeholder="-" {...textProps}>
          {t('na')}
        </Text.Title>
      </Cluster>
    );
  }
  if (['pass', 'success'].includes(val?.toLowerCase())) {
    return (
      <Cluster align="center" css={{ flexFlow: 'nowrap' }} gap="2">
        <IconBackground color="$green300">
          <CheckSVG />
        </IconBackground>
        <Text
          color="$gray600"
          css={{ fontWeight: '700' }}
          font="sans"
          size="sm"
          stretch="ultraCondensed"
          transform="uppercase"
        >
          {label}
        </Text>
        <Text.Title css={{ color: '$gray200' }} placeholder="-" {...textProps}>
          {t('passed')}
        </Text.Title>
      </Cluster>
    );
  }

  if (['error', 'fail', 'permerror', 'permfail', 'softfail', 'temperror', 'tempfail'].includes(val.toLowerCase())) {
    return (
      <Cluster align="center" css={{ flexFlow: 'nowrap' }} gap="2">
        <IconBackground color="$red300">
          <ErrorSVG />
        </IconBackground>
        <Text
          color="$gray600"
          css={{ fontWeight: '700' }}
          font="sans"
          size="sm"
          stretch="ultraCondensed"
          transform="uppercase"
        >
          {label}
        </Text>
        <Text.Title css={{ color: '$gray200' }} placeholder="-" {...textProps}>
          {t('failed')}
        </Text.Title>
      </Cluster>
    );
  }

  if (['mixed', 'neutral', 'none'].includes(val?.toLowerCase())) {
    return (
      <Cluster align="center" css={{ flexFlow: 'nowrap' }} gap="2">
        <IconBackground color="$orange300">
          <TriangleSVG />
        </IconBackground>
        <Text
          color="$gray600"
          css={{ fontWeight: '700' }}
          font="sans"
          size="sm"
          stretch="ultraCondensed"
          transform="uppercase"
        >
          {label}
        </Text>
        <Text.Title css={{ color: '$gray200' }} placeholder="-" {...textProps}>
          {t('none')}
        </Text.Title>
      </Cluster>
    );
  }

  return (
    <Cluster align="center" css={{ flexFlow: 'nowrap' }} gap="2">
      <IconBackground color="$orange300">
        <TriangleSVG />
      </IconBackground>
      <Text color="$gray600" font="sans" size="sm" stretch="ultraCondensed" transform="uppercase">
        {label}
      </Text>
      <Text.Title css={{ color: 'gray200' }} placeholder="-" {...textProps}>
        -
      </Text.Title>
    </Cluster>
  );
}
