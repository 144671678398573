import { css, Badge as BaseBadge } from '@a1s/ui';
import React, { ComponentProps } from 'react';

//
// Main component
// -------------------------------------------------------------------------------------------------

type BadgeProps = ComponentProps<typeof BaseBadge>;

/**
 * Use the same props as the `Badge` component from `@a1s/ui` but displays the badge in the style
 * made for the unified search design.
 */
export function Badge({ css: _css, ...props }: BadgeProps) {
  const styleProps = css({ fontSize: '0.75em', r: 0, whiteSpace: 'nowrap', width: 'min-content' });

  return <BaseBadge {...props} bg="white" className={styleProps()} />;
}
