import { Box, Button, Stack, Text } from '@a1s/ui';
import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { useQuery, QueryResult } from 'react-apollo';
import { Trans, useTranslation } from 'react-i18next';

import downloadEml from 'screens/App/DetectionSearchResults/utils';
import { usePermissions } from 'screens/Search/lib/permissions';

import { Dialog } from 'ui-new';

//
// Main component
// -------------------------------------------------------------------------------------------------

interface Props {
  emlPath: string | undefined;
}

export function RawEmail({ emlPath }: Props) {
  const userPermitted = usePermissions();
  const { t } = useTranslation('common');
  const { data, loading } = useRemoteData(emlPath);

  // Early return if user doesn't have permission
  if (!userPermitted)
    return (
      <Text font="sans" size="sm" stretch="ultraCondensed">
        {t('components:NoAccess.needAccessFromAdmin')}
      </Text>
    );

  if (!emlPath)
    return (
      <Text font="sans" size="sm" stretch="ultraCondensed">
        {t('unisearch:notAvailable')}
      </Text>
    );

  if (loading)
    return (
      <Text font="sans" size="sm" stretch="ultraCondensed">
        {t('loading')}
      </Text>
    );

  return (
    <Box px>
      <Stack gap>
        <DownloadEmlButton emlData={data} emlPath={emlPath} loading={loading} />
        <Text as="div" color="$gray700" css={{ lineHeight: 1, whiteSpace: 'pre' }} font="mono" size="xs">
          {data}
        </Text>
      </Stack>
    </Box>
  );
}

//
// Private components
// -------------------------------------------------------------------------------------------------
interface DownloadEmlButtonProps {
  emlPath: string;
  emlData?: string;
  loading: boolean;
}

function DownloadEmlButton({ emlPath, emlData, loading }: DownloadEmlButtonProps) {
  const [dialogVisible, setDialogVisible] = useState<boolean>(false);
  const { t } = useTranslation('unisearch');

  const handleDownloadButtonPress = () => {
    setDialogVisible(true);
  };

  const handleCloseDialog = () => {
    setDialogVisible(false);
  };

  const handleConfirm = () => {
    if (!emlData || !emlPath || loading) return;
    downloadEml(emlPath, emlData);
    setDialogVisible(false);
  };

  return (
    <>
      <Button appearance="primary" onPress={handleDownloadButtonPress}>
        {t('downloadEml')}
      </Button>
      <Dialog.Confirmation
        confirmButtonText={t('downloadEml')}
        message={
          <>
            <Trans i18nKey="downloadEmlConfirmation" ns="unisearch">
              <Text as="p" font="sans" size="md" stretch="ultraCondensed" weight="medium">
                The .eml format you&apos;re about to download contains a <Text weight="bold">malicious payload</Text>.
              </Text>
              <Text as="p" font="sans" size="md" stretch="ultraCondensed" weight="medium">
                We&apos;ve added a .infected extension to this file as a reminder. You can remove the extension to open
                the file but <Text weight="bold">exercise extreme caution</Text>!
              </Text>
            </Trans>
          </>
        }
        handleClose={handleCloseDialog}
        handleConfirm={handleConfirm}
        visible={dialogVisible}
      />
    </>
  );
}

//
// Private hooks
// -------------------------------------------------------------------------------------------------

const query = loader('./query.graphql');

interface HookResult {
  /**
   * The data that has been returned from the API
   */
  data?: string;

  /**
   * If there is a problem loading the data, the error information will be available as an error object
   */
  error: QueryResult['error'] | null;

  /**
   * Returns true if the data is currently being loaded
   */
  loading: boolean;
}

function useRemoteData(emlPath: string | undefined): HookResult {
  const { data, error, loading } = useQuery(query, {
    skip: !emlPath,
    variables: { path: emlPath, renderImages: false, waitSeconds: 300 },
  });

  if (error) return { data: undefined, error, loading: false };
  if (!data?.mail || !data?.mail?.email) return { data: undefined, error: null, loading };

  return { data: data.mail.email, error, loading };
}
