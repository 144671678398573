// @flow

import { rem } from 'polished';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

const StyledTimeDiv = styled.time`
  padding: 0 ${({ theme }) => rem(theme.spacing.sm)} 0 0;
  word-wrap: normal;
`;

type Props = {
  date: Date | string | number,
};

export default function FormatDate({ date }: Props) {
  const { t } = useTranslation('components');

  return <StyledTimeDiv>{t('Incident.dateSlashed', { date: new Date(date) })}</StyledTimeDiv>;
}
