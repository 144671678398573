// @flow

// $FlowFixMe
import React, { useEffect } from 'react';
import { ApolloProvider } from 'react-apollo';
import ReactGA from 'react-ga';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation, I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
// $FlowFixMe
import { StoreContext } from 'redux-react-hook';
import { ThemeProvider } from 'styled-components';

import client from './apollo';
import i18n from './config/i18next';
import { GlobalStyle } from './config/styling';
import theme from './config/theme';
import Router from './Router';
import store from './store';
import { CurrentUserProvider } from './utils/hooks/useCurrentUser';

import './setupAxios';

// Google Analytics GA IDs. This keeps preview.area1security.com views separated from horizon.area1security.com views
const DEMO_GA_ID = 'G-FPZG14R0X0';
const HORIZON_GA_ID = 'UA-132693716-1';

export default function App() {
  useEffect(() => {
    const { hostname } = window?.location;
    const gaId = hostname === 'preview.area1security.com' ? DEMO_GA_ID : HORIZON_GA_ID;
    ReactGA.initialize(gaId);
  }, []);

  return (
    <Provider store={store}>
      <ApolloProvider client={client}>
        <I18nextProvider i18n={i18n}>
          <HelmetProvider>
            <ThemeProvider theme={theme}>
              <StoreContext.Provider value={store}>
                <>
                  <Head />
                  <BrowserRouter>
                    <CurrentUserProvider>
                      <Router />
                    </CurrentUserProvider>
                  </BrowserRouter>
                </>
              </StoreContext.Provider>
            </ThemeProvider>
          </HelmetProvider>
        </I18nextProvider>
      </ApolloProvider>
    </Provider>
  );
}

//
// Private function
// -------------------------------------------------------------------------------------------------

function Head() {
  const { i18n: i18next } = useTranslation();

  return (
    <>
      <GlobalStyle />
      <Helmet>
        <html lang={i18next.language} />
        <title>Cloudflare Area 1 Security</title>
        <link href="https://use.typekit.net/vwe0tel.css" rel="preconnect stylesheet" />
        <link
          href="https://fonts.googleapis.com/css?family=Roboto+Mono:400,400i,500,500i,700,700i&display=swap"
          rel="preconnect stylesheet"
        />
      </Helmet>
    </>
  );
}
