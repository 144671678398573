import { downloadBlob, encodeBase64 } from '@a1s/lib';
import { formatISO, subDays } from 'date-fns';
import { loader } from 'graphql.macro';
import React, { useMemo, useState, ComponentProps } from 'react';
import { useApolloClient } from 'react-apollo';
import { useTranslation } from 'react-i18next';

import { IconButton } from '..';
import { detectionsToCSV } from '../../lib';
import { APIData as DetectionsData } from '../../views/DetectionSearch/ui/Results';

import { ModalHeader } from '.';

import { usePermissions } from 'screens/Search/lib/permissions';

//
// Load GraphQL Query
// -------------------------------------------------------------------------------------------------

export const query = loader('./queries/all.graphql');

//
// Main component
// -------------------------------------------------------------------------------------------------

interface CSVDownloadButtonProps {
  params: ComponentProps<typeof ModalHeader>['params'];
}

export default function CSVDownloadButton({ params }: CSVDownloadButtonProps) {
  const client = useApolloClient();
  const userPermitted = usePermissions();
  const [loading, setLoading] = useState(false);
  const date = useMemo(() => new Date(), []);

  const { daysBack, searchTerm, searchType } = params;

  const { t } = useTranslation('unisearch');

  async function handleClick() {
    if (!params.searchTerm) return;

    setLoading(true);

    const [start, end] = Array.isArray(daysBack) ? daysBack : [subDays(date, Number(daysBack)), date];

    const variables = {
      detections_only: searchType === 'detection-only',
      enable_redress: true,
      end,
      limit: 1000, // max limit per request
      search: encodeBase64(searchTerm),
      start,
    };

    type QueryResult = { unisearchResults: { messages: DetectionsData[] } };
    const { data } = await client.query<QueryResult>({ query, variables });

    const csv = detectionsToCSV(data.unisearchResults.messages);

    downloadBlob(new Blob([csv]), filename(searchTerm));

    setLoading(false);
  }

  if (!userPermitted) return null;

  return (
    <IconButton icon="xls" disabled={!searchTerm} onClick={handleClick} title={t('downloadCSV')} waiting={loading} />
  );
}

//
// Private function
// -------------------------------------------------------------------------------------------------

function filename(term: string): string {
  const timestamp = formatISO(new Date());

  if (term === '') return `${timestamp}.csv`;
  return `${timestamp}-${term}.csv`;
}
