"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.lineCSS = exports.gridCSS = exports.StyledCircle = exports.StyledBase = void 0;

var _styled = require("../../styled");

var _utils = require("../../utils");

//
// Styled components
// -------------------------------------------------------------------------------------------------
const StyledBase = (0, _styled.styled)('figure', {
  display: 'block'
});
exports.StyledBase = StyledBase;
const StyledCircle = (0, _styled.styled)('circle', {
  stroke: '$white',
  strokeWidth: 2,
  variants: {
    color: (0, _utils.tokenVariant)('fill', _styled.theme.colors)
  }
}); //
// Style classes
// -------------------------------------------------------------------------------------------------

exports.StyledCircle = StyledCircle;
const gridCSS = (0, _styled.css)({
  line: {
    stroke: '$gray300',
    strokeDasharray: '0, 4',
    strokeLinecap: 'round',
    strokeWidth: 1
  }
});
exports.gridCSS = gridCSS;
const lineCSS = (0, _styled.css)({
  strokeLinecap: 'round',
  strokeWidth: 2,
  variants: {
    color: (0, _utils.tokenVariant)('stroke', _styled.theme.colors),
    lineThickness: {
      normal: {
        strokeWidth: 2
      },
      thin: {
        strokeWidth: 1
      }
    }
  }
});
exports.lineCSS = lineCSS;