import { styled, Box, Button, Table } from '@a1s/ui';
import { useFeatureFlag } from '@marshall/hooks';
import { loader } from 'graphql.macro';
import hash from 'object-hash';
import React, { useEffect, useMemo, ComponentProps } from 'react';
import { useQuery, QueryResult } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { Waypoint } from 'react-waypoint';

import { fixMessagesTs, toVariables } from '../../../lib';
import { Dispositions, SearchResultRow } from '../../../types';
import {
  MetaCell,
  ModalHeader,
  ReasonCell,
  ReleaseButton,
  RenderNoData,
  RetractButton,
  RowWrapper,
  SectionHeader,
  StatusCell,
  ThreatCell,
} from '../../../ui';

import { Scrollable } from 'ui-new';

//
// Main component
// -------------------------------------------------------------------------------------------------

interface ResultsProps {
  checked?: Array<SearchResultRow['messageId']>;

  collapsed: boolean;

  /**
   * Sets the selected row in state to be shared with the <Detail /> component
   */
  onPressViewButton?: RowProps['onPressViewButton'];

  // eslint-disable-next-line  no-unused-vars
  onRowCheck?(checked: Array<SearchResultRow['messageId']>): void;

  // eslint-disable-next-line no-unused-vars
  onSearchFinished?(counts: ComponentProps<typeof SectionHeader>['searchCounts']): void;

  /**
   * The string to run the search query on.
   */
  search: ComponentProps<typeof ModalHeader>['params'];

  /**
   * The message ID of the message details currently selected
   */
  selectedMessageId?: string;
}

export function Results({
  checked = [],
  collapsed,
  onPressViewButton,
  onRowCheck,
  onSearchFinished,
  search,
  selectedMessageId,
}: ResultsProps) {
  const clawbackFeatureEnabled = useFeatureFlag('clawback');
  const { data, error, loading, fetchMore } = useRemoteData(search);

  const effectDependency = hash({ data });
  useEffect(() => {
    if (!onSearchFinished) return;
    if (!data?.length) return;

    const detections = data.filter((d) => d.finalDisposition?.toLowerCase() !== 'none');
    onSearchFinished({ total: data.length, detections: detections.length });
  }, [effectDependency]); // eslint-disable-line react-hooks/exhaustive-deps

  function handleCheck(id: string, isChecked: boolean) {
    if (!onRowCheck) return;

    if (isChecked) {
      onRowCheck([...checked, id]);
    } else {
      onRowCheck(checked.filter((i) => i !== id));
    }
  }

  function handleEnter() {
    if (data?.length && fetchMore) fetchMore();
  }

  const hasData = data && data.length > 0 && !loading && !error;

  return (
    <Box css={{ flexGrow: 1, height: '100%', minWidth: 470, position: 'relative' }}>
      <Scrollable>
        <Table>
          <tbody>
            {hasData && !loading ? (
              <>
                {data.map((row, index) => (
                  <Row
                    checked={checked.includes(row.messageId)}
                    clawbackFeatureEnabled={clawbackFeatureEnabled}
                    collapsed={collapsed}
                    data={row}
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${row.messageId}-${index}`}
                    onCheck={handleCheck}
                    onPressViewButton={onPressViewButton}
                    selectedMessageId={selectedMessageId}
                  />
                ))}
                <Waypoint onEnter={handleEnter} />
              </>
            ) : (
              <tr>
                <td>
                  <RenderNoData loading={loading} term={search.searchTerm} />
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Scrollable>
    </Box>
  );
}

//
// Private components
// -------------------------------------------------------------------------------------------------

interface BlankCellProps {
  hidden?: boolean;
}

function BlankCell({ hidden }: BlankCellProps) {
  return <Table.Cell border="none" css={{ display: hidden === true ? 'none' : undefined }} />;
}

interface RowProps {
  checked: ComponentProps<typeof MetaCell>['checked'];
  clawbackFeatureEnabled?: boolean;
  collapsed: ResultsProps['collapsed'];
  data: SearchResultRow;
  onCheck?: ComponentProps<typeof MetaCell>['onCheck'];
  onPressViewButton?: (details: SearchResultRow) => void; // eslint-disable-line no-unused-vars
  selectedMessageId?: string;
}

function Row({
  checked,
  clawbackFeatureEnabled,
  collapsed,
  data,
  onCheck,
  onPressViewButton,
  selectedMessageId,
}: RowProps) {
  const { t } = useTranslation('unisearch');

  const {
    clientRecipients,
    detectionReasons,
    finalDisposition,
    from,
    isQuarantined,
    messageId,
    phishSubmission,
    redressedActions,
    storedAt,
    subject,
    threatCatsBlocking = [],
    threatCatsSpam = [],
    threatCatsSuspicious = [],
    ts,
    validation,
  } = data;

  const isBenign = finalDisposition?.toLowerCase() === 'none';

  const onPressView = () => {
    if (onPressViewButton) onPressViewButton(data);
  };

  const onRowClick = (e: any) => {
    e.stopPropagation();
    if (collapsed && onPressViewButton) onPressViewButton(data);
  };

  const allFindings = Array.from(
    new Set([...(threatCatsBlocking || []), ...(threatCatsSpam || []), ...(threatCatsSuspicious || [])])
  );

  return (
    <RowWrapper onClick={onRowClick} selected={selectedMessageId === messageId}>
      <MetaCell
        checked={checked}
        from={from}
        messageId={messageId}
        onCheck={onCheck}
        subject={subject}
        timestamp={ts}
        to={clientRecipients}
      />
      <StatusCell
        disposition={finalDisposition}
        isQuarantined={isQuarantined}
        redressedActions={redressedActions}
        truncated={collapsed}
        validation={validation}
      />
      <RenderThreatCell collapsed={collapsed} dispositionType={finalDisposition} findings={allFindings} />
      <RenderReasonCell collapsed={collapsed} detectionReasons={detectionReasons} />
      <Table.Cell
        border="none"
        css={{ display: collapsed === true ? 'none' : undefined, maxWidth: 266, minWidth: '10%' }}
      >
        <ButtonWrapper>
          {isQuarantined && <ReleaseButton releaseParams={[{ clientRecipients, storedAt }]} />}
          {!phishSubmission && !isQuarantined && (
            <RetractButton
              clawbackFeatureEnabled={clawbackFeatureEnabled}
              retractParams={[{ clientRecipients, messageId }]}
            />
          )}
          <Button onPress={onPressView}>{t(isBenign ? 'viewDetails' : 'view')}</Button>
        </ButtonWrapper>
      </Table.Cell>
    </RowWrapper>
  );
}

//
// Private hooks
// -------------------------------------------------------------------------------------------------

export const AllMailQuery = loader('../queries/UnisearchAllMailSearchQuery.graphql');

interface HookResult {
  data?: SearchResultRow[];
  error: QueryResult['error'] | null;
  fetchMore?(): void;
  loading: boolean;
}

function useRemoteData(search: ResultsProps['search']): HookResult {
  const date = useMemo(() => new Date(), []);

  const { data, error, fetchMore, loading } = useQuery(AllMailQuery, {
    fetchPolicy: 'network-only',
    variables: toVariables(date, search),
  });

  if (error) return { data: undefined, error, fetchMore: undefined, loading: false };
  if (!data?.unisearchAllMailResults?.messages) return { data: undefined, error: null, fetchMore: undefined, loading };

  function more() {
    fetchMore({
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        return {
          unisearchAllMailResults: {
            messages: [
              // @ts-ignore
              ...(prev.unisearchAllMailResults.messages || []),
              // @ts-ignore
              ...(fetchMoreResult.unisearchAllMailResults.messages || []),
            ],
            __typename: 'UnisearchAllMailSearchResult',
          },
        };
      },
      variables: { limit: 50, offset: data?.unisearchAllMailResults?.messages.length },
    });
  }

  const results = fixMessagesTs(data.unisearchAllMailResults.messages);

  return { data: results, error, fetchMore: more, loading };
}

//
// Private functions
// -------------------------------------------------------------------------------------------------

interface ThreatCellProps {
  collapsed: ResultsProps['collapsed'];
  dispositionType: Dispositions;
  findings: string[];
}

function RenderThreatCell({ collapsed, dispositionType, findings }: ThreatCellProps) {
  if (dispositionType !== 'NONE') {
    return <ThreatCell findings={findings} hidden={collapsed} />;
  }

  return <BlankCell hidden={collapsed} />;
}

interface ReasonCellProps {
  collapsed: ResultsProps['collapsed'];
  detectionReasons: SearchResultRow['detectionReasons'];
}

function RenderReasonCell({ collapsed, detectionReasons }: ReasonCellProps) {
  if (detectionReasons.length > 0) {
    return <ReasonCell data={detectionReasons} hidden={collapsed} />;
  }

  return <BlankCell hidden={collapsed} />;
}

//
// Styled components
// -------------------------------------------------------------------------------------------------

const ButtonWrapper = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  float: 'right',
  gap: '$2',
  justifyContent: 'end',
  maxWidth: 180,
  width: '100%',
});
