import { Cluster, Stack, Text } from '@a1s/ui';
import { useFeatureFlag } from '@marshall/hooks';
import { format, parseISO } from 'date-fns';
import { get } from 'lodash';
import React, { useEffect, useMemo, useState, ComponentProps } from 'react';
import { useApolloClient } from 'react-apollo';
import { useTranslation } from 'react-i18next';

import { Checkbox, ModalHeader, ReleaseButton, RetractButton } from '..';
import { ReleaseParams, RetractParams, SearchResultRow } from '../../types';

//
// Main component
// -------------------------------------------------------------------------------------------------

type SearchCounts = Record<'detections' | 'total', number>;

interface SectionHeaderProps {
  dateRange: [string, string] | unknown;
  messageIds: Array<SearchResultRow['messageId']>;
  onSelectAll?: ComponentProps<typeof SelectAll>['onPress'];
  params: ComponentProps<typeof ModalHeader>['params'];
  searchCounts?: SearchCounts;
}

/**
 * Displays the header of each search section.
 */
export function SectionHeader({ dateRange, messageIds, onSelectAll, params, searchCounts }: SectionHeaderProps) {
  const clawbackFeatureEnabled = useFeatureFlag('clawback');
  const { releaseParams, retractParams } = useMessageData(messageIds);
  const [selectAll, setSelectAll] = useState(false);

  // Uncheck the search all button when a new search is run
  const deps = JSON.stringify(params);
  useEffect(() => {
    setSelectAll(false);
  }, [deps]);

  function handlePressed(all: boolean) {
    if (onSelectAll) onSelectAll(all);
    setSelectAll(all);
  }

  return (
    <Cluster align="center" justify="space-between">
      <Cluster gap="5">
        <SelectAll onPress={handlePressed} checked={selectAll} />
        <ReleaseButton appearance="faded" disabled={releaseParams.length === 0} releaseParams={releaseParams} />
        <RetractButton
          appearance="faded"
          clawbackFeatureEnabled={clawbackFeatureEnabled}
          disabled={retractParams.length === 0}
          retractParams={retractParams}
        />
      </Cluster>

      <Text as="div" color="$white" font="sans" size="sm" stretch="ultraCondensed" transform="uppercase" weight="light">
        <Stack justify="end">
          <DisplayCounts searchCounts={searchCounts} /> <DateRange dateRange={dateRange} />
        </Stack>
      </Text>
    </Cluster>
  );
}

//
// Private components
// -------------------------------------------------------------------------------------------------

function DateRange({ dateRange }: Pick<SectionHeaderProps, 'dateRange'>) {
  if (!isDateRange(dateRange)) return null;
  return (
    <Text css={{ opacity: 0.8, textAlign: 'right' }} weight="medium">
      {formatDateRange(dateRange)}
    </Text>
  );
}

function DisplayCounts({ searchCounts }: Pick<SectionHeaderProps, 'searchCounts'>) {
  const { t } = useTranslation('unisearch');

  if (!searchCounts) return null;

  if (searchCounts.total === searchCounts.detections)
    return (
      <Text css={{ textAlign: 'right' }} weight="semibold">
        {t('showingEmails', { count: searchCounts.total })}
      </Text>
    );

  return (
    <Text css={{ textAlign: 'right' }} weight="semibold">
      {t('showingEmails', { count: searchCounts.total })}{' '}
      <Text css={{ opacity: 0.8 }}>{t('showingEmailDetections', { count: searchCounts.detections })}</Text>
    </Text>
  );
}

interface SelectAllProps {
  checked: ComponentProps<typeof Checkbox>['checked'];
  // eslint-disable-next-line
  onPress?(shouldSelectAll: boolean): void;
}

function SelectAll({ checked = false, onPress }: SelectAllProps) {
  const { t } = useTranslation();

  return (
    <Cluster align="center" gap="2">
      <Checkbox checked={checked} onClick={() => onPress && onPress(!checked)} />
      <Text color="$white" font="roboto" stretch="ultraCondensed">
        {t('Select All')}
      </Text>
    </Cluster>
  );
}

//
// Private functions
// -------------------------------------------------------------------------------------------------

const DATE_FORMAT = 'LL/dd/yyyy kk:mm:ss O';
function formatDateRange([start, end]: [string, string]) {
  return `${format(parseISO(start), DATE_FORMAT)} – ${format(parseISO(end), DATE_FORMAT)}`;
}

function isDateRange(value: [string, string] | unknown): value is [string, string] {
  return Array.isArray(value);
}

//
// Private hooks
// -------------------------------------------------------------------------------------------------

type MessageCollection = Record<
  SearchResultRow['messageId'],
  Pick<SearchResultRow, 'clientRecipients' | 'isQuarantined' | 'storedAt'>
>;
interface ApolloRecordType {
  clientRecipients: {
    json: string[];
  };
  isQuarantined: boolean;
  messageId: string;
  storedAt: string;
}

function useMessageData(messageIds: Array<SearchResultRow['messageId']>): {
  releaseParams: ReleaseParams[];
  retractParams: RetractParams[];
} {
  const client = useApolloClient();

  const messages = useMemo(() => {
    const data: Record<string, ApolloRecordType | null> | null = get(client, 'cache.data.data');
    if (!data) return {};

    return Object.values(data)
      .filter((record) => {
        const id = record?.messageId;
        if (!id) return false;
        return messageIds.includes(id);
      })
      .reduce((acc, record) => {
        if (!record) return acc;
        const message = {
          clientRecipients: record.clientRecipients.json,
          isQuarantined: record.isQuarantined,
          storedAt: record.storedAt,
        };
        return { ...acc, [record.messageId]: message };
      }, {} as MessageCollection);
  }, [client, messageIds]);

  const releaseParams = useMemo(() => {
    return Object.entries(messages).reduce(
      (
        acc: { clientRecipients: string[]; storedAt: string }[],
        row: [string, { clientRecipients: string[]; isQuarantined: boolean; storedAt: string }]
      ) => {
        const [, { clientRecipients, isQuarantined, storedAt }] = row;
        // We only want to release messaages where `isQuarantined` is true.
        if (isQuarantined) return [...acc, ...[{ clientRecipients, storedAt }]];
        return acc;
      },
      []
    );
  }, [messages]);

  const retractParams = useMemo(() => {
    return Object.entries(messages).reduce(
      (
        acc: { clientRecipients: string[]; messageId: string }[],
        row: [string, { clientRecipients: string[]; isQuarantined: boolean; storedAt: string }]
      ) => {
        const [messageId, { clientRecipients, isQuarantined }] = row;
        // Only messages NOT in quarantine can be retracted - Jira AREA1-1322
        if (!isQuarantined) return [...acc, ...[{ clientRecipients, messageId }]];
        return acc;
      },
      []
    );
  }, [messages]);

  return { releaseParams, retractParams };
}
