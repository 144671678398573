// @flow

import { usePrevious, useWindowSize } from '@a1s/hooks';
import { useResizeObserver } from '@marshall/hooks';
import { loader } from 'graphql.macro';
import { throttle, truncate } from 'lodash';
import { rem } from 'polished';
// $FlowFixMe
import React, { useEffect, useReducer, useRef, useState, ReactNode } from 'react';
import { useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
// $FlowFixMe
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { VariableSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import styled, { css } from 'styled-components';

import EmlView from './EmlView';
import downloadEml from './utils';

import ConditionalRender from 'ui/atoms/ConditionalRender';
import MaxHeight from 'ui/atoms/MaxHeight';
import PaddedContent from 'ui/atoms/PaddedContent';
import DispositionBadge from 'ui/molecules/DispositionBadge';
import Drawer from 'ui/molecules/Drawer';
import FormatTime from 'ui/molecules/FormatTime';
import PopupMenu, { Group, Option } from 'ui/molecules/PopupMenu';
import Toast from 'ui/molecules/Toast';
import Table, { Body, Cell, Header, Head, Row } from 'ui/molecules/WindowedTable';

import useAccessControl, { permissionTypes } from 'utils/hooks/useAccessControl';

const loadQuery = loader('./queries/load.graphql');

//
// Styled components
// -------------------------------------------------------------------------------------------------

const Line = styled.p`
  margin: 0;
`;

const List = styled(VariableSizeList)`
  overflow-x: hidden !important;
`;

const RowActionsContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
`;

const StyledCell = styled(Cell)`
  font-size: ${rem(11)};
  height: 100%;
  vertical-align: ${({ centered }) => (centered ? 'middle' : 'top')};
  word-wrap: break-word !important;

  :not(:first-of-type) {
    padding-left: ${(p) => rem(p.theme.spacing.sm)} !important;
  }

  :not(:last-of-type) {
    padding-right: ${(p) => rem(p.theme.spacing.sm)} !important;
  }
`;

const StyledHeader = styled(Header)`
  :not(:first-of-type) {
    padding-left: ${(p) => rem(p.theme.spacing.sm)} !important;
  }

  :not(:last-of-type) {
    padding-right: ${(p) => rem(p.theme.spacing.sm)} !important;
  }

  ${({ centered }) =>
    centered &&
    css`
      text-align: center;
    `};
`;

const StyledRow = styled(Row)`
  max-width: ${rem(1304)};
`;

const StyledTable = styled(Table)`
  padding-top: ${(p) => rem(p.theme.spacing.md)};
  width: 100%;

  &::after {
    display: none !important;
  }
`;

const WidthTracker = styled.div`
  height: 0;
  opacity: 0;
  width: 100%;
`;

//
// Main Component
// -------------------------------------------------------------------------------------------------

interface Props {
  fetchMore: Function;
  results: Array<any>;
  searchEmailPart: Function;
}

export default function SearchResults({ fetchMore, results = [] }: Props) {
  const history = useHistory();
  const location = useLocation();
  const [expandedRows, dispatch] = useReducer(reducer, new Set());
  const listRef = useRef(null);
  const [tableWidth, setTableWidth] = useState(1304);
  const [currentPath, setCurrentPath] = useState(null);
  const [emlDownloading, setEmlDownloading] = useState(false);
  const [emlDownloadPath, setEmlDownloadPath] = useState(null);

  const { t } = useTranslation('components');

  const windowSize = useWindowSize();

  const { ADMIN, READ_WRITE } = permissionTypes;
  const { loading: accessControlLoading, permissions } = useAccessControl([ADMIN, READ_WRITE]);
  const [adminPermitted, readWritePermitted] = permissions;
  const rowActionsPermitted = adminPermitted || readWritePermitted;

  function handleClose() {
    setCurrentPath(null);
  }

  function handleWidthChange(width) {
    setTableWidth(width);
  }

  function triggerEmlDownload({ mail = {} }) {
    if (!emlDownloadPath) return null;

    const { email } = mail;
    downloadEml(emlDownloadPath, email);
    setEmlDownloading(false);
    return setEmlDownloadPath(null);
  }

  if (accessControlLoading) return null;

  function handleToggle(index: number) {
    return (expanded: boolean) => {
      dispatch({ payload: index, type: expanded ? 'add' : 'remove' });
      if (listRef.current) listRef.current.resetAfterIndex(index);
    };
  }

  function isItemLoaded(index) {
    return index < results.length;
  }

  function itemSize(index) {
    const reasons = (results && results[index] && results[index].detectionReasons) || [];
    const height = Math.max(Math.ceil(reasons.join().length / 32), 5) * 27 + 8;

    if (expandedRows.has(index)) return height;
    return Math.min(height, 182);
  }

  // eslint-disable-next-line no-unused-vars
  async function loadMoreItems(startIndex, stopIndex) {
    try {
      await fetchMore({
        variables: { limit: 50, offset: startIndex },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!prev) return fetchMoreResult;
          return fetchMoreResult
            ? { ...prev, results: [...(prev.results || []), ...(fetchMoreResult.results || [])] }
            : prev;
        },
      });
    } catch (e) {
      // check Sentry for potential errors
    }
  }

  function ItemRow({ data: itemData, index, style }: any) {
    const result = itemData[index];

    function handleDownloadClick() {
      setEmlDownloading(true);
      setEmlDownloadPath(result.storedAt);
    }

    function handleExpandDetectionClick() {
      history.push({
        pathname: `/detection/${result.postfixIdent}`,
        state: {
          background: location,
        },
      });
    }

    function handleLoad(emlDownloadData: any) {
      if (emlDownloadData) triggerEmlDownload(emlDownloadData);
    }

    function handleViewRawDetailClick() {
      setCurrentPath(result.storedAt);
    }

    return (
      <ResultsRow
        actionsPermitted={rowActionsPermitted}
        emlDownloadPath={emlDownloadPath}
        expandedRows={expandedRows}
        handleDownloadClick={handleDownloadClick}
        handleExpandDetectionClick={handleExpandDetectionClick}
        handleViewRawDetailClick={handleViewRawDetailClick}
        index={index}
        onLoad={handleLoad}
        onToggle={handleToggle}
        result={result}
        style={style}
      />
    );
  }

  return (
    <>
      <ResultsTable
        actionsPermitted={rowActionsPermitted}
        onWidthChange={throttle(handleWidthChange, 500, { leading: true })}
      >
        <InfiniteLoader isItemLoaded={isItemLoaded} itemCount={1000} loadMoreItems={loadMoreItems}>
          {({ onItemsRendered, ref }) => (
            <List
              height={windowSize.innerHeight - 82}
              itemCount={results.length}
              itemData={results}
              itemSize={itemSize}
              onItemsRendered={onItemsRendered}
              ref={(el) => {
                listRef.current = el;
                ref(el);
              }}
              width={tableWidth}
            >
              {ItemRow}
            </List>
          )}
        </InfiniteLoader>
      </ResultsTable>

      <Drawer.Modal
        dataTestId="email-content-and-metadata-modal"
        onClose={handleClose}
        visible={currentPath !== null}
        size="full"
      >
        <EmlView path={currentPath} />
      </Drawer.Modal>

      <Toast open={emlDownloading} variant="success">
        {t('MultiSearch.processingFileDownload')}
      </Toast>
    </>
  );
}

//
// Private components
// -------------------------------------------------------------------------------------------------

interface ResultsRowProps {
  actionsPermitted: boolean;
  emlDownloadPath: string;
  expandedRows: Set<number>;
  index: number;
  onLoad: (data: {}) => void;
  onToggle: (index: number) => (expanded: boolean) => void;
  handleDownloadClick: () => void;
  handleExpandDetectionClick: () => void;
  handleViewRawDetailClick: () => void;
  result: {
    bccRecipient: string[],
    cc: string[],
    clientRecipients: string[],
    detectionReasons: string[],
    envelopeTo: string[],
    finalDisposition: string,
    from: string,
    postfixIdent: string,
    storedAt: string,
    subject: string,
    to: string | string[],
    ts: string,
  };
  style: {};
}

function ResultsRow({
  actionsPermitted,
  emlDownloadPath,
  handleDownloadClick,
  handleExpandDetectionClick,
  handleViewRawDetailClick,
  index,
  onLoad,
  result,
  style,
}: ResultsRowProps) {
  const { bccRecipient, cc, detectionReasons, envelopeTo, finalDisposition, from, storedAt, subject, to, ts } = result;

  const { loading: downloadEmlLoading } = useQuery(loadQuery, {
    onCompleted: onLoad,
    skip: storedAt !== emlDownloadPath,
    variables: { path: emlDownloadPath, renderImages: false, waitSeconds: 300 },
  });

  const recipients = getUniqueRecipientsAsString(to, envelopeTo, cc, bccRecipient);

  return (
    <StyledRow data-testid={`result-row-${index}`} style={style}>
      <>
        <StyledCell>
          <FormatTime date={ts} displayTime />
          <PaddedContent pushBottom="sm" />
          <DispositionBadge disposition={finalDisposition} />
        </StyledCell>
        <StyledCell>
          <MaxHeight height={150}>{recipients}</MaxHeight>
        </StyledCell>
        <StyledCell>{from}</StyledCell>
        <StyledCell>{truncate(subject, { length: 200 })}</StyledCell>
        <StyledCell>
          <MaxHeight height={150}>
            {detectionReasons &&
              detectionReasons.map((reason) => <Line dangerouslySetInnerHTML={{ __html: reason }} key={reason} />)}
          </MaxHeight>
        </StyledCell>
        <ConditionalRender condition={!!actionsPermitted} fallback={<StyledCell />}>
          <StyledCell>
            <RowActionsContainer>
              <RowOptions
                downloadEmlLoading={downloadEmlLoading}
                handleDownloadClick={handleDownloadClick}
                handleExpandDetectionClick={handleExpandDetectionClick}
                handleViewRawDetailClick={handleViewRawDetailClick}
                index={index}
                key={`row-menu-${index}`}
                result={result}
              />
            </RowActionsContainer>
          </StyledCell>
        </ConditionalRender>
      </>
    </StyledRow>
  );
}

interface ResultsTableProps {
  actionsPermitted: boolean;
  children: ReactNode;
  onWidthChange: (width: number) => void;
}

function ResultsTable({ actionsPermitted, children, onWidthChange }: ResultsTableProps) {
  const el = useRef();
  const rect = useResizeObserver(el);
  const prevWidth = usePrevious(rect && rect.width);
  const { t } = useTranslation('components');
  const windowSize = useWindowSize();

  const trueWidth = windowSize?.innerWidth || rect.width;
  useEffect(() => {
    if (onWidthChange && trueWidth !== prevWidth) onWidthChange(trueWidth);
  }, [prevWidth, onWidthChange, trueWidth]);

  return (
    <>
      <WidthTracker ref={el} />
      <StyledTable
        data-testid="detections-search-results-table"
        colWidths={[11, 14, 14, 20, 36, actionsPermitted ? 12 : 20, actionsPermitted ? 8 : 0]}
        fixed
        spaced
        width={rect && rect.width}
        zebraStripes
      >
        <Head>
          <StyledRow>
            <>
              <StyledHeader>{t('searchResults:dateAndDisposition')}</StyledHeader>
              <StyledHeader>{t('searchResults:recipientsLabel')}</StyledHeader>
              <StyledHeader>{t('searchResults:from')}</StyledHeader>
              <StyledHeader>{t('searchResults:subject')}</StyledHeader>
              <StyledHeader>{t('searchResults:reasons')}</StyledHeader>
              {actionsPermitted && <StyledHeader />}
            </>
          </StyledRow>
        </Head>
        <Body>{children}</Body>
      </StyledTable>
    </>
  );
}

//
// Popup Menu
// -------------------------------------------------------------------------------------------------
interface RowOptionsProps {
  handleDownloadClick: () => void;
  handleViewRawDetailClick: () => void;
  handleExpandDetectionClick: () => void;
  index: number;
  result: {
    storedAt: string,
  };
}

function RowOptions({
  handleDownloadClick,
  handleExpandDetectionClick,
  handleViewRawDetailClick,
  index = 0,
  result,
}: RowOptionsProps) {
  const { t } = useTranslation('components:MultiSearch');

  return (
    <PopupMenu dataTestId={`row-menu-${index}`}>
      <ConditionalRender condition={!!result.storedAt}>
        <Group>
          <Option dataTestId="view-detection" icon="expand" onClick={handleExpandDetectionClick}>
            {t('searchResults:viewDetection')}
          </Option>
        </Group>
        <Group>
          <Option dataTestId="view-raw-eml" icon="viewDetails" onClick={handleViewRawDetailClick}>
            {t('searchResults:viewEml')}
          </Option>
        </Group>
      </ConditionalRender>
      <Group>
        <Option icon="download" onClick={handleDownloadClick}>
          {t('searchResults:download')}
        </Option>
      </Group>
    </PopupMenu>
  );
}

//
// Private functions
// -------------------------------------------------------------------------------------------------

type Action = { payload: number, type: 'add' | 'remove' };
type State = Set<number>;

/**
 * Reducer function for the `useReducer` hook. Use a `Set` object as its state and makes sure that the state isn't mutated in place.
 */
function reducer(state: State, action: Action) {
  switch (action.type) {
    case 'add':
      state.add(action.payload);
      return new Set<number>([...state]);
    case 'remove':
      state.delete(action.payload);
      return new Set<number>([...state]);
    default:
      throw new Error();
  }
}

const getUniqueRecipientsAsString = (...recipientsArray: Array<string | string[] | null>) => {
  const recipientSet = new Set();
  recipientsArray.forEach((recipients: any) => {
    if (Array.isArray(recipients)) {
      recipients.forEach((recipient: string) => {
        if (recipient) recipientSet.add(recipient);
      });
    }
    if (recipients && typeof recipients === 'string') {
      recipientSet.add(recipients);
    }
  });

  return [...recipientSet].join(', ');
};
