"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledTab = exports.StyledBase = void 0;

var _styled = require("../../styled");

var _chevron = _interopRequireDefault(require("./chevron.svg"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const StyledTab = (0, _styled.styled)('button', {
  backgroundColor: '$white',
  border: 'none',
  color: '$$color',
  flexGrow: '1',
  fontFamily: '$sans',
  fontSize: '$sm',
  fontStretch: '50%',
  padding: '$2 $5',
  position: 'relative',
  textTransform: 'uppercase',
  '&:first-of-type': {
    rl: '$2'
  },
  '&:last-of-type': {
    rr: '$2'
  },
  '&[aria-selected="true"]': {
    backgroundColor: '$$color',
    color: '$white'
  }
});
exports.StyledTab = StyledTab;
const StyledBase = (0, _styled.styled)('div', {
  $$color: '$colors$blue500',
  backgroundColor: '$$color',
  borderColor: '$$color',
  borderStyle: '$solid',
  borderWidth: '$1',
  columnGap: '$borderWidths$1',
  display: 'flex',
  r: '$2',
  variants: {
    appearance: {
      'arrow-down': {
        [`${StyledTab}[aria-selected="true"]`]: {
          '&::after': {
            backgroundColor: '$$color',
            bottom: 0,
            content: '',
            display: 'inline-block',
            height: 8,
            left: '50%',
            maskImage: `url('${_chevron.default}')`,
            position: 'absolute',
            transform: 'translate(-50%, 100%)',
            verticalAlign: 'middle',
            width: 14
          }
        }
      }
    }
  }
});
exports.StyledBase = StyledBase;