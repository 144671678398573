// @flow

import * as Sentry from '@sentry/browser';

import store from '../store';
import { getCurrentUserCustomerId, getCurrentUserEmail } from '../store/selector/user';

const genRanHex = (size) => [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');

let traceId;

export default () => {
  if (!traceId) {
    traceId = genRanHex(32);
  }
  Sentry.configureScope((scope) => {
    scope.setTag('X-TRACE-ID', traceId);
    scope.setTag('email', getCurrentUserEmail(store.getState()));
    scope.setUser({
      id: getCurrentUserCustomerId(store.getState()),
    });
  });
  return traceId;
};
