// @flow

import React from 'react';
import { useTranslation } from 'react-i18next';

import { dataForThreatTypeGraphTable } from '../dataTypesAndUtils';

import { type ThreatType } from './dataTypesAndUtils';

import BlockHeader from 'ui/molecules/BlockHeader';
import ContentBlock from 'ui/molecules/ContentBlock';
import ProgressTable from 'ui/molecules/ProgressTable';
import ProgressTablePlaceholder from 'ui/molecules/ProgressTablePlaceholder';
import withDataHandler from 'utils/withDataHandler';

type Props = {
  data: Array<ThreatType>,
  error: boolean,
  loading: boolean,
};

const ProgressTableWithDataHandler = withDataHandler({
  empty: <ProgressTablePlaceholder />,
})(ProgressTable);

export default function TopThreatTypes({ data, error, loading }: Props) {
  const { t } = useTranslation('email');
  const total = data.reduce((acc, { count }) => {
    return acc + count;
  }, 0);

  return (
    <ContentBlock
      dataTestId="top-threat-types"
      top={
        <>
          <BlockHeader title={t('maliciousThreatTypes')} />
          <ProgressTableWithDataHandler
            /* eslint-disable no-shadow */
            // eslint-disable-next-line no-unused-vars
            calculatePercent={(data, [_, number]) => {
              return parseFloat(number.split('%')[0]) / 100;
            }}
            cellWidths={['50%', '50%']}
            data={dataForThreatTypeGraphTable(data, total, t)}
            formatPercentage
            isEmpty={() => loading || error || !data || data.length === 0}
            isError={error}
            isLoading={loading}
            tint
          />
        </>
      }
    />
  );
}
