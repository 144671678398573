import React, { PropsWithChildren } from 'react';
import { I18nextProvider } from 'react-i18next';

import i18n from 'config/i18next';

export function Providers({ children }: PropsWithChildren<{}>) {
  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
}

export function filterAndSortData({ filterBy, sortBy }, data = []) {
  const other = data.filter((d) => d[filterBy].toLowerCase() === 'other');
  const sorted = data.filter((d) => d[filterBy].toLowerCase() !== 'other').sort((a, b) => b[sortBy] - a[sortBy]);

  return [...sorted, ...other];
}
