// @flow

// $FlowFixMe
import { LinearGradient } from '@a1s/ui';
import { ConfigProvider } from '@marshall/hooks';
import qs from 'qs';
// $FlowFixMe
import React, { createContext, lazy, useState } from 'react';
import { type ContextRouter, Redirect, Route, Switch, useLocation } from 'react-router-dom';

// $FlowFixMe - flow doesn't like .tsx files
import HomeScreen from '../Dashboard';
// $FlowFixMe - flow doesn't like .tsx files
import { DotGrid } from '../Dashboard/ui/DotGrid';
import EmailScreen from '../Email';
// $FlowFixMe - flow doesn't like .tsx files
import SearchScreen from '../Search';

import Header from 'screens/App/Header';
import Footer from 'ui/molecules/Footer';
import type { SearchType, SearchValue } from 'ui/molecules/GlobalSearch';
import { PopupMenuProvider } from 'ui/molecules/PopupMenu';
import PageNotFound from 'ui/organisms/PageNotFound';
import AppTemplate from 'ui/templates/App';
// $FlowFixMe - flow doesn't like .tsx files
import { DurationProvider } from 'utils/duration';
import useAccessControl, { permissionTypes } from 'utils/hooks/useAccessControl';
import { CurrentUserProvider } from 'utils/hooks/useCurrentUser';
import type { CurrentIntervalType } from 'utils/sharedTypes';
import WaitingComponent from 'utils/WaitingComponent';

const AccountabilityScreenPromise = import('../Accountability');
const AccountabilityScreen = lazy(() => AccountabilityScreenPromise);

/*
  I'm leaving this bit of commented code as a reminder to remove
  this hack: https://github.com/facebook/react/issues/15919#issuecomment-503508600
  Once the related bug (https://github.com/facebook/react/issues/14188)
  is fixed in the next release of React.
  Ideally this would just be using lazy and Suspense; due to the bug
  above we're using the WaitingComponent hack, but that breaks the
  "EmailScreen" for some reason.

  const EmailScreenPromise = lazy(() => EmailScreenPromise);
  const EmailScreen = lazy(() => import('../Email'));
*/
const DetectionScreenPromise = import('../DetectionContext');
const DetectionScreen = lazy(() => DetectionScreenPromise);
const LandscapeScreenPromise = import('../Landscape');
const LandscapeScreen = lazy(() => LandscapeScreenPromise);
const PhishguardScreenPromise = import('../Phishguard');
const PhishguardScreen = lazy(() => PhishguardScreenPromise);
const SettingsScreenPromise = import('../Settings');
const SettingsScreen = lazy(() => SettingsScreenPromise);
const SupportScreenPromise = import('../Support');
const SupportScreen = lazy(() => SupportScreenPromise);
const UserScreenPromise = import('../User');
const UserScreen = lazy(() => UserScreenPromise);
const WebScreenPromise = import('../Web');
const WebScreen = lazy(() => WebScreenPromise);

type DaysBackContextType = {
  currentInterval: CurrentIntervalType,
  setDaysBackContext: Function,
};

type GlobalSearchContextType = {
  globalSearchConnector: ?(searchType: SearchType, searchTerm: SearchValue) => void,
  setGlobalSearchConnector: ?(searchTerm: SearchValue) => void,
};

const DEFAULT_DAYS_BACK = 30;

// eslint-disable-next-line no-console
const DEFAULT_GLOBAL_SEARCH_CONNECTOR_CONTEXT = () => console.warn('********* you need to implement this');

export const GlobalSearchContext = createContext<GlobalSearchContextType>({
  globalSearchConnector: null,
  setGlobalSearchConnector: null,
});

export const DaysBackContext = createContext<DaysBackContextType>({
  currentInterval: DEFAULT_DAYS_BACK,
  // eslint-disable-next-line no-unused-vars
  setDaysBackContext: (currentInterval: number) => null,
});

export default function App() {
  const {
    BETTERMAIL_ONLY,
    DELEGATED_ROLES_ENABLED,
    DEMO_ENABLED,
    ENTERPRISE_ENABLED,
    PHISHGUARD_ENABLED,
    RESUME_PARENT_ENABLED,
  } = permissionTypes;

  const [daysBack, setDaysBack] = useState(DEFAULT_DAYS_BACK);

  const location = useLocation();
  const background = location.state && location.state.background;

  const { loading: accessControlLoading, permissions } = useAccessControl([
    BETTERMAIL_ONLY,
    DELEGATED_ROLES_ENABLED,
    DEMO_ENABLED,
    ENTERPRISE_ENABLED,
    PHISHGUARD_ENABLED,
    RESUME_PARENT_ENABLED,
  ]);

  const [bettermailOnly, delegatedRolesAccess, demoEnabled, enterpriseEnabled, phishguardEnabled, resumeParentEnabled] =
    permissions;

  const [globalSearchConnector, setGlobalSearchConnector] = useState(() => DEFAULT_GLOBAL_SEARCH_CONNECTOR_CONTEXT);

  if (accessControlLoading) return null;

  return (
    <>
      <Route>
        {({ match }: ContextRouter) => {
          const { path } = match;
          return (
            <PopupMenuProvider>
              <CurrentUserProvider>
                <ConfigProvider group="client">
                  <GlobalSearchContext.Provider value={{ globalSearchConnector, setGlobalSearchConnector }}>
                    <DaysBackContext.Provider value={{ currentInterval: daysBack, setDaysBackContext: setDaysBack }}>
                      <DurationProvider>
                        <LinearGradient>
                          <DotGrid>
                            <AppTemplate
                              footer={<Footer />}
                              header={<Header />}
                              main={
                                <>
                                  <Switch location={background || location}>
                                    <Route
                                      path="/settings/domains/new"
                                      render={({ location: { search } }) => (
                                        <Redirect
                                          to={{
                                            pathname: '/settings/email/routing/domains',
                                            state: {
                                              drawer: true,
                                              authorizedTenantId: qs.parse(search, { ignoreQueryPrefix: true })?.tenant,
                                            },
                                          }}
                                        />
                                      )}
                                    />
                                    <Route
                                      path="/settings/domains/scanning"
                                      render={({ location: { search } }) => (
                                        <Redirect
                                          to={{
                                            pathname: '/settings/email/routing/domains',
                                            state: {
                                              drawer: true,
                                              scanningTenantId: qs.parse(search, { ignoreQueryPrefix: true })?.tenant,
                                            },
                                          }}
                                        />
                                      )}
                                    />

                                    {!delegatedRolesAccess ? (
                                      <Redirect exact from={path} to={`${path}home`} />
                                    ) : (
                                      <Redirect exact from={path} to={`${path}settings/account-delegation/accounts`} />
                                    )}

                                    {!delegatedRolesAccess && (
                                      <Route
                                        component={WaitingComponent(AccountabilityScreen)}
                                        path={`${path}accountability`}
                                      />
                                    )}
                                    {!delegatedRolesAccess && <Route component={EmailScreen} path={`${path}email`} />}
                                    {!delegatedRolesAccess ? (
                                      <Route component={HomeScreen} path={`${path}home`} />
                                    ) : (
                                      <Redirect
                                        exact
                                        from={`${path}home`}
                                        to={`${path}settings/account-delegation/accounts`}
                                      />
                                    )}

                                    {!delegatedRolesAccess && (
                                      <Route component={WaitingComponent(WebScreen)} path={`${path}web`} />
                                    )}
                                    {!bettermailOnly && !delegatedRolesAccess && enterpriseEnabled && !demoEnabled && (
                                      <Route component={WaitingComponent(LandscapeScreen)} path={`${path}landscape`} />
                                    )}
                                    {!delegatedRolesAccess && phishguardEnabled && (
                                      <Route
                                        component={WaitingComponent(PhishguardScreen)}
                                        path={`${path}phishguard`}
                                      />
                                    )}
                                    {!resumeParentEnabled && (
                                      <Route component={WaitingComponent(UserScreen)} exact path={`${path}user`} />
                                    )}

                                    <Route component={WaitingComponent(SettingsScreen)} path={`${path}settings`} />
                                    <Route component={WaitingComponent(SupportScreen)} path={`${path}support`} />

                                    <Route component={WaitingComponent(DetectionScreen)} path="/detection/:messageId" />
                                    <Route component={SearchScreen} path="/beta/search" />

                                    <Route component={PageNotFound} path="*" status={404} />
                                  </Switch>

                                  {background && (
                                    <Route component={WaitingComponent(DetectionScreen)} path="/detection/:messageId" />
                                  )}
                                  {background && <Route component={SearchScreen} path="/beta/search" />}
                                </>
                              }
                            />
                          </DotGrid>
                        </LinearGradient>
                      </DurationProvider>
                    </DaysBackContext.Provider>
                  </GlobalSearchContext.Provider>
                </ConfigProvider>
              </CurrentUserProvider>
            </PopupMenuProvider>
          );
        }}
      </Route>
    </>
  );
}
