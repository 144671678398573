// @flow

type MaliciousSenderCounts = {
  maliciousSendersTotal: number,
  maliciousTotal: number,
};

export type MaliciousSenderCountsByDataType = {
  count?: number,
  domain?: string,
};

export type MaliciousSenderCountsByDate = { date: number, keys: string[], values: number[] };

export type MaliciousSendersListByName = { count?: number, threatType?: string, sender?: string };

export type PreviousMaliciousSenderCountsByDate = { date: number, keys: string[], values: number[] };

type MaliciousSendersType = {
  maliciousSenderCounts: MaliciousSenderCounts,
  maliciousSenderCountsByDataType: Array<MaliciousSenderCountsByDataType>,
  maliciousSenderCountsByDate: Array<MaliciousSenderCountsByDate>,
  maliciousSendersListByName: Array<MaliciousSendersListByName>,
  previousMaliciousSenderCounts: MaliciousSenderCounts,
  previousMaliciousSenderCountsByDate: Array<PreviousMaliciousSenderCountsByDate>,
};

export const maliciousSendersRecord: MaliciousSendersType = {
  maliciousSenderCounts: { maliciousSendersTotal: 0, maliciousTotal: 0 },
  maliciousSenderCountsByDataType: [],
  maliciousSenderCountsByDate: [],
  maliciousSendersListByName: [],
  previousMaliciousSenderCounts: { maliciousSendersTotal: 0, maliciousTotal: 0 },
  previousMaliciousSenderCountsByDate: [],
};
