// @flow

import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Logo } from '../Login/logo.svg';

import Container from './Container';

import PaddedContent from 'ui/atoms/PaddedContent';
import Title from 'ui/atoms/Title';
import Content from 'ui/molecules/Content';
import ForgotPasswordForm from 'ui/organisms/ForgotPasswordForm';
import LoginTemplate from 'ui/templates/Login';

export default function Expired() {
  const { t } = useTranslation('invitePage');

  return (
    <LoginTemplate
      main={
        <>
          <Helmet title={`${t('inviteExpired')} - ${t('common:area1security')}`} />
          <Container>
            <Logo />
            <PaddedContent pushBottom="md" pushTop="md">
              <Title>{t('inviteExpired')}</Title>
              <Content>
                <p>{t('inviteExpiredCopy')}</p>
              </Content>
              <ForgotPasswordForm />
            </PaddedContent>
          </Container>
        </>
      }
    />
  );
}
