// @flow

import { loader } from 'graphql.macro';
import { get } from 'lodash';
import { useQuery } from 'react-apollo';

const tfaQuery = loader('./CheckTFA.graphql');

const DEFAULT_TFA_ENABLED = true;

export default function useTfaEnabled() {
  const { data, error: tfaError, loading: tfaLoading } = useQuery(tfaQuery);
  const tfaEnabled = get(data, 'tfaEnabled.tfaEnabled', DEFAULT_TFA_ENABLED);
  return { tfaError, tfaLoading, tfaEnabled };
}
