"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parseQueryString = parseQueryString;

var _queryString = require("query-string");

/**
 * Parse a URL [query string](https://en.wikipedia.org/wiki/Query_string) into a Javascript value.
 */
function parseQueryString(value) {
  return (0, _queryString.parse)(value);
}