// @flow

import gql from 'graphql-tag';
import { get } from 'lodash';
import { rem } from 'polished';
import React from 'react';
import { useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import PaddedContent from 'ui/atoms/PaddedContent';
import Content from 'ui/molecules/Content';
import Field from 'ui/molecules/Field';

const Image = styled.div`
  border: ${({ theme }) => theme.colors.antiFlashWhite} solid ${rem(2)};
  border-radius: ${rem(4)};

  img {
    max-width: 100%;
  }
`;

const StyledField = styled(Field)`
  display: flex;
  justify-content: center;
  padding-left: 0px;
`;

const query = gql`
  query TfaSecret {
    tfaSecret @rest(path: "/users/codes", type: "TfaCode") {
      qrcode
    }
  }
`;

export default function QRCode() {
  const { data, loading, error } = useQuery(query, { fetchPolicy: 'network-only' });
  const { t } = useTranslation('invitePage');

  const qrcode = get(data, 'tfaSecret.qrcode', null);

  if (loading) return null;

  if (!loading && error) {
    window.location = '/';
    return null;
  }

  return (
    <PaddedContent dataTestId="login-qrcode" padding="sm" pushBottom="sm" pushTop="md">
      <StyledField>
        <Content>{t('tfaCopy')}</Content>
      </StyledField>
      <StyledField>
        <Image>
          <img alt="SVG" src={`data:image/svg+xml;utf8,${qrcode}`} />
        </Image>{' '}
      </StyledField>
    </PaddedContent>
  );
}
