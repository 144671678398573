// @flow

// $FlowFixMe
import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

import RetractDialog from '.';

import Backdrop from 'ui/atoms/Backdrop';

type Props = {
  buttonText: number,
  destination: string,
  handleRetractConfirm: Function,
  onActionCancel: Function,
  onClose?: (SyntheticEvent<HTMLDivElement>) => void,
  setDestination: Function,
  visible?: boolean,
};

export default function Modal({
  buttonText,
  destination,
  handleRetractConfirm,
  onActionCancel,
  onClose,
  setDestination,
  visible,
  ...props
}: Props) {
  const elRef = useRef(document.createElement('div'));
  const modalRef = useRef(document.getElementById('modal'));

  useEffect(() => {
    const el = elRef.current;
    const modal = modalRef.current;

    if (el && modal) modal.appendChild(el);
    return () => {
      if (el && modal) modal.removeChild(el);
    };
  });

  function handleClick(event: SyntheticEvent<HTMLDivElement>) {
    if (onClose) onClose(event);
  }

  return createPortal(
    <>
      <RetractDialog
        buttonText={buttonText}
        destination={destination}
        handleRetractConfirm={handleRetractConfirm}
        onActionCancel={onActionCancel}
        closed={!visible}
        onClose={onClose}
        setDestination={setDestination}
        {...props}
      />
      <Backdrop invisible={!visible} onClick={handleClick} />
    </>,
    elRef.current
  );
}
