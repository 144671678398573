import { styled } from '@a1s/ui';
import React, { useEffect, ComponentProps, PropsWithChildren } from 'react';

//
// Main container
// -------------------------------------------------------------------------------------------------

type ModalProps = PropsWithChildren<ComponentProps<typeof Container>>;

export function Modal({ children, ...props }: ModalProps) {
  // Prevent the body from being able to scroll while the modal is open.
  useEffect(() => {
    document.body.style.position = 'fixed';

    return () => {
      document.body.style.position = '';
    };
  });

  return <Container {...props}>{children}</Container>;
}

//
// Styled components
// -------------------------------------------------------------------------------------------------

const Container = styled('div', {
  background: '$white',
  borderTop: '$colors$orange500 solid 4px',
  bottom: 0,
  left: 0,
  overflow: 'hidden',
  position: 'fixed',
  right: 0,
  top: 0,
  zIndex: 999,
});
