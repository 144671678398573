"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.dictionary = void 0;

var symbols = _interopRequireWildcard(require("."));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function (nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

/**
 * A object-dictionary intended for symbols to be able to be referenced by string.
 */
const dictionary = {
  add: symbols.Add,
  close: symbols.Close,
  closeCircle: symbols.CloseCircle,
  expandVertical: symbols.ExpandVertical,
  fileDownload: symbols.FileDownload,
  loading: symbols.Loading,
  pageNext: symbols.PageNext,
  trash: symbols.Trash,
  triangleDown: symbols.TriangleDown
};
/**
 * A string in which to reference a symbol by.
 */

exports.dictionary = dictionary;