"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledBase = void 0;

var _styled = require("../../styled");

var _utils = require("../../utils");

const StyledBase = (0, _styled.styled)('div', {
  border: 0,
  display: 'block',
  variants: {
    /**
     * Sets the color of the rule.
     * @type $colors
     */
    color: (0, _utils.tokenVariant)('bg', _styled.theme.colors),

    /**
     * The direction that separator will expand to.
     * @type 'horizontal' | 'vertical'
     */
    direction: {
      horizontal: {
        height: '1px',
        width: 'auto'
      },
      vertical: {
        height: '100%',
        minHeight: '1.5ch',
        width: '1px'
      }
    }
  }
});
exports.StyledBase = StyledBase;