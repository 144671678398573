"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledList = exports.StyledItem = exports.StyledButton = exports.StyledBase = void 0;

var _styled = require("../../styled");

var _chevron = _interopRequireDefault(require("./chevron.svg"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * Container element for the entire `Dropdown` component. Reponsible for setting of
 * the relative positioning context so that the `StyledList` element below can use
 * absolute positioning to align itself.
 */
const StyledBase = (0, _styled.styled)('div', {
  display: 'inline-block',
  position: 'relative'
});
/**
 * Styled element for the opener button. It displays the selected option too.
 */

exports.StyledBase = StyledBase;
const StyledButton = (0, _styled.styled)('button', {
  appearance: 'none',
  backgroundColor: '$gray100',
  border: 'none',
  borderRadius: '$2',
  color: '$gray600',
  cursor: 'inherit',
  fontFamily: '$sans',
  fontSize: '$sm',
  fontStretch: '50%',
  fontWeight: '$medium',
  lineHeight: 'inherit',
  m: 0,
  outline: 0,
  p: '$2 $3',
  position: 'relative',
  textTransform: 'uppercase',
  '&:disabled': {
    color: '$gray400',
    cursor: 'not-allowed'
  },
  '&::after': {
    backgroundColor: '$gray400',
    content: '',
    display: 'inline-block',
    height: 6,
    marginLeft: '$2',
    maskImage: `url('${_chevron.default}')`,
    verticalAlign: 'middle',
    width: 9
  }
});
/**
 * Styled container element for the `Dropdown.Option` component
 */

exports.StyledButton = StyledButton;
const StyledItem = (0, _styled.styled)('li', {
  cursor: 'default',
  fontStretch: '50%',
  fontWeight: '$medium',
  lineHeight: 'inherit',
  m: 0,
  p: '$2 $3',
  r: '$2',
  whiteSpace: 'nowrap',
  '&:hover': {
    backgroundColor: '$gray200',
    color: '$gray700'
  },
  '&[aria-selected=true]': {
    backgroundColor: '$blue500',
    color: '$white'
  }
});
/**
 * Styled container element for all of the options.
 */

exports.StyledItem = StyledItem;
const StyledList = (0, _styled.styled)('ul', {
  appearance: 'none',
  backgroundColor: '$gray100',
  border: 'none',
  boxShadow: '$1',
  color: '$gray600',
  cursor: 'inherit',
  fontFamily: '$sans',
  fontSize: '$sm',
  fontStretch: '50%',
  fontWeight: '$medium',
  left: '$space$1',
  lineHeight: 'inherit',
  listStyle: 'none',
  m: 0,
  outline: 0,
  position: 'absolute',
  p: '$05',
  r: '$2',
  textTransform: 'uppercase',
  top: 'calc(0px - $space$2)',
  zIndex: 999
});
exports.StyledList = StyledList;